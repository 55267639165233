import { Component, ChangeDetectorRef, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ChartService } from 'src/app/shared/services/chart.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MagnifierDialogComponent } from 'src/app/shared/magnifier/magnifier-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BarChartComponent } from 'src/app/shared/basic-charts/bar-chart/bar-chart.component';
import { PieChartComponent } from 'src/app/shared/basic-charts/pie-chart/pie-chart.component';
import { LineChartComponent } from 'src/app/shared/basic-charts/line-chart/line-chart.component';
import { GaugeChartComponent } from 'src/app/shared/basic-charts/gauge-chart/gauge-chart.component';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css'],
    standalone: false
})
export class ChartComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit {

  contextMenuActions: any;
  hoveredNow: boolean = false;
  @Input() isExistingFiltersReadOnly: boolean = false;
  @Output() applyImageHeight = new EventEmitter<any>();
  @ViewChild('filterMenu') filterMenu: MatMenuTrigger
  // trigger: any

  @ViewChild('cover') cover

  records = [];
  heightToApply: number = 0
  currentWidth: number = 0
  chartDimensions: {height?: number, width?: number} = {}
  isChartDataLoaded: boolean = false
  widgetMetaBackup: any
  dataLoadLock: boolean = false
  interruptIterativeDataLoad;

  private destroy:any = new Subject();

  constructor(
    public pageService: PageService,
    public metaService: MetaService,
    private chartService: ChartService,
    // private widgetService: WidgetService,
    private matSnackbar: MatSnackBar,
    public resourcePermissionService: ResourcePermissionService,
    public cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public wmtService: WidgetMetaTransformationService,
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService)
  }

  ngOnInit(): void {
    console.log("widgetMeta", this.widgetMeta)
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.widgetMetaBackup = JSON.parse(JSON.stringify(this.widgetMeta))
    this.chartService.isPreviewMode();
    this.loadChartData()
    this.handleDimensions()
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      if(changes.widgetMeta?.currentValue?.id != changes.widgetMeta?.previousValue?.id){
        this.widgetMetaBackup = JSON.parse(JSON.stringify(this.widgetMeta))
        this.loadChartData(false, true)
        this.handleDimensions()
      }
    }


    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }

    if(changes.widgetMeta?.currentValue && !changes.widgetMeta?.firstChange){
      // console.log("widgetMeta change", changes.widgetMeta)
      if(changes.widgetMeta.currentValue.id == changes.widgetMeta.previousValue.id){
        // console.log("will handle meta change")
        // check if dataSource changed, if yes, load data else skip
        if(this.checkIfDatasourceChanged(this.widgetMetaBackup, changes.widgetMeta?.currentValue)) {
          this.loadChartData()
        }
        this.generateStyles()
        this.handleDimensions()
      }
    }
  }

  ngAfterViewInit(): void {

    this.handleDimensions()
  }

  applyUserFilters(event){
    this.widgetMeta.config.dataSource.filter.filterEnabled = event.filterEnabled
    this.widgetMeta.config.dataSource.filter.filterItems = event.filterItems
    this.widgetMeta.config.dataSource.filter.operator = event.operator
    this.loadChartData()
    this.handleDimensions()
    this.filterMenu?.closeMenu()
  }

  cancelFilter(){
    this.filterMenu?.closeMenu()
  }

  async loadChartData(clearCache? : boolean, skipCache?: boolean){
    // console.log("dataLoadLock", this.dataLoadLock)
    // check if cahced data exists
    let isCache: any = this.chartService.getChartDataCache(this.widgetMeta.id)
    if(isCache.length && !skipCache){
      this.records = [{ "__USECACHED__": true }]  // this will trigger onChanges in child
      this.dataLoadLock = false
      this.isChartDataLoaded = true
      // console.log("records set with cache", JSON.parse(JSON.stringify(this.records)))
      return
    }

    if(this.widgetMeta.config.dataSource.dataSourceType == 'static'){
      this.handleStaticData()
      return
    }

    if(this.dataLoadLock) return
    this.dataLoadLock = true

    let config = JSON.parse(JSON.stringify(this.widgetMeta.config.dataSource))
    console.log("config-->", config)

    if(['count', 'sum', 'max', 'min', 'average'].includes(config.metric.operation) && (config.isBoxSupportGroup || config.boxId == "starch")){
      let operation: string = ''
      operation = config.metric?.operation == 'average' ? 'avg' : config.metric?.operation
      let aggregate: any = {
        groupBy: config.dimensions?.[0]?.attribute,
        aggregateBy: config.metric?.attribute,
        operation: operation
      }
      config['aggregate'] = aggregate
      let res = await this.chartService.loadBoxData(config, clearCache)
      let temp: any = [{ __START__: true, __GROUPED__: true }]
      temp = temp.concat(res?.data || [])
      this.records = temp
      temp.push('__END__')
      if (res?.data.length) this.isChartDataLoaded = true

    } else {
      // set page size
      config.pageSize = 50
      config.pageNumber = 1

      let loopControl = true
      // let repeater = 2
      // while(loopControl && repeater > 0 && this.interruptIterativeDataLoad != true){
      while(loopControl && this.interruptIterativeDataLoad != true){
        // repeater -= 1
        console.log("will load page", config.pageNumber, "of", config.pageSize, "records")
        let temp = []
        temp = [{
          pageNumber: config.pageNumber,
          pageSize: config.pageSize
        }]
        if(config.pageNumber == 1){
          temp[0].__START__ = true
        }
        let res
        try{
          res = await this.chartService.loadBoxData(config)
        }catch(e){
          // TODO : use custom snackbar component
          console.error("error in getting chart data", e)
          this.matSnackbar.open("Chart data could not be fetched", "", {
            duration: 5000
          })
          this.interruptIterativeDataLoad = true
        }
        console.log("chart data loaded for page", config.pageNumber, "with", res?.data?.length, "records")
        temp = temp.concat(res?.data || [])
        // console.log("-----> records", temp)

        if(temp.length < config.pageSize){
          loopControl = false
          temp.push('__END__')
        } else {
          config.pageNumber += 1
        }

        this.records = temp
        if (res?.data.length) this.isChartDataLoaded = true
      }
      // console.log("out of the loop")
    }
    this.records = await this.chartService.applySystemObjectMapping (this.widgetMeta, this.records);
    this.dataLoadLock = false
    if(this.interruptIterativeDataLoad) {
      // console.log("preempted iterative data load")
      this.interruptIterativeDataLoad = false
    }
    this.cdr.markForCheck();
  }




  handleStaticData(){
    let dataSource = this.widgetMeta.config.dataSource

    if(this.widgetMeta.config.chartType.value == 'scorecard'){
      this.isChartDataLoaded = true
      return
    } else {
      if(dataSource?.dataSourceType == 'static' &&
        (!dataSource?.dimensions?.[0]?.attribute?.__id ||
        !this.widgetMeta.config.dataSource?.metric?.attribute.__id ||
        !dataSource.staticData?.length)
      ){
        console.log("incomplete config")
        return
      }

      this.records = [{'__START__': true}]
      this.records.push(...dataSource.staticData)
      this.isChartDataLoaded = true
    }
  }

  async handleDimensions(width?: any){
    if(!this.cover) return
    this.currentWidth = width || this.cover.nativeElement.clientWidth
    this.heightToApply = Math.floor((this.currentWidth / this.widgetMeta.gridX) * this.widgetMeta.gridY)
    // console.log("width", this.currentWidth)
    // console.log("height", this.heightToApply)

    this.chartDimensions = {
      height: this.heightToApply,
      width: this.currentWidth
    }
    // this.applyImageHeight.next(currentHeight)
  }


  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "italic",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
        "stopIterativeDataLoad",
        "edit",
        // "class",
        // "chartType"
      ],
      // chartType: {
      //   value: this.widgetMeta?.config.chartType.value,
      //   availableTypes: this.widgetMeta?.config.chartType.availableTypes,
      //   type: this.widgetMeta?.config.chartType.type
      // }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  async action(event) {
    // console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case 'resize':
        // console.log("existing meta", JSON.parse(JSON.stringify(this.widgetMeta)))
        // console.log("new dimensions", event.newDimensions)
        this.handleDimensions(event.newDimensions?.width)
        this.widgetMeta = event.data
        // this.trigger = new Object()
        this.newWidgetMeta.emit(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      case 'settingsChanged':
      case 'updateStyles':
        // console.log("setting changed, updating widgetMeta")
        this.widgetMeta = JSON.parse(JSON.stringify(event.data))
        this.newWidgetMeta.emit(this.widgetMeta)
        this.generateStyles()
        // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        if(this.checkIfDatasourceChanged(this.widgetMetaBackup, this.widgetMeta)) await this.loadChartData()
        break;
      case 'stopIterativeDataLoad':
        this.interruptIterativeDataLoad = true
      default:
        break;
    }
  }

  stopIterativeDataLoad(){
    this.interruptIterativeDataLoad = true
  }
  refreshChartData(){
    // console.log("refresh chart data hit")
    this.loadChartData(true)
  }

  checkIfDatasourceChanged(oldMeta: any, newMeta: any){
    // console.log("oldDataSource", JSON.parse(JSON.stringify(oldMeta.config.dataSource)))
    // console.log("newDataSource", JSON.parse(JSON.stringify(newMeta.config.dataSource)))
    if(JSON.stringify(oldMeta.config.dataSource) != JSON.stringify(newMeta.config.dataSource)) {
      this.widgetMetaBackup = JSON.parse(JSON.stringify(newMeta))
      // console.log("changed")
      return true
    } else {
      // console.log("no change")
      return false
    }
  }

  onClick(event: any) {
    if(!this.builderMode) return
    this.selectedWidgetId = this.widgetMeta.id
    this.widgetSelection.emit(this.widgetMeta.id)

  }

  onDelete() {
    if(!this.builderMode) return
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  async openMagnifier(event: Event) {
    event.stopPropagation();
    await this.loadChartData(true);
    // Find the active chart based on the chart type
    const activeChartComp = this.getActiveChartComponent();
    
    // Open the magnifier dialog with only the active chart
    this.dialog.open(MagnifierDialogComponent, {
      width: '90vw',
      maxWidth: 'none',
      height: '90vh',
      panelClass: 'magnifier-dialog-container',
      data: { 
        content: activeChartComp,
        inputs: {
          widgetMeta: this.widgetMeta,
          chartDimensions: this.chartDimensions,
          records: this.records,
          builderMode: this.builderMode,
          magnifierView: true
        }
      }
    });
  }

  private getActiveChartComponent() {
    // Determine which chart is active based on the chart type
    const chartType = this.widgetMeta?.config?.chartType?.value;
    console.log('chartType', chartType)
    
    let activeChartComp = null;
    
    switch (chartType) {
      case 'pie':
        activeChartComp = PieChartComponent;
        break;
      case 'bar':
        activeChartComp = BarChartComponent;
        break;
      case 'line':
        activeChartComp = LineChartComponent;
        break;
      case 'gauge':
        activeChartComp = GaugeChartComponent;
        break;
    }
    console.log('Active chart', activeChartComp)
    
    return activeChartComp;
  }
}
