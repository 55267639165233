<mat-form-field [appearance]="appearance" [floatLabel]="value?'always':'auto'">
  <input matInput #trigger="matAutocompleteTrigger" [formControl]="iconPickerControl" [placeholder]="value" [matAutocomplete]="auto" (keyup)="checkEnter($event)">
  <mat-label>{{label}}</mat-label>
  <mat-icon *ngIf="showSelectedIcon || value" matPrefix>{{iconPickerControl.value||value}}</mat-icon>
  <button *ngIf="iconPickerControl.value" mat-icon-button matSuffix (click)="clearSelectedIcon()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete (optionSelected)="iconSelected()" #auto="matAutocomplete">
    <mat-optgroup *ngIf="iconPickerControl.value=='';else allIcons" label="Popular Icons">
      <mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByFn" [value]="option.name">
        <mat-icon>{{option.name}}</mat-icon> {{option.name}}
      </mat-option>
    </mat-optgroup>
    <ng-template #allIcons>
      <mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByFn" [value]="option.name">
        <mat-icon>{{option.name}}</mat-icon> {{option.name}}
      </mat-option>
    </ng-template>
  </mat-autocomplete>
