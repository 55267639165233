import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { GoogleiconPickerService } from './googleicon-picker.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-googleicon-picker',
    templateUrl: './googleicon-picker.component.html',
    styleUrls: ['./googleicon-picker.component.scss'],
    standalone: false
})
export class GoogleiconPickerComponent implements OnInit {

  //Observable
  filteredOptions: any;
  //FormControl
  iconPickerControl = new UntypedFormControl('');

  lastFilteredIcons: any[] = []

  /**'legacy' | 'standard' | 'fill' | 'outline'*/
  @Input() appearance: any = 'standard';
  @Input() value: string = '';
  //Flag
  showSelectedIcon: boolean = false;
  //Output
  @Output() iconSelectionChanged: EventEmitter<string> = new EventEmitter();
  //Input
  private _label: string = '';
  @Input() set label(v) { this._label = v; }
  get label() { return this._label; }

  @ViewChild('trigger') autocomplete: MatAutocompleteTrigger;

  constructor(private _googleIconService: GoogleiconPickerService) { }

  ngOnInit(): void {
    this._googleIconService.getIconsMeta().then(() => {
      // this._googleIconService.IconMeta = res;
      this.filteredOptions = this.iconPickerControl.valueChanges.pipe(startWith(''),
        map((value:any) => value?.length == 0 ? this._googleIconService.topTenPopularIcons : this._filter(value)),
      );
    });
  }

  private _filter(value: any) {
    let filterValue: any;
    if (value == '' || value == undefined) { this.showSelectedIcon = false; }
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }

    let arr = this._googleIconService.iconMeta.filter((option: any) => option.tags.find((element: string) => element.includes(filterValue)));
    this.lastFilteredIcons = arr
    return arr
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  iconSelected() {
    this.showSelectedIcon = true;
    this.iconSelectionChanged.emit(this.iconPickerControl.value);
  }

  clearSelectedIcon() {
    this.showSelectedIcon = false;
    this.iconPickerControl.reset('');
  }

  checkEnter(event){
    if(event.keyCode == 13){
      let icon = this.lastFilteredIcons.find(icon => icon.name == this.iconPickerControl.value)
      this.autocomplete.closePanel()
      if(icon) this.iconSelectionChanged.emit(this.iconPickerControl.value);
    }
  }

}
