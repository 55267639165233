<h1 mat-dialog-title>Drill Down</h1>
<div mat-dialog-content>
  <p>Use drill down for nested field mappings.</p>
    <div fxLayout="column" fxLayoutGap="15px">
      <mat-form-field class="custom-form-field" style="width: 100%;" appearance="outline">
        <mat-label>Nested field Path</mat-label>
        <input matInput [(ngModel)]="data.__id" placeholder="Use .(dot) operator for nested path." required>
        <mat-hint fxHide="lt-sm">Eg. For Nested object fields use like 'custom_field.field_1' & for array types use with case no. like 'custom_field.0.field_1' etc.</mat-hint>
      </mat-form-field>
      <!-- <span class="form-field-hint" fxHide fxShow.lt-sm>
        Eg. For Nested object fields use like 'custom_field.field_1' & for array types use with case no. like 'custom_field.0.field_1' etc.
      </span> -->
      <mat-form-field appearance="outline">
        <mat-label>Field type</mat-label>
        <mat-select [(ngModel)]="data.dataType">
          <mat-option *ngFor="let type of availableBoxTypes; trackBy: trackByFn" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>  
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="!data.__id" cdkFocusInitial>Add Field</button>
    <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
</div>
