<app-toolbar></app-toolbar>

<mat-sidenav-container

  style="min-height: 100%"
  [ngClass]="{'h-100': isFullScreen }"
  dir="{{ themeService.textDirection }}"
  [hasBackdrop]="sidenavService.mobileQuery?.matches"
>
  <mat-sidenav
    class="sidenav-container"
    [mode]="sidenavService.mobileQuery?.matches ? 'over' : 'side'"
    [ngStyle.lt-sm]="{ 'width.%': '55', position: 'fixed' }"
    [ngStyle]="{
      'width.em': sidenavService.leftSidenavDesktopWidth
    }"
    [opened]="
      sidenavService.mobileQuery?.matches
        ? false
        : sidenavService.showSideNav
        ? true
        : false
    "

    #leftSideNav
    *ngIf="!hideNavbar && authService.loggedIn && !connectionService.isClientPlatform"
  >
    <app-leftnav></app-leftnav>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-container">
    <!-- <app-toolbar></app-toolbar> -->
    <div  [ngClass]="{'hide-scrollbar': hideScrollbar}" [ngClass]="{ 'sidenav-content': !clientPlatformService.clientType }">
      <router-outlet> </router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <div *ngIf="hideNavbar && !connectionService.isClientPlatform">
  <router-outlet> </router-outlet>
</div> -->

<app-footer *ngIf="!(clientPlatformService.clientType) && !(router.url.includes('/p/'))"></app-footer>
