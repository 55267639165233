import { Component, Input, OnInit, OnChanges, Output, ViewChild, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MetaService } from '../../../services/meta-service';
import { Router, ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { NavigationAction } from "../../../models/Action/NavigationAction";
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.css'],
    standalone: false
})
export class LinkComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  styles: any;
  // @ViewChild('menuTrigger') linkMenuTrigger: MatMenuTrigger
  @ViewChild('textEditInput', { static: false }) private _textEditInput: ElementRef;
  textEditMode: boolean = false;
  prevent: boolean = false;
  timer: any = 0;
  private destroy:any = new Subject();
  isBrowser: boolean;

  constructor(
    public metaService: MetaService,
    private _router: Router,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    private navigationAction: NavigationAction,
    public wmtService: WidgetMetaTransformationService,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      super(metaService, pageService, resourcePermissionService, wmtService)
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    // super call should be the first statement in any derived class constructor
    //if property injections from parent needs to be referenced, (same copy), it needs to be passed into super. e.g. super(router)

  }

  ngOnInit(): void {
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    //setting default value if the value doesn't exist
    if ((this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') && this.widgetMeta?.config?.defaultValue?.value) {
      this.widgetMeta.config.value.value = this.widgetMeta.config.defaultValue.value
    }

    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in link", widgetMeta)
      this.decompressMetaAndAssign(widgetMeta)
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    // if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
    //   if (this.linkMenuTrigger && this.linkMenuTrigger.menuOpen) {
    //     this.linkMenuTrigger.closeMenu()
    //   }
    // }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  //------------------METHODS-----------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
        "class",
        "edit",
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = JSON.parse(JSON.stringify(event.data));
          //delete linkConfig if present
          if(this.widgetMeta.config.linkConfig){
            delete this.widgetMeta.config.linkConfig
          }
          super.generateStyles()
          // this.newWidgetMeta.emit(this.widgetMeta)
          if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;
      default: break;
    }
  }

  onClick(event: any) {
    console.log("link clicked")
    let currentBloomCode;
    this.metaService.get_bloomMeta.subscribe((data) => {
      currentBloomCode = data;
      console.log(currentBloomCode)
    });
    // event.stopPropagation()
    if (!this.builderMode) {
      let linkUrl
      //if same site navigation, use angular router
      //if cross site navigation, replace window.loaction.href
      if(this.isValidUrl(this.widgetMeta.config.value.value)){ //For external link URL
        window.open(this.widgetMeta.config.value.value, "_blank")
        console.log("can navigate to:", this.widgetMeta.config.value.value)
      }else if(this.widgetMeta.config.value.value == 'Previous Page'){ //For previous page navigation
        console.log(this.pageService.previousUrl)
        linkUrl = this.pageService.previousUrl
      }else{ //For same site page navigation
        linkUrl = "p/" + currentBloomCode.code + "/" + this.widgetMeta.config.value.value
        console.log("can navigate to:", this.widgetMeta.config.value.value)
      }
      if(this.widgetMeta.config.value.parameters && this.widgetMeta.config.value.parameters.length){
        linkUrl = linkUrl + "/q"
        // Reuse navigationAction constructURLParams func to get filter values
        let filters = this.navigationAction.constructUrlParams(this.widgetMeta.config.value.parameters, 'array', "")
        console.log("filters", filters)
        filters.forEach(element => {
          linkUrl = linkUrl + "/" + element.attributeId + "/" + element.value;
        });
      }
      if(linkUrl) this._router.navigate([linkUrl])

    }
    // else {
    //   //do not navigate, let context menu open
    //   if (this.linkMenuTrigger.menuOpen) {
    //     this.linkMenuTrigger.closeMenu()
    //   } else {
    //     this.linkMenuTrigger.openMenu()
    //   }
    // }

    this.widgetSelection.emit(this.widgetMeta.id)
  }
  isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  onOuterClick() {
    console.log("link clicked in widget")
    if (!this.builderMode) {
      return;
    }
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    // this.linkMenuTrigger.closeMenu();
  }
  onDoubleClicked() {
    console.log('Double Click');
    if (!this.builderMode) {
      this.textEditMode = false;
      return;
    }
    clearTimeout(this.timer);
    // this.prevent = true;
    this.turnOnEditMode();
  }

  turnOnEditMode() {
    this.textEditMode = true;
    setTimeout(() => { this._textEditInput.nativeElement.focus(); }, 0);
  }
  onFocusOut(event: any) {
    this.textEditMode = false;
  }
}
