import { BaseValidator } from "./BaseValidator"

export class MinValueValidator extends BaseValidator{
    id = "minvalue"
    icon =  ""
    name = "Minimum Value"
    config = {
        inputParams: {
            params: ['min', 'customErrorMessage'],
            customErrorMessage: {
                value : "Minimum value criteria failed",
                displayName: "Custom Error Message",
                type: 'string'
            },
            min: {
                value : 0,
                displayName: "Minimum Value",
                type: 'number'
            }
        }
    }

    public validate(val:number, inputParams:any){
        return (val >= inputParams.min.value)
    }
    public getValidatorConfig(){
        return this.config
    }
    public getDetails(){
        return {
            id: this.id,
            icon: this.icon,
            name: this.name
        }
    }
}