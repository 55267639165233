<div style="width: 100%; padding: .5rem">
  <div *ngIf="!hideToggle">
    <mat-slide-toggle
      [checked]="sort.sortEnabled"
      (change)="sortToggleChanged($event)"
      color="primary"
    > Apply Sort </mat-slide-toggle>
    <div style="height: 1rem"></div>
  </div>

  <div *ngIf="sort.sortEnabled" fxLayout="column" fxLayoutAlign="space-around center" style="width: 95%" fxLayoutGap=".5rem">
    <div *ngFor="let sort of sort.sortAttributes; let i = index; trackBy: trackByFn" style="width: 100%" fxLayoutAlign="space-around center">
      <mat-card appearance="outlined" fxFlex="100">
        <div class="sortSubPartWrap" fxLayoutAlign="start center" fxLayoutGap=".5rem">
          <div class="sortSubPart" *ngIf="selectSortAttributeForm.get('form0')" fxFlex="70" style="width: 100%;">
            <form [formGroup]="selectSortAttributeForm" style="width: 100%;">
              <mat-form-field class="noBottomMargin" style="margin-bottom: -1.25em;">
                <mat-label>Attribute</mat-label>
                <input
                  #boxInput
                  type="text"
                  aria-label="attribute autocomplete"
                  matInput
                  (focus)="setSortList(i)"
                  formControlName="{{'form' + i}}"
                  [matAutocomplete]="auto2"
                >
                <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectSortAttributeForm.get('form0').patchValue('')">
                  <mat-icon class="closeIcon">close</mat-icon>
                </button>
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto2="matAutocomplete"
                  [displayWith]="displayFn"
                  (optionSelected)="sortAttrSelected($event, sort, i);"
                >
                  <mat-option *ngFor="let attr of attributesForSort | async; trackBy: trackByFn" [value]="attr" [disabled]="isAttributeDisabled(attr)">
                    <span>{{ attr.name || attr.__id}}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>

          <div class="sortSubPart" fxFlex="25">
            <button
                mat-icon-button
                [matTooltip]="sort.order === 'DESC' ? 'Sort Descending' : 'Sort Ascending'"
                [matMenuTriggerFor]="sortMenu"
                #menuTrigger="matMenuTrigger"
            >
                <mat-icon [style.transform]="sort.order === 'DESC' ? 'rotate(0deg)' : 'rotate(180deg)'">sort</mat-icon>
            </button>
        
            <mat-menu #sortMenu="matMenu">
                <button mat-menu-item (click)="sortOrderChanged('ASC', sort, i); $event.stopPropagation()" matTooltip="Sort Ascending" [disabled]="!sort.attribute">
                    <mat-icon [style.transform]="'rotate(180deg)'">sort</mat-icon><span>Ascending</span>
                </button>
                <button mat-menu-item (click)="sortOrderChanged('DESC', sort, i); $event.stopPropagation()" matTooltip="Sort Descending" [disabled]="!sort.attribute">
                    <mat-icon>sort</mat-icon><span>Descending</span>
                </button>
            </mat-menu>
        </div>

          <!-- <div class="sortSubPart" fxFlex="45">
            <mat-radio-group [disabled]="!sort.attribute" (change)="sortOrderChanged($event, sort)" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap=".5rem">
              <mat-radio-button class="sortOrderRadioItem" color="primary" [checked]="sort.order == 'ASC'" [value]="'ASC'">Ascending</mat-radio-button>
              <mat-radio-button class="sortOrderRadioItem" color="primary" [checked]="sort.order == 'DESC'" [value]="'DESC'">Descending</mat-radio-button>
            </mat-radio-group>
          </div> -->

        </div>
      </mat-card>

      <div fxFlex="10" fxLayout="column" fxLayoutGap=".5rem" fxLayoutAlign="space-around center">
        <button *ngIf="enableSecurity" mat-icon-button color="acent" fxLayoutAlign="center center" (click)="sort.isSecurity = !sort.isSecurity">
          <mat-icon>disabled_visible</mat-icon>
        </button>
        <button fxLayout="column" mat-icon-button color="warn" fxLayoutAlign="center center" (click)="removeSort(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <app-security-config *ngIf="sort.isSecurity" [meta]="sort" [isNoEffect]="true">
      </app-security-config>

    </div>
  </div>

  <div *ngIf="sort.sortEnabled">
    <div style="height: 1rem"></div>
    <button  mat-stroked-button color="primary" (click)="addSortItem()">
      <mat-icon>add</mat-icon> Add Sort
    </button>
  </div>

  <div *ngIf="sort.sortEnabled">
    <div style="height: 1rem"></div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap=".5rem" style="margin-right: 5%;">
      <button *ngIf="supportApplyButton" mat-stroked-button color="warn" (click)="cancelSorts()">
        Cancel
      </button>
      <button *ngIf="supportApplyButton" mat-raised-button color="primary" (click)="applySort()">
        Apply Sort
      </button>
    </div>
  </div>
</div>
