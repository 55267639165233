import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { BallAtomSpinnerComponent } from '../ball-atom-spinner/ball-atom-spinner.component';
import { SpinnerService } from '../spinner.service';

import { SkeletonElement } from '../skeleton/skeleton.component';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: false
})
export class SpinnerComponent extends BallAtomSpinnerComponent implements OnInit {
  @Input() type = '';
  @Input() text = 'Hang tight...';
  @Input() selfSpin : boolean= false;
  // @Input() appearance : Appearance;
  @Input() skeletonSpinnerGridMap: SkeletonElement[] = [];
  @Input() skeletonPresetCode: string;
  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) { super(spinnerService, themeService); }

  ngOnInit(): void { }
}
