import {
  Injectable,
} from '@angular/core';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { SystemDataService } from './system-data.service';
import { MetaService } from 'src/app/bloom/services/meta-service';

interface CHART_DATA_BOX_INFO {
  connectionId: string,
  boxName: string,
  boxId: string
  boxObjectId: string,
  getFnOptions: any[],
  attributeOptions?: any[]
}

type DataCacheItem = {
  widgetId: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  colorPalette: any[] = [
    '#1B9C85', '#FF6D60', '#47B39C', '#3C9D4E', '#7031AC',
    '#C94D6D', '#E4BF58', '#4174C9', '#F47A1F', '#FDBB2F',
    '#377B2B', '#7AC142', '#007CC3', '#00529B', '#52D726',
    '#FFEC00', '#FF7300', '#FF0000', '#007ED6', '#7CDDDD',
    '#0029D9', '#00CCFF', '#FF1106', '#3EF400', '#4C0C7B',
    '#C61CA4', '#FF69B3', '#EC3499', '#000081', '#1974D1'
  ]

  builderMode: boolean = false;
  precision: any;
  previewMode: any;
  chartDataCache: DataCacheItem[] = []

  constructor(
    private boxService: BoxService,
    public systemDataService: SystemDataService,
    public metaService: MetaService
  ) {}

  setChartBoxConfig(widgetMeta: any, dataConfig: CHART_DATA_BOX_INFO) {
    console.log("set chart box config")
    if(!widgetMeta.config.dataSource) {
      console.log("cant set data source")
      throw new Error("cant set data source")
    }
    widgetMeta.config.dataSource.dataSourceType = 'dynamic'
    // Object.assign(widgetMeta.config.dataSource, dataConfig)
    for (const property in dataConfig) widgetMeta.config.dataSource[property] = dataConfig[property]
    console.log("box config assigned", JSON.parse(JSON.stringify(widgetMeta)))
    return widgetMeta
  }


  setChartDataCache(widgetId: string, data: any[]){
    // console.log("setting chart data cache for", widgetId, "data", data)
    for (let i = 0; i < this.chartDataCache.length; i++) {
      if(this.chartDataCache[i].widgetId == widgetId){
        this.chartDataCache[i].data = data
        return
      }
    }
    this.chartDataCache.push({
      widgetId: widgetId,
      data: data
    })
    console.log("chart cache now", this.chartDataCache)
  }

  getChartDataCache(widgetId: string){
    console.log("getting chart data cache for", widgetId, "data", this.chartDataCache)
    for (let i = 0; i < this.chartDataCache.length; i++) {
      if(this.chartDataCache[i].widgetId == widgetId){
        return this.chartDataCache[i].data
      }
    }
    return []
  }

  isPreviewMode(){
    this.previewMode = this.metaService.previewMode;
    this.metaService.previewMode;
  }


  /**
 * Constructs a mapping for system objects in the given records.
 *
 * This function iterates through the `dimensions` inside `widgetMeta.config.dataSource` and checks if
 * any dimension is configured to use system objects. If a match is found, it fetches the system object data
 * and replaces the corresponding values in `records`.
 *
 * This ensures that instead of showing raw IDs or codes, meaningful system object names/values
 * are displayed in the UI.
 *
 * @param widgetMeta - Metadata that includes configuration details about data sources.
 * @param records - Array of records that need to be updated with system object values.
 * @returns Updated records with system object values replaced where applicable.
 */
async applySystemObjectMapping (widgetMeta, records) {
  for (const element of widgetMeta.config.dataSource?.dimensions) {
      // Check if the dimension is set to use system objects and has a valid systemObjectSemantic & attribute ID
      if (element?.useSystemObjects && element?.systemObjectSemantic && element?.attribute?.__id) {
          // Fetch the system object mappings for the given semantic type
          let systemObjectsValueMap = await this.systemDataService.getSystemData(element.systemObjectSemantic);
          records.forEach(item => {
              if (element.attribute.__id && systemObjectsValueMap[item[element.attribute.__id]]) {
                  // Replace the original value with the corresponding system object value
                  item[element.attribute.__id] = systemObjectsValueMap[item[element.attribute.__id]];
              }
          });
      }
  }
  // Return the updated records with system object mappings applied
  return records;
}

  clearChartCacheForWidget(widgetId: string){
    let i = this.chartDataCache.findIndex(cacheItem => cacheItem.widgetId == widgetId)
    this.chartDataCache.splice(i, 1)
    console.log("chart data cache cleared for widget", this.chartDataCache)
  }

  checkDataSourceChange(oldMeta: any, newMeta: any){
    console.log("old meta", oldMeta)
    console.log("new meta", newMeta)
    if(JSON.stringify(oldMeta.config.dataSource) !== JSON.stringify(newMeta.config.dataSource)){
      console.log("DATASOURCE CHANGED")
    }else{
      console.log("NO DATASOURCE CHANGE")
    }
  }

  /**
   * @param precision : floating point precision to maintain in next calculation
   */
  setPrecision(precision: any){
    if(typeof precision != 'number') this.precision = !isNaN(parseInt(precision)) ? parseInt(precision) : 0
    else this.precision = Math.round(precision)
  }

  /**
   *
   * @param records: if records are passed, they will be directly added for calculation
   * @param config: if config is passed, box data will be fetched and then used for calculation based on config
   * @returns readyData: array of array
   */
  async prepareScorecardData(records?: any[], config?: any){
    // console.log("[CHART SERVICE] prepareScorecardData(): records:", records)
    // console.log("[CHART SERVICE] prepareScorecardData(): config:", config)

    if(!records?.length && config){
      let response = await this.loadBoxData(config)
      records = response.data
    }
    // console.log("[SCORE CARD DATA] box data", records)
    let readyData = this.processScorecardData(config, records)
    return readyData
  }

  processScorecardData(config, records, isGrouped: boolean = false){
    let metricAttrId = config.metric?.attribute?.__id
    if(!metricAttrId || !Array.isArray(records)) return []
    let skimmedData = []

    if(isGrouped){
      console.log("isGrouped true: records", records)
      // records.forEach(record => {
      //   if(record.hasOwnProperty(dimensionAttrId)){
      //     let chartDataItem = [String(record[dimensionAttrId]), record[config?.metric?.operation || ""]]
      //     skimmedData.push(chartDataItem)
      //   }
      // })
      let valueKey = config?.metric?.operation == 'average' ? 'avg' : config?.metric?.operation
      let score =  records[0]?.[valueKey] || records[0]?.[`${metricAttrId} ${config?.metric?.operation}`];
      skimmedData.push([metricAttrId, score])
      console.log("skimmedData", skimmedData)
      return skimmedData
    }

    if (config.metric.operation != 'no_op'){
      // console.log("records", records)
      let recordsObj = {}
      recordsObj[metricAttrId] = records
      skimmedData = this.aggregator(recordsObj, config.metric.operation, metricAttrId)
    }
    // console.log("SCORE CARD processed data", skimmedData)
    return skimmedData
  }

  /**
   *
   * @param chartData: type is return type of "new google.visualization.DataTable();"
   * @param records: if records are passed, they will be directly added to chartData
   * @param config: if config is passed and no records, then box data will be fetched and then added to chartData based on config
   * @returns
   */
  async preparePieData(chartData: any, records?: any[], config?: any){
    // console.log("[CHART SERVICE] preparePieData(): records:", records)
    // console.log("[CHART SERVICE] preparePieData(): config:", config)
    if(!chartData) return null
    chartData.addColumn('string', config?.dimensions?.[0]?.attribute?.name || "Item");
    chartData.addColumn('number', config?.metric?.attribute?.name || "Amount");

    if(!records?.length && config){
      let response = await this.loadBoxData(config)
      records = response.data
    }
    let readyChartData = this.processPieData(config, records)
    chartData.addRows(readyChartData)

    return chartData
  }

  processPieData(config, records, isGrouped: boolean = false){
    let dimensionAttrId = config.dimensions?.[0]?.attribute?.__id
    let metricAttrId = config.metric?.attribute?.__id
    if(!dimensionAttrId || !metricAttrId || !Array.isArray(records) || !records?.length) return []
    let skimmedData = []

    if(isGrouped){
      records.forEach(record => {
        if(record.hasOwnProperty(dimensionAttrId)){
          let chartDataItem = [String(record[dimensionAttrId]),  record[`${metricAttrId} ${config?.metric?.operation}` || `${config?.metric?.operation}`]]
          skimmedData.push(chartDataItem)
        }
      })
      return skimmedData
    }

    if (config.metric.operation != 'no_op'){
      // group by nameAttrId
      let groupedData = this.groupData(records, dimensionAttrId)
      // console.log("grouped data", groupedData)
      skimmedData = this.aggregator(groupedData, config.metric.operation, metricAttrId)

    } else {
      records.forEach(record => {
        if(record[dimensionAttrId]) skimmedData.push([record[dimensionAttrId], this.sanitizeValue(record[metricAttrId])])
      })
    }
    // console.log("pie chart data from box", skimmedData)
    return skimmedData
  }

  /**
   *
   * @param chartData
   * @param records
   * @param config
   * @returns
   */
  async prepareBarData(chartData: any, records?: any[], config?: any){
    // console.log("prepareBarData(): isNew:", isNew)
    if(!chartData) return null

    if(!records?.length && config){
      let response = await this.loadBoxData(config)
      records = response.data
    }
    let readyChartData = this.processBarData(config, records)
    chartData.addRows(readyChartData)
    return chartData
  }

  /**
   *
   * @param config
   * @param records
   * @returns
   */
  processBarData(config, records, isGrouped: boolean = false){
    let dimensionAttrId = config.dimensions?.[0]?.attribute?.__id
    let metricAttrId = config.metric?.attribute?.__id
    if(!dimensionAttrId || !metricAttrId || !Array.isArray(records) || !records?.length) return []
    let skimmedData = []

    if(isGrouped){
      records.forEach(record => {
        if(record.hasOwnProperty(dimensionAttrId)){
          let chartDataItem = [String(record[dimensionAttrId]), record[`${metricAttrId} ${config?.metric?.operation}`] || record[`${config?.metric?.operation}`] || record[metricAttrId]]
          skimmedData.push(chartDataItem)
        }
      })
      // skimmedData = records
      return skimmedData
    }

    if (config.metric.operation != 'no_op'){
      // group by nameAttrId
      let groupedData = this.groupData(records, dimensionAttrId)
      // console.log("grouped data", groupedData)
      skimmedData = this.aggregator(groupedData, config.metric.operation, metricAttrId)
    } else {
      records.forEach(record => {
        if(record[dimensionAttrId]) skimmedData.push([record[dimensionAttrId], this.sanitizeValue(record[metricAttrId])])
      })
    }
    // console.log("processed bar chart data", skimmedData)
    return skimmedData
  }


  async prepareLineData(chartData: any, records?: any[], config?: any){
    // console.log("[CHART SERVICE] prepareLineData(): records:", records)
    // console.log("[CHART SERVICE] prepareLineData(): config:", config)
    if(!chartData) return null
    chartData.addColumn('string', config?.dimensions?.[0]?.attribute?.name || "Item");
    chartData.addColumn('number', config?.metric?.attribute?.name || "Amount");

    if(!records?.length && config){
      let response = await this.loadBoxData(config)
      records = response.data
    }
    let readyChartData = this.processLineData(config, records)
    chartData.addRows(readyChartData)

    return chartData
  }

  /**
   *
   * @param config
   * @param records
   * @returns
   */
  processLineData(config, records, isGrouped: boolean = false){
    let dimensionAttrId = config.dimensions?.[0]?.attribute?.__id
    let metricAttrId = config.metric?.attribute?.__id
    if(!dimensionAttrId || !metricAttrId || !Array.isArray(records) || !records?.length) return []
    let skimmedData = []

    if(isGrouped){
      records.forEach(record => {
        if(record.hasOwnProperty(dimensionAttrId)){
          let chartDataItem = [String(record[dimensionAttrId]), record[config?.metric?.operation || ""]]
          skimmedData.push(chartDataItem)
        }
      })
      return skimmedData
    }

    if (config.metric.operation != 'no_op'){
      // group by nameAttrId
      let groupedData = this.groupData(records, dimensionAttrId)
      // console.log("grouped data", groupedData)
      skimmedData = this.aggregator(groupedData, config.metric.operation, metricAttrId)

    } else {
      records.forEach(record => {
        if(record[dimensionAttrId]) skimmedData.push([record[dimensionAttrId], this.sanitizeValue(record[metricAttrId])])
      })
    }
    // console.log("pie chart data from box", skimmedData)
    return skimmedData
  }

  async prepareGaugeData(chartData: any, records?: any[], config?: any){
    // console.log("[CHART SERVICE] prepareGaugeData(): records:", records)
    // console.log("[CHART SERVICE] prepareGaugeData(): config:", config)
    if(!chartData) return null
    chartData.addColumn('string', config?.dimensions?.[0]?.attribute?.name || "Item");
    chartData.addColumn('number', config?.metric?.attribute?.name || "Amount");

    if(!records?.length && config){
      let response = await this.loadBoxData(config)
      records = response.data
    }
    let readyChartData = this.processGaugeData(config, records)
    chartData.addRows(readyChartData)

    return chartData
  }

  /**
   *
   * @param config
   * @param records
   * @returns
   */
  processGaugeData(config, records, isGrouped: boolean = false){
    let dimensionAttrId = config.dimensions?.[0]?.attribute?.__id
    let metricAttrId = config.metric?.attribute?.__id
    if(!dimensionAttrId || !metricAttrId || !Array.isArray(records) || !records?.length) return []
    let skimmedData = []

    if(isGrouped){
      records.forEach(record => {
        if(record.hasOwnProperty(dimensionAttrId)){
          let chartDataItem = [String(record[dimensionAttrId]), record[config?.metric?.operation || ""]]
          skimmedData.push(chartDataItem)
        }
      })
      return skimmedData
    }

    if (config.metric.operation != 'no_op'){
      // group by nameAttrId
      let groupedData = this.groupData(records, dimensionAttrId)
      // console.log("grouped data", groupedData)
      skimmedData = this.aggregator(groupedData, config.metric.operation, metricAttrId)

    } else {
      records.forEach(record => {
        if(record[dimensionAttrId]) skimmedData.push([record[dimensionAttrId], this.sanitizeValue(record[metricAttrId])])
      })
    }
    // console.log("gauge chart data from box", skimmedData)
    return skimmedData
  }

  sanitizeValue(val: any){
    if(!val) val = 0
    else if(typeof val == 'string' && !isNaN(parseInt(val))) val = parseInt(val)
    else if(typeof val != 'number') val = 0

    return val
  }

  groupData(records: any[], groupByField: string){
    let result = records.reduce(function (accumulator, element) {
      if(element[groupByField]){
        accumulator[element[groupByField]] = accumulator[element[groupByField]] || [];
        accumulator[element[groupByField]].push(element);
      }else{
        accumulator['__ungrouped'] = accumulator['__ungrouped'] || [];
        accumulator['__ungrouped'].push(element);
      }
      return accumulator;
    }, Object.create(null));

    return result
  }

  aggregator(groupedData: any, operation: string, valueAttrId: string){
    let data: any[] = []

    if(operation == 'sum'){
      Object.keys(groupedData).forEach(key => {
        let sum = groupedData[key].reduce((sum, element) => {
          let val = this.sanitizeValue(element[valueAttrId])
          return sum + val
        }, 0);
        data.push([key, Number(sum.toFixed(this.precision))])
      })

    } else if(operation == 'average') {
      Object.keys(groupedData).forEach(key => {
        let sum = groupedData[key].reduce((sum, element) => {
          let val = this.sanitizeValue(element[valueAttrId])
          return sum + val
        }, 0);
        data.push([key, Number((sum / groupedData[key].length).toFixed(this.precision))])
      })

    } else if(operation == 'count') {
      Object.keys(groupedData).forEach(key => {
        data.push([key, groupedData[key].length])
      })

    } else if(operation == 'min') {
      Object.keys(groupedData).forEach(key => {
        let minInGroup = this.sanitizeValue(groupedData[key]?.[0]?.[valueAttrId])
        groupedData[key].forEach(element => {
          let currentVal = this.sanitizeValue(element[valueAttrId])
          if(currentVal < minInGroup) minInGroup = currentVal
        })
        data.push([key, Number(minInGroup.toFixed(this.precision)) || 0])
      })

    } else if(operation == 'max') {
      Object.keys(groupedData).forEach(key => {
        let maxInGroup = this.sanitizeValue(groupedData[key]?.[0]?.[valueAttrId])
        groupedData[key]?.forEach(element => {
          let currentVal = this.sanitizeValue(element?.[valueAttrId])
          if(currentVal > maxInGroup) maxInGroup = currentVal
        })
        data.push([key, Number(maxInGroup.toFixed(this.precision)) || 0])
      })

    } else if(operation == 'count_distinct') {
      Object.keys(groupedData).forEach(key => {
        let valArr = []
        groupedData[key].forEach(el => valArr.push(el[valueAttrId]))
        let count = [...new Set(valArr)].length
        data.push([key, count])
      })
    }
    // this.precision = undefined
    return data
  }

  merge(oldArr: any[][], newArr: any[][], config, currentPageNumber: number, pageSize: number, recordsLength: number, isScoreData?: boolean){
    // console.log("oldArr", JSON.parse(JSON.stringify(oldArr)))
    // console.log("newArr", JSON.parse(JSON.stringify(newArr)))
    // console.log("operation", config.metric?.operation)
    let operation = config.metric?.operation

    newArr.forEach(item => {
      let matchedItemOld
      if(isScoreData){
        if(oldArr[0] == newArr[0][0]){
          matchedItemOld = oldArr
          console.log("score matched item", matchedItemOld)
        }
      }else{
        matchedItemOld = oldArr.find(existingItem => existingItem[0] == item[0])
      }
      if(matchedItemOld){
        // console.log("old aggregate found", matchedItemOld)
        // console.log("new aggregate", item)
        switch (operation) {
          case 'sum':
          case 'count':
          case 'count_distinct':
            matchedItemOld[1] += item[1]
            break;
          case 'min':
            if(item[1] < matchedItemOld[1]) matchedItemOld[1] = item[1]
            break;
          case 'max':
            if(item[1] > matchedItemOld[1]) matchedItemOld[1] = item[1]
            break;
          case 'average':
            let newAvg = (((currentPageNumber - 1) * pageSize * matchedItemOld[1]) + recordsLength * item[1]) / (((currentPageNumber - 1) * pageSize) + recordsLength)
            item[1] = newAvg
            break;
          case 'no_op':
            matchedItemOld[1] = item[1]
            break;
          default:
            break;
        }
      }else{
        // console.log("no old aggregate found", item)
        if(isScoreData){
          oldArr = item
        }else{
          oldArr.push(item)
        }
      }
    })
    return oldArr
  }

  async loadBoxData(config: any, clearCache?: boolean){
    console.log("config", config)
    let dataBindSetup:any = {
      boxId: config.boxId,
      baseId: config.baseId,
      baseMap: config.baseMap,
      boxConfigToken: config.boxConfigToken,
      connectionId: config.connectionId,
      boxObject: config.boxObjectId,
      pageSize: config.pageSize || 100,
      pageNumber: config.pageNumber || 1,
      getFnOptions: config.getFnOptions || [],
      attributes: [config.dimensions?.[0]?.attribute?.__id, config.metric.attribute.__id],
      filters: config.filter?.filterEnabled && config.filter?.filterItems?.length ? config.filter.filterItems : [],
      // sort: config.sort.sortEnabled && config.sort?.sortAttributes?.length ? config.sort.sortAttributes : []
      aggregate: config.aggregate
    }
    if(config.chartOrder?.field == "dimension"){
      dataBindSetup.sort = `${config.dimensions?.[0]?.attribute?.__id}=${config.chartOrder.order || 'ASC'}`;
    }


    console.log("will load page", dataBindSetup)
    let httpChOptions = {clearCache: clearCache};
    try{
      let boxData = await this.boxService.getAny(dataBindSetup, this.builderMode ? 'preauthenticated_token' : 'user_api_key', httpChOptions)
      console.log("[loadBoxData] box data loaded", boxData)

      if(config.chartOrder?.field == "value"){
        let groupValueField = `${dataBindSetup.aggregate.aggregateBy.__id} ${dataBindSetup.aggregate.operation}`;
        boxData.data = this.sortByValue(boxData.data, config.chartOrder.order, groupValueField)
      }

      return boxData
    }catch(e){
      console.log("error occurred in loading box data", e)
      return null
    }
  }

  sortByValue(arr, order, valueField) {
    try {
      arr.sort((a, b) => {
        const countA = parseInt(a[valueField]);
        const countB = parseInt(b[valueField]);

        if (order === 'ASC') {
            return countA - countB; // Sort in ascending order
        } else if (order === 'DESC') {
            return countB - countA; // Sort in descending order
        }
      });
      return arr;
    } catch (error) {
      return arr;
    }
}

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
