<div>
    <div class="ribbon-event" *ngIf="!hideRibbon">Trigger</div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <img *ngIf="flowMap.trigger.box_id" [src]="flowMap.trigger.box_logo_url" class="trigger-image"
      />&nbsp;
      <span *ngIf="flowMap.trigger.box_id" style="margin: 0px" fxLayoutAlign="center center"
      >
        {{ selectedEventBox?.name || flowMap.trigger.box_id }}
        {{ boxEvent?.name || flowMap.trigger?.event }}
      </span>
      &nbsp;
      <span *ngIf="!flowMap.trigger.box_id"
        style="margin: 0px" fxLayoutAlign="center center">Event</span> &nbsp;
    </div>
    <app-app-selection
      [boxType]="'event Application'"
      [existingBox]="flowMap.trigger.box_id"
      [config]="{ filter: 'supports=event' }"
      (selectedBox)="boxSelected($event)"
    ></app-app-selection>
    <div fxLayout="row">
      <mat-form-field style="width: 80%">
        <mat-icon color="primary" matPrefix>event</mat-icon>
        <mat-label>
          <span>&nbsp;Event</span>
        </mat-label>
        <input
          matInput
          [formControl]="boxEventListingACFrom"
          (focus)="setFilterOptions('trigger_box_event')"
          [matAutocomplete]="autoBoxEvent"
        />
        <button
          *ngIf="boxEventListingACFrom.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="boxEventListingACFrom.patchValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
          #autoBoxEvent="matAutocomplete"
          [displayWith]="displayFuntion.bind(this, 'name')"
          (optionSelected)="
            flowMap.trigger.event = $event.option.value.__id;
            getSourceFields()
          "
        >
          <mat-option
            *ngFor="let option of boxEventListingOptions | async; trackBy: trackByFn"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <app-connection-selection
      *ngIf="flowMap.trigger.box_id"
      [existingCon]="flowMap.trigger.connection"
      [config]="{ box_id: flowMap.trigger.box_id }"
      (selectedConnection)="flowMap.trigger.connection = $event._id"
    >
    </app-connection-selection>

    <!-- <div *ngIf="boxEvent?.options">
      <div *ngFor="let opt of boxEvent.options; trackBy: trackByFn">
        <div fxLayout="row">
          <mat-form-field style="width: 80%" *ngIf="opt.dataType === 'string'">
            <mat-label>{{ opt.name }}</mat-label>
            <input
              matInput
              [(ngModel)]="flowMap.trigger.webhook.optionMap[opt.__id]"
              placeholder="{{ opt.name }}"
            />
          </mat-form-field>
        </div>
      </div>
    </div> -->

    <!-- [action]="stepOptions.action" [action]="boxEvent"  -->
    <shared-parameter-inputs *ngIf="flowMap.trigger.connection && boxEvent?.options"
        [existingInputs]="{'options': flowMap.trigger.webhook.optionMap}"
        [action]="eventOptions"
        [connection]="flowMap.trigger.connection"
        (inputParameter)="inputRecevied($event)"
      ></shared-parameter-inputs>
</div>
