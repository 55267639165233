@if(!hideToggle){  
 <mat-slide-toggle [checked]="filter?.filterEnabled" (change)="filterToggleChanged($event)"
 color="primary"> Apply Filters </mat-slide-toggle>
}
<br>
@if(filter?.filterItems?.length > 1){
    <mat-form-field>
        <mat-label>Relation</mat-label>
        <select matNativeControl (change)="selectOuterCondition($event)" [disabled]="options?.isExistingFiltersReadOnly || options?.isOuterOperatorSelectionDisabled">
        <option value=""></option>
        @for (option of outerCondition; track option) {
            <option [value]="option.value"
                [selected]="selectedOuterCondition === option.value">{{ option.viewValue }}</option>
        }
        </select>
    </mat-form-field>
}
@if(filter?.filterEnabled){
    <app-filter-config
        [filter]="filter"
        [boxObjectAttributes]="filterableAttributes"
        [pageMeta]="pageMeta"
        [enableSecurity]="false"
        [supportApplyButton]="supportApplyButton"
        [fieldListingMap]="fieldListingMap"
        [showAttributePicker]="showAttributePicker"
        [lockFilterType]="lockFilterType"
        [boxObjectFunction]="boxObjectFunction"
        [hideToggle]="hideToggle"
        [maskOperator]="maskOperator"
        (cancelFilter)="cancelFilterInfo()"
        (selectedFilter)="saveFilterConfig($event)"
        (filtersValid)="filterValid($event)"
    ></app-filter-config>
}
@if(isShowFilterGroup && filter?.filterEnabled){
    <app-filter-set-component
        [filter]="filter"
        [boxObjectAttributes]="filterableAttributes"
        [pageMeta]="pageMeta"
        [enableSecurity]="false"
        (selectedFilter)="saveFilterConfig($event)"
    ></app-filter-set-component>
}

@if(supportApplyButton){
    <div style="display: flex; justify-content: flex-end; margin-bottom: 4px; gap: 0.5rem;">
      <button mat-stroked-button color="warn" (click)="cancelFilterInfo()">
        Cancel
      </button>
      <button mat-raised-button color="primary" (click)="applyFilter()">
        Apply Filter
      </button>
    </div>
}
