// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: "development",
  production: false,
  build_number: '$BITBUCKET_BUILD_NUMBER',
  version: `${require('../../package.json').version}-dev`,
  SERVER_BASE_URL: "https://us-central1-appiworks-dev-ci.cloudfunctions.net/workspace-server1/api",
  BOX_URL:"https://us-central1-appiworks-dev-ci.cloudfunctions.net/box-server3/api/box",
  DB_BOX_URL:"https://us-central1-appiworks-dev-ci.cloudfunctions.net/box-server3/api/box",
  DB_BOX_TOKEN: "U2FsdGVkX1+fYQwGkmTUCVmmiGa0M6vdk5r8j6pZ+UWMz/NjaGgKoqj0gvQOSzuumY1hgEWZ95jWVO9b3JtRPoykI7AKhTLvoAK8HIz8HkYa4+7FAX3AXDrazsxIfS+JDTnFias4Qv3uWM0kd0+L62y8LcfHVrMvZwMRNjJfzLIPYY1iNrNWYUXVsnsFdm2J91VcR4LmmIUMbRWjLgzuwwubll6dH6dTuSb1PRgVCuLOSLyvVUt5fdC29YXMe5Cj",
  GOOGLE_CLIENT: "935353577969-qk8fbp3nhpoukh5l19hut49t3166tqep.apps.googleusercontent.com",
  GOOGLE_SECRET: "zgOGnXbUUnWqPCgs5BffBHfA",
  LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/products/appiworks/",
  FORM_BANNER_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/form/banner/",
  STARCH_LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/starch/logo/",
  ASSETS_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/",
  IMAGE_PLACEHOLDER: {
    PLACEHOLDER_GENERAL: "image-placeholder.jpg",
    PLACEHOLDER_NO_IMAGE: "no-image-placeholder.jpg"
  },
  GCS_BUCKET: "appiworks-dev-ci.appspot.com",
  GCS_BASE_URL: "https://storage.googleapis.com",
  GCS_CONNECTION_ID: "61b3180b9536d880ad8d560a",
  BLOOM_LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/bloom/avatar/",
  HOTJAR_ID: "",
  GTM_KEY: "GTM-MJKRWZW",
  BASE_URL: "https://dev.ci.appiworks.com",
  SES_CONNECTION_ID: "625ffc505ddcfd658b93d5e0",
  "SMS_BASE_URLS": [
    "https://us-central1-sms-stage.cloudfunctions.net/server/api/",
    "https://sms-stage.appspot.com/api/"
  ],
  "SMS_SECRET_KEYS": {
      "SECRET_KEY": "fd08b9b2-3c23-4f1f-bccd-9154072f8015",
      "COMPARE_KEY": "1581bb4b-c172-43a5-864d-07a27d77b3be"
   },
  "SMS_PORTAL_URL": "https://sms-stage.appspot.com/",
  SMS_AUTH_KEY : "fddd0981-68c2-4e8b-bf9f-87026cdadfb4",
  "BLOOM_LOGO": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/appiworks-bloom-logo-256x256.png",
  "FORM_LOGO": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/form-builder/aw-form-builder-logo.png",
  INTERNAL_BLOOM: {
    ARTICLE: "bloom-5d754ef3",
    INTEGRATIONS: "lousy-snail-404d185b",
    LEGAL: "platform-legal-dev",
    BLOOM_TEMPLATES: "bloom-templates",
    FLOW_TEMPLATES: "flow-templates",
    DEMO_BLOOMS: "",
    FORM_TEMPLATES: "form-templates"
  },
  BOUTIQUE_WORKSPACE: "65c1fdab0634b1e56468a306",
  AGENT_WS_URL: 'ws://localhost:8000/ws'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
