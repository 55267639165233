import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormControl, Validators } from '@angular/forms';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.css'],
    standalone: false
})
export class ChipsComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  editingHeading: boolean = false;
  hoveredNow: boolean = false;
  styles: any;
  availableItems: any[] = []
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  private destroy:any = new Subject();
  fc: FormControl = new FormControl({ value: '', disabled: true });

  @ViewChild('menuTrigger') chipsMenuTrigger: MatMenuTrigger
  oldVal: any;
  validationSubscription: any

  constructor(
    private widgetUtilityService: WidgetUtilityService,
    private pageService: PageService,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService,
    public validationService: ValidationService,
    public wmtService: WidgetMetaTransformationService,
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService)
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in chips", widgetMeta)
      this.decompressMetaAndAssign(widgetMeta)
      this.generateAvailableOptions(false)
    })

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    this.validationSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.chipsMenuTrigger && this.chipsMenuTrigger.menuOpen) {
        this.chipsMenuTrigger.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.generateAvailableOptions(false)
      this.setContextActions()
      this.initForm()
    }
  }

  ngDoCheck(){
    // let oldOpts = this.oldVal;
    // let newVal = this.widgetMeta.config.availableOptions.staticOptions || []
    // if(oldOpts != newVal){
    //   if(!newVal || !newVal.length){
    //     this.oldVal = []
    //   }else{
    //     this.oldVal = newVal;
    //   }
    //   this.generateAvailableOptions(false)
    // }
  }

  initForm(){
    if(this.widgetMeta.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }
    // this.fc.patchValue(this.widgetMeta.config?.value?.value || '')
    this.fc.updateValueAndValidity()
    if (!this.widgetMeta.config.viewOnly || !this.widgetMeta.config.viewOnly?.value) {
      this.fc.enable()
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
      ]
    }
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          console.log("localMeta changed", this.widgetMeta)
          this.newWidgetMeta.emit(this.widgetMeta)
          // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        }
        // this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
        break;

      case "customPropertyUpdate":
        this.widgetMeta.config[event.propertyName].value = event.data
        console.log("custom property changed", event.propertyName, this.widgetMeta)
        this.newWidgetMeta.emit(this.widgetMeta)
        // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      case 'settingsChanged':
        this.widgetMeta = event.data
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
        // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      default:
        break;
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our chip
    if (value) {
      this.widgetMeta.config.availableOptions.staticOptions.push({
        name: value,
        value: value
      })
    }
    this.generateAvailableOptions(true)

    // Clear the input value
    event.chipInput!.clear();
    console.log("chips in list", this.availableItems)
  }


  remove(chip: any, index): void {

    if (index >= 0) {
      this.widgetMeta.config.availableOptions.staticOptions.splice(index, 1);
    }
    this.generateAvailableOptions(true)
  }


  onClick(event: any) {
    if(!this.builderMode) return
    this.selectedWidgetId = this.widgetMeta.id
    console.log("icon clicked", this.widgetMeta.id)
    this.widgetSelection.emit(this.widgetMeta.id)

  }

  titleViewToggle(flag: boolean){
    if (this.widgetMeta.config.title.hasOwnProperty('show')) {
      this.widgetMeta.config.title.show = flag
    }
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  onDelete() {
    if(!this.builderMode) return
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.chipsMenuTrigger.closeMenu();
  }

  saveNewHeading(newHeaderRef: any) {
    console.log("new title will be saved", newHeaderRef.value)
    if (newHeaderRef.value == '') {
      console.log("empty header received, cant update")
      return
    }
    this.widgetMeta.config.title.value = newHeaderRef.value

    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  async generateAvailableOptions(noRefetch: boolean = false, widget?: any){
    console.log("generate available options hit", noRefetch, "wid", JSON.parse(JSON.stringify(this.widgetMeta)))

    this.oldVal = this.widgetMeta.config.availableOptions.staticOptions;

    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions || []

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableItems.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      // rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode)
      dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
    }

    // console.log("chip staticOptionsg w", this.widgetMeta.config.availableOptions)
    // console.log("chip staticOptionsg", staticOptions)

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    this.availableItems = []
    this.availableItems = this.availableItems.concat(staticOptions)
    this.availableItems = this.availableItems.concat(dynamicOptionItems)

    console.log("chip options", this.availableItems)
    this.emitUserInput()
  }

  emitUserInput(){

    let value;

    if(this.widgetMeta.config.csvMode.value){
      value = ''
      this.availableItems.forEach((val, i) => {
        if(i > 0) value += ','
        value += val.value
      })
    }else{
      value = []
      this.availableItems.forEach(e=>{
        value.push(e.value)
      })
    }
    // console.log("value is", value)
    // console.log("fc", this.fc)
    // console.log("this.widgetMeta.config.availableOptions.staticOptions", this.widgetMeta.config.availableOptions.staticOptions)
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: value,
      validity: this.isInputValid(value)
    }
    console.log("emitter data:", userInput)
    this.userInputReceived.emit(userInput)
  }


  isInputValid(value) {
    if (this.widgetMeta.config.required?.value) {
      if (this.widgetMeta.config.csvMode.value) {
        if (value && typeof value == 'string' && value.length > 0) return true
        else return false
      } else {
        return (Array.isArray(value) && value.length)
      }
    } else {
      return true
    }
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

  // onChipClick(chip, i){
  //   console.log("chip clicked", chip, "index", i)
  //   if(this.widgetMeta.config.navigationEnabled.value && this.widgetMeta.config.navigationFieldName.value){
  //     console.log("can navigate: field name", this.widgetMeta.config.navigationFieldName.value)
  //   }
  // }


}
