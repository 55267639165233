import { Component, Input, OnInit, OnChanges, Output, ViewChild, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-space',
    templateUrl: './space.component.html',
    styleUrls: ['./space.component.css'],
    standalone: false
})
export class SpaceComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  styles: any;
  safeUrl: any;
  height: any;
  // width: number;

  @Output() applyWidgetHeight = new EventEmitter<any>();

  @ViewChild('cover') cover: ElementRef
  private destroy:any = new Subject();

  constructor(public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public wmtService: WidgetMetaTransformationService,
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService)
  }

  ngOnInit(): void {
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngAfterViewInit(): void {
    console.log("cover", this.cover)
    this.handleHeight()
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    console.log("changes", changes)

    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        // "height",
        // "width"
      ],
      // height: { type: this.widgetMeta?.config.height.type },
      // width: { type: this.widgetMeta?.config.width.type }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      default:
        break;
    }
  }

  onClick(event: any) {
    console.log("space clicked")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onOuterClick(event) {
    console.log("embed clicked in widget")
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }
  // onResizeStart(event: any) {
  //   event.stopPropagation();
  // }

  // onResizeEnd(event: any) {
  //   const newHeight = event.target.clientHeight;
  //   const newWidth = event.target.clientWidth;
  //   this.height = newHeight;
  //   this.width = newWidth;
  // }

  handleHeight(){
    let initialRenederedWidth = this.cover.nativeElement.clientWidth
    let newHeight = Math.floor((initialRenederedWidth / this.widgetMeta.gridX) * this.widgetMeta.gridY)
    console.log("emitting space height", newHeight)
    this.height = newHeight + 'px';
    this.applyWidgetHeight.next(newHeight)
  }

}
