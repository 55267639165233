import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { BoxService } from 'src/app/shared/services/box.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
    selector: 'app-flow-collaboration',
    templateUrl: './flow-collaboration.component.html',
    styleUrls: ['./flow-collaboration.component.scss'],
    standalone: false
})
export class FlowCollaborationComponent implements OnInit {
  restoreInput: number = 0;
  isShowInvite: boolean = false;
  flow: any;
  showMoreLogos: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
      public dialogData: any, 
      public dialogRef: MatDialogRef<FlowCollaborationComponent>,
      private boxService: BoxService,
      public spinner: SpinnerService,
      public adminService: AdminService,
      public connectionService: ConnectionService,
      public authservice: AuthServiceService
    ) {
    console.log("DIALOGREF dialogData : ", dialogData);
  }

  ngOnInit(): void {
    this.flow = this.dialogData.resource
  }

  resetLoading(){
    this.restoreInput += 1
  }

  toggleLogos() {
    this.showMoreLogos = !this.showMoreLogos;
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

}
