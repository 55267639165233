import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // enable it for zoho client platform urls
  if(window && !window.location.pathname.includes('client-platform/zoho')){
    window.console['orgLog'] = window.console.log;
    window.console['orgInfo'] = window.console.info;
    window.console.log = function(){};
    window.console.info = function() {};
  }
}


if(environment.production){



  // Set Microsoft Clarity
let clarityScript = document.createElement('script');
clarityScript.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "pbrvz24i3z");
`;
document.head.appendChild(clarityScript);
}


// //Set Google Tag Manager
const gtmScript = document.createElement('script');
gtmScript.async = true; // Set the script element itself to async
gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${environment.GTM_KEY}')
      `;

// Defer the addition of the script to the DOM
setTimeout(() => {
  document.head.appendChild(gtmScript);
}, 0);


const gtmNoScript = document.createElement('noscript');
gtmNoScript.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM_KEY}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

// Also defer the noscript element
setTimeout(() => {
  document.body.appendChild(gtmNoScript);
}, 0);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
