import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Component({
    selector: 'widget-data-source-box-config',
    templateUrl: './widget-data-source-box-config.component.html',
    styleUrls: ['./widget-data-source-box-config.component.scss'],
    standalone: false
})
export class DataSourceBoxConfig implements OnInit {

  @Input() dataSource: any
  @Output() newDataSource: any = new EventEmitter<any>()

  config: any
  filters: any

  filteredNameAttributes: any;
  filteredValueAttributes: any;
  nameAttributeControl = new UntypedFormControl();
  valueAttributeControl = new UntypedFormControl();

  isAppSelected: boolean = false
  isConnectionSelected: boolean = false
  isBoxObjectSelected: boolean = false

  selectedBoxName: string;
  selectedBoxId: string;
  selectedConnectionId: string;
  selectedBoxObjectId: string;

  canGetBoxObjects: boolean = false

  boxFunctionSpinner: boolean = false
  gettingObjFunSpinner: boolean = false
  attributeSpinner: boolean = false

  objectFuntions: any[] = []
  boxFunctions: any[] = []
  boxConfigToken: string

  isBoxObjectConfigError: boolean = false;
  boxObjectConfigError: any = {};
  disableBoxObjectInput: boolean = false

  getFn: any
  attributeOptions: any[] = []
  getFnOptions: any[] = []
  isOptionsToCollect: boolean = false
  isGetOptionsToCollect: boolean = false
  isAttributesError: boolean = false
  isGetFnOptionsError: boolean = false
  attributeError: any
  terminationError: any
  terminationErrorMessage: any

  getAttrFn: any;
  attributes: any = []
  isAttributesReady: boolean = false
  collectAttrOptions: boolean = false
  collectGetOptions: boolean = false
  availableInputParams: any = {
    options: {}
  }
  isBoxSupportGroup: boolean = false

  isOldAttributeOptionsExist: boolean = false

  constructor(
    private boxService: BoxService,
    private pageService: PageService,
    private _snack: MatSnackBar
  ) {
    console.log("data source box config constructor hit.")
  }

  ngOnInit(): void {

    console.log("[DATA SOURCE BOX CONFIG] onInit(): dataSource", this.dataSource)

    this.filteredNameAttributes = this.nameAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    this.filteredValueAttributes = this.valueAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    this.config =  JSON.parse(JSON.stringify(this.dataSource))
    this.filters = this.config.filter || {}

    // && this.config.connectionId
    if(this.config && this.config.boxId && this.config.boxObjectId){
      console.log("will assign existing values")
      this.isAppSelected = true
      this.isConnectionSelected = true
      this.isBoxObjectSelected = true

      this.selectedBoxId = this.config.boxId
      this.selectedConnectionId = this.config.connectionId
      this.selectedBoxObjectId = this.config.boxObjectId
      console.log("box object id assigned", this.selectedBoxObjectId)
      this.boxConfigToken = this.config.boxConfigToken

      this.attributeOptions = this.config.attributeOptions
      this.getFnOptions = this.config.getFnOptions

      this.boxObjectSelected(this.selectedBoxObjectId)
      this.saveChanges()
    }

    console.log("config", this.config)
  }

  appSelected(e){
    console.log("appselected", e);
    if(this.selectedBoxId && e.__id != this.selectedBoxId){
      this.resetObjectConfigs()
    }
    this.selectedBoxId = e.__id;
    if (e.supports?.includes('group')) this.isBoxSupportGroup = true
    if(this.selectedBoxId){
      this.isAppSelected = true
    }
  }

  async boxSelected(box: any) {
    console.log("selected box", box)
    if(this.selectedConnectionId && box._id != this.selectedConnectionId){
      this.resetObjectConfigs()
    }
    this.selectedBoxName = box.name
    this.selectedBoxId = box.box_id
    this.selectedConnectionId = box._id
    this.boxConfigToken = box.box_token

    await this.getBoxFunctions()

    this.isConnectionSelected = true
  }

  baseSelected(base){
    console.log("selected base", base)
    // if(this.selectedConnectionId && box._id != this.selectedConnectionId){
    //   this.resetObjectConfigs()
    // }
    this.selectedBoxId = 'starch';
    this.config['baseMap'] = {
      box_id: base.storage_box,
      base_id: base._id
    }
    this.config['baseId'] = base._id;
    this.boxConfigToken = base.storage_token;
    this.getBoxFunctions(this.config['baseMap'].box_id, this.boxConfigToken);

    this.isConnectionSelected = true
  }

  getSelectedConnectionId(box){
    if(box != 'starch') return this.selectedConnectionId;
    return
  }

  getSelectedBoxId(){
    let box = this.selectedBoxId == 'starch' ? this.config.baseMap?.box_id : this.selectedBoxId;
    return box;
  }

  /**
   *
   * @param boxObjectId
   */
  async boxObjectSelected(boxObjectId: any) {
    console.log("box object selected", boxObjectId)
    if(this.selectedBoxObjectId && this.selectedBoxObjectId != boxObjectId){
      this.resetAttributeConfigs()
    }

    this.selectedBoxObjectId = boxObjectId
    this.isBoxObjectSelected = true


    this.gettingObjFunSpinner = true
    let boxId = this.selectedBoxId == 'starch' ? this.config.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    this.objectFuntions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType)
    // this.objectFuntions = await this.boxService.getBoxObjectFuntions(this.selectedBoxId, this.selectedBoxObjectId, this.selectedConnectionId)
    this.gettingObjFunSpinner = false
    console.log("box object functions received", this.objectFuntions)

    let getFn: any = this.objectFuntions?.find(fn => fn.__id == 'get')
    let getAttributesFunction = this.boxFunctions?.find(fn => fn.__id == 'getattributes')

    if(!getAttributesFunction){
      console.log("getAttributes function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'getAttributes' function not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
      return
    } else if(!getFn) {
      console.log("get function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'get' function not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
      return
    }

    this.constructAttrOptions()
  }

  resetObjectConfigs(){
    this.selectedBoxObjectId = ""
    this.isBoxObjectSelected = false
    this.resetAttributeConfigs()
  }

  resetAttributeConfigs(){
    this.collectAttrOptions = false
    this.attributeOptions = []
    this.collectGetOptions = false
    this.getFnOptions = []
    this.isAttributesReady = false
    this.attributes = []
    this.availableInputParams.options = {}
  }

  // calculates if input parameters needed for fetching attributes
  async constructAttrOptions(){
    console.log("[CONSTRUCT ATTR OPTIONS]")
    this.getAttrFn = this.boxFunctions.find(fn => fn.__id == 'getattributes')
    if(!this.getAttrFn) return
    console.log("existing", JSON.parse(JSON.stringify(this.attributeOptions)))
    if(this.attributeOptions?.length) this.isOldAttributeOptionsExist = true
    this.attributeOptions = this.attributeOptions?.length ? this.attributeOptions : this.pageService.checkOptionsToCollect(this.getAttrFn)

    console.log("attributeOptions assigned", this.attributeOptions)
    this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)

    // keep availableInputParams in sync with attribute Options
    this.attributeOptions.forEach(op => this.availableInputParams.options[op.__id || op.name] = op.value)

    console.log("availableInputParams initialized", this.availableInputParams)

    if(this.attributeOptions?.length){
      this.collectAttrOptions = true

      // when old options exist and all required options already have value
      if(this.isOldAttributeOptionsExist && !this.attributeOptions.find(op => op.required && !op.value)){
        this.constructGetOptions()
      }
    }else{
      this.constructGetOptions()
    }
  }

  // calculates if input parameters needed for get fn
  constructGetOptions(){
    console.log("[CONSTRUCT GET OPTIONS]")
    this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
    if(!this.getFn) return
    console.log("existing", JSON.parse(JSON.stringify(this.getFnOptions)))
    this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)

    this.getFnOptions = this.getFnOptions.length ? this.getFnOptions : this.pageService.checkOptionsToCollect(this.getFn)
    this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)
    if(!this.getFnOptions?.length) {
      // this.getAttributes()
      this.saveChanges()
    } else {
      // if some property already there in availableInputParams, consider as existing value
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })

      // if a new property is there in getFnOptions but not in availableParams, add it to availableParams
      this.getFnOptions.forEach(op => {
        if(!this.availableInputParams.options.hasOwnProperty[op.__id]){
          this.availableInputParams.options[op.__id] = op.value
        }
      })

      this.collectGetOptions = true

      // when all required options already have value
      if(!this.getFnOptions.find(op => op.required && !op.value)){
        // this.getAttributes()
        this.saveChanges()
      }
    }
  }

  async attributeOptionsCollected(){
    console.log("attribute inputs collected")
    this.constructGetOptions()
    // await this.getAttributes();
  }

  /**
   * bound with event emitter to catch input parameters for getAttribute functions
   * @param attrInputParams
   */
  attrOptionInputsRecevied(attrInputParams){
    console.log("attribute input params received", attrInputParams)
    if(!attrInputParams.options) return
    Object.keys(attrInputParams.options).forEach(optionId => {
      this.attributeOptions.forEach(attrOp => {
        if(attrOp.__id == optionId){
          attrOp.value = attrInputParams.options[optionId]
        }
      })
      // update availableInputParams for further use
      this.availableInputParams['options'][optionId] = attrInputParams.options[optionId]
    })
    console.log("availableInputParams updated", this.availableInputParams)
    console.log("attribute options", this.attributeOptions)
    // this.
  }

   /**
   * bound with event emitter to catch input parameters for other getFn functions
   * @param getFnInputParams
   */
   getOptionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams?.options) return
    Object.keys(getFnInputParams.options).forEach(optionId => {
      this.getFnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams.options[optionId]
        }
      })
    })
    console.log("get options", this.getFnOptions)
  }

  async getBoxFunctions(box?, token?) {
    console.log("getting boxFunctions with id:", box || this.selectedBoxId)
    console.log("getting boxFunctions with token:", token || this.boxConfigToken)
    this.boxFunctionSpinner = true
    let res = await this.boxService.getBoxFunctions(box || this.selectedBoxId, token || this.boxConfigToken)
    console.log("box functions received", res)
    if(res.find(fn => fn.__id == "getobjects")){
      this.canGetBoxObjects = true
    }
    this.boxFunctionSpinner = false
    this.boxFunctions = res
    return this.boxFunctions
  }


  // async getFnOptionsConfirmed(){
  //   // let res: any = await this.boxService.getAttributes(this.selectedConnectionId, this.selectedBoxId, this.selectedBoxObjectId, this.attributeOptions)
  //   // this.attributes = res.result
  //   // console.log("attributes fetched", this.attributes)
  //   // this.isAttributesReady = true
  //   this.saveChanges()
  // }

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }

  private _attributeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.attributes.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  boxObjectSelectionError(event){
    console.log("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = event
  }

  saveChanges(){

    this.config.connectionId = this.selectedConnectionId
    this.config.boxName = this.selectedBoxName
    this.config.boxId = this.selectedBoxId
    this.config.isBoxSupportGroup = this.isBoxSupportGroup
    this.config.boxObjectId = this.selectedBoxObjectId
    this.config['getFnOptions'] = this.getFnOptions
    this.config['attributeOptions'] = this.attributeOptions
    this.config['boxConfigToken'] = this.boxConfigToken

    // this.widgetMeta.config.dataSource = this.config
    this.newDataSource.emit(this.config)
  }

}
