import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    standalone: false
})
export class ReplacePipe implements PipeTransform {

  transform(value: string): string {
    return value? value.replace(/_/g, " ") : value;
  }

}
