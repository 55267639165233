import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { MetaService } from '../../services/meta-service';

@Component({
    selector: 'app-bloom-error-diolog',
    templateUrl: './bloom-error-diolog.component.html',
    styleUrls: ['./bloom-error-diolog.component.scss'],
    standalone: false
})
export class BloomErrorDiologComponent implements OnInit {
  errorCode: any;
  userMap: any = {};
  errorMessage: any

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public metaService: MetaService,
    public dialog: MatDialogRef<BloomErrorDiologComponent>,
    public spinner: SpinnerService,
  ) { }

  ngOnInit(): void {
    this.errorCode = this.dataInput.ERROR_CODE;
    this.userMap = this.dataInput.userMap;
    if( this.errorCode == "NO-BLOOM-ACCESS"){
      this.errorMessage = `This bloom is not accessible to ${this.userMap.email}. \nPlease contact the Bloom Administrator.`;
    }
  }

}
