import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Period extends Widget {
  type = 'period';
  gridX = 6;
  minGridX = 3;

  config = {
    props: [
      'appearance',
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = "Choose a date range"
    this.config['value'].resetValue = '';
    this.config['value'].periodType = 'date';
    this.config['value'].value = { start: '', end: '' };
    this.config['defaultValue'].valueType = 'period';
    this.config['defaultValue'].periodType = 'date';
    this.config['defaultValue'].value = { start: '', end: '' };
    this.config['placeholder'].value = 'Default input placeholder';

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);
    return map;
  }
}
