import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientPlatformService } from '../../client-platform/client-platform.service';
import { DialogSetupComponent } from '../../client-platform/common-components/dialog-setup/dialog-setup.component';
import { MappingIcons } from './mapping-icons';
import { MappingDrillDownDialog } from './mapping-drill-down/mapping-drill-down.dialog';
import { MappingAddAttributeDialog } from './mapping-add-attribute/mapping-add-attribute.dialog';

@Component({
    selector: 'app-mapping-setup',
    templateUrl: './mapping-setup.component.html',
    styleUrls: ['./mapping-setup.component.css'],
    standalone: false
})
export class MappingSetupComponent implements OnInit {

  @ViewChild('appFieldInput') appFieldInput!: ElementRef;
  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;


  @Input() sourceFields: any;
  @Input() appFields: any;
  @Input() systemFields: any;
  @Input() mappingArr: any = [];
  @Input() preDefinedMapping?: any;
  @Input() appInfo: any;
  @Input() sourceInfo: any;
  @Input() serviceType?: string = "export"; //by default it is export, can be import, view etc.
  @Input() displayOption: any;
  @Input() suppressDrillDown: boolean = false;
  @Input() isAllowCustomAppField: boolean = false;
  @Output() mappingChange = new EventEmitter<any>();

  @Input() showFormFieldsRefreshButton: boolean = false;
  @Output() refreshFormfieldsRequested: EventEmitter<void> = new EventEmitter<void>();

  isEditEnabled: boolean = false;
  isMappingValid: boolean = true;
  mappingToggle: boolean = true;
  allMapping: any = [];
  autoMappedArr: any = [];
  itemsMappingArr: any = {};
  editMapFieldIndex = null;
  fieldMap: any;
  spinner: boolean = false;

  appFieldIconMap: any = MappingIcons.appFieldIconMap;
  sourceFieldIconMap: any = {};
  objectAttributes: any = [];
  filteredObjectAttributes: Observable<any[]>;
  filteredSourceAttributes: Observable<any[]>;
  filteredReferenceAttributes: Observable<any[]>;

  //form control for mapping fields
  selectedAppField = new UntypedFormControl();
  selectedMappingType = new UntypedFormControl('sourceField'); //initialize with source field as mapping type.
  selectedSourceField = new UntypedFormControl();
  templatedValue = new UntypedFormControl('');

  referenceFields: any = [];
  selectedReferenceField = new UntypedFormControl('');

  isSmallScreen: boolean;
  isChildObjectMapping: boolean = false;
  isChildObjectFieldsFetched: boolean = false;
  childObjectMappings: any = [];
  selectedChildObject: any;
  childObjectNewObjectData: any;
  childObjectAppInfo: any;
  childObjectSourceInfo: any;
  childObjectFields: any = [];
  childObjectLineItemFieldsMap = {};
  previousChildObjectId: string;
  richTextTemplate: boolean = false;
  quillEditorRef: any;

  defaultConditionMap: any = {
    defaultField: "",
    defaultType :"text",
    conditionMap: {
      conditionSets: {
        conditions : [],
        resultField : "",
        resultType : "text"
      }
    }
  };

  conditionMap:any = this.defaultConditionMap;
  loadedAttributes: any = [];

  get isSupportsCustomAttribute() {
    if(this.isAllowCustomAppField) {
      return this.isAllowCustomAppField;
    } else return this.appInfo?.object?.supports?.includes('customattribute')
  }

  get sourceFieldValue() {
    return this.selectedMappingType.value == 'templateField' ?
    this.templatedValue.value : (this.selectedAppField.value.dataType == 'array' ? this.itemsMappingArr[this.selectedAppFieldId] : this.selectedSourceField.value);
  }

  get selectedSourceFieldId() {
    return this.selectedSourceField.value.__id;
  }

  get selectedAppFieldId() {
    return this.selectedAppField.value.__id;
  }

  get isLineItemMapping() {
    const appFieldValue = this.selectedAppField?.value;
    return appFieldValue?.dataType === 'array' && appFieldValue?.semanticType === 'lineItem';
  }

  constructor(
    public dialog: MatDialog,
    private breakPointObserver: BreakpointObserver,
    public clientPlatformService: ClientPlatformService
  ) {
    this.isSmallScreen = this.breakPointObserver.isMatched(Breakpoints.XSmall);
  }

  async ngOnInit() {
    console.log('service type', this.serviceType)
    //pick the field data type either from client service or from mapping icons.
    if(this.clientPlatformService.sourceFieldIconMap)
      this.sourceFieldIconMap = this.clientPlatformService.sourceFieldIconMap;
    else
      this.sourceFieldIconMap = MappingIcons.appFieldIconMap;

    if(this.mappingArr.length)
      this.allMapping = this.mappingArr;

    if(!this.sourceFields?.length) {
      let sourceDisplayName = `${this.clientPlatformService.sourceApp || "source"}`;
      this.clientPlatformService.openErrorSnackBar(`${sourceDisplayName} fields are missing, please provide all the required form inputs in the previous step!`);
    }

    //check whether the client is passing app fields as an input, if true, use that else get it from client service.
    if(this.appFields && this.appFields.length) {
      this.objectAttributes = this.appFields.filter((attr) => !(attr.writable == false));
      this.loadedAttributes = [];
      let appFields = JSON.parse(JSON.stringify(this.appFields))
      appFields.forEach(element => {
        const cachedEle = JSON.parse(JSON.stringify(element));
        element.__id = `old.${element.__id}`;
        element.name = `${element.name}`

        //If there are mandatory fields then add it to mapping list without source field.
        if (element.required) {
          let mapObj:any = {
            appField: cachedEle,
            sourceField: null,
            mappingType: "sourceField"
          }

          //enable richtest for html type
          if(mapObj?.appField?.dataType == "HTML") mapObj.isRichText = true;

          const existingMapObj = this.allMapping.find((currMapObj: any) => currMapObj.appField.__id == cachedEle.__id);
          if (cachedEle.dataType == "array" && cachedEle.semanticType == "lineItem") {
            if (!this.itemsMappingArr[cachedEle.__id]) {
              this.itemsMappingArr[cachedEle.__id] = existingMapObj?.sourceField || [[]]
            }
            mapObj.sourceField = this.itemsMappingArr[cachedEle.__id];
          }

          if (!existingMapObj) {
            this.allMapping.push(mapObj);
            this.mappingChange.emit(this.allMapping);
          }
        }

        this.loadedAttributes.push(element)
      });
    }

    if (this.sourceFields?.length) {
      this.sourceFields.forEach(field => {
        if (field.dataType === "array" && field.semanticType === "lineItem") {
          field.semanticOptions.fields.list.forEach(lineField => {
            let lineItemFieldObj = JSON.parse(JSON.stringify(field.semanticOptions.fields[lineField]));
            lineItemFieldObj.__id = `\${${field.__id}.\$[__AW_LINE_index].${lineItemFieldObj.__id}}`;
            lineItemFieldObj.name = `${field.name} # ${lineItemFieldObj.name}`;
            if (!this.sourceFields.find((sField) => sField.__id == lineItemFieldObj.__id)) {
              this.sourceFields.push(lineItemFieldObj);
            }
          });
        }
      })
    }


    console.log("loadedAttributes-->", this.loadedAttributes)

    this.constructFieldMap();
    this.getFilteredObjectAttributes();
    this.getFilteredSourceAttributes();
    this.setPredefinedMapping();
    /* Auto mapping */
    this.checkForAutoMapping();
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  setPredefinedMapping() {
    if (this.preDefinedMapping) {
      const preDefinedMappingArr = [];
      Object.keys(this.preDefinedMapping).forEach((key) => {
        const appField = this.objectAttributes.find((field) => field.__id === key);
        const sourceField = this.sourceFields.find((field) => this.preDefinedMapping[key].__id === field.__id);
        const mapObj = { appField, sourceField, mappingType: "sourceField" };
        const isMappingExist = this.allMapping.some((mObj) => this.formatField(mObj.appField.name) == this.formatField(appField.name));
        if (isMappingExist) {
          this.handlePredefinedMapping(appField, mapObj);
        } else {
          preDefinedMappingArr.push(mapObj);
        }
      })
      this.allMapping = this.allMapping.concat(preDefinedMappingArr);
      this.mappingChange.emit(this.allMapping);
    }
  }

  formatField(field: any) {
    return field.replace(/[\-_ ?*]/g, '').toLowerCase();
  }

  //rich text setup
  getEditorInstance(editorInstance: any) {
    console.log("RICHTEST INIT", editorInstance)
    this.quillEditorRef = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('color', (value: any) => {
      this.quillEditorRef.format('color', value);
    });
  }

  // Helper function to remove HTML tags
  stripHtml(html: string): string {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  //on change of rich text
  enabledRichTextChanged(event){
    // if (!this.richTextTemplate && this.templatedValue.value) {
      // Converting Quill HTML to Plain Text when switching to textarea
      // console.log("html", this.stripHtml(this.templatedValue.value))
      // this.templatedValue.setValue(this.stripHtml(this.templatedValue.value));
    // }
  }

  constructFieldMap(){
    let fieldObj:any = {};
    let list = [];

    if(this.appFields?.length > 0) {
      list.push('appFields');
      let fieldObjAppFields = this.getFieldMapObject('appFields','APP FIELDS',this.objectAttributes)
      fieldObj[fieldObjAppFields.id] = fieldObjAppFields
    }
    if(this.sourceFields?.length > 0) {
      list.push('sourceFields');
      let displayName = `${this.clientPlatformService.sourceApp?.toUpperCase() || "SOURCE"} FIELDS`;
      let fieldObjsourceFields = this.getFieldMapObject('sourceFields', displayName, this.sourceFields)
      fieldObj[fieldObjsourceFields.id] = fieldObjsourceFields
    }
    if(this.systemFields?.length > 0) {
      list.push('systemFields');
      var fieldObjsystemFields = this.getFieldMapObject('systemFields','SYSTEM FIELDS',this.systemFields)
      fieldObj[fieldObjsystemFields.id] = fieldObjsystemFields
    }

    fieldObj.list = list;
    this.fieldMap = fieldObj;
    console.log("FIELD MAP PREPARED : ",this.fieldMap)
  }

  getFieldMapObject(_id:string, displayName:string, fields:any, option?:any){
    var obj = {
      id : _id,
      displayName : displayName,
      fields : fields,
      options : option ? option : {}
    }
    return obj
  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log("changes in action", changes)

    if(!(changes?.appFields?.firstChange) && changes?.appFields?.currentValue){
      this.appFields = changes?.appFields?.currentValue;
      this.objectAttributes = this.appFields.filter((attr) => !(attr.writable == false));
    }

    if(!(changes?.sourceFields?.firstChange) && changes?.sourceFields?.currentValue) {
      this.sourceFields = changes?.sourceFields?.currentValue;
    }

    if(!(changes?.mappingArr?.firstChange) && changes?.mappingArr?.currentValue) {
      this.mappingArr = changes?.mappingArr?.currentValue;
      if(!this.mappingArr.length) this.allMapping = [];
    }

    if (changes['sourceFields']) {
      console.log('Form fields updated in child component:', this.sourceFields);
      this.clearSourceField();
    }

  }

  radioChange(e){
    if(e.value == "conditional"){
      if(!this.conditionMap.conditionSets) this.conditionMap.conditionSets = [];
      if(!this.conditionMap.defaultField) this.conditionMap.defaultField = "";
      if(!this.conditionMap.defaultType) this.conditionMap.defaultType = "text";
      if(this.conditionMap.conditionSets.length == 0 ) {
        this.conditionMap.conditionSets.push({
          conditions : [],
          resultField : "",
          resultType : "text"
        })
      }
    }
    console.log("this.conditionMap", this.conditionMap)
  }

  openAutoMappingDialog(): void {
    const dialogRef = this.dialog.open(DialogSetupComponent, {
      width: '250px',
      data: {
        actionType: 'prompt',
        title: 'Intelligent Mapping',
        message: `${this.autoMappedArr.length} ${this.autoMappedArr.length == 1 ? 'field' : 'fields'} can be automatically mapped. Proceed?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed ', result);
      this.clientPlatformService.isAutoMapping = true;
      if (!result) this.autoMappedArr = [];
      else {
        this.allMapping = this.allMapping.concat(this.autoMappedArr);
        this.mappingChange.emit(this.allMapping);
        console.log('autoMapping', this.autoMappedArr);
      }
    });
  }

  async editMappingField(mapObj: any, index: string) {
    console.log('MAP OBJ', mapObj);
    console.log('INDEX', index);

    this.editMapFieldIndex = index;
    this.conditionMap = mapObj?.conditional || this.defaultConditionMap;

    if(mapObj.appField.dataType == 'array') {
      if (mapObj.sourceField) {
        this.itemsMappingArr[mapObj.appField.__id] = mapObj.sourceField;
      } else {
        this.addItem();
      }
    } else if (mapObj.mappingType == 'templateField') {
      this.templatedValue.setValue(mapObj.sourceField);
    } else {
      this.selectedSourceField.setValue(mapObj.sourceField);
    }

    // If the sourcefield is of type foreignKey, disable the source field.
    if (mapObj.sourceField?.foreignKey) {
      this.selectedSourceField.disable();
    }

    this.richTextTemplate = mapObj?.isRichText ? true : false;

    if (mapObj.mappingType === "sourceField" && mapObj.sourceField?.childObject?.isEnabled) {
      this.isChildObjectMapping = mapObj.sourceField.childObject.isEnabled;
      this.previousChildObjectId = mapObj.sourceField.childObject.object.__id;
      this.childObjectMappings = mapObj.sourceField.childObject.mapping;
      this.initChildObjectMapping();
    }

    this.selectedAppField.setValue(mapObj.appField);
    this.selectedMappingType.setValue(mapObj.mappingType);
    console.log('this.conditionMap', this.conditionMap);
    this.isEditEnabled = true;

    if (mapObj.sourceField?.referenceField) {
      this.selectedReferenceField.setValue(mapObj.sourceField.referenceField);
      await this.checkForReferenceMapping(mapObj.sourceField);
    }
  }

  showMappingType(mapObj){
    let result = mapObj?.sourceField?.name || "---Field needs to be mapped---";
    if (mapObj.appField.dataType == "array") result = "Items"
    else if(mapObj.mappingType == "templateField") result = "Templated"
    else if (mapObj.mappingType == "conditional") result = "Conditional"
    else if (this.isSmallScreen) result = mapObj?.sourceField?.name;
    return result;
  }

  deleteCondition(i){
    this.conditionMap.conditionSets.splice(i, 1);
  }

  addCondition(){
    this.conditionMap.conditionSets.push({
      conditions : [],
      resultField : "",
      resultType : "text"
    })
  }

  deleteMappingField(index: string) {
    this.allMapping.splice(index, 1);
    this.mappingChange.emit(this.allMapping);
  }

  clearAppField() {
    this.selectedAppField.setValue('');
  }
  clearSourceField() {
    this.selectedSourceField.setValue('');
  }
  clearReferenceField() {
    this.selectedReferenceField.setValue('');
  }
  clearTemplateField() {
    this.templatedValue.setValue('');
  }

  displayFnForAppField(attribute: any) {
    return (attribute && attribute.name) ? attribute.name : '';
  }

  displayFnForSourceField(attribute: any) {
    return attribute && attribute.name ? attribute.name : '';
  }

  filterChanged(e, condition){
    console.log("filterChanged e", e, condition);
    condition.conditions = e.filterItems
  }

  onNewMapping(){
    this.conditionMap = this.defaultConditionMap;
    this.richTextTemplate = false;
  }

  addMappingField() {
    //check for validation
    this.checkForMappingValidation();
    if (!this.isMappingValid) return;

    let mapObj: any = {
      appField: this.selectedAppField.value,
      sourceField: this.sourceFieldValue,
      mappingType: this.selectedMappingType.value,
      isRichText: this.richTextTemplate
    };

    if (this.selectedMappingType.value === "sourceField" &&
      this.sourceFieldValue.dataType === "array" &&
      this.sourceFieldValue.semanticType === "lineItem"
    ) {
      if (!mapObj.sourceField.childObject) mapObj.sourceField.childObject = {};
      mapObj.sourceField.childObject.isEnabled = this.isChildObjectMapping;
      mapObj.sourceField.childObject.object = this.selectedChildObject;
      mapObj.sourceField.childObject.mapping = this.childObjectMappings;
    }

    if (this.sourceFieldValue.reference) {
      mapObj.sourceField.referenceField = this.selectedReferenceField.value
    }

    if(this.selectedMappingType.value == 'conditional'){
      mapObj.conditional = this.conditionMap;
    }
    console.log('MapObj', mapObj);

    let isAdded = true;
    if (this.editMapFieldIndex != null) {
      this.allMapping.splice(this.editMapFieldIndex, 1, mapObj);
      this.editMapFieldIndex = null;
      this.isEditEnabled = false;
      this.mappingToggle = true;
      isAdded = false;
    } else {
      this.allMapping.push(mapObj);
    }
    this.clientPlatformService.openSnackBar(`The mapping is ${isAdded ? 'added' : 'updated'}. You can continue adding more.`)

    console.log('Mapping', this.allMapping);

    this.mappingChange.emit(this.allMapping);

    this.isChildObjectMapping = false;
    //clearing the slected fields from App & Source.
    this.clearAppField();
    this.clearSourceField();
    this.clearReferenceField();
    if (this.selectedMappingType.value == 'templateField')
      this.clearTemplateField();

  }

  setMappingArr(event: Event, index: number) {
    this.itemsMappingArr[this.selectedAppFieldId][index] = event;
  }

  addItem() {
    if (!this.itemsMappingArr[this.selectedAppFieldId]) {
      this.itemsMappingArr[this.selectedAppFieldId] = []
    }
    this.itemsMappingArr[this.selectedAppFieldId].push([]);
  }

  removeItem(index: number) {
    this.itemsMappingArr[this.selectedAppFieldId].splice(index, 1);
  }

  getItemAppFields() {
    let itemAppFields = [];
    const itemFieldObj = this.appFields.find((field) => field.__id == this.selectedAppFieldId)
    if (itemFieldObj) {
      const fields = itemFieldObj.semanticOptions?.fields;
      for (const field of fields.list) {
        itemAppFields.push(fields[field]);
      }
    }
    return itemAppFields;
  }

  resetMappingSettings() {
    this.clearAppField();
    this.clearSourceField();
    this.clearTemplateField();

    //reset toggles
    this.mappingToggle = !this.mappingToggle;
    if (this.isEditEnabled) this.isEditEnabled = !this.isEditEnabled;
    this.conditionMap = this.defaultConditionMap;
  }

  checkForMappingValidation() {

    var appDisplayName = this.appInfo?.app ? this.appInfo.app : 'app';
    var sourceDisplayName = `${this.clientPlatformService.sourceApp || "source"}`;

    // Validation 1: Validate that required fields are selected
    if (!this.selectedMappingType.value || !this.selectedAppField.value) {
      this.isMappingValid = false;
      this.clientPlatformService.openSnackBar(`Please select a ${appDisplayName} field.`);
      return;
    }

    // Validation 2: Validate that selectedAppField is not a string
    // user cannot just type, they need to select the mapping object
    if (typeof this.selectedAppField.value === "string") {
      this.isMappingValid = false;
      this.clearAppField();
      this.clientPlatformService.openSnackBar(`Please choose a valid ${appDisplayName} field!`);
      return;
    }

    // Validation 3: Validate that selectedAppField's dataType is not 'array'
    if (this.selectedAppField.value.dataType === 'array') {
      this.isMappingValid = false;
      this.clearAppField();
      this.clearSourceField();
      this.clientPlatformService.openSnackBar(`${appDisplayName} field cannot be of type "array"!`);
      return;
    }

    // Validation 4: Validate sourceField if mapping type is 'sourceField'
    if (this.selectedMappingType.value === 'sourceField' && !this.selectedSourceField.value) {
      this.isMappingValid = false;
      this.clientPlatformService.openSnackBar(`Please select a ${sourceDisplayName} field.`);
      return;
    }

    // Validation 5: Validate that selectedSourceField is not a string
    // user cannot just type, they need to select the mapping object
    if (this.selectedMappingType.value === 'sourceField' && typeof this.selectedSourceField.value === "string") {
      this.isMappingValid = false;
      this.clearSourceField();
      this.clientPlatformService.openSnackBar(`Please choose a valid ${sourceDisplayName} field!`);
      return;
    }

    // Validation 6: Validate templateField if mapping type is 'templateField'
    if (this.selectedMappingType.value === 'templateField' && !this.templatedValue.value) {
      this.isMappingValid = false;
      this.clientPlatformService.openSnackBar('Please provide a value for the template mapping.');
      return;
    }

    // Validation 7: Check if the app field is already mapped
    if (!this.isEditEnabled && this.allMapping.some((mapObj: any) => mapObj.appField.__id === this.selectedAppFieldId)) {
      this.clientPlatformService.openSnackBar(`The ${appDisplayName} field '${this.selectedAppField.value.name}' is already mapped!`);
      this.isMappingValid = false;
      return;
    }

    // If all validations pass
    this.isMappingValid = true;
  }



  insertCursor(fieldObj: any) {
    let value = fieldObj?.formatedValue || fieldObj.__id;
    let cursorValue = '${' + value + '}';

    if (this.richTextTemplate) {
      // Handling for Quill Editor
      if (this.quillEditorRef) {
        const range = this.quillEditorRef.getSelection();

        if (range) {
          this.quillEditorRef.insertText(range.index, cursorValue)
          this.quillEditorRef.setSelection(range.index + cursorValue.length);
        } else {
          console.warn("No selection range found! Text will not be inserted.");
        }
      } else {
        console.error("Quill Editor Reference is NULL");
      }
    } else {
      // Handling for Normal Textarea
      if (!this.messageTextarea) {
        console.error("Textarea reference not found!");
        return;
      }

      const textarea = this.messageTextarea.nativeElement;
      const currentValue = this.templatedValue.value || '';
      const start = textarea.selectionStart ?? currentValue.length;
      const end = textarea.selectionEnd ?? currentValue.length;

      // Insert the cursorValue at the cursor position
      const updatedValue = currentValue.substring(0, start) + cursorValue + currentValue.substring(end);
      // console.log("Updated Value After Insertion:", updatedValue);

      // Update the FormControl value without triggering a full re-render
      this.templatedValue.setValue(updatedValue);

      // Restore cursor position after inserting text
      setTimeout(() => {
        textarea.setSelectionRange(start + cursorValue.length, start + cursorValue.length);
        textarea.focus();
        console.log("Cursor Restored at:", start + cursorValue.length);
      }, 0);
    }
  }   

  insertCursorDefault(e, conditionObj){
    let value = e?.formatedValue || e.__id;
    let cursorValue = '${' + value + '}';
    conditionObj.resultField = cursorValue;
  }

  insertCursorDefaultField(e, conditionObj){
    let value = e?.formatedValue || e.__id;
    let cursorValue = '${' + value + '}';
    conditionObj.defaultField    = cursorValue;
  }

  refreshFormFields() {
    this.refreshFormfieldsRequested.emit();
    this.spinner = true;

    // Hide the spinner and show the refresh icon again after 3 seconds (3000ms)
    setTimeout(() => {
      this.spinner = false;  // Revert to the refresh icon
    }, 3000);  // 3 seconds
  }


  private _filterAttributes(value: any, filterType: string): any {
    let filterValue;
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }

    if (filterType == 'appField') {
      return this.objectAttributes.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else if(filterType == 'referenceField') {
      return this.referenceFields.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return this.sourceFields.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  getFilteredObjectAttributes() {
    this.filteredObjectAttributes = this.selectedAppField.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.name)),
      map((action) =>
        action
          ? this._filterAttributes(action, 'appField')
          : this.objectAttributes.slice()
      )
    );
  }

  getFilteredSourceAttributes() {
    this.filteredSourceAttributes = this.selectedSourceField.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((action) =>
        action
          ? this._filterAttributes(action, 'sourceField')
          : this.sourceFields?.slice()
      )
    );
  }

  getFilteredReferenceAttributes() {
    this.filteredReferenceAttributes = this.selectedReferenceField.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((action) =>
        action
          ? this._filterAttributes(action, 'referenceField')
          : this.referenceFields.slice()
      )
    );
  }

  async checkForReferenceMapping(value: any) {
    this.referenceFields = [];

    const clientTypeToBoxIdMap = {
      "ZOHOCRM": "zohocrm"
    }

    if (value.reference && !value.referenceField?.hidden) {
      const event = { __id: value.reference };
      const connection = { _id: this.clientPlatformService.workspaceObj?.options?.[`${clientTypeToBoxIdMap[this.clientPlatformService.clientType]}ConnectionId`]}
      this.referenceFields = await this.clientPlatformService.getObjectAttributes(event, connection);
      console.log('REFERENCE fields', this.referenceFields)
    }

    this.getFilteredReferenceAttributes();
  }

  checkForAutoMapping(type?: string) {
    this.autoMappedArr = [];

    for (var i = 0; i < this.objectAttributes.length; i++) {
      const currAttr = this.objectAttributes[i];
      let formattedAppField = currAttr?.name?.replace(/[\-_ ?*]/g, '').toLowerCase();

      for (let j = 0; j < this.sourceFields.length; j++) {
        const currSourceField = this.sourceFields[j];
        let formattedSourceField = currSourceField?.name?.replace(/[\-_ ?*]/g, '')?.toLowerCase();
        // const preDefinedMappingCondition = this.preDefinedMapping && this.preDefinedMapping[currAttr.__id]?.__id == currSourceField.__id;

        if (formattedAppField == formattedSourceField) {
          let autoMapObj = {
            appField: currAttr,
            sourceField: currSourceField,//.name,
            mappingType: this.selectedMappingType.value,
          };
          const isMappingExist = this.allMapping.some(
            (mapObj) => mapObj.appField.name.replace(/[\-_ ?*]/g, '').toLowerCase() == formattedAppField
          );
          // if (preDefinedMappingCondition && isMappingExist) {
          //   this.handlePredefinedMapping(currAttr, autoMapObj);
          // }

          if (!isMappingExist) this.autoMappedArr.push(autoMapObj);
        }
      }
    }

    if (!this.clientPlatformService.isAutoMapping || type == 'manual') {
      console.log('Opening mapping dialog...', this.autoMappedArr);
      let autoMapArrLength = this.autoMappedArr.length;

      if (!autoMapArrLength && type == 'manual') {
        this.clientPlatformService.openSnackBar("Sorry, there is no recommendation of automatic mapping");
      } else if(autoMapArrLength){
        this.openAutoMappingDialog();
      }
    }
  }

  handlePredefinedMapping(currAttr: any, mapObj: any) {
    const currAtrrId = currAttr.__id;
    const index = this.allMapping.findIndex((mObj) => mObj.appField.__id === currAtrrId);

    if (currAttr.dataType == "array" && currAttr.semanticType == "lineItem") {
      if (this.itemsMappingArr[currAtrrId]?.[0]?.length == 0) {
        const lineMapObj = this.preDefinedMapping[currAtrrId]?.lineMapObj || {};
        const itemFields = Object.keys(lineMapObj);
        for (const itemField of itemFields) {
          const { __id, reference, referenceField } = lineMapObj[itemField] || {};
          const sourceField = this.sourceFields.find((sourceField) => sourceField.__id === __id);

          if (sourceField && reference) {
            sourceField.reference = reference;
            sourceField.referenceField = referenceField;
          }

          const itemMapObj = {
            appField: currAttr.semanticOptions.fields[itemField],
            sourceField,
            mappingType: "sourceField"
          }
          this.itemsMappingArr[currAtrrId][0].push(itemMapObj);
        }
      }
      mapObj.sourceField = this.itemsMappingArr[currAtrrId];
    }

    if(index !== -1) this.allMapping[index] = mapObj;
  }

  onSelectionOfAttr(value: any) {
    if (value?.dataType == "array" && value.semanticType === "lineItem") {
      if (!this.itemsMappingArr[value.__id]) {
        this.itemsMappingArr[value.__id] = [[]]
      }
    }

    //enable richtest for html type
    if(value?.dataType == "HTML") {
      value.isRichText = true;
      this.richTextTemplate = true;
    }

    if (value === "AW_CREATE_NEW_ATTRIBUTE") {
      this.openMappingAddAttrDialog();
      return;
    } else return;
  }

  openMappingAddAttrDialog(): void {

    const dialogRef = this.dialog.open(MappingAddAttributeDialog, {
      data: {
        attribute: {
          name: `Attribute_${this.objectAttributes.length + 1}`,
          dataType: "string",
        },
        options: {
          object: this.appInfo?.object,
          connection: this.appInfo?.connection,
          isCreateAttribute: this.appInfo?.object?.supports?.includes('customattribute') || false //create custom attribute if needed
        }
      },
    });

    dialogRef.afterClosed().subscribe(async attrResp => {
      console.log('The dialog was closed', attrResp);
      const attrObj = attrResp?.result?.[0];
      if(attrObj) {
        this.selectedAppField.setValue(attrObj);
        this.objectAttributes.push(attrObj);
      };
    });
  }

  openMappingDrillDownDialog(): void {
    const dialogRef = this.dialog.open(MappingDrillDownDialog, {
      width: "500px",
      data: {__id: "", name: "", dataType: "string"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if(!result?.__id) return;
      result.name = result.__id;
      this.selectedAppField.setValue(result);
      this.objectAttributes.push(result);
    });
  }

  openSourceMappingDrillDownDialog(): void {
    const dialogRef = this.dialog.open(MappingDrillDownDialog, {
      data: {__id: "", name: "", dataType: "string"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (!result?.__id) return;
      result.name = result.__id;
      this.selectedSourceField.setValue(result);
      this.sourceFields.push(result);
    });
  }

  initChildObjectMapping() {
    if(!this.childObjectLineItemFieldsMap[this.selectedSourceFieldId]) {
      this.childObjectLineItemFieldsMap[this.selectedSourceFieldId] = this.getSourceLineItemFields();
    }
    if(!this.childObjectNewObjectData) this.setChildObjectNewObjectDataObj();
  }

  setChildObjectNewObjectDataObj() {
    if(!this.childObjectAppInfo) this.childObjectAppInfo = JSON.parse(JSON.stringify(this.appInfo));
    if(!this.childObjectSourceInfo) this.childObjectSourceInfo = JSON.parse(JSON.stringify(this.sourceInfo));

    this.childObjectNewObjectData = {
      appInfo: this.childObjectAppInfo,
      sourceInfo: this.childObjectSourceInfo,
      sourceObjName: `${this.childObjectSourceInfo.object} ${this.selectedSourceField.value.name}`,
      appObjName: this.childObjectAppInfo.app,
      attributes: this.childObjectLineItemFieldsMap[this.selectedSourceFieldId]
    }
  }

  async onChildObjectSelected(value: any) {
    console.log('Child Object Selected', value);
    if (value.__id !== this.previousChildObjectId) {
      this.childObjectMappings = [];
    }
    this.selectedChildObject = value;
    const event = { __id: this.selectedChildObject.__id, cancelSave: true };
    this.childObjectFields = await this.clientPlatformService.getObjectAttributes(event, { _id: this.appInfo?.connection?._id });
    this.childObjectAppInfo.object = this.selectedChildObject;
    this.childObjectAppInfo.label = `${this.childObjectAppInfo.app} ${this.selectedChildObject.name} field`;
    this.childObjectSourceInfo.label = `${this.childObjectSourceInfo.app} ${this.childObjectSourceInfo.object} ${this.selectedSourceField.value.name} field`;
    this.isChildObjectFieldsFetched = Boolean(this.childObjectFields.length);
  }

  exportObjectSelectionError(err){}

  setChildObjectMapping(value: any) {
    console.log('Export mapping', value)
    this.childObjectMappings = value;
  }

  getSourceLineItemFields() {
    const lineItemFields = [];
    const lineItemFieldObj = this.sourceFields.find((field) => field.__id == this.selectedSourceFieldId)
    if (lineItemFieldObj) {
      const fields = lineItemFieldObj.semanticOptions?.fields;
      for (const field of fields.list) {
        lineItemFields.push(fields[field]);
      }
    }
    const primaryField = this.sourceFields.find((field) => field.primary);

    if(primaryField) {
      delete primaryField.primary;
      primaryField.foreignKey = true;
      lineItemFields.unshift(primaryField);
    }
    return lineItemFields;
  }
}
