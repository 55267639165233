<div *ngIf="builderMode" class="outerCover" fxLayout [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover" fxLayout="column" fxLayoutAlign="flex-start center">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <!-- <div fxFlexFill>
      <ng-container *ngIf="!editingHeading; else elseTemplate">
        <div class="editHeadingBlock" fxFlexFill fxLayout="row wrap"
          [style.justify-content]="widgetMeta?.config?.alignment?.value" fxLayoutGap=".5rem">
          <div [class.blurred]="!widgetMeta.config.title.show" [ngStyle]="styles">{{
            widgetMeta.config.title?.value }}</div>
          <mat-icon [class.blurred]="!widgetMeta.config.title?.show" class="icon editHeadingIcon"
            (click)="editingHeading = widgetMeta.config.title?.show ? true : false; $event.stopPropagation()"
            matTooltip="Edit Title">edit</mat-icon>
          <mat-icon class="icon" *ngIf="widgetMeta.config.title?.show"
            (click)="widgetMeta.config.title.show = false; $event.stopPropagation()" matTooltip="Hide Title">
            visibility_off</mat-icon>
          <mat-icon class="icon" *ngIf="!widgetMeta.config.title?.show"
            (click)="widgetMeta.config.title.show = true; $event.stopPropagation()" matTooltip="Show Title">
            visibility</mat-icon>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-form-field class="editHeadingBlock" style="width: 100%">
          <mat-label>Change Title</mat-label>
          <input matInput #newHeading type="text" [value]="widgetMeta.config.title.value"
            (click)="$event.stopPropagation()">
          <mat-icon matSuffix class="icon saveHeadingIcon"
            (click)="editingHeading = false; saveNewHeading(newHeading); $event.stopPropagation()">done</mat-icon>
        </mat-form-field>
      </ng-template>
    </div> -->


    <div style="height: .5rem; width: 100%;"></div>

    <span fxLayout fxLayoutAlign="center center" (click)="onClick($event)" (mousedown)="$event.stopPropagation()"
      style="width: 80%" fxLayoutGap="1rem">
      <ng-container *ngFor="let i of stars; let index = index; trackBy: trackByFn">
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i === 0" (click)="starred(index + 1)">{{ 'star_outline' }}
        </mat-icon>
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i === 1" (click)="starred(index + 1)">{{ 'star' }}
        </mat-icon>
      </ng-container>
    </span>
  </div>
</div>


<div
  class="outerCover" *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)" (click)="onClick($event)"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" fxLayout="column" fxLayoutAlign="center center">
    <!-- <div *ngIf="widgetMeta.config.title?.show" class="editHeadingBlock" fxLayout="row wrap"
      fxLayoutAlign="center center" fxLayoutGap=".5rem" style="width: 100%;">
      <span [ngStyle]="styles">{{ widgetMeta.config.title.value }}</span>
      <div style="height: .5rem; width: 100%"></div>
    </div> -->
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <div class="innerCover" style="width: 80%;" fxLayout fxLayoutAlign="space-around center" (click)="onClick($event)">
      <ng-container *ngFor="let i of stars; let index = index; trackBy: trackByFn">
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i === 0" (click)="starred(index + 1)"
          (mousedown)="$event.stopPropagation()">{{ 'star_outline' }}</mat-icon>
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i === 1" (click)="starred(index + 1)"
          (mousedown)="$event.stopPropagation()">{{ 'star' }}</mat-icon>
      </ng-container>
    </div>
    <div>
      <!-- Error & Note handling -->
      <mat-error *ngIf="this.widgetMeta.config.required?.value && widgetMeta.config.value?.value === 0">Rating is required</mat-error>
      <mat-hint *ngIf="widgetMeta.note">
        <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
        <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
      </mat-hint>
    </div>
  </div>
</div>
