import { EmailValidator } from "./emailValidator";
import { MinLengthValidator } from "./minLengthValidator";
import { MaxLengthValidator } from "./maxlengthValidator";
import { urlValidator } from "./urlValidator";
import { RegexValidator } from "./regexValidator";
import { ContainsValidator } from "./containsValidator";

import { MinValueValidator } from "./minValueValidator";
import { MaxValueValidator } from "./maxValueValidator";

export class ValidatorRegistry {

    constructor(){}

    static registry: any = {
        email: EmailValidator,
        minlength: MinLengthValidator,
        maxlength: MaxLengthValidator,
        url: urlValidator,
        regex: RegexValidator,
        contains: ContainsValidator,
        minvalue: MinValueValidator,
        maxvalue: MaxValueValidator 
    }

    static getValidator(validatorType: string){
        let ValidatorClass = ValidatorRegistry.registry[validatorType];
        if (!ValidatorClass) throw "No validation available: " + validatorType;
        return ValidatorClass;
    }

    static getValidators(supportedValidators?: any){
        let result: any[] = [];
        if(supportedValidators && supportedValidators?.length){
            for(var type of supportedValidators){
                var macroDescriptor = this.getValidator(type)
                result.push(macroDescriptor)
            }
        }

        return result;
    }

    static validate(validatorType:string, val:any, inputParams:any){
        let ValidatorClass = ValidatorRegistry.registry?.[validatorType];
        if(!ValidatorClass) return
        let obj = new ValidatorClass()
        let res = obj.validate(val, inputParams)
        return res;
    }
}