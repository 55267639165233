import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, Inject, Injector, LOCALE_ID } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject, Subscription } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { formatDate } from '@angular/common';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { NgModel } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { DateAdapter } from '@angular/material/core';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.css'],
    standalone: false
})
export class DateComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: {};
  @Output() dateReceived: EventEmitter<any> = new EventEmitter();
  setDateformat: any;
  value = "";
  hoveredNow: boolean = false;
  displayValue: any;
  oldMeta: any
  validationSubscription: Subscription;
  private destroy:any = new Subject();

  @ViewChild('menuTrigger', { static: false }) dateMenuTrigger: MatMenuTrigger
  @ViewChild('dateInput', { static: false }) dateInput: NgModel;

  constructor(
    public metaService: MetaService,
    @Inject(LOCALE_ID)
    private locale: string,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService,
    public themeService: ThemeService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public wmtService: WidgetMetaTransformationService,
    public injector: Injector
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService, injector)
    const browserLocale = navigator.language || 'en-US';
    this.dateAdapter.setLocale(browserLocale);
  }

  private getExpressionUtility(): ExpressionUtility {
    return this.injector.get(ExpressionUtility);
  }

  ngOnInit(): void {

    super.ngOnInit()
    this.initValue()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
        this.initValue()
      }
    })

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.dateInput.control.markAsTouched()
      }
    })

    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in date", widgetMeta)
      this.decompressMetaAndAssign(widgetMeta)
      this.initValue()
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    this.validationSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.dateMenuTrigger && this.dateMenuTrigger.menuOpen) {
        this.dateMenuTrigger.closeMenu();
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      this.initValue()
    }
  }
  ngDoCheck(): void {
    // console.log("do check fired: old", JSON.parse(JSON.stringify(this.oldMeta || {})), "new", JSON.parse(JSON.stringify(this.widgetMeta || {})))
    // if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
    //   // console.log("change detected in widgetMeta")
    //   this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
    //   this.initValue()
    // }
  }


  //----------------------------------- FUNCTIONS ---------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "appearance",
        "bold",
        "underline",
        "color",
        "backgroundColor",
        "italic",
        "fontSize",
        "fontFamily",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        type: this.widgetMeta?.config?.appearance?.type
      },
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
    if (event.actionType == "updateStyles") {
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        if (!event.noEmit) this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
      }
    }
  }


  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("date widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.dateMenuTrigger.closeMenu();
  }

  inputDetected(event: any) {
    this.value = event.value
    this.emitOutput()
    // let userInput: any = {
    //   dataBindConfig: this.widgetMeta?.dataBindConfig,
    //   widgetId: this.widgetMeta.id,
    //   value: `${new Date(event.value).toISOString()}|date`
    // }
    // if (this.value !== '') {
    //   this.userInputReceived.emit(userInput);
    // }

    // if(this.value !== ''){
    //   this.dateReceived.emit(dateInput)
    // }
  }

  initValue(){
    console.log("initValue: widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    // console.log('CURRENT LOCALE---------------------------', this.themeService.currentLocale)
    let timeZone = this.themeService.currentLocale.timeZone;
    // this.setTimeformat = this.themeService.currentLocale.timeFormat

    //set date format
    if(this.themeService.currentLocale.dateFormat) {
      this.setDateformat = this.themeService.currentLocale.dateFormat;
    } else {
      this.setDateformat = "MMM-dd-yyyy";
    }

    console.log('SET DATE FORMAT', this.setDateformat)
    //setting default value if the value doesn't exist
    if ((this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') && this.widgetMeta.config.defaultValue.value) {
      this.widgetMeta.config.value.value = this.widgetMeta.config.defaultValue.value;
    }

    // if(!this.setTimeformat) this.setTimeformat = "hh:mm a";

    if (this.widgetMeta.config.defaultValue?.dynamic && this.widgetMeta.config?.expressionConfig?.id == '__currentdatetime__') {
      this.value = this.getExpressionUtility().resolveExpression(this.widgetMeta.config?.expressionConfig);
    } else {
      this.value = JSON.parse(JSON.stringify(this.widgetMeta?.config?.value?.value || ""));
      if(this.value?.endsWith("|date")) this.value = this.value.slice(0, -5);    // discard the '|date' if exists
      this.value = this.value.substring(0, 16)  // discard the seconds and miliseconds from iso date string
    }
    console.log("value", this.value)

    if (this.value) {
      if (this.widgetMeta.config.viewOnly.value) {
        const isoDate = new Date(this.value).toISOString();
        this.displayValue = formatDate(isoDate, this.setDateformat, this.locale, timeZone)
      } else {
        this.displayValue = this.formatDateForDateInput(new Date(this.value).toISOString())
      }
    }

    // if (this.widgetMeta.config.viewOnly.value && this.value) {
    //   if(!this.builderMode) {
    //     const isoDate = new Date(this.value).toISOString();
    //     this.displayValue = formatDate(isoDate, this.setDateformat, this.locale, timeZone)
    //   } else {
    //     this.displayValue = this.formatDateForDateInput(new Date(this.value).toISOString())
    //   }
    // }
    // else {
    //   this.value = new Date().toISOString()
    //   this.displayValue = this.formatDateForDateInput(new Date(this.value).toISOString())
    //   this.emitOutput()
    // }
    // console.log("this.displayValue", this.displayValue)
  }

  formatDateForDateInput(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  emitOutput(){
    console.log("hit emitOutput: value", this.value)
    if(!this.value) return
    let dateInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: ``
    }
    if (this.widgetMeta.config.required?.value) {
      dateInput.validity = this.dateInput?.valid || false
    }
    if(this.widgetMeta.config.customOutputFormat?.enabled){
      let isoDate = new Date(this.value).toISOString()
      console.log("ISO date", isoDate)
      let formattedDate = formatDate(isoDate, this.widgetMeta.config.customOutputFormat.customDateFormat, this.locale)
      dateInput.value = formattedDate
      console.log("formatted date is", formattedDate)
    }else{
      console.log("no custom output format, will emit ISO")
      dateInput.value = `${new Date(this.value).toISOString()}|date`
    }
    console.log("emitting data", dateInput)
    this.userInputReceived.emit(dateInput);
  }

}
