import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  localeChangeEvent = new Subject<string>();
  currentLocale: string = 'en';
  languageList = {
    en: 'English',
    fr: 'Français'
    // ru: 'Pусский',
    // ja: '日本語'
  };

  constructor(private translate: TranslateService) { }

  changeLocale(locale: string) {
    this.currentLocale = locale;
    this.translate.use(locale);
    this.localeChangeEvent.next(locale);
  }
}
