<div fxLayout="row" fxLayoutAlign="start center" style="margin-left: 2.5%;">
  <img [src]="baseURL + 'form-builder/aw-form-builder-logo.png'" class="formIcon">
  <h2 mat-dialog-title>{{ publishSuccess ? "Form Published" : "Create a Form from " +  dialogData.objectId}}</h2>
</div>
<mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner style="margin-top: 40vh; position: fixed;" *ngIf="spinner" diameter="50" class="spinner" color="primary"></mat-spinner>
  </div>
  <div *ngIf="!publishSuccess">
    <app-create-form-detail [input]="{hideTitle: true}" (nameChanged)="nameChanged($event)"></app-create-form-detail>
     <app-action-selection *ngIf="dialogData?.box?.__id"
       [config]="{
           box_id: dialogData.box.__id,
           connection: dialogData.connection._id,
           boxObjectId: dialogData.objectId,
           hideSelection: true
       }"
       [existingActionId]="actionId"
        (actionFields)="actionSelected($event)"
        (attributeOptionsInput)="attributeOptionsInput($event)"
      ></app-action-selection>
      <app-form-attribute-selection *ngIf="submitWidget"
          [widgetMeta]="submitWidget" [attributes]="objectAttributes"
          (attibutesSelected)="attibutesSelected($event)">
      </app-form-attribute-selection>
  </div>
  <div *ngIf="publishSuccess" class="publish-success-container">
    <div class="url-display-section">
      <div class="subSection" fxLayout="column" fxLayoutAlign="center stretch" fxLayout.xs="column">
        <div class="blockTitle" fxFlex="100" fxLayout fxLayoutAlign="center center">Published URL</div>
        <div class="publishedUrlBlock" fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
          <div class="publishedUrlText" fxFlex="80">{{ fullPublishedUrl }}</div>
          <div class="copyIcon" fxFlex="10">
            <button mat-icon-button color="primary" matTooltip="Copy URL" [cdkCopyToClipboard]="fullPublishedUrl">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <div class="navigateIcon" fxFlex="10">
            <button mat-icon-button color="primary" matTooltip="View published list" (click)="navigateToNewPublished()">
              <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>


<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <button mat-stroked-button color="warn" mat-dialog-close>{{ publishSuccess ? "Close" : "Cancel" }}</button>
  <button mat-raised-button color="primary" *ngIf="!publishSuccess" (click)="onSave()">Publish</button>
</mat-dialog-actions>
