import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SmsService } from '../services/sms-service.service';
import { AuthServiceService } from '../services/auth-service.service';
import { SpinnerService } from '../spinner/spinner.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';


@Component({
    selector: 'qouta-popup',
    templateUrl: './qouta-popup.component.html',
    styleUrls: ['./qouta-popup.component.scss'],
    standalone: false
})
export class QoutaPopupComponent implements OnInit {
  processingPayment = false;
  config: any;
  upgradeUrl: any;
  paymentStatusMessage: string | null = null;
  spinner: boolean = false;
  isBrowser: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogRef: any,
    public dialog: MatDialogRef<QoutaPopupComponent>,
    public smsService: SmsService,
    private authService: AuthServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private spinnerSer: SpinnerService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    this.config = this.dialogRef;
  }

  readonly msgMap: any = {
    "APPIWORKS": {
      "CONNECTION": {
        title: "Connection Limit Reached!",
        subTitle: "You're already at full capacity 🚧",
        msg: "Please disconnect some connections or consider upgrading your account to unlock more connections!"
      },
      "BLOOM": {
        title: "Bloom Limit Reached!",
        subTitle: "You're already at full capacity 🚧",
        msg: "Clear space for new growth or upgrade for unlimited blooms!"
      },
      "FLOW": {
        title: "Flow Limit Reached!",
        subTitle: "You're already at full capacity 🚧",
        msg: "Make room for more or level up to experience seamless flow!"
      },
      "STARCH": {
        title: "Starch Limit Reached!",
        subTitle: "You're already at full capacity 🚧",
        msg: "Make room for more or level up to experience seamless flow!"
      }
    },
    "HYPERLIST": {
      "FETCH_RECORDS": {
        title: "HyperList Limit Reached!",
        subTitle: "Your current data quota has been exhausted 📊",
        msg: "You have exceeded the maximum allowed usage. For additional data, please contact your Hyperlist publisher for further assistance and to ensure uninterrupted service."
      }
    }
  };





  ngOnInit(): void {
    console.log("config", this.config);
    let eligibility = this.smsService.checkEligibility(this.config.type, this.config.product);
    console.log("isEligible", eligibility);
    this.upgradeUrl = eligibility.errorMap?.plan_details?.upgrade_url;
    // this.handlePayment();
  }

  upgradePlan() {
    // Fetch the logged-in user's email ID from the AuthServiceService.
    const userEmail = this.authService.getUserEmail();

    if (userEmail) {
      const upgradedUrl = `${this.upgradeUrl}?email=${userEmail}`;
      window.open(upgradedUrl, "_blank");
    } else {
      console.error('User email not available.');
    }
  }


  async handlePayment() {
    console.log('handlePayment start');

    try {
      // Show the spinner
      this.spinner = true;

      // await this.authService.checkAndCreateUser();
      await this.smsService.getSMSAuthentication(this.authService.userProfile, this.config.product, true)

      let eligibility = this.smsService.checkEligibility(this.config.type, this.config.product);
      if (eligibility.isEligible) {

        console.log('User is eligible, closing dialog');
        this.spinner = false;
        this.dialog.close(); // Close the dialog
        this.changeDetectorRef.detectChanges(); // Update the view
      } else {
        // User is not eligible, set a payment status message
        console.log('User is not eligible');
        this.paymentStatusMessage = 'Payment details are not yet updated.<br>Please check again later.';
      }
    } catch (error) {
      console.error('Error during payment:', error);
      this.paymentStatusMessage = 'Payment details are not yet updated.<br> <br> Please check again later.';
    } finally {
      this.spinner = false;
    }
  }

}
