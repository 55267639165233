<!-- <table>
  <tbody>

    <tr *ngIf="config.showLetters">
      <td *ngIf="config.showNegative"></td>
      <td id="dp-years-label" *ngIf="config.showYears">{{ config.labels.years }}</td>
      <td id="dp-months-label" *ngIf="config.showMonths">{{ config.labels.months }}</td>
      <td id="dp-weeks-label" *ngIf="config.showWeeks">{{ config.labels.weeks }}</td>
      <td id="dp-days-label" *ngIf="config.showDays">{{ config.labels.days }}</td>
      <td id="dp-hours-label" *ngIf="config.showHours">{{ config.labels.hours }}</td>
      <td id="dp-minutes-label" *ngIf="config.showMinutes">{{ config.labels.minutes }}</td>
      <td id="dp-seconds-label" *ngIf="config.showSeconds">{{ config.labels.seconds }}</td>
    </tr>

    <tr *ngIf="config.showButtons">
      <td *ngIf="config.showNegative"></td>
      <td *ngIf="config.showYears">
        <button mat-icon-button id="dp-years-up" (click)="years = years + config.unitSteps.years"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showMonths">
        <button mat-icon-button id="dp-months-up" (click)="months = months + config.unitSteps.months"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showWeeks">
        <button mat-icon-button id="dp-weeks-up" (click)="weeks = weeks + config.unitSteps.weeks"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showDays">
        <button mat-icon-button id="dp-days-up" (click)="days = days + config.unitSteps.days"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showHours">
        <button mat-icon-button id="dp-hours-up" (click)="hours = hours + config.unitSteps.hours"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showMinutes">
        <button mat-icon-button id="dp-minutes-up" (click)="minutes = minutes + config.unitSteps.minutes"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showSeconds">
        <button mat-icon-button id="dp-seconds-up" (click)="seconds = seconds + config.unitSteps.seconds"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_up</mat-icon>
        </button>
      </td>
    </tr>

    <tr>
      <td *ngIf="config.showNegative">
        <button mat-icon-button id="dp-sign" (click)="negative = !negative" [ngClass]="{ 'disabled': disabled }">
          <mat-icon [ngStyle]="{color: negative?'red':'green'}">{{negative?'remove':'add'}}</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showYears">
        <input matInput (blur)="onTouched()" type="number" placeholder="Y" id="dp-years" [(ngModel)]="years"
          [disabled]="disabled">
      </td>

      <td *ngIf="config.showMonths">
        <input (blur)="onTouched()" type="number" placeholder="M" id="dp-months" [(ngModel)]="months"
          [disabled]="disabled">
      </td>

      <td *ngIf="config.showWeeks">
        <input (blur)="onTouched()" type="number" placeholder="W" id="dp-weeks" [(ngModel)]="weeks"
          [disabled]="disabled">
      </td>

      <td *ngIf="config.showDays">
        <input (blur)="onTouched()" type="number" placeholder="D" id="dp-days" [(ngModel)]="days" [disabled]="disabled">
      </td>

      <td *ngIf="config.showHours">
        <input (blur)="onTouched()" type="number" placeholder="H" id="dp-hours" [(ngModel)]="hours"
          [disabled]="disabled">
      </td>

      <td *ngIf="config.showMinutes">
        <input (blur)="onTouched()" type="number" placeholder="M" id="dp-minutes" [(ngModel)]="minutes"
          [disabled]="disabled">
      </td>

      <td *ngIf="config.showSeconds">
        <input (blur)="onTouched()" type="number" placeholder="S" id="dp-seconds" [(ngModel)]="seconds"
          [disabled]="disabled">
      </td>

      <td *ngIf="config.showPreview">
        <span id="dp-duration-value">{{ preview }}</span>
      </td>
    </tr>

    <tr *ngIf="config.showButtons">
      <td *ngIf="config.showNegative"></td>

      <td *ngIf="config.showYears">
        <button mat-icon-button id="dp-years-down" (click)="years = years - config.unitSteps.years"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showMonths">
        <button mat-icon-button id="dp-months-down" (click)="months = months - config.unitSteps.months"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showWeeks">
        <button mat-icon-button id="dp-weeks-down" (click)="weeks = weeks - config.unitSteps.weeks"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showDays">
        <button mat-icon-button id="dp-days-down" (click)="days = days - config.unitSteps.days"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showHours">
        <button mat-icon-button id="dp-hours-down" (click)="hours = hours - config.unitSteps.hours"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showMinutes">
        <button mat-icon-button id="dp-minutes-down" (click)="minutes = minutes - config.unitSteps.minutes"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>

      <td *ngIf="config.showSeconds">
        <button mat-icon-button id="dp-seconds-down" (click)="seconds = seconds - config.unitSteps.seconds"
          [ngClass]="{ 'disabled': disabled }">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </td>
    </tr>

  </tbody>
</table> -->
@if(durationObj) {
  <mat-card fxLayout="row" fxLayoutAlign="space-between center" class="duration-picker-card">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <input type="number" [(ngModel)]="durationObj.years" class="duration-input" min="0" max="99" [disabled]="disabled">
      <span>Years</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <input type="number" [(ngModel)]="durationObj.months" class="duration-input" min="0" max="12" [disabled]="disabled">
      <span>Months</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <input type="number" [(ngModel)]="durationObj.weeks" class="duration-input" min="0" max="99" [disabled]="disabled">
      <span>Weeks</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <input type="number" [(ngModel)]="durationObj.days" class="duration-input" min="0" max="99" [disabled]="disabled"> 
      <span>Days</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <input type="number" [(ngModel)]="durationObj.hours" class="duration-input" min="0" max="24" [disabled]="disabled">
      <span>Hours</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <input type="number" [(ngModel)]="durationObj.mins" class="duration-input" min="0" max="59" [disabled]="disabled">
      <span>Mins</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <input type="number" [(ngModel)]="durationObj.secs" class="duration-input" min="0" max="59" [disabled]="disabled">
      <span>Secs</span>
    </div>
  </mat-card>
}
