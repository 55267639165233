import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlParamsService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  // Function to parse the dynamic part of the URL into an object
  parseUrlToObj(pathUrl?: any): { [key: string]: string } {
    const url = pathUrl || this.router.url;
    const paramIndex = url.indexOf('/q/')
    if (paramIndex !== -1) {
      let params = url.substring(paramIndex + 3).split('/');
      const obj: { [key: string]: string } = {};
      for (let i = 0; i < params.length; i += 2) {
        const key = params[i];
        const value = params[i + 1];
        if (key && value) {
          try {
            obj[key] = decodeURIComponent(value);
          } catch (e) {
            obj[key] = value; // Fallback in case decoding fails
          }
        }
      }

      return obj;
    }
    return {};
  }
}
