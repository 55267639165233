import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { SpinnerService } from '../spinner/spinner.service';
import { AuthServiceService } from '../services/auth-service.service';
import { ConnectionDialogComponent } from '../dialog/connection-dialog/connection.dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-common-member',
    templateUrl: './common-member.component.html',
    styleUrls: ['./common-member.component.scss'],
    standalone: false
})
export class CommonMemberComponent implements OnInit {
  workspaceId: any;
  permissions: any = [];
  usersMap: any = {};
  roleMap: any = {};
  updatePermission: any;
  selectedRoles: any[] = [];
  existingRoles: any[] = [];


  @Input() resourceMap: any;
  @Input() restoreInput: any;
  @Input() resourceType: string;
  @Input() availableRoles: any;
  spinner: boolean = false;
  superAdminRole: any[];

  constructor(
    private route: ActivatedRoute,
    public workspaceService: WorkspaceService,
    public spinnerService: SpinnerService,
    public adminService: AdminService,
    public connectionService: ConnectionService,
    public security: PermissionService,
    public resourcePermissions: ResourcePermissionService,
    private _dialog: MatDialog,
    public authService: AuthServiceService,
  ) {}

  ngOnInit(): void {
    this.workspaceId = this.connectionService.selectedWorkSpace;
    console.log('this.workspaceId', this.workspaceId);
    console.log('Resource Map', this.resourceMap);
    console.log('PROFILE ', this.authService.profile);
    this.getPermissions();
  }

  ngOnChanges() {
    this.getPermissions();
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  hasDeleteAccess(){
    let result = false;
    if (this.resourceType == 'bloom'){
      return this.resourcePermissions.hasAccess(['bloom.delete']);
    }
    return result;
  }

  hasEditAccess(){
    let result = false;
    if (this.resourceType == 'bloom'){
      return this.resourcePermissions.hasAccess(['bloom.management']);
    }
    return result;
  }

  async getPermissions(isClearCache: boolean = false) {
    var ownerRole = await this.adminService.getOwnerRole();
    this.spinner = true;
    var permissions: any = {};
    if (this.resourceType == 'bloom' && this.resourceMap?.isPermissionDelinked) {
      permissions = await this.resourcePermissions.getPermissions(this.resourceMap.code, isClearCache);
      console.log('bloomMap permissions', permissions);
    } else {
      permissions = await this.resourcePermissions.getPermissions(this.resourceMap._id, isClearCache);
      console.log(this.resourceType, 'Map permissions', permissions);
    }

    var permissionMap = [
      {
        user_id: this.authService.profile._id,
        workspace_id: this.workspaceId,
        resource_type: this.resourceType,
        resource_id:
          this.resourceType == 'bloom'
            ? this.resourceMap.code
            : this.resourceMap._id,
        status: 'Active',
        created_by: this.authService.profile.email,
        modified_by: this.authService.profile.email,
        roles: [ownerRole._id],
      },
    ];
    if(permissions.length == 0) permissions = permissionMap.concat(permissions);
    this.permissions = permissions;
    console.log('FINAL PERMISSIONS ARRAY : ', this.permissions);

    var userIds = [];
    var rolesIds = [];
    permissions.forEach((e) => {
      userIds.push(e.user_id);
      rolesIds = rolesIds.concat(e.roles);
    });
    var uniqueRoleIds = [];
    rolesIds.forEach((c) => {
      if (!uniqueRoleIds.includes(c)) {
        uniqueRoleIds.push(c);
      }
    });
    console.log('uniqueRoleIds', uniqueRoleIds);

    var roles = await this.adminService.getRolesbyIds(
      uniqueRoleIds,
      this.connectionService.preAuthenticatedToken
    );
    if (roles) {
      roles.forEach((e) => {
        this.roleMap[e._id] = e;
      });
    }

    var users = await this.workspaceService.getUserbyIds(userIds);
    if (users) {
      users.forEach((e) => {
        this.usersMap[e._id] = e;
      });
    }
    console.log('this.usersMap', this.usersMap);
    console.log('this.roleMap', this.roleMap);

    this.spinner = false;
  }

  setSelectedRole(){
    this.existingRoles = [];
    this.superAdminRole = [];
    this.updatePermission?.roles?.forEach(element => {
      let roleNotMatched = true;
      this.availableRoles.forEach(ele => {
        if(element == ele._id){
          this.existingRoles.push(ele);
          roleNotMatched = false;
        }
      });

      if(roleNotMatched)  this.superAdminRole.push(element);
    });
  }

  selectChangeRoles(event){
    this.selectedRoles = [];
    let data = event?.data || [];
    data.forEach(element => {
      this.selectedRoles.push(element._id);
    });
  }

  async updateResourcePermission(){
    if(this.selectedRoles.length == 0){
      this.updatePermission = undefined
      return;
    }

    var permission = this.updatePermission;
    permission.roles = this.selectedRoles.concat(this.superAdminRole);

    console.log("Permission to be updated : ",permission);
    this.spinner = true
    try {
      await this.resourcePermissions.updatePermission(permission)
      this.getPermissions()
      this.selectedRoles = [];
      this.updatePermission = undefined
    } catch (error) {
      console.log("Update Permission Failed : ",error)
      this.spinner = false
    }
    this.spinner = false
  }

  async deleteResourcePermission(permission){
    let dialogRef = this._dialog.open(ConnectionDialogComponent, {
      data: { name: "Permission" },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.spinner = true
        console.log("Permission to be deleted : ",permission)
        try {
          await this.resourcePermissions.deletePermission(permission._id);
          this.getPermissions();
        } catch (error) {
          console.log("Failed to delete permission : ",error)
          this.spinner = false
        } finally {
          this.spinner = false
        }
      }
    })
  }
}
