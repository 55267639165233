<div
  #outerCover
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onOuterClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div
    *ngIf="securityMap.show;"
    [ngSwitch]="widgetMeta.config.tagType.value"
    class="innerCover"
    fxLayout="row wrap"
  >

  <div>
    <div fxLayoutAlign="start center">
      <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    </div>
    <div fxLayoutAlign="center center"  fxLayout="row wrap">
      <div *ngFor="let item of availableItems; trackBy: trackByFn">
        <button *ngSwitchCase="'Stroked'" [ngStyle]="widgetMeta.effectStyle || styles" mat-stroked-button
          [color]="widgetMeta.config.tagColorType.value" class="toggler" (click)="tagItemClicked($event, item)"
          (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        <button *ngSwitchCase="'Raised'" [ngStyle]="widgetMeta.effectStyle || styles" mat-raised-button
          [color]="widgetMeta.config.tagColorType.value" class="toggler" (click)="tagItemClicked($event, item)"
          (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        <button *ngSwitchCase="'Flat'" [ngStyle]="widgetMeta.effectStyle || styles" mat-flat-button [color]="widgetMeta.config.tagColorType.value"
          class="toggler" (click)="tagItemClicked($event, item)" (dblclick)="onDoubleButtonClicked()"
          (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        <button *ngSwitchDefault [ngStyle]="widgetMeta.effectStyle || styles" mat-button [color]="widgetMeta.config.tagColorType.value"
          class="toggler" (click)="tagItemClicked($event, item)" (dblclick)="onDoubleButtonClicked()"
          (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        &nbsp;

        <ng-template #buttonContent let-item>
          <span >
            {{ item.name || item.value}}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
  </div>
</div>


<div
  #outerCover
  *ngIf="!builderMode && (!widgetMeta?.config?.hidden || !widgetMeta.config?.hidden?.value)"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>



  <div
    [ngSwitch]="widgetMeta.config.tagType.value"
    class="innerCover" *ngIf="securityMap.show"
    fxLayout="row wrap"
  >

  <div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div *ngFor="let item of availableItems; trackBy: trackByFn">
        <button *ngSwitchCase="'Stroked'" [ngStyle]="widgetMeta.effectStyle || styles" mat-stroked-button
          [color]="widgetMeta.config.tagColorType.value" class="toggler" (click)="tagItemClicked($event, item)"
          (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        <button *ngSwitchCase="'Raised'" [ngStyle]="widgetMeta.effectStyle || styles" mat-raised-button
          [color]="widgetMeta.config.tagColorType.value" class="toggler" (click)="tagItemClicked($event, item)"
          (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        <button *ngSwitchCase="'Flat'" [ngStyle]="widgetMeta.effectStyle || styles" mat-flat-button [color]="widgetMeta.config.tagColorType.value"
          class="toggler" (click)="tagItemClicked($event, item)" (dblclick)="onDoubleButtonClicked()"
          (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        <button *ngSwitchDefault [ngStyle]="widgetMeta.effectStyle || styles" mat-button [color]="widgetMeta.config.tagColorType.value"
          class="toggler" (click)="tagItemClicked($event, item)" (dblclick)="onDoubleButtonClicked()"
          (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
          <ng-container *ngTemplateOutlet="buttonContent context: {$implicit: item}"></ng-container>
        </button>
        &nbsp;

        <ng-template #buttonContent let-item>
          <span >
            {{ item.name || item.value}}
          </span>
        </ng-template>
      </div>
    </div>

  </div>


  </div>

</div>
