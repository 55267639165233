<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div class="row" *ngIf="!isCreateRole">
  <!--Connection Table-->
  <div class="column" style="margin: 10px">
    <mat-card appearance="outlined" fxLayout="column">
      <header fxLayoutAlign="start center" fxLayout="row" fxLayout-xs="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="5px">
          <mat-icon [inline]="true" [ngStyle]="{'font-size': '75px'}">manage_accounts</mat-icon>
          <h2>Roles</h2>
        </div>

        <mat-form-field style="width: 70px;" >
            <mat-label>  </mat-label>
            <mat-select [(ngModel)] = "filterResType" (selectionChange)="listRole()">
              <mat-option [value]="filter" *ngFor="let filter of filterResTypes; trackBy: trackByFn">
                {{ filter }}</mat-option>
            </mat-select>
          </mat-form-field>
      </header>


      <div fxLayout="row" *ngIf="roles && roles.length > 0" >
        <div fxLayout="column" fxFlex="100">
            <div   *ngFor="let role of roles;let i = index; trackBy: trackByFn">
                <mat-card   appearance="outlined"   class="list-card" fxLayout="row wrap"
                 layout-wrap fxLayoutAlign="start center"
                   style="word-break: break-all;width: 100%" >
                <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                    {{i + 1}}
                 </div>

                <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{role.name}}
                </div>
                <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{role.code}}
                </div>
                <div matTooltip="Priviladges" fxFlex="20" fxLayoutAlign="center start" fxLayout="column wrap" style="word-break: break-word;">
                  {{role.privileges}}
                </div>
                <div matTooltip="Created by" fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{role.created_by}}
                </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                      <mat-icon *ngIf="resource_type == role.resource_type" style="margin: 0px;cursor: pointer;"  matTooltip="Edit role"
                      (click)="editRole(role)">
                      edit</mat-icon>
                </div>
                <div fxFlex="5" fxLayout="column wrap">
                  <mat-icon *ngIf="resource_type == role.resource_type" style="margin: 0px;cursor: pointer;"  matTooltip="Delete role"
                      (click)="deleteRole(role)">
                      delete</mat-icon>
                  </div>
              </mat-card>
            </div>
        </div>
      </div>


       <!-- <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator> -->

      <div fxLayout="end start" *ngIf="filterResType == 'Bloom'">
        <button mat-stroked-button color="primary"  (click)="isCreateRole = true" style="margin-top: 10px">
          <mat-icon>add</mat-icon><span style="margin-top: 10px">Create</span>
        </button>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="isCreateRole">
    <app-role-create [roleConfig]="roleConfig" [role]="" (listRole)="listRole()" > </app-role-create>
</div>
