import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, NgModule, OnInit, NgZone, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { BoxService } from 'src/app/shared/services/box.service';
import { ConnectionDialogComponent } from '../../../../shared/dialog/connection-dialog/connection.dialog';
import { SnackbarComponent } from './../../../../shared/snackbar/snackbar.component';
import { ConnectionService } from '../../connection.service';
import { InputparameterDialogComponent } from './inputparameter-dialog/inputparameter-dialog.component';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location, isPlatformBrowser } from '@angular/common';
import { CustomObjectAttributeDialogComponent } from './custom-object-attribute-dialog/custom-object-dialog.component';
import { ConnectionExplorerSidePanelComponent } from './connection-explorer-side-panel/connection-explorer-side-panel.component';
import { ConnectionExplorerDeleteRecordDialogComponent } from './connection-explorer-delete-record-dialog/connection-explorer-delete-record-dialog.component';
import { FormPanel } from 'src/app/bloom/models/panelClasses/formPanel';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { PublishService } from 'src/app/shared/services/publish.service';
import { ListPublishingDialogComponent } from 'src/app/shared/list-publishing-dialog/list-publishing-dialog.component';
import { ObjectExplorerService } from 'src/app/shared/object-explorer/object-explorer.service';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon: string;
  snackBarError: boolean;
  snackBarDuration: number;
}

interface DataNode {
  name: string;
  children?: any;
  supports?: any[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  supports?: any[];
}

@Component({
    selector: 'app-connection-explorer',
    templateUrl: './connection-explorer.component.html',
    styleUrls: ['./connection-explorer.component.scss'],
    standalone: false
})
export class ConnectionExplorerComponent implements OnInit {
  snackBarObj: snackBarValues;
  navigation: any;
  connection: any;
  starch: any;
  boxLogo: any;
  box: any;
  workspaceId: any;
  preAuthenticatedToken: any;
  spinner: boolean = true;
  objects: any[];
  actions: any[];
  events: any[];

  ACTION_DATA: DataNode[] = [{ name: 'Actions', children: [{ load: true }] }];
  OBJECT_DATA: DataNode[] = [{ name: 'Objects', children: [{ load: true }] }];
  EVENT_DATA: DataNode[] = [{ name: 'Events', children: [{ load: true }] }];

  boxObjectFunctionInputLists: any;
  showInputField: boolean = false;
  lists: any = [];
  builderMode: boolean = false;
  editButtonMeta: any = {};
  attributeFetched: any;

  objectRefreshSpinner: boolean = false;
  actionRefreshSpinner: boolean = false;
  customAttributeSpinner: boolean = false;

  newTimerId: any = '';
  authError: boolean = false;

  currentBaseUrl: string = ""

  private _transformer = (node: DataNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      supports: node.supports,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSourceObjects: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  dataSourceActions: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  dataSourceEvents: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  userQueryObj: any;
  isBrowser: any;

  get spinnerService() {
    return this._spinnerService;
  }

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private boxService: BoxService,
    private connectionService: ConnectionService,
    private dialog: MatDialog,
    private _spinnerService: SpinnerService,
    public authService: AuthServiceService,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private location: Location,
    private publishService: PublishService,
    public clientPlatformService: ClientPlatformService,
    private objectExplorerService: ObjectExplorerService,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  async ngOnInit(){
    if(!this.isBrowser) return;
    this.connection = this.navigation?.extras?.state?.connection;
    if (this.connection?.status == 'FAILED'){
      let msg = 'Could not establish the connection. Please Re-Authorize the connection'
      this.clientPlatformService.openErrorActionSnackBar(msg, 'Re-Authorize', () => {
        let workspaceId = this.navigation?.extras?.state?.workspaceId;
        this.editConnectionData(this.connection, workspaceId)
      })
      return;
    } else {
      console.log("on init")
      this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
      this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl

      console.log("this.connectionService.selectedWorkSpace", this.connectionService.selectedWorkSpace)
      console.log("this.authService.loggedIn", this.authService.loggedIn)
      if (
        !this.connectionService.selectedWorkSpace &&
        !this.authService.loggedIn
      ) {
        this.authService.authCheck();
        this.authService.authCheckPositive.subscribe((authStatus) => {
          //if logged in
          if (authStatus) {
            this.spinner = true;
            console.log('logged in');
            this.themeService.loadTheme();
            this.themeService
              .getExistingTheme(this.authService.profile._id)
              .then((res: any) => {
                if (res?.data != null) {
                  console.log(res);
                  this.themeService.settings_Id = res?.data[0]._id;
                  let theme = res.data[0].themeSetting.theme;
                  this.themeService.setTheme(theme);
                  this.themeService.currentLocale = res.data[0].localeSetting;
                  this.themeService.textDirection =
                    res.data[0].themeSetting.direction;
                  if (res.data[0].themeSetting.direction == 'rtl') {
                    this.themeService.enableDirMode('rtl');
                  }
                }
              });
            this.spinner = false;
            this.initialize();
          } else {
            this.authError = true;
            this.spinner = false;
            // this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
            //   horizontalPosition: 'center',
            //   verticalPosition: 'top',
            // });
            // redirect to login page
            this.ngZone.run(() => {
              this.router.navigate(['../../'], { relativeTo: this.route });
            });

            return;
          }
        });
      } else if (this.connectionService.selectedWorkSpace) {
        this.initialize()
      }
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.newTimerId);
    this.authService?.authCheckPositive?.unsubscribe()
  }

  initialize(){
    if (this.navigation?.extras?.state?.connection) {
      this.connection = this.navigation?.extras?.state?.connection;
      console.log('CONNECTION', this.connection);
    } else if (this.navigation?.extras?.state?.starch) {
      this.starch = this.navigation?.extras?.state?.starch
      console.log('STARCH BASE', this.starch);
    }
    this.spinner = false;

    this.dataSourceObjects.data = this.OBJECT_DATA;
    this.dataSourceActions.data = this.ACTION_DATA;
    this.dataSourceEvents.data = this.EVENT_DATA;

    this.getBox();
    // this.getActions();
    // this.getEvents();
    // this.getObjects();
  }

  editConnectionData(connection: any, workspaceId: string) {
    this.router.navigate(['connection/newconnection'], {
      queryParams: {
        workspaceid: workspaceId || this.connectionService.workSpaceId,
        authorization: encodeURIComponent(
          this.connectionService.preAuthenticatedToken
        ),
        isEdit: true,
      },
      state: { connection: connection },
    });
  }

  async getBox() {
    try {
      this.box = await this.connectionService.getBox(this.connection?.box_id || this.starch.storage_box);
      this.objectExplorerService.parentBox.next(this.box)
      console.log(this.box);
    } catch (error) {
      console.error('Could not fetch box :', error);
    }
  }
  async getActions() {
    try {
      this.actions = await this.boxService.getAllActions(
        this.connection.box_id,
        this.connection._id
      );
      console.log('ACTIONS', this.actions);
      this.initializeMeta();
      this.ACTION_DATA = [
        {
          name: 'Actions',
          children: this.actions,
        },
      ];
      this.dataSourceActions.data = this.ACTION_DATA;
      if (this.actionRefreshSpinner) {
        this.actionRefreshSpinner = false;
      }
    } catch (error) {
      console.error('Could not fetch actions :', error);
    }
  }
  async getObjects() {
    try {
      this.objects = await this.boxService.getBoxObjects(
        this.connection._id,
        this.connection.box_id
      );
      // if (this.box?.supports?.includes('customobject')) {
      //   this.objects.push({
      //     name: '__custom',
      //   });
      // }
      console.log('OBJECTS', this.objects);
      this.OBJECT_DATA = [
        {
          name: 'Objects',
          children: this.objects,
        },
      ];
      this.dataSourceObjects.data = this.OBJECT_DATA;
      console.log('DATA SOURCE OBJECT DATA : ', this.dataSourceObjects.data);
      if (this.objectRefreshSpinner) {
        this.objectRefreshSpinner = false;
      }
    } catch (error) {
      console.error('Could not fetch objects :', error);
    }
  }

  async getEvents() {
    try {
      this.events = await this.boxService.getEvents(
        this.connection.box_id,
        this.connection._id
      );
      console.log('EVENTS', this.events);
      this.EVENT_DATA = [
        {
          name: 'Events',
          children: this.events,
        },
      ];
      this.dataSourceEvents.data = this.EVENT_DATA;
    } catch (error) {
      console.error('Could not fetch events :', error);
    }
  }

  openSnackBar(snackBarObj: snackBarValues) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
      },
      duration: snackBarObj.snackBarDuration,
      horizontalPosition: 'end',
    });
  }

  async openInputDialog(selectedAction) {
    console.log("execute action", selectedAction)
    var action: any;

    for (action of this.actions) {
      if (action.name === selectedAction.name) {
        if (action.input?.list) {
          var dialog = this.dialog.open(InputparameterDialogComponent, {
            minWidth: '60%',
            data: {
              action: action,
              getAttributesFunction: this.actions.find(
                (fn) => fn.__id == 'getattributes'
              ),
              connection: this.connection,
            },
          });
          selectedAction.spin = true;
          result = await dialog.afterClosed().toPromise();
        } else {
          selectedAction.spin = true;
          var result = await this.boxService.executeBoxObjectFunction(
            this.connection._id,
            this.connection.box_id,
            action.__id
          );
          if(result && result?.data) result = result?.data
        }
        break;
      }
    }

    console.log(result);
    selectedAction.result = Array.isArray(result) ? result?.slice(0, -1) : result
    selectedAction.spin = false;
  }

  //UPDATE/DELETE OPERATION ON OBJECT DATA
  async selectedRowData(event, node) {
    var result: any;
    if (event?.actionId == 'deleteById') {
      var deleteRecord = this.dialog.open(ConnectionExplorerDeleteRecordDialogComponent, {
        // width: '400px',
        minWidth: '30%'
      });
      var confirmedDelete = await deleteRecord.afterClosed().toPromise();
      if (confirmedDelete){
        for (let item = 0; item < this.attributeFetched.length; item++){
          if(Object.keys(this.attributeFetched[item]).includes('primary')) var id = this.attributeFetched[item].__id
        }
        if(id){
          let data = event.data[id].value;
          let payload = { parameters: {id:""} };
          payload.parameters.id = data;
          let actionId: string = node.name + '/' + event.actionId;
          console.log('PAYLOAD', payload);
          var executionResponse = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, actionId, payload)
          if(!executionResponse.error) result = 'success'
        } else {
          this.snackBarObj = {
            snackBarMessage: 'Error while Deleting record. Primary Key not found!',
            snackBarIcon: 'error',
            snackBarDuration: 6000,
            snackBarError: true,
          };
          this.openSnackBar(this.snackBarObj);
        }
      }
    } else {
      let hostPanel = new FormPanel(Date.now(), 'formPanel_Meta');
      if (event?.data) {
        var rowDataObj = {};
        Object.keys(event.data)
          .filter((key) => event.data[key].widgetType != 'icon')
          .forEach((key) => (rowDataObj[key] = event.data[key].value));
        console.log(
          'SELECTED ROW DATA RECIEVED IN CONNECTION EXPLORER',
          rowDataObj
        );
        var dialog = this.dialog.open(ConnectionExplorerSidePanelComponent, {
          // maxWidth: '50%',
          // minWidth: '30%',
          // maxHeight: 'auto',
          minWidth: '40%',
          position: { right: '2.5%' },
          data: {
            meta: hostPanel,
            selectedRowData: rowDataObj,
            connection: this.connection,
            box: this.box,
            object: node.name,
            attributeFetched: this.attributeFetched ? this.attributeFetched : undefined,
            operationMode: 'update',
            getAttributesData: this.attributeFetched,
            action: this.actions.find(
              (fn) => fn.__id == node.name+'/update'
            )
          },
        });
        result = await dialog.afterClosed().toPromise();
        if (result) result = result[0];
        console.log('UPDATE OBJECT RESPONSE', result);
      }
    }
    //refresh data source
    if (result == 'success') {
      this.initializeMeta(node);
      this.customAttributeSpinner = true;
      node.isIconChanged = false;
      var name = 'Get ' + node.name;
      for (var action of this.actions) {
        if (action.name == name) {
          var inputList = action.functions?.get?.input?.list || [];
          console.log('list', inputList);
          var payload = { parameters: {query:{page:'1|10|1000'}} };
          for (var j = 0; j < inputList.length; j++) {
            var list = inputList[j];
            let value: any = list.value || '';
            if (value) {
              console.log('list.value', value);
              value = JSON.parse(value);
            }
            payload.parameters[list.name] = value;
          }
          if(this.userQueryObj) payload.parameters.query = {...payload.parameters.query, ...this.userQueryObj}
          console.log("FINAL PAYLOAD",JSON.stringify(payload))
          var response = await this.boxService.executeBoxObjectFunction(
            this.connection._id,
            this.connection.box_id,
            action.__id,
            payload
          );
          if (response.data) {
            console.log(name, ' RESULT FETCHED : ', response.data);
            node.objectData = response.data;
            this.customAttributeSpinner = false;
            node.isIconChanged = true;
            break;
          }
        }
      }
    }
  }


  async selectedFilter(filterObj){
    console.log("filter object recevied in connection explorer : ",filterObj);
    var filter = {}
    for (let key in filterObj){
      switch(key){
        case "attributes":{
          let selectedAttr = '';
          if(filterObj[key].length > 0){
            selectedAttr = filterObj[key].join(",")
            console.log("selected attr filter string : ",selectedAttr)
            filter[key] = selectedAttr
          }
          break
        }
        case "filter":{
          let list = []
          if(filterObj[key].length > 0){
            for(let i=0; i<filterObj[key].length; i++){
              var filterString = ''
              let obj = filterObj[key][i]
              filterString+=obj["attribute"]+obj["operator"]+obj["value"]+"|"+obj["dataType"]
              list.push(filterString)
            }
            filterString = list.join(",")
            filter[key] = filterString
          }
          break
        }
        case "sort":{
          let list = []

          if(filterObj[key].length > 0){
            for(let i=0; i<filterObj[key].length; i++){
              var sortString = ''
              let obj = filterObj[key][i]
              sortString+=obj["attribute"]+"="+obj["order"]
              list.push(sortString)
            }
            sortString = list.join(",")
            filter[key] = sortString
          }
          break
        }
      }
    }
    if(Object.keys(filter).length > 0) this.userQueryObj = filter;
  }


  //CustomObject Implementation
  async openCustomObjectInputDialog(node) {
    var dialog = this.dialog.open(CustomObjectAttributeDialogComponent, {
      // maxWidth: '70%',
      // minWidth: '30%',
      // maxHeight: 'auto',
      minWidth: '80%',
      data: {
        type: 'customObject',
        connection: this.connection,
        box: this.box,
        createObjectFunction: this.actions.find(
          (fn) => fn.__id == 'createobject'
        ),
      },
    });
    var result = await dialog.afterClosed().toPromise();
    console.log('CUSTOM OBJECT RESPONSE', result);
    //refresh datasource
    if (result) {
      this.objectRefreshSpinner = true;
      this.actionRefreshSpinner = true;
      await this.getObjects();
      await this.getActions();
    }
  }

  async fetchObjectData(node) {
    console.log(node)
    this.initializeMeta(node);
    if (node.objectData) {
      node.isIconChanged = !node.isIconChanged;
    } else {
      node.isIconChanged = !node.isIconChanged;
      node.spin = true;
      var name = 'Get ' + node.name;
      console.log('NAME', name);
      for (var action of this.actions) {
        if (action.name.toLowerCase() == name.toLowerCase()) {
          console.log('ACTION', action);
          var dialog = this.dialog.open(InputparameterDialogComponent, {
            // maxWidth: '55%',
            minWidth: '60%',
            // maxHeight: '500px',
            data: {
              action: action,
              getAttributesFunction: this.actions.find(
                (fn) => fn.__id == 'getattributes'
              ),
              connection: this.connection,
            },
          });
          var result = await dialog.afterClosed().toPromise();
          console.log('RESULT', result);
          node.objectData = result?.slice(0, -1);
          this.attributeFetched = result
            ? result[result.length - 1]
            : undefined;
          console.log('ATTRIBUTES FETCHED', this.attributeFetched);
          break;
        }
      }
      console.log('CHANGED OBJECTDATA', node.objectData);
      node.spin = false;
    }
  }

  closeResult(node) {
    node.result = null;
  }

  async initializeMeta(node?) {
    let deleteButtonMeta = {
          columnName: 'Delete',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'delete',
          show_hide: false,
          sortEnabled: false,
          value: 'delete',
          widgetType: 'icon',
          __id: 'delete',
    }
    this.editButtonMeta = {
      listAttributes: [
        {
          columnName: 'Edit',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'edit',
          show_hide: false,
          sortEnabled: false,
          value: 'edit',
          widgetType: 'icon',
          __id: 'edit',
        }
      ],
      type: 'listpanel',
      viewTypes: {
        defaultView: 'table',
        canSelectFields: true
      },
      userFilterEnabled: true,
      userSortEnabled: true,
      boxId: this.connection.box_id,
      connectionId: this.connection._id,
      boxObjectId: node?.name,
      boxConfigToken: this.connection.box_token
    };
    //insert delete button meta only if box supports deleteById action
    for (let i =0; i < this.actions?.length; i++){
      if(this.actions[i]?.__id.includes('deleteById')){
        this.editButtonMeta.listAttributes.push(deleteButtonMeta);
        break
      }
    }
  }


  async publishList(node: any){
    console.log("all actions", this.actions)
    let actionId = node.name +  "/get"
    let action = this.actions.find(a => a.__id == actionId)
    var dialog = this.dialog.open(ListPublishingDialogComponent, {
      minWidth: '60%',
      disableClose: true,
      data: {
        action: action,
        getAttributesFunction: this.actions.find(
          (fn) => fn.__id == 'getattributes'
        ),
        connection: this.connection,
      },
    });
    let result = await dialog.afterClosed().toPromise();
    console.log("result", result)
    return
  }

  exploreObject(node: any){
    console.log("explore object", node)
    if(this.connection){
      this.objectExplorerService.setConnectionData(this.connection)
    }else if(this.starch){
      this.objectExplorerService.setBaseData(this.starch)
    }
    let obj = this.objects.find(o => o.name == node.name)
    this.objectExplorerService.parentObject.next(obj)
    console.log("object to open is", obj)
    console.log("current url", this.currentBaseUrl)
    this.router.navigate(['./' + obj.__id], {relativeTo: this.route});
    // window.open(this.currentBaseUrl + node.name, '_blank')
  }

  openView(item: any){
    console.log("need to open", item)
    window.open(this.currentBaseUrl + item.url, '_blank')
  }

  goBack(){
    // this.location.back()
    if(this.connection){
      this.router.navigate(['connection'], {
        queryParams: {
          workspaceid: this.connectionService.workSpaceId,
          authorization: encodeURIComponent(this.connectionService.preAuthenticatedToken),
        },
      });
    }else {
      this.router.navigate(['starch']);
    }
  }
}
