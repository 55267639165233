import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-connection.dialog',
    templateUrl: './connection.dialog.html',
    styleUrls: ['./connection.dialog.css'],
    standalone: false
})
export class ConnectionDialogComponent implements OnInit {

  //get
  get data() { return this._data; }

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any) { }

  ngOnInit(): void { }
}
