<mat-spinner *ngIf="connectionService.spinner" color="warn" class="centerOfAll" [diameter]="50"
  fxLayoutAlign="center center"></mat-spinner>
<!--Show Apps-->
<!-- [fxHide]="organizationService.isClientPlatform" -->
<mat-card appearance="outlined">
  <h2 *ngIf="header" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="start center"
    [ngStyle.lt-sm]="{ 'font-size': 'medium', 'padding-left': '5px' }">{{header}}</h2>
  <!--search-->
  <div fxLayoutAlign="start center" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="1.5%" ngStyle.lt-sm="padding-left:5px;" >
    <div fxLayout="row" class="form-field-width-search" ngStyle.lt-sm="width:100%;">
      <mat-form-field class="full-width span-margin">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>
          <span>Search</span>
        </mat-label>
        <input matInput [formControl]="boxSearchInput" />
      </mat-form-field>
      <button mat-icon-button fxHide fxShow.lt-sm (click)="showSearchMenu=!showSearchMenu" matTooltip="{{showSearchMenu ? 'Less search options':'More search options'}}">
        <mat-icon>{{showSearchMenu?'expand_less':'expand_more'}}</mat-icon>
      </button>
    </div>
    <mat-form-field class="form-field-width-small" ngStyle.lt-sm="width:100%" fxHide.lt-sm
      [fxShow.lt-sm]="showSearchMenu" class="span-margin">
      <mat-icon matPrefix>category</mat-icon>
      <mat-label>
        <span>Category</span>
      </mat-label>
      <input matInput [formControl]="boxCategoryInput" [matAutocomplete]="autoCategory" />
      <mat-icon *ngIf="boxCategoryInput.value"
      matSuffix
      aria-label="Clear" class="clickable-icon" (click)="boxCategoryInput.setValue('')">close</mat-icon>
      <!-- <button *ngIf="boxCategoryInput.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="boxCategoryInput.patchValue('')">
        <mat-icon>close</mat-icon>
      </button> -->
      <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="displayBoxFuntion"
        (optionSelected)="appFilter()">
        <mat-option *ngFor="let option of filteredOptionsCategory | async; trackBy: trackByFn" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>

    </mat-form-field>
    <mat-form-field class="form-field-width-small" ngStyle.lt-sm="width:100%" fxHide.lt-sm
      [fxShow.lt-sm]="showSearchMenu" class="span-margin">
      <mat-icon matPrefix>corporate_fare</mat-icon>
      <mat-label>
        <span>Organization</span>
      </mat-label>
      <input matInput [formControl]="boxOrganizationInput" [matAutocomplete]="autoOrganization" />
      <button *ngIf="boxOrganizationInput.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="boxOrganizationInput.patchValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #autoOrganization="matAutocomplete" [displayWith]="displayBoxFuntion"
        (optionSelected)="appFilter()">
        <mat-option *ngFor="let option of filteredOptionsOrganization | async; trackBy: trackByFn" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="form-field-width-small" ngStyle.lt-sm="width:100%" fxHide.lt-sm
      [fxShow.lt-sm]="showSearchMenu" class="span-margin">
      <mat-icon matPrefix>sort</mat-icon>
      <mat-label>
        <span>Sort By</span>
      </mat-label>
      <mat-select [formControl]="sortSelect" (selectionChange)="appFilter()">
        <mat-option *ngFor="let item of sortTypes; trackBy: trackByFn" [value]="item">{{item | titlecase}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!--ImageIcons-->
  <div class="grid" fxLayoutAlign.lt-sm="center center">
    <!-- Material card for each box item, dynamically adjusting the width based on screen size -->
    <mat-card appearance="outlined" [ngStyle]="{'width': (isSmallScreen ? '120px' : '180px')}"
      *ngFor="let box of allBoxes;let i = index; trackBy: trackByFn" fxLayoutGap="10px" class="appicon app-margin"
      (click)="selectedBoxChange.emit(box)" matTooltip="{{ box.name }}" fxLayout="column">
      <div *ngIf="box.status == 'beta'" class="ribbon text-uppercase">
        <span>{{ box.status }}</span>
      </div>
      <div *ngIf="box.status == 'alpha'" class="ribbon2 text-uppercase">
        <span>{{ box.status }}</span>
      </div>
      <div fxLayoutAlign="center center" class="full-width" fxLayout="column">
        <!-- Image section with dynamic width based on screen size -->
        <div [ngStyle]="{
            'width': (isSmallScreen ? '60px' : '80px'),
            'height': 'auto',
            'text-align': 'center'
          }" fxLayoutAlign="center center">
          <img 
            [src]="box.logo" 
            (error)="showErrorIcon('img' + i)" 
            class="img-logo" 
            [ngStyle]="{
              'width': (isSmallScreen ? '60px' : '80px'),
              'height': 'auto',
              'text-align': 'center'
            }" 
            [id]="'img' + i" 
          />
        </div>
        <mat-icon [id]="'img' + i + 'error'" class="customIconSize img-icon" ngClass.lt-sm="customIconSizeMobile">
          broken_image
        </mat-icon>

        <!-- Conditionally render <br> tag to create space between the image and title on larger screens -->
        <br *ngIf="!isSmallScreen" />
      </div>
      
      <mat-card-title 
        fxLayoutAlign="center center" 
        class="full-width" 
        [ngStyle.lt-sm]="{ 'font-size': '12px' }" 
        style="font-size: 18px;"
      >
      <!-- Dynamically truncate box name based on screen size, shortening the name on small screens -->
      {{ box.name.length>15 ? box.name.slice(0,15)+'...' : box.name }}
      </mat-card-title>
      
    </mat-card>
    <div fxHide [fxShow]="!connectionService.spinner||boxNotFound" fxLayout="column" fxLayoutGap="2px"
      class="infoMessagecenter">
      <pre></pre>

    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center" *ngIf="!connectionService.spinner">
      <span *ngIf="boxNotFound" style="font-size: 18px;">{{ allBoxes.length? '': 'No more results to display' }}</span>
    </div>
  </div>
  <div fxHide [fxShow]="!connectionService.spinner" fxLayout="end start" class="mat-elevation-z0">
    <mat-paginator class="full-width" [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
      [length]="!allBoxes.length || allBoxes.length < _appPaginator.pageSize? 1 : connectionService.totalApps"
      [pageSize]="20" [pageIndex]="0" [disabled]="" (page)="pageChanged($event)" #appPaginator>
    </mat-paginator>
  </div>
  <div style="margin-bottom: 10px" *ngIf="allBoxes?.length > 0">
    <p fxLayout="row nowrap" fxLayoutGap="5px"><span>Not seeing your application?</span><a target="_blank"
      href="https://appiworks.jivrus.com/support/contact-us">Ask for
      it</a></p>
  </div>

</mat-card>
