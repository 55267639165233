import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-apps-popup',
    templateUrl: './apps-popup.component.html',
    styleUrls: ['./apps-popup.component.scss'],
    standalone: false
})
export class AppsPopupComponent implements OnInit {

  config: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dialogRef: any,
    public dialog: MatDialogRef<AppsPopupComponent>, 
  ) { 
    this.config = this.dialogRef;
    console.log("-----> cofig", this.config)
  }

  ngOnInit(): void {
  }

  newAppSelected(app){
    this.dialog.close(app);
  }

}
