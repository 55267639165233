import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, SimpleChanges, OnChanges, DoCheck } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionListBoxPopupComponent } from './connection-list-popup/connection-list-box-popup.component';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';


@Component({
    selector: 'app-connection-list',
    templateUrl: './connection-list.component.html',
    styleUrls: ['./connection-list.component.css'],
    standalone: false
})
export class ConnectionListComponent extends BaseWidgetComponent implements OnInit, AfterViewInit, OnChanges, DoCheck {


  contextMenuActions: any = {};


  private destroy:any = new Subject();
  isWhiteLabeled: boolean;
  whiteLabeledConnections: any;
  connectionId: any;
  oldValue: string;
  filterList: any = []

  constructor(
    public pageService: PageService, 
    public metaService: MetaService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    public resourcePermissionService: ResourcePermissionService,
    private dialog: MatDialog,
    public wmtService: WidgetMetaTransformationService,
  ) {
      super(metaService, pageService, resourcePermissionService, wmtService)
  }

  async ngOnInit() {
    super.ngOnInit()
    // this.image.src = this.widgetMeta.config.src.value
    this.destroy = this.metaService?.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        console.log("context changed", contextActions)
        this.action(contextActions)
      }
    })

    this.filterList = this.getFilterIds()
    if(!['formpanel', 'detailspanel'].includes(this.widgetMeta.origin))await this.getWhiteLabeledConnections()
  }

  getFilterIds(){
    console.log("this.widgetMeta.config?.predefinedFilters?.value", this.widgetMeta.config?.predefinedFilters?.value)
    let lists:any = this.widgetMeta.config?.predefinedFilters?.value || [];
    let result = [];
    for (let index = 0; index < lists.length; index++) {
      const element = lists[index];
      result.push(element.__id);
    }
    return result;
  }

  async pickBox(){
    this.filterList = this.getFilterIds()
    var dialog = this.dialog.open(ConnectionListBoxPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: { filterList: this.filterList, filterType: this.widgetMeta.config?.predefinedFilters?.filterType }
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult--> ", diologResult);

    let connection = diologResult?.connection?.connection;
    let bloomMeta = this.metaService.bloomMeta;
    let bloomUser = this.metaService.bloomUserMap;
    let authUser = this.authService.userProfile;
    
    if(!bloomUser || Object.keys(bloomUser).length == 0) {
      bloomUser = authUser;
    }

    console.log("bloomUser", bloomUser)

    let workspace = connection.workspace_id;
    connection.white_label_id = this.widgetMeta.config.whiteLabelId.value;
    connection.white_label_user = bloomUser._id;
    let updatedConnection = await this.connectionService.updateConnection(connection, workspace);
    if(updatedConnection.connection){
      console.log("updatedConnection", updatedConnection)
      this.isWhiteLabeled = true;
      this.whiteLabeledConnections.push(updatedConnection.connection);
      // this.userInputDetected(this.whiteLabeledConnection._id)
    }
  }

  async getWhiteLabeledConnections(){
    let bloomMeta = this.metaService.bloomMeta;
    let bloomUser = this.metaService.bloomUserMap;
    let authUser = this.authService.userProfile;

    if(!bloomUser || Object.keys(bloomUser).length == 0) {
      bloomUser = authUser;
    }

    let filter = `white_label_id=${this.widgetMeta.config.whiteLabelId.value}|string,white_label_user=${bloomUser._id}|string`;
    let connection = await this.connectionService.getTotalConnection(1, 100, null, null, filter);
    console.log("white label connection", connection);
    if(connection?.data){
      this.isWhiteLabeled = true;
      this.whiteLabeledConnections = connection.data;
      // this.userInputDetected(this.whiteLabeledConnection._id)
      console.log("innnn set");
    }
  }

  async editConnection(con, i){
    var dialog = this.dialog.open(ConnectionListBoxPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: { "edit": true, connection: con._id}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult--> on edit", diologResult);
    let updatedConnection = diologResult?.connection?.connection;
    if(updatedConnection)this.whiteLabeledConnections[i] = updatedConnection;
    this.getWhiteLabeledConnections();
  }

  async deleteConnection(con, i){
    let connectionId = con._id;
    await this.connectionService.deleteConnection(connectionId)
    this.isWhiteLabeled = false;
    this.getWhiteLabeledConnections()
  }

  async setConnection(){
    
    // if(this.connectionId){
    //   let connection = await this.connectionService.getConnection(this.connectionId);
    //   console.log("connection", connection);
    //   if(connection){
    //     this.isWhiteLabeled = true;
    //     this.whiteLabeledConnection = connection;
    //     this.userInputDetected(this.whiteLabeledConnection._id)
    //   }
    // } else {
    //   this.isWhiteLabeled = false;
    //   this.whiteLabeledConnection = null;
    //   this.userInputDetected("");

    //   console.log("innnn w");
    // }
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
  }

  ngAfterViewInit(): void { }
  

  userInputDetected(connection){
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: connection
    }
    this.userInputReceived.emit(userInput);
  }


  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    console.log("changes in connection", changes)
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {

    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  async ngDoCheck() {
    let newValue = this.widgetMeta.config?.connection?.value || "";
    if(newValue != this.oldValue){
      this.oldValue = JSON.parse(JSON.stringify(newValue))
      this.connectionId = newValue
      console.log("calling from doCheck", this.connectionId)
      await this.setConnection()
    }
   }


  //-----------------------------METHODS---------------------------------

  onClick(event) {
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit"
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)

    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "customPropertyUpdate":
        console.log("RETURNED FROM MENU", event)
        this.widgetMeta.config[event.propertyName].value = event.data
        break;

      default:
        break;
    }
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

}
