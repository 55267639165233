import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-collect-options-dialog',
    templateUrl: './collect-options-dialog.component.html',
    styleUrls: ['./collect-options-dialog.component.css'],
    standalone: false
})
export class CollectOptionsDialogComponent implements OnInit {

  ngOnInit(): void {
  }

  constructor(
    public dialogRef: MatDialogRef<CollectOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){ }

  getOptionChanged(event, index){
    this.data.getFnOptions[index]['value'] = event.srcElement.value
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save(){
    this.dialogRef.close(this.data);
  }

  cancel(){
    this.dialogRef.close(false);
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

}
