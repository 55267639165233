import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { PreviewDialogComponent } from './preview-dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormWithoutRoutingModule } from 'src/app/form/form-without-routing.module';
import { BloomWithoutRoutingModule } from 'src/app/bloom/bloom-without-routing.module';
import { ViewerModule } from '../viewer.module';

@NgModule({
  declarations: [PreviewDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatToolbarModule,
    FormsModule,
    MatDialogModule,
    SpinnerModule,
    SharedModule,
    MatFormFieldModule,
    FormWithoutRoutingModule,
    BloomWithoutRoutingModule,
    ViewerModule
  ],
  exports: [PreviewDialogComponent]
})
export class PreviewDialogModule { }
