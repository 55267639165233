
<!-- router.url.includes('/bloom/') ||  color="primary"-->
<div >
  <div class="mat-app-background">
    <mat-toolbar class="custom-toolbar" fxLayout="row" fxLayoutAlign="start center" fxflex="100"
    *ngIf="showToolbar">
      <!-- <div class="ribbon" (click)="navigateToLogin()" *ngIf="uic.manageBetaTag('get')">
        <span>BETA</span>
      </div> -->
      <div fxLayoutAlign="start center" class="bring_to_front">
        <button *ngIf="authService.loggedIn && !authService.isFromMobile" color="primary" mat-icon-button (click)="toggleLeftSideNav()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".7rem"
        style="cursor: pointer; margin-left: .5rem;">
        <a aria-label="Home" fxLayout fxLayoutAlign="center center" class="logo-container">
          <img *ngIf="uic.manageProductMenu('get').includes('home'); else noLinkLogo" [routerLink]="authService.loggedIn? '/home': '/'" src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png"
            class="appworks_logo" loading="lazy" alt="AppiWorks Logo 3D"/>
          <ng-template #noLinkLogo>
              <img src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png"
                   class="appworks_logo" loading="lazy" alt="AppiWorks Logo 3D" />
          </ng-template>
        </a>
        <span *ngIf="uic.manageProductMenu('get').includes('home'); else noLinkText" [routerLink]="authService.loggedIn? '/home': '/'" class="appiworks_name"> AppiWorks </span>
        <ng-template #noLinkText>
          <span class="appiworks_name">AppiWorks</span>
        </ng-template>
      </div>
      <div class="spacer"></div>

      <div fxHide.lt-sm *ngIf="uic.manageMenu('get').includes('products')">
        <button
          [class.mat-mdc-menu-trigger-active]="menuTriggerMenuProducts?.menuOpen || menuStates['products']"
          (mouseover)="onMenuHoverWithDelay('products')"
          (mouseleave)="onMenuHoverLeave('products'); cancelMenuOpenDelay();resetAllMenu()"
          mat-button [matMenuTriggerFor]="menuProducts" #menuProduct="matMenuTrigger">
          Products
          <mat-icon class="expansionIcon" *ngIf="menuTriggerMenuProducts?.menuOpen">expand_less</mat-icon>
          <mat-icon class="expansionIcon" *ngIf="!menuTriggerMenuProducts?.menuOpen">expand_more</mat-icon>
        </button>
        <mat-menu #menuProducts="matMenu"
          [hasBackdrop]="false"
          (click)="$event.stopPropagation()">
          <menu-products
            (mouseenter)="recheckIfInMenu = true; onMenuItemHover();"
            (mouseleave)="onMenuHoverLeave('products'); recheckIfInMenu = false">
          </menu-products>
        </mat-menu>
      </div>

      <div fxHide.lt-sm *ngIf="uic.manageMenu('get').includes('integrations')">
        <button
          [class.mat-mdc-menu-trigger-active]="menuTriggerMenuIntegrations?.menuOpen || menuStates['integrations']"
          (mouseover)="onMenuHoverWithDelay('integrations')"
          (mouseleave)="onMenuHoverLeave('integrations'); cancelMenuOpenDelay();resetAllMenu()"
          mat-button [matMenuTriggerFor]="menuIntegrations" #menuIntegration="matMenuTrigger">
          Integrations
          <mat-icon class="expansionIcon" *ngIf="menuTriggerMenuIntegrations?.menuOpen">expand_less</mat-icon>
          <mat-icon class="expansionIcon" *ngIf="!menuTriggerMenuIntegrations?.menuOpen">expand_more</mat-icon>
        </button>
        <mat-menu #menuIntegrations="matMenu"
          [hasBackdrop]="false"
          (click)="$event.stopPropagation()">
          <menu-integrations
            (mouseenter)="recheckIfInMenu = true; onMenuItemHover();"
            (mouseleave)="onMenuHoverLeave('integrations'); recheckIfInMenu = false">
          </menu-integrations>
        </mat-menu>
      </div>
      <div fxHide.lt-sm *ngIf="uic.manageMenu('get').includes('solutions')">
        <button
          [class.mat-mdc-menu-trigger-active]="menuTriggerMenuSolutions?.menuOpen || menuStates['solutions']"
          (mouseover)="onMenuHoverWithDelay('solutions')"
          (mouseleave)="onMenuHoverLeave('solutions'); cancelMenuOpenDelay();resetAllMenu()"
          mat-button [matMenuTriggerFor]="menuSolutions" #menuSolution="matMenuTrigger">
          Solutions
          <mat-icon class="expansionIcon" *ngIf="menuTriggerMenuSolutions?.menuOpen">expand_less</mat-icon>
          <mat-icon class="expansionIcon" *ngIf="!menuTriggerMenuSolutions?.menuOpen">expand_more</mat-icon>
        </button>
        <mat-menu #menuSolutions="matMenu"
          [hasBackdrop]="false"
          (click)="$event.stopPropagation()">
          <menu-solutions
            (mouseenter)="recheckIfInMenu = true; onMenuItemHover();"
            (mouseleave)="onMenuHoverLeave('solutions'); recheckIfInMenu = false">
          </menu-solutions>
        </mat-menu>
      </div>

      <div (mouseover)="resetAllMenu();closeAllMenu()" fxLayoutAlign="end center" fxHide.lt-sm *ngIf="uic.manageMenu('get').includes('pricing')">
        <a mat-button target="_blank" href="https://appiworks.jivrus.com/pricing"
          rel="noreferrer noopener">
          Pricing
        </a>
      </div>

      <div fxHide.lt-sm *ngIf="uic.manageMenu('get').includes('resource')">
        <button
          [class.mat-mdc-menu-trigger-active]="menuTriggerMenuResources?.menuOpen || menuStates['resource']"
          (mouseover)="onMenuHoverWithDelay('resource')"
          (mouseleave)="onMenuHoverLeave('resource'); cancelMenuOpenDelay();resetAllMenu()"
          mat-button [matMenuTriggerFor]="menuResources" #menuResource="matMenuTrigger">
          Resources
          <mat-icon class="expansionIcon" *ngIf="menuTriggerMenuResources?.menuOpen">expand_less</mat-icon>
          <mat-icon class="expansionIcon" *ngIf="!menuTriggerMenuResources?.menuOpen">expand_more</mat-icon>
        </button>
        <mat-menu #menuResources="matMenu"
          [hasBackdrop]="false"
          (click)="$event.stopPropagation()">
          <menu-resources
            (mouseenter)="recheckIfInMenu = true; onMenuItemHover();"
            (mouseleave)="onMenuHoverLeave('resource'); recheckIfInMenu = false">
          </menu-resources>
        </mat-menu>
      </div>

      <div fxLayoutAlign="end center" *ngIf="isBrowser && window.location.pathname == '/'" fxHide.lt-sm>
        <button mat-button color="white" (click)="navigateToSignUp()">
          Log in
        </button>
      </div>
      <div fxLayoutAlign="end center" *ngIf="isBrowser && window.location.pathname == '/'" fxHide.lt-sm>
        <button class="button-signup" (click)="navigateToSignUp()">
          Sign Up
        </button>
      </div>
      <div fxFlex="3.5"></div>
      <div fxHide.lt-sm>
        <a mat-icon-button target="_blank" href="https://appiworks.jivrus.com" [matTooltip]="'Help'" aria-label="Help"
          rel="noreferrer noopener">
          <mat-icon color="primary">help</mat-icon>
        </a>
        <button color="primary" mat-icon-button [matTooltip]="'Notifications'" *ngIf="authService.loggedIn" aria-label="Notifications">
          <mat-icon >notifications_none</mat-icon>
        </button>
        <button color="primary" *ngIf="authService.loggedIn" mat-icon-button [routerLink]="['settings']" matTooltip="Settings"
          aria-label="Settings">
          <mat-icon>settings</mat-icon>
        </button>
        <button color="primary" mat-icon-button [matTooltip]="'Language'" aria-label="Language" [matMenuTriggerFor]="translateMenu">
          <mat-icon>translate</mat-icon>
        </button>
        <mat-menu #translateMenu>
          <button color="primary" mat-menu-item *ngFor="let option of translate.getLangs(); trackBy: trackByFn"
            (click)="switchLang(option)">{{languageList[option]}}</button>
        </mat-menu>
      </div>
      <div *ngIf="!authService.loggedIn"></div>
      <div *ngIf="authService.loggedIn">
        <app-profile-menu></app-profile-menu>
      </div>
    </mat-toolbar>
    <!-- <div class="toolbar-divider"></div> -->
  </div>
</div>