import { Component, NgZone, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { FormService } from '../form.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { FormActionPopupComponent } from '../form-action-popup/form-action-popup.component';

@Component({
    selector: 'app-form-automate',
    templateUrl: './form-automate.component.html',
    styleUrls: ['./form-automate.component.scss'],
    standalone: false
})
export class FormAutomateComponent implements OnInit {

  submitButtonWidget:any;
  submitButtonPanelId: any;
  widgetActionConfig: any = {
    defaultEvent: "click",
    hideActionSelection: true,
    disableActions: [1],
    hideLastAction: true,
    isHideWhiteLabel: true,
    hideFlowUseInput: true,
    disableActionsMap: {
      1: {
        actionDesc: "Form Response Storage"
      }
    },
    allowActions: ["application", "flow"]
  }
  previousWidgetMeta: any;
  appConfig: any = {}


  constructor(
    private dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private formService: FormService,
    private pageService: PageService,
    private renderer: Renderer2,
    public metaService: MetaService
  ) { }


  ngOnInit(): void {
    console.log(" this.formService.formMeta.",  this.formService.formMeta.getValue());
    let widgetMap = this.getSubmitButton();
    console.log("getSubmitButtonWidget", widgetMap);
    this.submitButtonWidget = widgetMap.widget;
    this.submitButtonPanelId = widgetMap.panelId;
    this.previousWidgetMeta = JSON.parse(JSON.stringify(this.submitButtonWidget));
    this.metaService.pageMeta.next(this.formService.formMeta.getValue())
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngDoCheck(){
    if (this.hasWidgetMetaChanged()) {
      this.previousWidgetMeta = JSON.parse(JSON.stringify(this.submitButtonWidget));
      this.setSubmitButton(this.submitButtonWidget);
    }
  }

  private hasWidgetMetaChanged(): boolean {
    return JSON.stringify(this.submitButtonWidget) !== JSON.stringify(this.previousWidgetMeta);
  }

  newAppSelected(app){
    this.initAction(app);
  }

  async initAction(app){
    this.metaService.pageMeta.next(this.formService.formMeta.getValue())
    var dialog = this.dialog.open(FormActionPopupComponent, {
      // maxWidth: '500px',
      maxHeight: '500px',
      data: {widget: this.submitButtonWidget, selectedBox: app, origin: "automate"}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult", diologResult);
    if(diologResult){
      this.pageService.widgetActionChange.next({action: "add_action_map", actionMap: diologResult.action});
      this.scrollToWidgetActionConfig();
    }
  }

  getSubmitButton(){
    let form = this.formService.formMeta.getValue();
    let section;
    if(!form?.sections || form?.sections?.length == 0){
      section = form;
    } else section = form.sections[form?.sections?.length - 1];
    let result = this.formService.getSubmitButton(section, form);
    return result;
  }

  setSubmitButton(newWidget: any) {
    let form = this.formService.formMeta.value;
    let panels = form.panels;
    let formPanel = this.formService.getFormPanel(form);
    for (let index = 0; index < panels.length; index++) {
      const element = panels[index];
      if(element.type != 'regular' && element.type != 'formpanel') continue;
      else if (element.type == 'formpanel') {
        element.submitButtonMeta = newWidget;
      } else {
        for (let i = 0; i < element?.layoutMap?.list?.length; i++) {
          let columnId = element.layoutMap.list[i];
          let column = element.layoutMap[columnId];
          for (let j = 0; j < column?.list?.length; j++) {
            let rowId = column.list[j];
            let row = column[rowId];
            for (let k = 0; k < row?.elements?.length; k++) {
              let ele = row.elements[k];
              if(ele.type == "button" && ele.id == formPanel?.submitButtonMeta?.id) {
                // Replace the existing widget with the new widget
                row.elements[k] = newWidget;
                this.formService.formMeta.next(form); // Update the form meta with the new widget
                this.formService.userMadeChanges.next(true);
                return;
              }
            }
          }
        }
      }
    }
  }



  scrollToWidgetActionConfig(): void {
    const element = document.getElementById('widgetActionConfigContainer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.renderer.selectRootElement('#widgetActionConfigContainer', true).focus();
    }
  }


}
