<h2 matDialogTitle *ngIf="data?.title">{{data?.title||""}}</h2>
<mat-dialog-content>
  <mat-spinner *ngIf="spinner" color="accent" [diameter]="40" class="centerOfAll"></mat-spinner>
  <div *ngFor="let element of data.widgetConfig.props; trackBy: trackByFn">
    <ng-container [ngSwitch]="data.widgetConfig[element]?.type">
      <ng-container *ngSwitchCase="'select'">
        <!-- <div>select input comes here</div> -->
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'password'">
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'image-picker'">
        <dropzone type="button" matSuffix (fileChange)="imageSelectionChange($event,element)"></dropzone>
        <mat-form-field [style.width.%]=100>
          <mat-label>
            {{data.widgetConfig[element].displayName }}
          </mat-label>
          <input #url matInput [type]="data.widgetConfig[element].type" [id]="element" (input)="linkInputReceived(url.value,element)"
            [(ngModel)]="data.widgetConfig[element].value" />
        </mat-form-field>
        <div *ngIf="linkError" class="linkError" fxLayout fxLayoutAlign="start center">{{ linkErrorMessage }}</div>

      </ng-container>
      <ng-container *ngSwitchCase="'app-filter'">
        <box-filter-config
        [widgetMeta]="data.widgetMeta"
        (filterChanged)="newAppFilterList($event)"> </box-filter-config>
      </ng-container>
      <ng-container *ngSwitchCase="'chart-data-source'">
        <!-- <div>chart data config comes below</div> -->
        <chart-data-config
          [widgetMeta]="data.widgetMeta"
          (newChartDataSource)="newChartDataSource($event)"
        ></chart-data-config>
      </ng-container>
      <ng-template #input>
        <div *ngIf="data.widgetMeta.type == 'link' && element == 'linkUrl'">
          <div class="fullWidth">
            <mat-form-field class="fullWidth" [style.width.%]=100 style="margin-bottom: -1.25em">
              <mat-label>Select from available pages or write external URL</mat-label>
              <input
                class="fullWidth"
                #linkInput
                type="text"
                aria-label="page autocomplete"
                matInput
                [formControl]="selectPageControl"
                [matAutocomplete]="auto"
                (input)="linkInputReceived(linkInput.value,element)"

              >
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="linkPageSelected($event,element);">
                <mat-option *ngFor="let pageName of filteredPages | async; trackBy: trackByFn" [value]="pageName">
                  <span>{{ pageName }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="linkError" class="linkError" fxLayout fxLayoutAlign="start center">{{ linkErrorMessage }}</div>
          </div>
          <div style="height: 2rem"></div>
        </div>
        <div *ngIf="data.widgetMeta.type != 'link' || element != 'linkUrl'">
          <mat-form-field  [style.width.%]=100>
            <mat-label>{{data.widgetConfig[element].displayName || 'Widget property'}}</mat-label>
            <input #checkUrl matInput [id]="element" [type]="data.widgetConfig[element].type" (input)="linkInputReceived(checkUrl.value,element)"
              [(ngModel)]="data.widgetConfig[element].value">
          </mat-form-field>
        </div>
      </ng-template>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button (click)="closeDialog()" cdkFocusInitial color="primary" [disabled]="!isReadyToSave">Ok</button>
</mat-dialog-actions>
