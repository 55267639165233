<div class="outerCover"
  *ngIf="builderMode"
  #cover
  fxLayout="row" fxLayoutAlign="flex-start center"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onOuterClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" style="height: 100%" fxLayout="column">
    <div fxLayoutAlign="end center" class="action-bar">
      <a mat-icon-button color="primary" [href]="safeUrl" target="_blank">
        <mat-icon>open_in_new</mat-icon>
      </a>
    </div>
    <div class="iframe-wrap">
      <div class="upper-layer" (click)="onClick($event)"></div>
      <iframe
        class="builder-iframe"
        fxFlexFill
        #iframe
        [src]="safeUrl"
        [title]="widgetMeta.title"
        (load)="fitIframeContent($event, iframe)"
      ></iframe>    
    </div>
      <!-- [style.height.px]="widgetMeta.config.height.value"
      [style.width.px]="widgetMeta.config.width.value" -->
  </div>
</div>
<div
  *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)" class="outerCover" #cover
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" style="height: 100%" fxLayout="column">
    <div fxLayoutAlign="end center"  class="action-bar">
      <a mat-icon-button color="primary" [href]="safeUrl" target="_blank">
        <mat-icon>open_in_new</mat-icon>
      </a>
    </div>
    <iframe
      #iframe
      [src]="safeUrl"
      [title]="widgetMeta.title"
      frameborder="0"
      class="viewer-iframe"
      (load)="fitIframeContent($event, iframe)"
      (click)="onClick($event)"
    ></iframe>
      <!-- [style.height.px]="widgetMeta.config.height.value"
      [style.width.px]="widgetMeta.config.width.value" -->
  </div>
</div>
