import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { ActionServiceUtility } from './ActionServiceUtility';
import { BaseAction } from './BaseAction';
import { FormService } from 'src/app/form/form.service';
import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationAction extends BaseAction {
  previousUrl: string;
  isBrowser: any;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public pageService: PageService,
    public metaService: MetaService,
    public formService: FormService,
    public actionServiceUtility: ActionServiceUtility,
    public expressionUtility: ExpressionUtility,
    private validationService: ValidationService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
  }

  getActionDetail() {
    return {
      name: 'Navigation',
      id: 'navigation',
    };
  }

  getActionConfiguration() {
    let config = {
      types: [
        {
          id: 'bloom_page',
          name: 'This bloom page',
        },
        {
          id: 'prev_page',
          name: 'Previous page',
        },
        {
          id: 'link',
          name: 'Url Link',
        },
      ],
    };
    return config;
  }

  public doAction(options?: any, event?: any, widgetOptions?: any) {
    console.log('options in doactions', options);
    console.log('event in doactions', event);


    let actionMap = options.actionMap;
    let onMouseEvent = options.event;
    let parameters = options.actionMap?.parameters || [];
    let directWidgetValue = widgetOptions?.value || "";
    if(!this.actionServiceUtility.isEventMatch(onMouseEvent, event)) return;

    // this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized),pairwise())
    //   .subscribe((events: RoutesRecognized[]) => {
    //     console.log('previous url', events[0].urlAfterRedirects);
    //     console.log('current url', events[1].urlAfterRedirects);
    //     this.previousUrl = events[0].urlAfterRedirects;
    //   });

    if (actionMap.pageType == 'bloom_page') {
      var currentBloomMeta: any = {};
      this.metaService.get_bloomMeta.subscribe((data) => {
        currentBloomMeta = data;
      });
      console.log("parameters--> ", parameters)
      let filters:any = this.constructUrlParams(parameters, "array", directWidgetValue);
      console.log("filters", filters);
      // let queryParams = {};
      let pathParams = "";
      if(filters && filters.length > 0) {
        // this.pageService.navigationData.next({filters: filters, isActionNav: true, "destination-page-code": actionMap.page});
        filters.forEach(element => {
          // queryParams[element.attributeId] = element.value;
          pathParams = pathParams + "/" + element.attributeId + "/" + element.value;
        });
      }

      if(pathParams) pathParams = "/q" + pathParams;
      // console.log("queryParams ", queryParams);
      console.log("pathParams ", pathParams);
      if(!this.metaService.isBloomPreview){
        if(this.pageService.isSubdomainBloom) this.router.navigate([actionMap.page, pathParams]);
        else this.router.navigate(['/p/' + currentBloomMeta.code + '/' + actionMap.page + pathParams]);
      } else {
        this.pageService.navigationData.next({pathParams: pathParams, isActionNav: true, "pageCode": actionMap.page});
      }

    } else if (actionMap.pageType == 'link') {

      let urlParams:any = this.constructUrlParams(parameters, "string", directWidgetValue);
      console.log("urlParams", urlParams)
      let link = actionMap.link;
      if(urlParams) {
        let join = link.includes("?") ? "&" : "?";
        link = link + join + urlParams;
      }
      window.open(link, '_blank');
    } else if (actionMap.pageType == 'prev_page') {
        console.log("this.previousUrl", this.pageService.previousUrl)
        if(this.pageService?.previousUrl) this.router.navigate([this.pageService.previousUrl]);
    } else if (actionMap.pageType == 'form_success') {
      if(this.formService.isPreview){
        this.formService.isPreviewSuccess = true;
      } else {
        let formMeta: any = this.formService.formMeta.value
        this.router.navigate(['form', 'p', formMeta.code, 'success']);
      }
    } else if (actionMap.pageType == 'form_section') {
      let formMeta: any = this.formService.formMeta.value;
      let page = actionMap.page;
      let pageMeta = null;
      formMeta.sections.forEach(element => {
        if(page == element.code) pageMeta = element;
      });
      this.formService.pageSelectionRequest.next(pageMeta);
    }
  }

  constructUrlParams(parameters, type, directWidgetValue?){
    let pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
    console.log("[ApplicationAction construct payload()] pageModel", pageModel),
    pageModel = this.expressionUtility.resolvePageModelExpressions(pageModel)
    let result:any = "";
    if(type == "array") { result = []; }

    for(var i = 0; i < parameters.length; i++) {
      let field = parameters[i];
      let sourceField: string = field.sourceField?.__id || field.sourceField;
      let value = this.getObjectValue(sourceField, pageModel);
      if(!value || directWidgetValue) value = directWidgetValue;
      if(type == "array"){
        let obj:any = {};
        obj.attributeId = field["keyField"]["__id"];
        obj.dataType = "string"; //TODO get datatype of attribute and use that
        obj.value = value
        result.push(obj);
      } else {
        if(result) result = result + "&";
        result =  result + field["keyField"]["__id"] + "=" + value;
      }
    }

    return result;
  }

  getObjectValue(string: any, data: any) {
    console.log('string', string);
    console.log('data', data);
    var nameArr = string.split('|');
    var result = null;
    var name = nameArr[0];

    // if there is a dot, then drill down to the object
    var dotIndex = name.indexOf('.');
    while (dotIndex != -1) {
      var parent = name.substring(0, dotIndex);
      name = name.substring(dotIndex + 1);
      data = data[parent];
      if (data == null) break;
      else dotIndex = name.indexOf('.');
    }

    if (data != null) {
      var contructedValue = data[name];
    }
    result = contructedValue;

    return result;
  }
}
