import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { BoxService } from '../../services/box-service.service';

@Component({
    selector: 'chart-data-config',
    templateUrl: './chart-data-config.component.html',
    styleUrls: ['./chart-data-config.component.scss'],
    standalone: false
})
export class ChartDataConfig implements OnInit {

  @Input() widgetMeta: any
  @Output() newChartDataSource: any = new EventEmitter<any>()

  config: any
  filters: any

  // filteredNameAttributes: any;
  // filteredValueAttributes: any;
  // nameAttributeControl = new UntypedFormControl();
  // valueAttributeControl = new UntypedFormControl();

  selectedBoxName: string;
  selectedBoxId: string;
  selectedConnectionId: string;
  selectedBoxObjectId: string;
  boxConfigToken: string;

  attributeOptions: any[] = []
  getFnOptions: any[] = []

  // nameAttribute: any;
  // namePath: any;
  // valueAttribute: any
  // valuePath: any

  getAttrFn: any;
  attributes: any = []
  isAttributesReady: boolean = false

  lockOperation: boolean = false

  // dataSourceType: string = 'dynamic'    // static | dynamic
  staticData: any[] = []
  editModeIndex: number = -1

  constructor(
    private boxService: BoxService,
  ) {
  }

  ngOnInit(): void {

    // console.log("[CHART DATA CONFIG] onInit(): widgetMeta", this.widgetMeta)
    if (!this.widgetMeta.config.dataSource?.chartOrder){
      this.widgetMeta.config.dataSource.chartOrder = {
        field: 'none',
        order: "ASC"
      }
    }

    this.config = JSON.parse(JSON.stringify(this.widgetMeta.config.dataSource))
    this.filters = this.config.filter || {}
    // this.dataSourceType = this.config.dataSourceType || 'dynamic'
    if(!this.config.dataSourceType) this.config.dataSourceType = 'dynamic'
    if(this.config.dataSourceType == 'static'){
      this.staticDataInit()
    }

    this.staticData = this.generalizeSemanticStaticData(JSON.parse(JSON.stringify(this.config.staticData || [])))
    console.log("config", this.config)
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  async getAttributes(){
    let boxId = this.selectedBoxId == 'starch' ? this.config.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;

    let payloadOptions:any = {}
    if(this.selectedBoxId == 'starch'){
      payloadOptions = {
        relationObject: "starch_relationship"
      }
    }

    // let res: any = await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType, this.attributeOptions)
    let res: any = await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType, payloadOptions)
    // let res: any = await this.boxService.getAttributes(this.selectedConnectionId, this.selectedBoxId, this.selectedBoxObjectId, this.attributeOptions)
    this.attributes = res.result

    // this.config.connectionId = this.selectedConnectionId
    // this.config.boxName = this.selectedBoxName
    // this.config.boxId = this.selectedBoxId
    // this.config.boxObjectId = this.selectedBoxObjectId
    // this.config['getFnOptions'] = this.getFnOptions
    // this.config['attributeOptions'] = this.attributeOptions
    // // this.widgetMeta.config.dataSource = this.config
    // Object.assign(this.widgetMeta.config.dataSource, this.config)

    console.log("widgetMeta before passing for dimension and metric config", this.widgetMeta)

    this.isAttributesReady = true
  }

  displayFn(attr) {
    let val =  (attr && attr.__id) ? attr.__id : ''
    return val
  }

  newChartDataSourceRceived(data){
    this.selectedConnectionId = data.connectionId
    this.boxConfigToken = data.boxConfigToken
    this.selectedBoxName = data.boxName
    this.selectedBoxId = data.boxId
    this.selectedBoxObjectId = data.boxObjectId
    this.getFnOptions = data.getFnOptions
    this.attributeOptions = data.attributeOptions
    this.config = Object.assign(this.config, data)
    this.getAttributes()
  }

  dataSourceTypeChanged(event){
    // console.log("selected data source type", this.config.dataSourceType)
    console.log("event", event)
    this.config.dataSourceType = event.value
    if(this.config.dataSourceType == 'static'){
      console.log("static data source selected")
      this.staticDataInit()
    }else{
      console.log("dynamic data source selected")
      if(this.config.dimensions?.length) this.config.dimensions[0].attribute = {}
      if(this.config.metric.attribute) this.config.metric.attribute = {}
      // this.config.staticData = []
    }
    this.saveChanges()
    // this.config.dataSourceType = event.value
  }

  staticDimensionInput(event){
    console.log("static dimension", event)
    let obj = {
      dataType: 'string',
      name: event,
      __id: event
    }
    this.config.dimensions[0]['attribute'] = obj
    console.log("datasource now", this.config)
    this.saveChanges()
  }

  staticMetricAttrInput(event){
    console.log("static metric", event)
    let obj = {
      dataType: 'string',
      name: event,
      __id: event
    }
    this.config.metric['attribute'] = obj
    console.log("datasource now", this.config)
    this.saveChanges()
  }

  deleteDataRow(i){
    console.log("delete option at index", i)
    this.staticData.splice(i, 1)
    if(this.editModeIndex == i) this.editModeIndex = -1
    this.saveChanges()
  }

  saveEditedRow(){
    this.editModeIndex = -1
  }

  editDataRow(i: number){
    console.log("edit static clicked for", i, "option", this.staticData[i])
    this.editModeIndex = i
  }

  addNewStaticRow(){
    let dataRow = {
      name: this.getUniqueValue(),
      value: 0
    }

    this.staticData.push(dataRow)
    console.log(this.staticData.length)
    this.editDataRow(this.staticData.length - 1)
    this.saveChanges()
  }

  getUniqueValue(){
    let valuePoint = this.staticData.length
    let value = (this.config.dimensions?.[0]?.attribute?.__id || 'item') + ' ' + valuePoint
    while(this.staticData.findIndex(data => data.value == value) > -1){
      value = this.config.dimensions?.[0]?.attribute?.__id || 'item' + " " + ++valuePoint
    }
    return value
  }

  staticDataInit(){
    if(!this.config.metric?.attribute?.__id){
      this.config.metric.attribute = {
        dataType: 'number',
        name: 'Amount',
        __id: 'Amount'
      }
    }
    if(this.config.dimensions?.[0] && !this.config.dimensions?.[0]?.attribute?.__id){
      this.config.dimensions[0].attribute = {
        dataType: 'string',
        name: 'Item',
        __id: 'Item'
      }
    }
    this.config.metric.operation = 'no_op'
    this.staticData = []
    if(this.widgetMeta.config.chartType.value == 'scorecard'){
      delete this.config.dimensions
    }
  }

  dimValueChanged(event, i){
    console.log("new name", event.srcElement.value, "for index", i)
    this.staticData[i].name = event.srcElement.value
    this.saveChanges()
  }

  metricValueChanged(event, i){
    console.log("new value", event.srcElement.value, "for index", i)
    this.staticData[i].value = !isNaN(parseFloat(event.srcElement.value)) ? parseFloat(event.srcElement.value) : 0
    this.saveChanges()
  }


  semanticTransformStaticData(data){
    if(!data || !data.length) return []
    console.log("config in semantic transform", JSON.parse(JSON.stringify(this.config)))
    let dimensionPropertyName = this.config.dimensions?.[0]?.attribute?.__id
    let metricPropertyName = this.config.metric?.attribute?.__id
    if(!dimensionPropertyName || !metricPropertyName) {
      console.log("dimension or metric does not exist")
      console.log("returning", data)
      return data
    }

    data.forEach((dataRow, i) => {
      let obj = {}
      obj[dimensionPropertyName] = dataRow.name
      obj[metricPropertyName] = dataRow.value

      data[i] = obj
    })
    console.log("data after transform", data)
    return data
  }

  generalizeSemanticStaticData(data){
    if(!data || !data.length) return []
    let dimensionPropertyName = this.config.dimensions?.[0]?.attribute?.__id
    let metricPropertyName = this.config.metric?.attribute?.__id

    if(!dimensionPropertyName || !metricPropertyName) {
      console.error("dimension or metric does not exist")
      return data
    }

    data.forEach((dataRow, i) => {
      let obj = {}
      obj['name'] = dataRow[dimensionPropertyName]
      obj['value'] = dataRow[metricPropertyName]

      data[i] = obj
    })
    console.log("data after generalization", data)
    return data
  }

  staticScorecardLabelInput(val){
    this.widgetMeta.config.scorecardLabel.value = val
    console.log("scorecard label now", JSON.stringify(this.widgetMeta.config.scorecardLabel.value))
    this.saveChanges()
  }

  staticScorecardValueInput(val){
    this.staticData = [this.widgetMeta.config.scorecardLabel.value, val]
    console.log("datasource", JSON.parse(JSON.stringify(this.staticData)))
    this.saveChanges()
  }

  saveChanges(){

    // this.config.dataSourceType = this.config.dataSourceType
    if(this.config.dataSourceType == "static"){
      this.config.staticData = this.semanticTransformStaticData(JSON.parse(JSON.stringify(this.staticData)))
      console.log("new staticData", this.config.staticData)
    } else {
      this.config.connectionId = this.selectedConnectionId
      this.config.boxName = this.selectedBoxName
      this.config.boxId = this.selectedBoxId
      this.config.boxObjectId = this.selectedBoxObjectId
      this.config['getFnOptions'] = this.getFnOptions
      this.config['attributeOptions'] = this.attributeOptions
    }
    
    this.widgetMeta.config.dataSource = this.config
    Object.assign(this.widgetMeta.config.dataSource, this.config)
    console.log("emitting data source", JSON.parse(JSON.stringify(this.config)))
    this.newChartDataSource.emit(this.widgetMeta)
  }
  
  attributeConfigReceived(widgetMeta: any){
    Object.assign(this.config, widgetMeta.config.dataSource)
    this.saveChanges()
  }

  onUserFilterChanged(event){
    this.widgetMeta.config.dataSource.filter['userFilterEnabled'] = event.checked;
    this.widgetMeta.config.dataSource['boxObjectAttributes'] = this.attributes;
    this.newChartDataSource.emit(this.widgetMeta)
  }

}
