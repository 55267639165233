import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
    selector: 'headers-inputs',
    templateUrl: './headers-inputs.component.html',
    styleUrls: ['./headers-inputs.component.scss'],
    standalone: false
})
export class HeadersInputsComponent implements OnInit {


  queryBody:any = {};
  constructor(
    private spin: SpinnerService
  ) { }

  @Input() sourceFields: any;
  @Input() systemFields: any;
  @Input() isDynamicData: boolean = false;
  @Input() headers: any = [];
  @Input() existingInputs:any;
  @Output() "inputParameter" = new EventEmitter<any>();

  fieldMap: any;

  async ngOnInit() {
    if(this.isDynamicData && this.existingInputs?.length) this.headers = this.headers.concat(this.existingInputs);
    this.constructFieldMap()
  }

  addNewHeader(): void {
    this.headers.push({
      __id: "",
      value: ""
    });
  }

  deleteHeader(index: number): void {
    this.headers.splice(index, 1);
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  inputAdded(e){
    this.inputParameter.emit(this.headers);
  }

  insertCursor(e, data){
    let value = e.__id
    let cursorValue = '${' + value + '}';
    data.value = cursorValue;
  }

  constructFieldMap(){
    let fieldObj:any = {};
    let list = [];

    if(this.sourceFields?.length > 0) {
      list.push('sourceFields');
      let fieldObjsourceFields = this.getFieldMapObject('sourceFields', 'SOURCE FIELDS', this.sourceFields)
      fieldObj[fieldObjsourceFields.id] = fieldObjsourceFields
    }
    if(this.systemFields?.length > 0) {
      list.push('systemFields');
      var fieldObjsystemFields = this.getFieldMapObject('systemFields','SYSTEM FIELDS',this.systemFields)
      fieldObj[fieldObjsystemFields.id] = fieldObjsystemFields
    }

    fieldObj.list = list;
    this.fieldMap = fieldObj;
    console.log("FIELD MAP PREPARED : ",this.fieldMap)
  }

  getFieldMapObject(_id:string, displayName:string, fields:any, option?:any){
    var obj = {
      id : _id,
      displayName : displayName,
      fields : fields,
      options : option ? option : {}
    }
    return obj
  }

}
