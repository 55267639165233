import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { PageService } from '../../services/page-service.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MetaService } from '../../services/meta-service';
import { ActivatedRoute } from '@angular/router';
import { direction } from 'html2canvas/dist/types/css/property-descriptors/direction';

@Component({
    selector: 'app-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.css'],
    standalone: false
})
export class CanvasComponent implements OnInit, OnDestroy {
  imageCopied: boolean = false;
  // isFooterInFocus: boolean = false
  pageStructure: any;
  builderMode: boolean = false
  fragmentSubscription: any
  pageChangeNotifierSub: any
  showFooter: boolean = false;
  // @Input() pageNames = []
  // @Input() bloomCode = '';
  private reader: FileReader = new FileReader();
  copiedFile:any;
  isBrowser: boolean;

  lastMouseEvent: any = { type: "", direction: "" };

  @ViewChild('footerHandleWrapper', { static: false }) footerHandleWrapper!: ElementRef;

  constructor(
    private metaService: MetaService,
    public pageService: PageService,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) public document: Document,
    private route: ActivatedRoute
  ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
      this.document.addEventListener("paste", async(e) => {
        if (!e.clipboardData.files.length) {
          return;
        }
        const file = e.clipboardData.files[0];
        if(this.pageService.isPointerInCanvas && this.pageService.isPastedOnce){
          this.imageCopied = true;
          this.copiedFile = file;
          // this.pageService.createCopiedContent(file, "image");
        }
      })
  }
  // comment

  ngOnInit(): void {
    this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
      this.builderMode = (fragment == 'edit') ? true : false
    });

    this.pageChangeNotifierSub = this.pageService.$pageChangeNotifier.subscribe(isChange => {
      if(isChange) this.showFooter = false
    })
    // this.document.addEventListener("paste", this.pasteFromListener.bind(this))
   }

  
  /**
   * while a widget is being dragged from a panel and it reaches near the footer divider (to be dragged across the divider), 
   * clear all visible placeholders in the currently active section (main or footer)
   * pre-divider and post-divider along with footer handle is a buffer area
   * @returns 
   */
  clearPlaceholders() {
    if(!this.pageService.isDragging()) return
    this.pageService.clearVisibilityFlagsExcept()
    this.pageService.dragNewPanelPlaceholder = {panelId: '', direction: '', newIndex: -1}
  }

  /**
   * register the mouse pointer entering the footer handle from top or bottom, and save for future reference
   * @param event 
   */
  mouseenterFooterHandle(event) {
    let currentEvent: any
    if (!this.footerHandleWrapper) return;
    if (!this.pageService.isDragging()) return
    // console.log("mouse enter footer handle", event, "this.pageService.isDragging", this.pageService.isDragging())

    const rect = this.footerHandleWrapper.nativeElement.getBoundingClientRect();
    const mouseY = event.clientY;

    // based on proximity of mouse pointer to top and bottom boundary, calculate direction of entry
    if (Math.abs(mouseY - rect.top) < Math.abs(mouseY - rect.bottom)) {
      console.log('Entered from Top');
      currentEvent = { type: "mouseenter", direction: "top" }
    } else {
      console.log('Entered from Bottom');
      currentEvent = { type: "mouseenter", direction: "bottom" }
    }
    this.lastMouseEvent = currentEvent
  }

  /**
   * check if the mouse pointer exited the footer handle from top or bottom, then switch the context of page if needed
   * @param event 
   */
  mouseleaveFooterHandle(event) {
    let currentEvent: any
    if (!this.footerHandleWrapper) return;
    if (!this.pageService.isDragging()) return
    // console.log("mouse leave footer handle", event, "this.pageService.isDragging", this.pageService.isDragging)

    const rect = this.footerHandleWrapper.nativeElement.getBoundingClientRect();
    const mouseY = event.clientY;
    
    // based on proximity of mouse pointer to top and bottom boundary, calculate direction of exit
    if (Math.abs(mouseY - rect.top) < Math.abs(mouseY - rect.bottom)) {
      console.log('Exited from Top');
      currentEvent = { type: "mouseleave", direction: "top" }
    } else {
      console.log('Exited from Bottom');
      currentEvent = { type: "mouseleave", direction: "bottom" }
    }
    this.checkContextSwitch(currentEvent, this.lastMouseEvent)
    this.lastMouseEvent = currentEvent
  }

  /**
   * if current mouseenter and mouseleave events happen in sequence and from opposite directions, then switch the context of page
   * and activate the other section (main or footer)
   * @param currentEvent
   * @param lastEvent 
   */
  checkContextSwitch (currentEvent, lastEvent) {

    // if current event is mouseleave and last event is mouseenter, then only proceed
    if (currentEvent.type !== 'mouseleave' || lastEvent.type !== 'mouseenter') return
    // console.log("current event", currentEvent.type, "last event", lastEvent.type)

    // check direction of current and last event
    if (currentEvent.direction == "top" && lastEvent.direction == "bottom") {
      console.log("entered from bottom, exited from top. will defocus footer")
      this.defocusFooter()
    } else if (currentEvent.direction == "bottom" && lastEvent.direction == "top") {
      console.log("entered from top, exited from bottom. will focus footer")
      this.focusFooter()
    }
  }

  ngOnDestroy() {
    this.fragmentSubscription.unsubscribe()
    this.pageChangeNotifierSub.unsubscribe()

    // this.document.removeEventListener('paste', this.pasteFromListener.bind(this));
  }

  mouseover(){
    this.pageService.isPointerInCanvas = true;
  }

  mouseout(){
    this.pageService.isPointerInCanvas = false;
    // this.pageService.resetCopiedData()
  }

  @HostListener('window:keydown',['$event'])
  async onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.key == "c" && !$event.repeat){
      console.log('CTRL + C', $event.repeat);
      this.imageCopied = false;
      if(this.pageService.isPointerInCanvas) {
        this.pageService.copyWidget();
        this.pageService.isPastedOnce = false;
        this.pageService.isToolCutted = false;
      }
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "x" && !$event.repeat){
      console.log('CTRL + x', $event.repeat);
      this.imageCopied = false;
      this.pageService.isPastedOnce = false;
      if(this.pageService.isPointerInCanvas) this.pageService.cutWidget();
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "v" && !$event.repeat){

      let content = await navigator.clipboard.readText();
      if(this.pageService.isPointerInCanvas && this.pageService.isPastedOnce && content){
        this.imageCopied = false;
        this.pageService.createCopiedContent(content);
        console.log("in coppied clip")
      } else if(this.pageService.isPointerInCanvas && !this.imageCopied) {
        this.imageCopied = false;
        this.pageService.pasteWidget();
      } else if(this.imageCopied && this.copiedFile){
        this.pageService.createCopiedContent(this.copiedFile, "image");
        this.copiedFile = null;
      }
    }
  }

  focusFooter(event?){

    // if footer is empty while dragging something to footer, add an empty panel in footer
    if (this.pageService.isDragging() && this.metaService.page_structure.value?.footer?.meta?.panels && this.metaService.page_structure.value?.footer?.meta?.panels?.length == 0) {
      let panelId = Date.now() + Math.random().toString(16).slice(8);
      let layoutId = Date.now() + Math.random().toString(16).slice(8);
      let newEmptyPanel = {
        id: panelId,
        name: "Panel 1",
        type: "regular",
        layoutMap: {
          "list": [layoutId]
        }
      }
      newEmptyPanel.layoutMap[layoutId] = {
        "gridX": 12,
        "list": []
      }

      this.metaService.page_structure.value.footer.meta.panels.push(newEmptyPanel)
      console.log("empty panel added", JSON.parse(JSON.stringify(this.metaService.page_structure.value.footer?.meta || "")))
      this.metaService.userMadeChanges.next(true);
    }

    // console.log("configure footer", event)
    // console.log("footer in focus", this.pageService.isFooterInFocus())
    // if (this.pageService.isFooterInFocus()) {
    //   console.log("will put focus on main")
    //   this.defocusFooter()
    //   return
    // }
    // console.log("configure footer")
    // this.isFooterInFocus = true
    this.pageService.footerInFocus.next(true)
  }
  
  defocusFooter(){
    // this.isFooterInFocus = false

    this.pageService.footerInFocus.next(false)
    if (this.pageService.pageMeta.code !== '__FOOTER__') return // footer is already not in focus

    this.metaService.updatePageStructure(this.metaService.page_structure.value)
    this.pageService.restoreContext()
  }

  onActivate(event: any) {
    if (event.sendMessage) {
      event.messageEvent.subscribe((message: string) => {
        // console.log("message received", message);
        this.showFooter = true
      });
    }
  }
}
