import { Widget } from "./baseWidget";
import { AvailableOptions } from "./optionsConfig";
import { TextFormatting } from "./textFormat";

export class Autocomplete extends Widget {
  type = 'autocomplete';
  gridX = 6
  minGridX = 3
  value: any = '';

  config: any = {
    props: [
      "availableOptions",
      'floatLabel',
      'appearance',
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    floatLabel: {
      displayName: 'Floating label',
      type: 'radio',
      value: 'auto',
      valueOptions: ['auto','always']
    },
    availableOptions: AvailableOptions,
  }

  textFormat = undefined;

  constructor(id: number, name: string) {
    super(id, name)
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    
    this.attachCommonConfig(this)
    this.config['title'].value = "Select an option"
    this.config['label'].value = "Start typing for options"
    this.config['placeholder'].value = "Pick one"
  }

  public getEvents(){
    let events = [
      {
        id: "select",
        name: "Select",
        function: "select"
      },
      {
        id: "hover",
        name: "Hover",
        function: "hover"
      }
    ]

    return events;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    return map;
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    // return false
    return true
  }

  public setOptions(value: any): void {
    console.log("setOptions hit in AC")
    if (typeof value == 'string') {
      this.config.value.value = value
      console.log("value set in AC", this.config.value)
    }
  }
}
