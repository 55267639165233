<mat-nav-list class="nav">
    <!-- <mat-form-field appearance="outline" *ngIf="
        sidenavService.leftSidenavDesktopWidth > 3.0 ||
        sidenavService.mobileQuery?.matches
      ">
      <mat-label>Organization</mat-label>
      <mat-select [ngModel]="authService.selectedOrganization">
        <mat-option *ngFor="let org of authService.organizations; trackBy: trackByFn" [value]="org.id">
          {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <mat-divider></mat-divider> -->
    <div class="itemwrap" *ngIf="uic.manageProductMenu('get').includes('home')">
      <mat-list-item (click)="onHomeClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
                else homeTooltip" matTooltip="Home" class="navMatIcon">home</mat-icon>
            <ng-template #homeTooltip>
              <mat-icon class="navMatIcon">home</mat-icon>
            </ng-template>
            <span class="adminTextSpan" [fxHide]="
                sidenavService.leftSidenavDesktopWidth == 3.0 &&
                !sidenavService.mobileQuery?.matches
              ">
              Home
            </span>
          </div>
      </mat-list-item>
    </div>

    <div class="itemwrap" *ngIf="uic.manageProductMenu('get').includes('connection')">
      <mat-list-item (click)="onConnectionClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'connection/appiworks-connection-logo.png'" class="navIcon" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
              else connectionTooltip" matTooltip="Connection">
          <ng-template #connectionTooltip>
            <img [src]="baseURL + 'connection/appiworks-connection-logo.png'" class="navIcon">
          </ng-template>
          <span class="textSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 3.0 &&
              !sidenavService.mobileQuery?.matches
            ">
            Connection
          </span>
        </div>
      </mat-list-item>
    </div>

    <div class="itemwrap" *ngIf="uic.manageProductMenu('get').includes('starch')">
      <mat-list-item (click)="onStarchClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'starch/appiworks-starch-logo.png'" class="navIcon" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
              else starchTooltip" matTooltip="Starch">
          <ng-template #starchTooltip>
            <img [src]="baseURL + 'starch/appiworks-starch-logo.png'" class="navIcon">
          </ng-template>
          <span class="textSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 3.0 &&
              !sidenavService.mobileQuery?.matches
            ">
            Starch
          </span>
        </div>
      </mat-list-item>
    </div>


    <div class="itemwrap" *ngIf="uic.manageProductMenu('get').includes('form')">
      <mat-list-item (click)="onFormClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'form-builder/aw-form-builder-logo.png'" class="navIcon" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0; else FormTooltip" matTooltip="Form">
          <ng-template #FormTooltip>
            <img [src]="baseURL + 'form-builder/aw-form-builder-logo.png'" class="navIcon">
          </ng-template>
          <span class="textSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 3.0 &&
              !sidenavService.mobileQuery?.matches
            ">Form</span>
        </div>
      </mat-list-item>
    </div>

    <div class="itemwrap" *ngIf="uic.manageProductMenu('get').includes('bloom')">
      <mat-list-item (click)="onBloomClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'bloom/appiworks-bloom-logo.png'" class="navIcon" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0; else BloomTooltip" matTooltip="Bloom">
          <ng-template #BloomTooltip>
            <img [src]="baseURL + 'bloom/appiworks-bloom-logo.png'" class="navIcon">
          </ng-template>
          <span class="textSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 3.0 &&
              !sidenavService.mobileQuery?.matches
            ">Bloom</span>
        </div>
      </mat-list-item>
    </div>

    <div class="itemwrap" *ngIf="uic.manageProductMenu('get').includes('flow')">
      <mat-list-item (click)="onFlowClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'flow/appiworks-flow-logo.png'" class="navIcon" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0; else FlowTooltip" matTooltip="Flow">
          <ng-template #FlowTooltip>
            <img [src]="baseURL + 'flow/appiworks-flow-logo.png'" class="navIcon">
          </ng-template>
          <span class="textSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 3.0 &&
              !sidenavService.mobileQuery?.matches
            ">Flow</span>
        </div>
      </mat-list-item>
    </div>


  <mat-accordion *ngIf="permission.hasAccess(['super.admin'])">
    <mat-expansion-panel class="mat-elevation-z0" hideToggle [ngClass]="{
        adminsize:
          sidenavService.leftSidenavDesktopWidth == 3.0 &&
          !sidenavService.mobileQuery?.matches
      }">
      <mat-expansion-panel-header class="navitem-admin-content">
        <mat-list-item fxlayoutAlign="start center" fxLayout="row" class="list-item">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
              else AdminTooltip" matTooltip="Admin" class="navMatIcon">admin_panel_settings
            </mat-icon>
            <ng-template #AdminTooltip>
              <mat-icon class="navMatIcon">admin_panel_settings</mat-icon>
            </ng-template>
            <span class="textSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 3.0 &&
              !sidenavService.mobileQuery?.matches
            "> Admin
            </span>
          </div>
        </mat-list-item>
      </mat-expansion-panel-header>
      <mat-selection-list [multiple]="false">
        <mat-list-option *ngIf="permission.hasAccess(['super.admin'])"
         matTooltip="Roles"
         (click)="router.navigate(['roles'],{queryParams:{workspaceid: this.connectionService.workSpaceId,
          authorization: encodeURIComponent(
            this.connectionService.preAuthenticatedToken
          )}})">
          <span fxLayout="row" fxLayoutAlign="start center"
            *ngIf="sidenavService.leftSidenavDesktopWidth != 3.0">
            <mat-icon class="adminIcon">manage_accounts</mat-icon>
            <span class="adminTextSpan">Roles</span>
          </span>
          <span fxLayout="row" fxLayoutAlign="center center" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0">
            <mat-icon class="adminIcon">manage_accounts</mat-icon>
            <span [fxHide]="sidenavService.leftSidenavDesktopWidth == 3.0 && !sidenavService.mobileQuery?.matches">
              Roles
            </span>
          </span>
        </mat-list-option>
        <mat-list-option *ngIf="permission.hasAccess(['super.admin'])" matTooltip="Privileges" (click)="router.navigate(['privileges'],{queryParams:{workspaceid: this.connectionService.workSpaceId,
          authorization: encodeURIComponent(
            this.connectionService.preAuthenticatedToken
          )}})">
          <span fxLayout="row" fxLayoutAlign="start center"
            *ngIf="sidenavService.leftSidenavDesktopWidth != 3.0">
            <mat-icon class="adminIcon">gavel</mat-icon>
            <span class="adminTextSpan">Privileges</span>
          </span>
          <span fxLayout="row" fxLayoutAlign="center center" *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0">
            <mat-icon class="adminIcon">gavel</mat-icon>
            <span [fxHide]="sidenavService.leftSidenavDesktopWidth == 3.0 && !sidenavService.mobileQuery?.matches">
              Privileges
            </span>
          </span>
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>

  <div fxShow.lt-sm fxHide>
    <br/>
    <br/>
    <mat-divider> </mat-divider>
  </div>
  <div class="itemwrap" fxShow.lt-sm fxHide>
    <mat-list-item (click)="openUrl('https://appiworks.jivrus.com')" fxLayout="row" fxLayoutAlign="start center" class="list-item">
      <div fxLayout="row" fxLayoutAlign="start center" >
        <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
            else helpTooltip" matTooltip="Help" class="navMatIconUtil">help</mat-icon>
        <ng-template #helpTooltip>
          <mat-icon class="navMatIconUtil">help</mat-icon>
        </ng-template>
        <span class="adminTextSpan" [fxHide]="
            sidenavService.leftSidenavDesktopWidth == 3.0 &&
            !sidenavService.mobileQuery?.matches
          ">
          Help
        </span>
      </div>

    </mat-list-item>
  </div>
  <div class="itemwrap" fxShow.lt-sm fxHide>
    <mat-list-item fxLayout="row" fxLayoutAlign="start center" class="list-item">
      <div fxLayout="row" fxLayoutAlign="start center" >
        <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
            else notifyTooltip" matTooltip="Help" class="navMatIconUtil">notifications_none</mat-icon>
        <ng-template #notifyTooltip>
          <mat-icon class="navMatIconUtil">notifications_none</mat-icon>
        </ng-template>
        <span class="adminTextSpan" [fxHide]="
            sidenavService.leftSidenavDesktopWidth == 3.0 &&
            !sidenavService.mobileQuery?.matches
          ">
          Notifications
        </span>
      </div>
    </mat-list-item>
  </div>
  <div class="itemwrap" fxShow.lt-sm fxHide>
    <mat-list-item [routerLink]="['settings']" fxLayout="row" fxLayoutAlign="start center" class="list-item">
      <div fxLayout="row" fxLayoutAlign="start center" >
        <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
            else settingsTooltip" matTooltip="Settings" class="navMatIconUtil">settings</mat-icon>
        <ng-template #settingsTooltip>
          <mat-icon class="navMatIconUtil">settings</mat-icon>
        </ng-template>
        <span class="adminTextSpan" [fxHide]="
            sidenavService.leftSidenavDesktopWidth == 3.0 &&
            !sidenavService.mobileQuery?.matches
          ">
          Settings
        </span>
      </div>
    </mat-list-item>
  </div>
  <div class="itemwrap" fxShow.lt-sm fxHide>
    <mat-list-item [matMenuTriggerFor]="translateMenu" [routerLink]="['settings']" fxLayout="row" fxLayoutAlign="start center" class="list-item">
      <div fxLayout="row" fxLayoutAlign="start center" >
        <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 3.0;
            else settingsTooltip" matTooltip="Language" class="navMatIconUtil">translate</mat-icon>
        <ng-template #translateTooltip>
          <mat-icon class="navMatIconUtil">translate</mat-icon>
        </ng-template>
        <span class="adminTextSpan" [fxHide]="
            sidenavService.leftSidenavDesktopWidth == 3.0 &&
            !sidenavService.mobileQuery?.matches
          ">
          Language
        </span>
      </div>
    </mat-list-item>
    <mat-menu #translateMenu>
      <button color="primary" mat-menu-item *ngFor="let option of translate.getLangs(); trackBy: trackByFn"
        (click)="switchLang(option)">{{localeService.languageList[option]}}</button>
    </mat-menu>
  </div>

</mat-nav-list>
