<spinner
  [center]="true"
  [selfSpin]="spinner"
  size="la-2x"
  color="grey"
  type="ball-triangle-path"
></spinner>
<div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 20px;">
  <button mat-stroked-button color="primary" class="smallButton" (click)="getPermissions(true)">
    <div fxLayout="row" fxLayoutAlign="center center">
      <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">refresh</mat-icon></span>
      <span>Refresh</span>
    </div>
  </button>
</div>
<diV style="margin-top: 20px;">
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="100">
      <mat-card
        appearance="outlined"
        class="list-card-head"
        fxLayout="row wrap"
        layout-wrap
        fxLayoutAlign="start center"
        style="word-break: break-all; width: 100%"
      >
        <div
          fxLayout="column wrap"
          style="text-align: center; word-break: break-word"
          fxFlex="5"
        >
          #
        </div>
        <div
          fxFlex="5"
          fxLayout="column wrap"
          style="word-break: break-word"
        ></div>
        <div
          fxFlex="20"
          fxLayout="column wrap"
          fxLayoutAlign="center start"
          style="word-break: break-word"
        >
          Name
        </div>
        <div
          fxFlex="25"
          fxLayout="column wrap"
          fxLayoutAlign="center start"
          style="word-break: break-word"
        >
          Email
        </div>
        <div
          fxFlex="20"
          fxLayoutAlign="center start"
          fxLayout="column wrap"
          style="word-break: break-word"
        >
          Roles
        </div>
        <div
          fxFlex="15"
          fxLayout="column wrap"
          fxLayoutAlign="center start"
          style="word-break: break-word"
        >
          Status
        </div>
        <div
          fxFlex="5"
          fxLayout="column wrap"
          fxLayoutAlign="center start"
          style="word-break: break-word"
        ></div>
        <div
          fxFlex="5"
          fxLayout="column wrap"
          fxLayoutAlign="center start"
          style="word-break: break-word"
        ></div>
      </mat-card>
    </div>
  </div>

  <div fxLayout="row" *ngIf="permissions.length > 0">
    <div fxLayout="column" fxFlex="100">
      <div *ngFor="let permission of permissions; let i = index; trackBy: trackByFn">
        <mat-card
          appearance="outlined"
          *ngIf="usersMap[permission.user_id]"
          class="list-card"
          fxLayout="row wrap"
          layout-wrap
          fxLayoutAlign="start center"
          style="word-break: break-all; width: 100%"
        >
          <div
            fxLayout="column wrap"
            style="text-align: center; word-break: break-word"
            fxFlex="5"
          >
            {{ i + 1 }}
          </div>
          <div fxFlex="5" fxLayout="column wrap" style="word-break: break-word">
            <img
              *ngIf="usersMap[permission.user_id]?.image_url"
              src="{{ usersMap[permission.user_id]?.image_url }}"
              onerror="this.onerror = null; this.src = 'https://storage.googleapis.com/jivrus-web-images/avatar/no-user-photo.jpg';"
              class="image-size"
            />
          </div>
          <div
            fxFlex="20"
            fxLayout="column wrap"
            fxLayoutAlign="center start"
            style="word-break: break-word"
          >
            {{ usersMap[permission.user_id]?.first_name }}
            {{ usersMap[permission.user_id]?.last_name }}
          </div>
          <div
            fxFlex="25"
            fxLayout="column wrap"
            fxLayoutAlign="center start"
            style="word-break: break-word"
          >
            {{ usersMap[permission.user_id]?.email }}
          </div>
          <div
            matTooltip="Roles"
            fxFlex="20"
            fxLayoutAlign="center start"
            fxLayout="column wrap"
            style="word-break: break-word"
          >
            <div *ngFor="let role of permission.roles; trackBy: trackByFn">
              {{ roleMap[role]?.name }}
            </div>
          </div>
          <div
            matTooltip="Status"
            fxFlex="15"
            fxLayout="column wrap"
            fxLayoutAlign="center start"
            style="word-break: break-word"
          >
            {{ permission.status }}
          </div>
          <div
            fxFlex="5"
            fxLayout="column wrap"
            fxLayoutAlign="center start"
            style="word-break: break-word"
          >
            <mat-icon
              *ngIf="hasEditAccess()"
              style="margin: 0px; cursor: pointer"
              matTooltip="Update Permission"
              (click)="updatePermission = permission; setSelectedRole()"
            >
              edit
            </mat-icon>
          </div>
          <div
            *ngIf="hasDeleteAccess()"
            fxFlex="5"
            fxLayout="column wrap"
            fxLayoutAlign="center start"
            style="word-break: break-word"
          >
            <mat-icon

              style="margin: 0px; cursor: pointer"
              matTooltip="Delete permission"
              (click)="deleteResourcePermission(permission)"
            >
              delete
            </mat-icon>
          </div>
        </mat-card>

        <mat-card
          *ngIf="updatePermission && permission._id == updatePermission?._id"
          fxLayout="column"
        >
          <br />
          <div fxLayout="row" fxLayoutAlign="center center">
            Update Roles for {{ usersMap[permission.user_id]?.first_name }}
            {{ usersMap[permission.user_id]?.last_name }}
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column wrap" fxFlex="5"></div>
            <div fxLayout="column wrap" fxFlex="90" fxLayoutAlign="Center start">
            <multiselect-autocomplete placeholder="Select Roles..." key="options"
              [data]="availableRoles"
              (result)="selectChangeRoles($event)"
              [isSetData]="true"
              [setDataObj]="existingRoles">
            </multiselect-autocomplete>
           </div>
          <div fxLayout="column wrap" fxFlex="5"></div>
          <div fxLayout="column wrap" fxFlex="15" fxLayoutAlign="center start">
            <button
              mat-raised-button
              color="primary"
              (click)="updateResourcePermission()"
            >
              Update
            </button>
          </div>
          <div fxLayout="column wrap" fxFlex="5"></div>
          <div fxLayout="column" fxFlex="15" fxLayoutAlign="center start">
            <button
              mat-raised-button
              (click)="updatePermission = undefined"
            >
              Cancel
            </button>
          </div>
          </div>

        </mat-card>
      </div>
    </div>
  </div>
</diV>
