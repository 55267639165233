import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'inline-title',
    templateUrl: './inline-title.component.html',
    styleUrls: ['./inline-title.component.scss'],
    standalone: false
})
export class InlineTitleComponent implements OnInit {

  @Input() widgetMeta: any
  @Input() styles: any = {}
  @Input() showControls: boolean = false  // this input is used to control visibility of show/hide and edit button when widget is hovered

  @Input() builderMode: boolean = false
  @Output() widgetTitleChanged = new EventEmitter<any>();

  editingHeading: boolean = false

  constructor() {}

  ngOnInit(): void {}

  titleVisibilityChanged () {
    console.log("title vis changed", this.widgetMeta)
    this.widgetTitleChanged.emit(this.widgetMeta)
  }

  saveNewTitle(newHeaderRef: any){
    if(newHeaderRef.value == ''){
      console.log("empty text")
      return
    }
    console.log("new title will be saved", newHeaderRef.value)
    this.widgetMeta.config.title.value = newHeaderRef.value

    this.widgetTitleChanged.emit(this.widgetMeta)
  }

}
