import { Injectable } from '@angular/core';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';

@Injectable({
  providedIn: 'root',
})

export class SystemDataService {

   /**
   * Static system object map containing metadata for various system objects.
   */
  static systemObjectMap: any = {
    fields: ['bloommember_id', 'bloommember_email'],
    bloommember_id: {
      __id: 'bloommember_id',
      code: 'BloomMemberId',
      name: 'Bloom Member Id',
      dataSourceMap: {
        type: "bloommembers",
        dataType: "array"
      },
      pathMap: {
        name: {
          value: "${first_name} ${last_name}",
        },
        value: {
          value: "${_id}",
        },
      },
    },
    bloommember_email: {
      __id: 'bloommember_email',
      code: 'BloomMemberId',
      name: 'Bloom Member Email',
      dataSourceMap: {
        type: "bloommembers",
        dataType: "array"
      },
      pathMap: {
        name: {
          value: "${first_name} ${last_name}",
        },
        value: {
          value: "${_id}",
        },
      },
    },
  };

  constructor(public widgetUtilityService: WidgetUtilityService) {}

  /**
   * Returns the static system object mapping.
   */
  public getSystemObjectMap(){
    return SystemDataService.systemObjectMap;
  }


  /**
   * Fetches Bloom Member Data and maps it based on a given `pathMap`.
   * This function:
   * - Retrieves user data from `widgetUtilityService.getBloomUsers()`
   * - Creates a key-value mapping where:
   *   - Key: The user `_id`
   *   - Value: The formatted name (e.g., "John Doe")
   *
   * @param {object} pathMap - Defines how the data should be structured.
   * @returns {Promise<object>} A mapping of `{ userId: userName }`
   */
  public async getBloomMemberData(pathMap){
    let te = new TemplateEngine(); // Template engine to replace placeholders
    let bloomUserData = await this.widgetUtilityService.getBloomUsers(); // get user data.
    let mappedData = {};
    bloomUserData.forEach(user => {
      const obj:any = te.fillAny(pathMap, user); // Process user data using pathMap.
      mappedData[obj.value.value] = obj.name.value;
    });
    return mappedData;
  };

  /**
   * Get all the mapped available system data
   *
   * */
  public async getAllSystemDataMap(){
    let systemObjectMap = SystemDataService.systemObjectMap;
    let fields = systemObjectMap.fields;
    let valueMap = {};
    for (const element of fields) {
      valueMap[systemObjectMap[element]?.code] = await this.getSystemData(element)
    };
    return valueMap;
  }

  /**
   * Fetches system data based on a given `dataSourceType`.
   * This function determines the appropriate system object to fetch based on the type.
   * @param {string} dataSourceType - The type of system object to fetch.
   * @returns {Promise<object>} A key-value mapping of system object data.
   */
  public async getSystemData(dataSourceType){
    let systemObjectMap = SystemDataService.systemObjectMap[dataSourceType];
    let result: any;
    switch (dataSourceType) {

      //For `bloommember_id` and `bloommember_email`
      case "bloommember_id":
      case "bloommember_email":
        result = await this.getBloomMemberData(systemObjectMap.pathMap);
      break;

      //Other types can be handled here as it increases.

      default:
      break;
    }
    return result;
  }
}
