import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { BoxService } from 'src/app/bloom/services/box-service.service';

@Component({
    selector: 'app-connection-explorer-side-panel',
    templateUrl: './connection-explorer-side-panel.component.html',
    styleUrls: ['./connection-explorer-side-panel.component.scss'],
    standalone: false
})
export class ConnectionExplorerSidePanelComponent implements OnInit {

  panelMeta: any
  response: any
  isInputRequired: boolean = false
  show: boolean = false
  availableInputParams: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<ConnectionExplorerSidePanelComponent>,
    private boxService: BoxService,
    private automationService: AutomationService
  ) {  
    console.log("DIALOGREF", dialogData);
  }

  async ngOnInit() {

    for (let index in this.dialogData.action?.input?.options?.list) {
      var key = this.dialogData.action?.input?.options?.list[index]
      if(this.dialogData.action?.input?.options[key]?.required) {
        this.isInputRequired = true;
        this.show = true;
        break
      }
    }
    if(!this.isInputRequired) this.constructMeta()
  }

  async constructMeta(){
    var meta: any;
    let attribute: any;
    if (this.dialogData.attributeFetched){
      attribute = this.dialogData.attributeFetched
    }
    else {
      let response: any = await this.boxService.getAttributes(this.dialogData.connection._id, this.dialogData.box.__id,this.dialogData.object);
      attribute = response.result;
    }
    attribute.forEach(attr => {
      attr['enabled'] = true
      attr['widgetType'] = 'input'
      attr['editable'] = true
    })
    meta = this.dialogData.meta;
    
    meta.actionFnOptions = []
    meta.attributeOptions = []
    meta.getFnOptions = []
    meta.boxConfigToken = this.dialogData.connection.box_token
    meta.boxId = this.dialogData.box.__id
    meta.boxName = this.dialogData.box.name
    meta.boxObjectId = this.dialogData.object
    meta.connectionId = this.dialogData.connection._id
    meta.formPanelTitle = this.dialogData.operationMode == 'update' ? "Update Form" : 'Create Record'
    meta.primaryAttribute = attribute.find(attr => attr.primary)
    meta.selectedNow = true
    meta.submitButtonTitle = this.dialogData.operationMode == 'update' ? "Update" : "Save"
    meta.mode = this.dialogData.operationMode == 'update' ? "update" : "save"
    meta.fromBloom = false

    meta = await this.automationService.generateFormWidgets(attribute, meta, meta.submitButtonMeta, this.dialogData.object+'/'+this.dialogData.operationMode)
    meta['submitButtonMeta'] = meta.widgets[meta.widgets.length - 1]
    meta.formAttributes = attribute;
    
    if(this.isInputRequired) meta.widgets[meta.widgets.length - 1].actionConfig.actions[0].actionMap['inputParams'] = this.availableInputParams
    this.panelMeta = meta;

    console.log(" F O R M  P A N E L  M E T A ",this.panelMeta);
    this.show = false;
  }

  executionResponse(data){
    this.response = data;
  }

  close(){
    this.response ? this.dialogRef.close(this.response) : this.dialogRef.close();
  }

  inputRecevied(event){
    if(event.query){
      event.query = JSON.parse(event.query);
    }
    console.log("event", event);
    this.availableInputParams = event.options;
  }

}
