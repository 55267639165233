
<div style="width: 100%;" *ngIf="!pageService.isPrerendered && ready === false && (spinner || pageService.spinner)">
  <spinner [type]="'skeleton'" [selfSpin]="true" [skeletonPresetCode]="'page-loader-2'"></spinner>
</div>

<ng-container *ngIf="builderMode; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
  <!-- *ngIf="ready" -->
  <div
    [hidden]="!ready"
    class="page-wrap"
    (click)="panelDeselected($event)"
  >
    <div
      *ngIf="currentPageMeta?.panels?.length"
      class="panel-list-wrap" #panelWrapRef
      [ngClass]="{ 'blurred': pageService.panelReorderDragging }"
      cdkDropList cdkScrollable
      [cdkDropListAutoScrollStep]="10"
      (cdkDropListDropped)="panelReorder($event)"
      [cdkDropListEnterPredicate]="checkIfDragAllowed"
      fxLayout="row wrap" fxLayoutAlign="start center"
    >
      <!-- autoScrollSteps="50" -->
      <!-- cdkDropListGroup -->
      <div
        #panel
        fxFlex="100%"
        *ngFor="let panel of currentPageMeta.panels; let i = index; trackBy: trackByFn"
        class="panel" [ngClass]="{ 'panel-with-margin': builderMode }" cdkDrag [cdkDragData]="{panel: panel, index: i}"
        (cdkDragStarted)="reorderDragStarted($event)"
        [cdkDragDisabled]="!builderMode"
      >
      <!-- (cdkDragEnded)="reorderDragEnded($event)"  -->

        <div class="panel-reorder-placeholder" *cdkDragPlaceholder></div>
        <div class="panel-reorder-preview" *cdkDragPreview [style.width]="panelWidthBeforeDrag" [style.height]="panelHeightBeforeDrag">
          <app-panel [panelMeta]="panel" [pageMeta]="currentPageMeta" [builderMode]="false"></app-panel>
        </div>
        <div class="new-panel-placeholder" *ngIf="!pageService.isNoWidgetDrop && currentPageMeta.code === pageService.pageMeta.code && pageService.dragNewPanelPlaceholder.newIndex === i"></div>

        <app-panel
          (onPanelSelect)="onPanelSelect($event)"
          (onPanelMouseenter)="onPanelMouseenter($event)"
          (onPanelMouseleave)="onPanelMouseleave($event)"
          (onPanelDelete)="panelDelete($event)"
          (newPanelMeta)="newPanelMeta($event)"
          (widgetDeletion)="widgetDeletion($event)"
          (userInputReceived)="userInputHandler($event)"
          [selectedPanelId]="activePanel"
          [panelMeta]="panel"
          [parentType]="'bloom'"
          [builderMode]="builderMode"
          [pageMeta]="currentPageMeta"
        >
        </app-panel>
        <!-- [hoveredPanelId]="pageService.hoveredPanelId" -->
      </div>

      <div class="new-panel-placeholder" *ngIf="currentPageMeta.code === pageService.pageMeta.code && pageService.dragNewPanelPlaceholder.newIndex === currentPageMeta.panels.length"></div>

    </div>
  </div>
  <!-- <br/><br/> -->
</ng-template>
<ng-template #elseTemplate>
  <div *ngIf="ready" class="page-wrap">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex="100%" *ngFor="let panel of currentPageMeta.panels; trackBy: trackByFn" class="panel">
        <app-panel [panelMeta]="panel" [pageMeta]="currentPageMeta" [parentType]="'bloom'" [builderMode]="builderMode" (userInputReceived)="userInputHandler($event)">
        </app-panel>
      </div>
    </div>
  </div>
</ng-template>

<!-- <pre>{{ currentPageMeta | json }}</pre> -->
