<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick($event)"

  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover" style="width: 100%">
    <!-- <div fxFlexFill>
      <ng-container *ngIf="!editingHeading; else elseTemplate">
        <div class="editHeadingBlock" fxFlexFill fxLayout="row wrap" [style.justify-content]="widgetMeta?.config?.alignment?.value" fxLayoutGap=".5rem">
          <div [class.blurred]="!widgetMeta.config.title?.show" [ngStyle]="styles">{{ widgetMeta.config.title.value }}</div>
          <mat-icon [class.blurred]="!widgetMeta.config.title?.show" class="icon editHeadingIcon" (click)="editingHeading = widgetMeta.config.title?.show ? true : false; $event.stopPropagation()" matTooltip="Edit Title">edit</mat-icon>
          <mat-icon class="icon" *ngIf="widgetMeta.config.title?.show" (click)="widgetMeta.config.title.show = false; $event.stopPropagation()" matTooltip="Hide Title">visibility_off</mat-icon>
          <mat-icon class="icon" *ngIf="!widgetMeta.config.title?.show" (click)="widgetMeta.config.title.show = true; $event.stopPropagation()" matTooltip="Show Title">visibility</mat-icon>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-form-field class="editHeadingBlock" style="width: 100%">
          <mat-label>Change Title</mat-label>
          <input matInput #newHeading type="text" [value]="widgetMeta.config.title.value" (click)="$event.stopPropagation()">
          <mat-icon matSuffix class="icon saveHeadingIcon" (click)="editingHeading = false; saveNewTitle(newHeading); $event.stopPropagation()">done</mat-icon>
        </mat-form-field>
      </ng-template>
    </div> -->

    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>

    <div style="height: 1rem;"></div>
    <mat-slider
      id="slider"
      color="primary"
      matSliderThumb
      showTickMarks
      [max]="widgetMeta.config.maximum.value"
      [min]="widgetMeta.config.minimum.value"
      [step]="widgetMeta.config.step.value"
      discrete [displayWith]="formatLabel"
      (mousedown)="$event.stopPropagation()"
     #ngSlider>
     <input matSliderThumb [value]="widgetMeta.config.value.value" (change)="sliderChanged({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>
    <span class="sliderLabel">
      <mat-label class="sliderLabelLeft" *ngIf="widgetMeta.config.leftMarker !== ''">{{ widgetMeta.config.leftMarker.value }}</mat-label>
      <mat-label class="sliderLabelRight" *ngIf="widgetMeta.config.rightMarker !== ''">{{ widgetMeta.config.rightMarker.value }}</mat-label>
    </span>
  </div>
</div>

<div  *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)" class="outerCover" (click)="onClick($event)" fxLayout
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover">
    <!-- <div *ngIf="widgetMeta.config.title?.show" [ngStyle]="styles"
    fxFlexFill fxLayout [style.justify-content]="widgetMeta?.config?.alignment?.value">
    {{ widgetMeta.config.title.value }}</div> -->

    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>

    <div style="height: 1rem;"></div>
    <div class="sliderLabel slider-label-container">
      <label id="slider-name-label" class="slider-name-label">Value</label>
      <label class="slider-value-label">{{ngSliderThumb.value}}</label>
    </div>
    <mat-slider
      id="slider"
      color="primary"
      matSliderThumb
      showTickMarks
      discrete [displayWith]="formatLabel"
      [disabled]="widgetMeta.config.viewOnly?.value"
      max="widgetMeta.config.maximum.value"
      min="widgetMeta.config.minimum.value"
      step="widgetMeta.config.step.value"
      (mousedown)="$event.stopPropagation()"
     #ngSlider>
     <input matSliderThumb [ngStyle]="{'cursor': widgetMeta.config.viewOnly?.value ? 'default': 'pointer' }" [value]="widgetMeta.config.value.value" (change)="sliderChanged({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb"/>
    </mat-slider>

    <span class="sliderLabel" fxFlexFill fxLayout fxLayoutAlign="space-between center">
      <mat-label class="sliderLabelLeft" *ngIf="widgetMeta.config.leftMarker !== ''">{{ widgetMeta.config.leftMarker.value }}</mat-label>
      <mat-label class="sliderLabelRight" *ngIf="widgetMeta.config.rightMarker !== ''">{{ widgetMeta.config.rightMarker.value }}</mat-label>
    </span>
  </div>
