import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { BaseAction } from './BaseAction';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
// import { BoxService } from 'src/app/bloom/services/box-service.service';
import { WidgetManager } from '../WidgetManager';
import { BoxService } from 'src/app/shared/services/box.service';
import { ActionServiceUtility } from './ActionServiceUtility';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ContextDataService } from 'src/app/shared/services/context-data.service';
import { SystemDataService } from 'src/app/shared/services/system-data.service';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';

@Injectable({
  providedIn: 'root',
})
export class ApplicationAction extends BaseAction {
  constructor(
    public router: Router,
    public metaService: MetaService,
    // public connectionService: ConnectionService,
    public tokenUtil: TokenUtil,
    public boxService: BoxService,
    public pageService: PageService,
    private snackBar: MatSnackBar,
    private httpCacheService: HttpCacheService,
    // public actionServiceUtility: ActionServiceUtility,
    // public expressionUtility: ExpressionUtility,
    public spinnerService: SpinnerService,
    // public contextDataService: ContextDataService,
    // public systemDataService: SystemDataService,
    public injector: Injector
  ) {
    super();
  }

  private getConnectionService(): ConnectionService {
    return this.injector.get(ConnectionService);
  }

  private getExpressionUtility(): ExpressionUtility {
    return this.injector.get(ExpressionUtility);
  }

  private getSystemDataService(): SystemDataService {
    return this.injector.get(SystemDataService);
  }

  private getContextDataService(): ContextDataService {
    return this.injector.get(ContextDataService);
  }

  private getActionServiceUtility(): ActionServiceUtility {
    return this.injector.get(ActionServiceUtility);
  }

  widgetManager: any = WidgetManager;
  primaryAttribute: any;
  clonedActionMap: any;
  actionMode: string;

  getActionDetail() {
    return {
      name: 'Application',
      id: 'application',
    };
  }

  getActionConfiguration() {
    let config = {};
    return config;
  }

  public async doAction(options?: any, event?: any) {
    let actionResult: any
    this.clonedActionMap = JSON.parse(JSON.stringify(options.actionMap))
    try{
      let actionMap = options.actionMap;
      let onMouseEvent = options?.event;
      this.spinnerService.show();

      if(event) {
        if(!this.getActionServiceUtility().isEventMatch(onMouseEvent, event)) return;
      }
      let mapping = actionMap.mapping;
      let box = actionMap.boxId;
      let valueMap = actionMap?.valueMap || null;

      let boxToken: null;

      let preauthenticatedToken = this.getConnectionService().getPreAuthenticatedTokenForEmail(this.metaService.publisher_email);
      preauthenticatedToken = this.metaService.publisher_email ? preauthenticatedToken : this.getConnectionService().preAuthenticatedToken;

      if(actionMap.boxId == "starch"){
        box = actionMap.baseMap?.box_id;
        boxToken = actionMap?.boxConfigToken;
      } else {

        let connectionId = actionMap.connection;
        if(!connectionId && options?.action == "application") return;

        if(actionMap?.whiteLabelConnection && actionMap?.whiteLabelMap?.widget){
          connectionId = await this.getWhiteLabeledConnection(actionMap?.whiteLabelMap?.widget)
        }
        console.log("[APPLICATION-ACTION] actionMap", actionMap)
        let connection: any
        try{
          connection = await this.getConnectionService().getConnection(connectionId, preauthenticatedToken);
        }catch(e){
          console.error("could not get connection", e)
          this.openSnackBar({iconname: "error"}, e)
        }
        boxToken = connection.box_token;
        console.log("[APPLICATION-ACTION] connection fetched", connection)
      }

      var statelessToken = await this.tokenUtil.getStatelessToken();

      let actionId = actionMap.action;
      let actionFunType = 'box';
      if (actionId.includes('/')) actionFunType = 'boxObject';
      this.actionMode = actionMap?.actionMode || null;
      let boxObjectId;
      if(actionId.includes('/save')){ //for save
        boxObjectId = actionId.split("/")[0];
        if(!this.actionMode) this.actionMode = "create";
        if(this.actionMode == "create") mapping = await this.checkAndGetBoxAction(box, actionId, boxToken, mapping, options.actionMap.inputParams);
        console.log("[APPLICATION-ACTION] mapping", mapping)
        actionId = boxObjectId + "/" + this.actionMode;
      }
      console.log("[APPLICATION-ACTION] actionId", actionId)
      let url = `${environment.BOX_URL}/${box}/${actionId}`;

      //clearing stored cache on boxobject CUD execution
      if(actionFunType == "boxObject" && !actionId.includes('get')){
        this.httpCacheService.deleteCacheBasedOnKeyPrefix(`box_get_${boxObjectId}`);
      }

      //replace pagemodel with input values if pagemodel is pre binded to actionMap
      if(actionMap.pageModel){
        actionMap.pageModel = this.replacePagemodel(actionMap.pageModel);
      }

      console.log("[API-EXECUTION] mapping", mapping)
      let payload: any;
      payload = actionMap?.payload;
      if(!payload) payload = await this.constructPayload(mapping, actionFunType, actionMap, valueMap, this.actionMode);
      console.log("[API-EXECUTION] [API] Final url %s and payload: ", url, payload);
      // console.log("[API-EXECUTION] [API] url: ", url);

      let httpOptions = { headers: {
          Authorization: `Bearer ${statelessToken}`,
          boxConfigToken: boxToken,
      }};

      actionResult = {
          status: "success"
      }

      var boxResponse = null;
      try {
          boxResponse = await this.boxService.execute(url, payload, null, null, httpOptions);
          console.log("[API-EXECUTION] boxResponse", boxResponse);

          /* if action is save. and (creation / update) is a success. and current payload do not contain primary key value which is available in boxresponse,
           update the pagemodel with the primary key value obtained from the response */

           if(actionMap?.action.includes('/save') && boxResponse?.result?.data?.[0]?.status == 'SUCCESS' && actionMap?.origin == 'formpanel'){
              this.updatePagemodelOnSave(boxResponse, payload)
           }

          if(options?.code) this.getContextDataService().setResult(options.code, boxResponse?.result?.data || {});
          this.showNotification(actionMap)
      } catch (err: any) {
          console.log("[EXE] Failed for Url", url, "ERROR : ", err)
          actionResult.status = "failure";
          this.openSnackBar({iconname: "error"}, err)
      }
      this.spinnerService.hide();
    }catch(e){
      console.error(e)
      this.openSnackBar({iconname: "error"}, e)
    }
    return actionResult;
  }

  updatePagemodelOnSave(boxResponse:any, payload:any){
    /*Whenever save action is configured, after a record is created on form submit (from form panel),
      we are putting the value of the unique Id field(primary key) taking from response and putting in the pagemodel in respective widget
      as we get the path information where to replace from mapping. This will prevent duplication of record if same form is submitted multiple times.*/

    if(this.primaryAttribute?.__id && !Object.keys(payload?.parameters?.data?.[0]).includes(this.primaryAttribute?.__id) && Object.keys(boxResponse?.result?.data?.[0]?.data).includes(this.primaryAttribute?.__id)){
      const primaryMapping = this.clonedActionMap.mapping.find(
        (mapping) => mapping.appField.__id === this.primaryAttribute.__id
      );
      if(primaryMapping) {
        const sourceFieldId = primaryMapping.sourceField.__id;
        const panelId = sourceFieldId.split('.')[0]
        const widgetId = sourceFieldId.split('.')[1]
        const primaryKey = this.primaryAttribute.__id;
        const newValue = boxResponse?.result?.data?.[0]?.data?.[primaryKey];
        if (this.pageService?.pageModel?.[panelId]?.[widgetId]) this.pageService.pageModel[panelId][widgetId]["value"] = newValue;
      }
    }
  }

  async constructPayload(mapping: any, actionFunType: string, actionMap?: any, valueMap?:any, actionMode?:any): Promise<any> {

    let inputParams = actionMap.inputParams;
    let pagemodel = actionMap.pageModel;
    let origin = actionMap.origin;
    let actionId = actionMap.action;
    let boxActionId = actionId?.split("/")?.[1] || "";

    let payload: any;
    let pageModel
    if(valueMap) {
      pageModel = valueMap;
    } else {
      pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
      if(pagemodel) if(Object.keys(pageModel).length === 0) pageModel = JSON.parse(JSON.stringify(pagemodel))
      pageModel = this.getExpressionUtility().resolvePageModelExpressions(pageModel)
    }

    pageModel = await this.getContextDataService().getContextData(pageModel);

    //set system object
    pageModel["SystemObject"] = await this.getSystemDataService().getAllSystemDataMap();

    console.log("[API-EXECUTION] [ApplicationAction construct payload()] pageModel", pageModel)
    let data = this.getActionServiceUtility().prepareAllHash(mapping, pageModel);
    console.log("payload", data)
    // merge context data
    console.log("[APPLICATION-ACTION] actionMode ", actionMode, "origin ", origin)

    if((actionMode == "update" || actionMode == "save") && origin == "formpanel" && pageModel.old){
      Object.keys(pageModel.old).forEach(key => {
        if(!data.hasOwnProperty(key)){
          data[key] = pageModel.old[key]
        }
      })
    }

    let te = new TemplateEngine();
    console.log("[ApplicationAction construct payload()] pageModel", pageModel)
    if(inputParams) {
      let convertedData = this.getActionServiceUtility().convertDataWithDots(pageModel);
      inputParams = te.fillAny(inputParams, convertedData)
    }

    let queryParams, headers;
    if(actionMap?.queryParams?.length){
      queryParams = this.constructQueryParams(actionMap?.queryParams, pageModel);
    }

    if(actionMap?.headers?.length){
      headers = this.constructHeaders(actionMap?.headers, pageModel);
    }
    if (actionFunType == 'boxObject') {
      //delete mode or id actionid has ById, i.e to avoid getById, DeleteById kind of funcions
      if((actionMode && actionMode == "deleteById") || (!actionMode && boxActionId && boxActionId.includes("ById"))){
        payload = {
          parameters: data,
        };

      } else {
        // we need to remove empty value properties only in create, in update, empty values will be considered as valid values
        if (actionMode && actionMode == "create") {
          data = this.getActionServiceUtility().removeEmptyProperties(data);
        }

        payload = {
          parameters: {
            data: [data],
            options: inputParams || {}
          },
        };
      }
    } else {
      let parameters:any = {};

      let functionOptions = actionMap?.functionOptions;

      // Check if multiple payloads are supported and a payload wrapper exists
      if(functionOptions?.isSupportMultiple && functionOptions.payloadWrapper){
        // If actions require wrapping the payload under a specific key.
        let wrappedData = {};
        wrappedData[functionOptions.payloadWrapper?.__id] = [data];
        data = wrappedData;
      }

      //currently considering queryParams and headers as a symantic type requirements, hence assign each seperatly
      // if any box has more generic implementation here we should get function config and implement the genric way
      if(queryParams || headers) {
        parameters.queryParams = queryParams;
        parameters.headers = headers;
        parameters.data = data;
      } else {
        parameters = data;
      }
      payload = {parameters};
    }
    return payload;
  }


  //replace pagemodel with input values if pagemodel is pre binded to actionMap
  replacePagemodel(actionMap){
    for(let obj in actionMap.pageModel[Object.keys(actionMap.pageModel)[0]]) {
      let widgetType = actionMap.pageModel[Object.keys(actionMap.pageModel)[0]][obj].widgetType
      let newWidget = WidgetManager.getWidget(widgetType)
      let widgetConfig = newWidget.getWidgetConfig();
      let valuePath = widgetConfig.valuePath
      let selectedWidget = actionMap.widgets.find(fn => fn.id == obj)
      Object.keys(selectedWidget).forEach(k => newWidget[k] = selectedWidget[k])
      let value = newWidget.getDeepObjectValue(newWidget, valuePath)
      actionMap.pageModel[Object.keys(actionMap.pageModel)[0]][obj].value = value
    }
    console.log("[API-EXECUTION] PAGE MODEL ", actionMap.pageModel);
    return actionMap.pageModel;
  }

  async checkAndGetBoxAction(box, actionId, boxToken, mapping, inputParams){
    this.primaryAttribute = await this.getPrimaryAttribute(box, actionId, boxToken, inputParams);

    let index = null;
    var clonedMapping = JSON.parse(JSON.stringify(mapping));
    for(var i = 0; i < clonedMapping.length; i ++){
      if(clonedMapping[i].appField?.__id == this.primaryAttribute?.__id){
        index = i;
        break;
      }
    }

    //if pageModel has the value for the primary field then do not remove from mapping - it will then update the existing record and not be duplicated.
    if(this.clonedActionMap?.action.includes('/save') && this.clonedActionMap?.origin == 'formpanel'){
      const sourceFieldId = clonedMapping[i].sourceField.__id;
      const panelId = sourceFieldId.split('.')[0]
      const widgetId = sourceFieldId.split('.')[1]
      if (this.pageService?.pageModel?.[panelId]?.[widgetId]?.value) {
        this.actionMode = 'update';
        return mapping;
      }
    }

    if(index !== null) mapping.splice(index, 1)
    console.log("mapping after", mapping)
    return mapping;
  }

  async getPrimaryAttribute(box, actionId, boxToken, inputParams){
    let attributes
    try{
      attributes = await this.boxService.getAttributes(box, actionId, null, boxToken, inputParams);
    }catch(e){
      console.error("could not fetch attributes", e)
      this.openSnackBar({iconname: "error"}, e)
    }
    var result = null;
    for(var i = 0; i < attributes.length; i++){
      if(attributes[i]?.primary){
        result = attributes[i];
      }
    }
    return result;
  }

  async getWhiteLabeledConnection(widget:any){
    let pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
    pageModel = this.getExpressionUtility().resolvePageModelExpressions(pageModel);
    return this.getActionServiceUtility().getObjectValue(widget.__id, pageModel);
  }

  constructQueryParams(queryParams, pageModel){
    let result = {};
    queryParams.forEach(element => {
      result[element.__id] = element.value
    });
    return result;
  }

  constructHeaders(headers, pageModel){
    let result = {};
    headers.forEach(element => {
      result[element.__id] = element.value
    });
    return result;
  }

  showNotification(actionMap){
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: actionMap?.successMessage || "Successfully executed the action",
        iconname: "check_circle",
      },
      duration: 3000,
      horizontalPosition: 'end',
    });
  }

  openSnackBar(snackBarObj: any, error: any){
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: error?.error?.error?.name || error.message || error || '',
        description: error?.error?.error?.message || error?.error?.error || error?.error?.message || '',
        recommendation: snackBarObj?.snackBarRecommendation || '',
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
        isError: true
      },
      duration: snackBarObj.snackBarDuration || undefined,
      horizontalPosition: 'end',
    });
  }

  getObjectValue(string: any, data: any) {
    console.log('string', string);
  }



  private fillIntoPayload(obj, path, value) {
    // console.log("")
    // console.log("obj", JSON.parse(JSON.stringify(obj, null, 2)))
    // console.log("path", path)
    // console.log("value", JSON.parse(JSON.stringify(value, null, 2)))

    let effectiveValue
    if(Array.isArray(value)){
      effectiveValue = JSON.parse(JSON.stringify(value))
    }else{
      effectiveValue = value
    }

    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = effectiveValue;
    return obj;
  }


    // prepareAllHash(fieldMap: string | any[], fieldValueMapObj: any) {
  //   console.log("mapping", fieldMap)
  //   console.log("values", fieldValueMapObj)
  //   let te = new TemplateEngine();
  //   let payload: any = {};

  //   for (let i = 0; i < fieldMap.length; i++) {
  //     let field = fieldMap[i];
  //     console.log("dealing field", field)
  //     let appField: string = field.appField.__id || field.appField.name;
  //     let mapType: string = field.mappingType;
  //     let sourceField: string = field.sourceField?.__id || field.sourceField;

  //     console.log("sourceField", sourceField)
  //     console.log("appField", appField)
  //     console.log("mapType", mapType)

  //     let resultValue: any
  //     if (mapType == 'templateField') {
  //       resultValue = te.fillAny(sourceField, fieldValueMapObj);
  //     } else {
  //       resultValue = this.getObjectValue(sourceField, fieldValueMapObj);
  //     }
  //     console.log("result value", resultValue)

  //     payload = this.fillIntoPayload(payload, appField, resultValue)
  //     console.log("payload after replace", JSON.parse(JSON.stringify(payload)))
  //     // console.log("appField", appField, payload[appField])
  //   }

  //   return payload;
  // }


}
