<div
  *ngIf="builderMode"
  class="outerCover"
  fxLayoutAlign="center center"
  cdkDragHandle
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <span 
    class="moveCursor innerCover" 
    (click)="onClick($event)"
    style="display: flex; align-items: center; margin: 3px;" 
    [style.justify-content]="widgetMeta?.config?.alignment?.value"
  >
    <mat-icon fxFlexFill cdkDragHandle fxLayoutAlign="center center" [ngStyle]="styles">{{ iconCode }}</mat-icon>
  </span>
</div>


<div
  *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)" class="outerCover" fxLayoutAlign="center center"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <span 
    class="innerCover"
    (click)="onClick($event)"
    style="display: flex; align-items: center" 
    [style.justify-content]="widgetMeta?.config?.alignment?.value"
  >
    <mat-icon class="pointerCursor" fxFlexFill [ngStyle]="styles" fxLayoutAlign="center center">{{ iconCode }}</mat-icon>
  </span>
</div>
