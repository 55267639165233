import { CommonModule } from "@angular/common";
import { Component, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuillModule } from "ngx-quill";
import { PageComponent } from "src/app/bloom/page/page.component";
import { PageService } from "src/app/bloom/services/page-service.service"
import { SharedModule } from "src/app/shared/shared.module";
import { ViewerCanvasComponent } from './viewer-canvas/viewer-canvas.component'
import { SpecializedPanelsModule } from "src/app/bloom/specialized-panels/specialized-panels.module";
import { BloomLoginComponent } from './bloom-login/bloom-login.component';
import { BloomModule } from "src/app/bloom/bloom.module";
import { BloomLoginDiologComponent } from "./bloom-login-diolog/bloom-login-diolog.component";
import { BloomToolbarComponent } from "./bloom-toolbar/bloom-toolbar.component";
import { SpinnerModule } from "src/app/shared/spinner/spinner.module";
import { BloomWithoutRoutingModule } from "src/app/bloom/bloom-without-routing.module";

/**
 * ALL THE BELOW ROUTES SHOULD BE SUPPORTED
 *
 * 1. p/bloomCode/pageCode
 * 2. p/bloomCode
 * 3. p/bloomCode
 */
const routes: Routes = [
  {
    path: ':bloomcode',
    component: ViewerCanvasComponent,
  },
  // {
  //   path: ':bloomcode/s/sign-up',
  //   component: BloomLoginComponent,
  // },
  {
    path: ':bloomcode',
    component: ViewerCanvasComponent,
    children: [
      {
        path: ':pagecode',
        component: PageComponent,
        children: [
          {
            path: '**',
            component: PageComponent,
          },
        ]
      },
      {
        path: ':pagecode',
        component: PageComponent,
      }

    ]
  },
]

@NgModule({
    declarations: [
        ViewerCanvasComponent,
        BloomLoginComponent,
        BloomLoginDiologComponent,
        BloomToolbarComponent,
        // SpinnerComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        QuillModule,
        SpecializedPanelsModule,
        RouterModule.forChild(routes),
        SpinnerModule,
        BloomWithoutRoutingModule,
    ],
    exports: [ViewerCanvasComponent]
})
export class ViewerModule { }
