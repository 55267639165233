import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';

declare const google: any

@Component({
    selector: 'app-bloom-login',
    templateUrl: './bloom-login.component.html',
    styleUrls: ['./bloom-login.component.scss'],
    standalone: false
})
export class BloomLoginComponent implements OnInit {


  bloomMap: any = {}
  isBrowser: boolean;
  currentBloomCode: any;
  spinner: boolean

  @Input() isDialog: boolean = false;
  @Output() loggedIn = new EventEmitter<any>()

  constructor(
    // @Inject(MAT_DIALOG_DATA)
    // public dataInput: any,
    // public dialog: MatDialogRef<BloomLoginComponent>,
    private tokenUtil: TokenUtil,
    private ngZone: NgZone,
    private metaService: MetaService,
    private authService: AuthServiceService,
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  async ngOnInit() {
    if(!this.isBrowser) return;

    this.route.params.subscribe(routeData => {
      console.log("route data received: ", routeData)
      if(routeData.bloomcode) this.currentBloomCode = routeData.bloomcode || undefined;
    })

    this.bloomMap = this.metaService.bloomMeta;
    if(!this.bloomMap && this.currentBloomCode) this.bloomMap = await this.metaService.getBloomAndPageStructure(this.currentBloomCode, "latest");
    google.accounts.id.initialize({
      client_id:  environment.GOOGLE_CLIENT,
      callback: this.handleCredentialResponse.bind(this),
      // context: "signin",
      ux_mode: "popup",
      // auto_select: true
    });

    google.accounts.id.renderButton(
      this.document.getElementById("buttonDiv"),
      {
        theme: "filled_blue",
        // size: "medium",
        logo_alignment: "left",
        text: "signin_with",
        type: "standard",
        shape: "rectangular"

      }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }

  async handleCredentialResponse(e){
    console.log("handleCredentialResponse", e);
    let that = this;
    this.spinner = true;
    // var userMap = this.tokenUtil.getDecodedJWT(e.credential);
    var userMap = await this.authService.checkAndGetBloomUser(e);
    this.metaService.bloomUserMap = userMap;
    this.metaService.bloomUserLoggedIn = true;
    let themeResult = await this.themeService.getExistingTheme(userMap._id);
    if (themeResult?.data?.[0]) {
      this.themeService.settings_Id = themeResult?.data[0]._id;
      this.themeService.setTheme(themeResult.data[0].themeSetting?.theme);
      this.themeService.currentLocale = themeResult.data[0].localeSetting;
      this.themeService.textDirection = themeResult.data[0].themeSetting.direction;
      if (themeResult.data[0].themeSetting.direction == 'rtl') {
        this.themeService.enableDirMode('rtl');
      }
    }
    console.log("userMap themeResult", userMap, themeResult);
    this.ngZone.run(() => {
      // if(this.isDialog){
        this.spinner = false;
        that.loggedIn.emit(userMap);
      // } else {
      //   this.router.navigate(['p', this.bloomMap.code, this.metaService.bloomLoginPageCode || this.metaService.bloomMeta?.page_structure_map?.homePageCode]);
      // }

    })


  }

}
