<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <!-- <mat-form-field style="width: 100%;">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" (dateChange)="inputDetected($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->
    <mat-form-field 
      fxFlexFill
      *ngIf="!(widgetMeta.config.viewOnly?.value)" 
      [appearance]="this.widgetMeta.config?.appearance?.value ? this.widgetMeta.config.appearance.value : 'standard'"
    >
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.name || widgetMeta.config.placeholder.value }}</mat-label>
      <input 
        readonly
        matInput 
        [placeholder]="widgetMeta.config.placeholder.value"
        [matDatepicker]="picker" 
        [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false"
        (dateChange)="inputDetected($event)"
      >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <!-- <input 
        matInput type="date" 
        [ngModel]="displayValue" 
        [placeholder]="widgetMeta.config.placeholder.value" 
        [required]="widgetMeta?.required ? widgetMeta.required : false" 
        (input)="inputDetected($event)"
      > -->
    </mat-form-field>
    <div *ngIf="widgetMeta.config.viewOnly?.value">{{ displayValue || "" }}</div>
  </div>
</div>

<div
  *ngIf="!builderMode && !widgetMeta.config?.hidden?.value" class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <mat-form-field 
      class="input" 
      *ngIf="!(widgetMeta.config.viewOnly?.value)" 
      style="width: 100%;"
      [appearance]="this.widgetMeta.config?.appearance?.value ? this.widgetMeta.config.appearance.value : 'standard'"
    >
    <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.name || widgetMeta.config.placeholder.value }}</mat-label>
      <input
        matInput 
        [placeholder]="widgetMeta.config.placeholder.value"
        [matDatepicker]="picker"
        [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false" 
        (dateChange)="inputDetected($event)"
        [(ngModel)]="displayValue"
        #dateInput="ngModel"
      >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <!-- Error & Note handling -->
      <mat-error *ngIf="dateInput.invalid && dateInput.hasError('required')">Date is required</mat-error>
      <mat-hint *ngIf="widgetMeta.note">
        <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
        <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
      </mat-hint>
    </mat-form-field>
    <div *ngIf="widgetMeta.config.viewOnly?.value">{{ displayValue || "" }}</div>
  </div>
</div>
