import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Component, NgZone, OnInit, ChangeDetectorRef, ViewChild, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { EventEmitter } from 'stream';

@Component({
    selector: 'menu-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss'],
    standalone: false
})
export class MenuSolutionsComponent implements OnInit {

  baseURL: any = environment.LOGO_BASE_URL
  isBrowser: any;
  constructor(
    private authService: AuthServiceService,
    private themeService: ThemeService,
    private connectionService: ConnectionService,
    private cd: ChangeDetectorRef,
    public router: Router,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  templatesMap :any = {
    list: ["bloom-templates", "flow-templates", "demo-blooms", "form-templates"],
    "bloom-templates": {
      title:"Bloom Templates",
      logo:'https://seeklogo.com/images/B/blue-flower-design-logo-F4C2DC0C40-seeklogo.com.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.BLOOM_TEMPLATES}`,
      type: 'external-bloom',
      tagline: "Empower Your Ideas with Versatile Bloom Templates"
    },
    "flow-templates": {
      title:"Flow Templates",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/platform/aw-flow-templates-logo-without-padding.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.FLOW_TEMPLATES}`,
      type: 'external-bloom',
      tagline: "Kick-start Your Business Automation With Flow Templates"
    },
    "demo-blooms": {
      title:"Demo Blooms",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/platform/aw-demo-blooms-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.DEMO_BLOOMS}`,
      type: 'external-bloom',
      tagline: "Amplify Your Creativity with Demo Blooms"
    },
    "form-templates": {
      title:"Form Templates",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/platform/aw-form-template-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.FORM_TEMPLATES}`,
      type: 'external-bloom',
      tagline: " Ignite Your Workflow with Form Templates"
    }
  }
  ngOnInit(): void {

  }

  openConnections(){
    this.themeService.closeMenu.next(null)
  }

  navigateToIntegration(app){
    window.open(`${app.link}`, "_blank");
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }


}
