import { Widget } from "./baseWidget";

export class Space extends Widget {
  type = 'space';
  gridX = 12;
  gridY = 1;
  minGridX = 12;

  config = {
    props: [],
  };

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }

  // public getWidgetConfig() {
  //   let map: any = {
  //     valuePath: "config.url.value",
  //     effect: {
  //       styles: []
  //     }
  //   }
  //   map = this.mergeEffectStyle(map);

  //   return map;
  // }
}
