import { Widget } from "./baseWidget";

export class Embed extends Widget {
  type = 'embed';
  title = 'default title'; // Corrected line
  gridX = 12;
  gridY = 6;
  minGridX = 6;

  config = {
    props: [
      // "tooltip",
      // "height",   // in px
      // "width",    // in px
    ],
    // height: {
    //   displayName: 'Height',
    //   value: 400,
    //   type: 'number'
    // },   // default
    // width: {
    //   displayName: 'Width',
    //   value: 600,
    //   type: 'number'
    // },    // default
    // tooltip: {
    //   displayName: 'Tooltip',
    //   value: "",
    //   type: 'text',
    //   enabled: true
    // },
  };

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['value'].value = 'https://appiworks.com/';
    this.config['value'].displayName = 'LInk URL';
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);

    return map;
  }
}
