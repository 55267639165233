import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ChartService } from '../../services/chart.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';

declare var google: any;

@Component({
    selector: 'app-score-card',
    templateUrl: './score-card.component.html',
    styleUrls: ['./score-card.component.css'],
    standalone: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreCardComponent implements OnInit, OnChanges {

  @Input() widgetMeta: any
  @Input() records: any[];
  @Input() chartDimensions: any
  @Input() builderMode: any
  @Input() styles: any

  skimmedData: any = [] = []
  customScoreLabel: string = ''
  widgetDragStartedSub: any;

  constructor(
    private chartService: ChartService,
    private widgetService: WidgetService
  ) { }

  ngOnInit(): void {
    // google.charts.load('current', { packages: ['corechart'] });
    this.chartService.builderMode = this.builderMode
    this.buildChart(true);

    this.widgetDragStartedSub = this.widgetService.$widgetDragStarted.subscribe(data => {
      // console.log("widget move start alert", data)
      this.chartService.setChartDataCache(this.widgetMeta.id, this.skimmedData)
      // if(data.id == this.widgetMeta.id){
      // }
    })
  }

  ngOnDestroy(): void {
    this.widgetDragStartedSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.chartDimensions?.currentValue && !changes.chartDimensions?.firstChange){
      this.buildChart()
    }
    if(changes.widgetMeta?.currentValue){
      this.customScoreLabel = changes.widgetMeta?.currentValue?.config?.scorecardLabel?.value
    }
    if(changes.records?.currentValue && !changes.records?.firstChange){
      this.buildChart(true)
    }
    if(changes.styles?.currentValue && !changes.styles?.firstChange){
      // console.log("styles change", changes.styles.currentValue)
      this.buildChart()
    }
  }


  async buildChart(rebuild?: boolean) {
    this.chartService.setPrecision(this.widgetMeta.config.precision?.value)
    let config = this.widgetMeta?.config?.dataSource
    // this.chartData = await this.chartService.prepareBarData(this.chartData, this.records, this.widgetMeta?.config?.dataSource)

    if(config.dataSourceType == 'static'){
      this.skimmedData = config.staticData
      return
    }

    if(rebuild){
      if (this.records?.[0]?.["__USECACHED__"]) {
        this.skimmedData = this.chartService.getChartDataCache(this.widgetMeta.id)
        this.chartService.clearChartCacheForWidget(this.widgetMeta.id)
        return
      } else {
        console.log("records", this.records)
        let isGrouped: boolean = this.records[0]?.__GROUPED__ || false
        let pageData: any
        if(this.records[0]?.__START__){
          this.clearCache()
          pageData = this.records.shift()
        }
        let rawRecords: any[] = []
        this.records.forEach(item => {
          if(item !== '__END__') rawRecords.push(item)
        })
        let res = this.chartService.processScorecardData(config, rawRecords, isGrouped)
        console.log("processed score data", JSON.parse(JSON.stringify(res)))
        this.skimmedData = this.chartService.merge(this.skimmedData, res, config, pageData?.pageNumber || 1, pageData?.pageSize || res.length, rawRecords.length, true)
      }
    }
    // console.log("skimmed data for scorecard", JSON.parse(JSON.stringify(this.skimmedData)))

    // if(this.skimmedData.length) {
    //   this.skimmedData = this.skimmedData[0]
    // }
    // console.log("final scorecard data", JSON.parse(JSON.stringify(this.skimmedData)))
  }

  clearCache(){
    this.skimmedData = []
  }
  isDelimitor(){
    console.log("last item", this.records[this.records.length - 1])
    if(this.records[this.records.length - 1] == '__END__') return true
    else return false
  }

}
