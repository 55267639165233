import { SnackbarComponent } from './../../../../shared/snackbar/snackbar.component';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionService } from '../../connection.service';
import { interval, Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon?: string;
  snackBarError?: boolean;
  snackBarDuration?: number;
  snackBarRecommendation?: string
}

@Component({
    selector: 'new-connection',
    templateUrl: './new-connection.component.html',
    styleUrls: ['./new-connection.component.css'],
    standalone: false
})
export class NewConnectionComponent implements OnInit {
  //FormGroup
  connectionFormGroup: UntypedFormGroup = new UntypedFormGroup({
    appNameCtrl: new UntypedFormControl('', [Validators.required]),
    nameCtrl: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  //FormControl
  authenticationTypeSelect: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.required
  );

  //Observable
  filteredOptions: Observable<any[]>;

  //FormGroup
  boxConfigForm: UntypedFormGroup;

  //Local Variable
  allBoxes: any[] = this.connectionService.allBoxes;
  boxId: string;
  selectedBoxId: string;
  boxConfig: any[] = [];
  authTypeValues: string[] = [];
  snackBarObj: snackBarValues;
  boxToken: string;
  state: string;
  updateConnection: any;
  userEmail: any;
  workSpaceId: string;
  preAuthenticatedToken: any;
  updateId: any;
  accessToken: any;
  navigation: any;
  tempBoxConfig: any;
  boxConfigValues: any = {};
  secondaryConfigs: any[] = [];
  showValidateButton: boolean = false;
  redirectUrl: string = '';
  formInputData : any = {};

  //Flag
  isConfig = false;
  hideFormInputs: boolean = false;
  isAuthorize: boolean = false;
  isNewConnection: boolean = true;
  isRevokeAuthorization: boolean = false;
  connectButton: any;
  collectSecondaryConfigs: boolean = false
  isSecondaryValid: boolean = false
  isWhitelistRequired: boolean = false;

  spinner: boolean = false;
  isRedirectEventReceived:boolean = false;
  disableAppSelection: boolean = false;
  ignoreInputs: any[] = [];

  connectionReceiver: Subscription;
  oldConfigForm: any;

  connection: any;
  connectionId: string
  connectionKey: string;
  availableParams: any;
  action: any;
  selectedBox: any;
  isBrowser: boolean;
  isBoxConfigChanged: boolean = false;
  authTypes: any;
  //get methods
  get configForm() { return this.boxConfigForm.get('config') as UntypedFormGroup };
  get fb() { return this._fb }
  get connectionService() { return this._connectionService; }

  @Input() inputConfig: any;
  @Output() connectionChange = new EventEmitter<any>();
  hasInput: boolean = false;

  //constructor
  constructor(
    private ngZone: NgZone,
    private location: Location,
    public clientPlatformService: ClientPlatformService,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _connectionService: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    this.navigation = this.router.getCurrentNavigation();
    this.boxConfigForm = this.fb.group({
      config: this.fb.group({}),
    });
    this.spinner = true;

  }

  //ngOnInit
  async ngOnInit() {
    if(!this.isBrowser) return;
    this.spinner = true;
    console.log("inputConfig", this.inputConfig)
    try {
      //Check New Connection is clicked or Leftnav Connection is clicked
      this.route.queryParamMap.subscribe(async (params) => {
        
        // Process the client platform parameters to determine if menu items should be hidden
        // and to store client-specific information such as clientType and sourceApp
        this.clientPlatformService.processClientPlatformParams(params);

        console.log('New connection params', params);
        this.workSpaceId = params.get('workspaceid') || this.connectionService.workSpaceId;

        if (decodeURIComponent(params.get('authorization')) == 'null') {
          this.preAuthenticatedToken = this.connectionService.preAuthenticatedToken;
        } else {
          this.preAuthenticatedToken = decodeURIComponent(params.get('authorization'));
          this.connectionService.preAuthenticatedToken = this.preAuthenticatedToken
        }

        if(params.get('formInputData')) {
          this.formInputData = JSON.parse(params.get('formInputData'));
          console.log("formInputData:", this.formInputData);
        }

        if(params.get('hideFormInputs')) {
          this.hideFormInputs = JSON.parse(params.get('hideFormInputs'));
          console.log("hideFormInputs:", this.hideFormInputs);
        }

        if(params.get('redirectUrl')) {
          this.redirectUrl = params.get('redirectUrl');
        }

        this.ngZone.run(() => {
          this.isNewConnection = !JSON.parse(params.get('isEdit'));
        });
        this.connectionId = params?.get('connectionId');
        this.connectionKey = params?.get('connectionKey');

        //check for boxId param
        this.boxId = params?.get('boxId');

        this.disableAppSelection = Boolean(params?.get('disableAppSelection') || this.inputConfig?.disableAppSelection) || false;
        //disable the app selection if disableAppSelection flag passed in query params
        // if (params?.get('disableAppSelection')) {
          if (this.disableAppSelection) this.connectionFormGroup.get('appNameCtrl').disable();
        //storing client type in client platform service in order to go back to client app side from connection.
        // if (params.get('clienttype')) this.clientPlatformService.clientType = params.get('clienttype');
        // }
        console.log("disableAppSelection", this.boxId, this.disableAppSelection, this.inputConfig?.disableAppSelection);
      });

      this.authTypes = await this.connectionService.getBoxauthTypes();
      if(!this.inputConfig?.box && this.inputConfig?.connection){
        this.ngZone.run(() => {
          this.isNewConnection = false;
        });
        this.hasInput = true;
        this.connectionId = this.inputConfig?.connection;
      }

      if(this.navigation?.extras?.state?.connExtraInputs) {
        this.ignoreInputs = Object.keys(this.navigation.extras.state.connExtraInputs);
      }

      if (this.isNewConnection && !this.inputConfig?.box) {
        //if true --> New Connection --> Set Connection Box Config with a deafult name plus Total Connection plus 1
        await this.setConnectionLength();
      } else if(!this.inputConfig?.box && (this.navigation?.extras?.state?.connection || this.connectionId)) {
        console.log("this.navigation?.extras?.state?.connection", this.navigation?.extras?.state?.connection)
        //set existing Conection Details in Corresponding Connection Fields --> set Box Config
        await this.setConnectionDetails(this.connectionId);
      }

      console.log("in con inputConfig", this.inputConfig)
      console.log('ON INIT CONFIG FORM', this.configForm.value)

      // this.validateSecondaryConfigs

      if (this.inputConfig?.box) {
        this.boxId = null;
        let box = await this.connectionService.getBox(this.inputConfig?.box);
        this.selectedBox = box
        this.connectionFormGroup.controls.appNameCtrl.setValue(box);
        //if it is database category enable below flag, to show warning message in GUI
        this.isWhitelistRequired = box?.categories.includes('database');      ;
        this.hasInput = true;
        this.spinner = true;
        await this.getBoxConfig(box);
      }
    } catch (error) {
      console.log("ERROR : ",error)
      this.snackBarObj = {
        snackBarMessage: 'Error',
        snackBarIcon: 'info'
      };
      this.openSnackBar(this.snackBarObj, error);
    }
    //autocomplete filter for app name control
    this.filteredOptions =
      this.connectionFormGroup.controls.appNameCtrl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );

    let that = this;
    this.spinner = false;
    //Listen Post Message
    window.addEventListener('message', async function (e) {
      // e.data hold the message from child
      console.log("Check Token : ", e.data)
      if (e.data.isToken == true) {
        const oldConfigForm = JSON.stringify(that.oldConfigForm);
        const newConfigForm = JSON.stringify(that.configForm.value);
        console.log('Old Config', oldConfigForm, 'New Config', newConfigForm);
        if (newConfigForm != oldConfigForm) return;
        console.log('Connection reciever unsubscribing...');
        that.connectionReceiver.unsubscribe();
        that.state = e.data.state;
        that.isAuthorize = false;
        that.isConfig = true;
        that.snackBarObj = {
          snackBarMessage: 'Connection is successfully established 1!!',
          snackBarIcon: 'info',
          snackBarDuration: 2000,
        };
        that.openSnackBar(that.snackBarObj);
        that.isRedirectEventReceived = true;
        console.log('CONFIG FORM', that.configForm)
        const stateSplitted = that.state.split('_');
        await that.getConnectionById(stateSplitted[2]);
      }
    }, false);

      if(this.inputConfig?.workspace_id){
        this.workSpaceId = this.inputConfig.workspace_id;
        console.log(this.workSpaceId);
      }

      if (this.formInputData && typeof this.formInputData === 'object') {
        Object.keys(this.formInputData).forEach(key => {
          const value = this.formInputData[key];
          if (this.configForm && this.configForm.get(key)) {
            this.configForm.get(key)?.setValue(value);
          }
        });
      }

      this.cdr.detectChanges();
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  listenConnection(){
    console.log('LISTENIG..', this.configForm.value);
    this.oldConfigForm = JSON.parse(JSON.stringify(this.configForm.value));
    const source = interval(3000);
    const state = this.connectionService.currentConnectionState;
    this.connectionReceiver = source.subscribe(val => this.checkConnectionCallback(state));
  }

  async checkConnectionCallback(state){
    let stateStatus = window.localStorage.getItem(state);
    if(stateStatus == "SUCCESS"){
      console.log("window get", stateStatus);
      window.localStorage.removeItem(state);
      this.connectionReceiver.unsubscribe();
      let that = this;
      let str: string = state;
      console.log("state", state)
      let splitted = str.split('_');
      that.isAuthorize = false;
      that.isConfig = true;
      that.snackBarObj = {
        snackBarMessage: 'Connection is successfully established 2!!',
        snackBarIcon: 'info',
        snackBarDuration: 2000,
      };
      that.openSnackBar(that.snackBarObj);
      that.isRedirectEventReceived = true;
      await that.getConnectionById(splitted[2]);
    }
  }

  //filter method for autocomplete
  private _filter(value: any): any[] {
    let filterValue;
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }
    return this.allBoxes.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // set values of connection table config into edit connection config
  async setBoxConfig(box: any, connection: any) {
    // this.getBoxConfig(box, this.authTypeValues.length > 1 ? connection.authentication_map.auth_type : null)
    await this.getBoxConfig(box, connection.authentication_map?.auth_type)
    this.configForm.patchValue(connection.box_config);
    this.initForSecondaryConfigs(this.boxToken)
    // console.log("[SET BOX CONFIG] connection.box_config", connection.box_config)
    // console.log("[SET BOX CONFIG] boxConfig", this.boxConfig)
  }

  //Clear App Name Field --> Clear Box Config
  resetAppName() {
    this.connectionFormGroup.controls.appNameCtrl.setValue('');
    this.isConfig = false;
    this.authTypeValues = [];
    this.authenticationTypeSelect.setValue('');
    this.configForm.controls = {};
    this.boxConfig = [];
  }

  //display method of autoxcomplete
  displayBoxFuntion(subject) {
    return subject ? subject.name : undefined;
  }

  /**
   * triggered when "connect" button clicked
   */
  async onConnect(){
    let isValid: boolean = false
    isValid = await this.checkFormValidation()
    console.log("is Valid Connection : ",isValid)
    if(isValid){
      console.log("this.newConnection", this.isNewConnection);
      if(!this.isNewConnection) {
        let newBoxConfig = await this.combinedBoxConfig();
        await this.checkIsBoxConfigChanged(this.connection?.box_config, newBoxConfig)
      }
      await this.insertOrUpdateConnection(true);
    }
  }

  /**
   * Check Config Form Validation
   * @returns if connection valid, returns true, false otherwise
   */
  async checkFormValidation() {
    console.log(this.boxConfigForm.valid);
    this.boxConfigForm.markAllAsTouched();
    this.spinner = true;

    if(!this.boxConfigForm.valid) {
      this.constructAndShowSnackBar('Some of the credentials entered are invalid. Please check and enter all required credentials before trying again.', 'e');
      this.spinner = false;
      return false;
    }

    if(this.authTypeValues.length > 0 && !this.authenticationTypeSelect.valid) {
      this.constructAndShowSnackBar('Auth Type Validation Error!!', 'e');
      this.spinner = false;
      return false;
    }

    // make connection
    let res = await this.checkConnectionConfig();
    if (!res.isError) {
      let isConnectionVaild = res.boxToken ? true : false;
      this.showValidateButton = false

      // check if secondary configs required
      if(!this.validateSecondaryConfigs()){
        this.constructAndShowSnackBar('Provide values for required parameters!!', 'e', 2000);

        this.initForSecondaryConfigs(res.boxToken)
        this.spinner = false;
        return false
      }else{
        this.spinner = false;
        return isConnectionVaild
      }
    } else {
      await this.insertOrUpdateConnection(false, 'FAILED');
    }
    this.spinner = false;
  }


  /**
   *
   * @param token
   */
  initForSecondaryConfigs(token){
    if(!this.secondaryConfigs.length) return

    // 1. create connection object
    this.connection = {
      // boxToken: token,
      box_id: this.boxId || this.selectedBoxId || this.selectedBox?.__id || ""
    }
    // if(this.updateConnection?._id){
    //   this.connection["_id"] = this.updateConnection._id
    // }else{
    //   this.connection["boxToken"] = token
    // }
    if(token){
      this.connection["boxToken"] = token
    }else if(this.updateConnection._id){
      this.connection["_id"] = this.updateConnection._id
    }
    console.log("connection obj created to send", this.connection)

    // 2. create existing params
    this.availableParams = {
      options: { }
    }
    this.secondaryConfigs.forEach(config => {
      if(this.updateConnection){
        console.log("update connection", this.updateConnection)
        if(this.updateConnection.box_config[config.__id]){
          config.value = this.updateConnection.box_config[config.__id]
        }
      }
      this.availableParams.options[config.__id] = config.value
    })
    console.log("available params created", this.availableParams)

    // 3. create custom action object
    this.action = {
      list: []
    }
    this.secondaryConfigs.forEach(config => {
      if(config.relation){
        console.log("config with relation", config)
        this.action.list.push(config.__id)
        this.action[config.__id] = config
      }
    })
    console.log("custom action created", this.action)

    this.collectSecondaryConfigs = true
  }

  /**
   * validates if all the required secondary configs are provided
   * secondary configs are those configs that are not required during connecting to app, but required as config.
   * e.g. airtable baseId, google sheets sheet id etc
   * @returns true/false
   */
  validateSecondaryConfigs(){
    // if(!this.secondaryConfigs.length) return true
    console.log("secondary configs status", this.secondaryConfigs)
    for (let i = 0; i < this.secondaryConfigs.length; i++) {
      if(this.secondaryConfigs[i].relation && !this.secondaryConfigs[i].value){
        this.isSecondaryValid = false
        return false
      }
    }
    this.isSecondaryValid = true
    return true
  }


  /**
   * updates the value part in corresponding config element in secondaryConfigs array
   * @param data triggered when value for seconday config is received from parameter input component
   */
  secondaryConfigReceived(data: any){
    console.log("[SECONDARY CONFIG RECEIVED] value:", data)
    // this.collectSecondaryConfigs = false
    if(!data || !Object.keys(data).length) return
    this.secondaryConfigs.forEach(config => {
      if(data.hasOwnProperty(config.__id)){
        config.value = data[config.__id]
      }
    })
    this.validateSecondaryConfigs()
  }


  constructAndShowSnackBar(message: string, icon?:string,  dur?: any, errorMessage?:any, recommendation?: string){
    let iconMap = {
      s: 'check_circle',
      e: 'error',
      i: 'info'
    }

    var snackBarObj: snackBarValues = {
      snackBarMessage: message,
      snackBarIcon: iconMap[icon] || 'error',
      snackBarDuration: dur || undefined,
      snackBarRecommendation: recommendation || undefined
    }

    this.openSnackBar(snackBarObj, errorMessage);
  }
  //common snackbar function
  openSnackBar(snackBarObj: snackBarValues, errorMessage?: any) {
    if (errorMessage) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          title: errorMessage?.error?.error?.name || '',
          description: errorMessage?.error?.error?.message || JSON.stringify(errorMessage?.error?.error) || errorMessage?.error?.message || '',
          recommendation: snackBarObj?.snackBarRecommendation || '',
          message: snackBarObj.snackBarMessage,
          iconname: snackBarObj.snackBarIcon,
          isError: true,
          type: 'error'
        },
        duration: snackBarObj.snackBarDuration || undefined,
        horizontalPosition: 'end',
      });
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          message: snackBarObj.snackBarMessage,
          iconname: snackBarObj.snackBarIcon,
        },
        duration: snackBarObj.snackBarDuration,
        horizontalPosition: 'end',
      });
    }
  }

  //check new connection config if success save button enabled
  async checkConnectionConfig() {
    this.spinner = true;
    let that = this;
    let boxUrl = '';
    let result: any = { isError: false };
    if (this.authenticationTypeSelect.value) {
      let authValue = this.authenticationTypeSelect.value;
      boxUrl = `/${this.connectionFormGroup.controls.appNameCtrl.value.__id}/config?authtype=${authValue}`;
    } else {
      boxUrl = `/${this.connectionFormGroup.controls.appNameCtrl.value.__id}/config`;
    }

    console.log("boxConfigForm", this.boxConfigForm);
    let params = JSON.parse(JSON.stringify(this.boxConfigForm.value))
    this.secondaryConfigs.forEach(config => {
      if(config.required && !params.config[config.__id]) params.config[config.__id] = config.value || config.defaultValue
    })
    if(this.authenticationTypeSelect.value && this.selectedBox?.authType && typeof(this.selectedBox?.authType) == 'object' && this.selectedBox?.authType.length > 1) params.config.authType = this.authenticationTypeSelect.value;
    console.log("box config params passing", params)
    let response = await this.connectionService.checkConnectionConfig(boxUrl, params)
    this.spinner = false;
    if (!response.isError) {
      that.constructAndShowSnackBar('Connection is successfully established!!', 's', 2000);
      this.boxToken = response.boxToken || null;
      result.boxToken = response.boxToken;
      return result;
    } else {
      console.log(response?.errorMessage)
      let snackBarObj = {
        snackBarMessage: 'Connection could not be established',
        snackBarIcon: 'error',
        snackBarRecommendation: 'Please check your credentials and try again.'
      };
      that.openSnackBar(snackBarObj, response?.errorMessage);
      result.isError = true
      return result;
    }
  }

  //get connection length and set length to nameCtrl
  async getConnectionLength(appName: string) {
    if (this.connectionService.connectionLength) {
      this.setConnectionName(appName);
    } else {
      await this.connectionService.getTotalConnection(1, 5, this.preAuthenticatedToken).then(() => {
        this.setConnectionName(appName);
      });
    }
  }

  setConnectionName(appName: string) {
    const connName = this.navigation?.extras?.state?.connName || `Connection ${this.connectionService.connectionLength + 1} (${appName})`;
    this.connectionFormGroup.controls.nameCtrl.setValue(connName);
  }

  onChangeBox(box: any){
    this.authTypeValues = [];
    this.selectedBox = box
    this.authenticationTypeSelect.setValue('');
    this.getBoxConfig(box)
  }

  // get config for new/edit connection from box api
  async getBoxConfig(box: any, authValue?: any) {
    this.spinner = true;
    console.log("selected box", box);
    console.log("authValue", authValue)
    console.log("this.authenticationTypeSelect.value", this.authenticationTypeSelect.value)
    console.log("this.connectionFormGroup", this.connectionFormGroup.controls.appNameCtrl)
    this.connectButton = box?.config?.connectButton || null;

    if (!this.connectionFormGroup.controls.nameCtrl.touched) {
      this.getConnectionLength(box.name);
    }

    //Mutipule Auth --> Set first Auth Value in authenticationTypeSelect for first time --> get box config
    if (typeof box['authType'] == 'object' && !this.authenticationTypeSelect.value) {
      this.authTypeValues = box.authType;
      authValue = this.authTypeValues[0]
    } else if(box['authType'] && this.authTypeValues.length==0) {
      if(box['authType'] == 'oauth2') {
        this.authTypeValues.push(box['authType'])
        authValue = box['authType']
      }
    }

    this.authenticationTypeSelect.setValue(authValue);

    if (authValue && authValue == 'oauth2') {
      this.isAuthorize = true;
    } else if(authValue) {
      this.isAuthorize = false
    }

    let url = `/${box.__id}/config`;
    if (authValue && this.authTypeValues && this.authTypeValues.length > 1) { //Multi Auth Box URL
      url = `/${box.__id}/config?authtype=${authValue}`;
    }

    //Clear Box Config
    if (this.configForm.controls) {
      this.configForm.controls = {};
      this.boxConfig = [];
    }

    //get Box Config using Box API
    try{
      console.log("config url", url)
      var response: any = await this.connectionService.getBoxConfig(url);
      if (response) {
        this.isConfig = true;
        console.log('response code', response);
        if (response?.attributes) {
          this.secondaryConfigs = []
          response.attributes.forEach((element: any) => {
            console.log("will push", element.descriptor.__id)

            // classify secondary configs to collect after auth
            if(element.descriptor.relation){
              console.log("[SECONDAY CONFIG FOUND]", element?.descriptor)
              this.secondaryConfigs.push(element?.descriptor)
            }else{
              let defaultValue = element?.descriptor.__id == 'authType' ? element?.descriptor?.defaultValue ? element?.descriptor?.defaultValue : this.authenticationTypeSelect.value : element?.descriptor?.defaultValue || ''
              element?.descriptor?.required ? this.configForm.addControl(element?.descriptor.__id, new UntypedFormControl(defaultValue, Validators.required)) :
              this.configForm.addControl(element?.descriptor.__id, new UntypedFormControl(defaultValue));
              if(!this.ignoreInputs.includes(element?.descriptor.__id)) this.boxConfig.push(element?.descriptor)
            }
          });
          console.log("secondary configs initialized", this.secondaryConfigs)
          console.log("primary configs initialized", this.boxConfig)
          this.setValidateButton()
          this.validateSecondaryConfigs()
        }
        console.log(this.boxConfigForm)
        //Check selected is box auth is auth2
        console.log("box", box)
        if ((box['authType'] == 'oauth2' || authValue == 'oauth2')) {
          console.log("inside if")
          await this.authorizeConnection();
        }
      } else this.isConfig = false;
    } catch(e){
      console.log(e);
      this.isConfig = false;
    }
    this.spinner = false;
  }

  setValidateButton(connection?: any){
    // if(this.authenticationTypeSelect.value == 'oauth2'){
    //   console.log("[setValidateButton] if - 1")
    //   this.showValidateButton = false
    // }else if(connection?.status == "ACTIVE" || this.updateConnection?.["status"] == "ACTIVE"){
    //   console.log("[setValidateButton] if - 2")
    //   this.showValidateButton = false
    // }else if(this.secondaryConfigs.length){
    //   console.log("[setValidateButton] if - 3")
    //   this.showValidateButton = true
    // }else{
    //   console.log("[setValidateButton] if - 4")
    //   this.showValidateButton = false
    // }
    this.showValidateButton = this.secondaryConfigs.length && this.authenticationTypeSelect.value != 'oauth2' ? true : false
  }

  //authorize Connection button --> create new connection --> Generate Authorization Link
  async authorizeConnection() {
    console.log("updateConnection", this.updateConnection);
    this.spinner = true;
    //check connection is already created
    //console.log("*****************************",this.connectionFormGroup.controls.appNameCtrl.value)
    if (this.updateConnection) {
      //check OAuth2 Link already generated
      //get OAuth2 Link from API
      this.updateConnection['box_id'] = this.connectionFormGroup.controls.appNameCtrl.value.__id;
      this.updateConnection['box_token'] = this.boxToken;
      if(!this.updateConnection.options) this.updateConnection.options = {};
      this.updateConnection.options['box_logo_url'] = this.connectionFormGroup.controls.appNameCtrl.value.logo;
      this.updateConnection.options['box_name'] = this.connectionFormGroup.controls.appNameCtrl.value.name;
      let connection = this.updateConnection;
      connection['authentication_map']['auth_type'] = this.authenticationTypeSelect.value;
      connection['authentication_map']['token_type'] = 'internal';
      console.log("connection", connection)
      var res: any = await this.connectionService.getAuthorizationLink(connection);
      if(res && this.authenticationTypeSelect.value == 'oauth2'){
        console.log("res", res);
        this.isAuthorize = true;
        //if connection status ACTIVE
        if (this.updateConnection['status'] == "ACTIVE") {
          this.isRevokeAuthorization = true;
        } else this.isRevokeAuthorization = false;
      } else this.isRevokeAuthorization = false;
      this.spinner = false;
      return
    }

    var payload = { options: {} };
    payload['workspace_id'] = this.workSpaceId;
    payload['name'] = 'Connection ' + (this.connectionService.connectionLength + 1);
    payload['box_id'] = this.connectionFormGroup.controls.appNameCtrl.value.__id;
    payload.options['box_name'] = this.connectionFormGroup.controls.appNameCtrl.value.name;
    payload['box_token'] = this.boxToken;
    payload.options['box_logo_url'] = this.connectionFormGroup.controls.appNameCtrl.value.logo;
    payload['status'] = 'DRAFT';
    payload['authentication_map'] = {
      auth_type: this.authenticationTypeSelect.value,
      token_type: 'internal',
    };

    //set input value for ignored inputs from user
    const connExtraInputs = this.navigation?.extras?.state?.connExtraInputs;
    if(connExtraInputs) {
      for (const key in connExtraInputs) {
        this.configForm.patchValue({
          [key]: connExtraInputs[key]
        });
      }
    }

    payload['box_config'] = this.configForm.value;
    console.log("Authorization Connection PAYLOAD",payload);
    try {
      var response = await this.connectionService.createConnection(payload, this.workSpaceId);
      console.log(response);
      this.ngZone.run(() => {
        this.connection = response;
      });
      this.connectionId = response?._id
      if (response?.connection?.authentication_map?.auth_type == 'oauth2') {
          this.updateConnection = response.connection;
          this.updateId = response.connection?._id;
          this.connectionService.getAuthorizationLink(this.updateConnection).then(() => {
          this.isAuthorize = true;
          this.isRevokeAuthorization = false;
          });
      } else if (this.hasInput) {
          this.connectionChange.emit({ isConnectionCreated: true, connection: response });
      } else {
          this.router.navigate(['connection'], {
            queryParams: {
              isnewconnection: false,
              workspaceid: this.workSpaceId,
              authorization: encodeURIComponent(this.preAuthenticatedToken),
            },
          });
      }
    } catch(e){
      console.log(e);
    }
    this.spinner = false;
  }

  //clear access Token and base URL is set to Default value.
  async deauthorizeConnection() {
    console.log("updateConnection", JSON.parse(JSON.stringify(this.updateConnection  || {})));
    this.spinner = true;
    let that = this;
    if (this.updateConnection) {
      this.accessToken = '';
      this.updateConnection['box_config']['accesstoken'] = '';
      this.updateConnection['box_token'] = '';
      this.updateConnection['_id'] = this.updateId;
      this.updateConnection['status'] = 'DRAFT';
      this.updateConnection['authentication_map']['auth_type'] = this.authenticationTypeSelect.value;
      this.updateConnection['authentication_map']['state'] = this.state;
      this.updateConnection['authentication_map']['token_type'] = 'internal';
      this.updateConnection['box_id'] =
      this.connectionFormGroup.controls.appNameCtrl.value.__id;
      this.connectionService.updateConnection(this.updateConnection).then(async (response) => {
        console.log("Connection Updated : ",response.connection);
        this.updateConnection = response.connection;
        this.ngZone.run(() => {
          this.connection = response.connection;
        });
        await this.getBoxConfig(this.connectionFormGroup.controls.appNameCtrl.value, this.authenticationTypeSelect.value);
        that.snackBarObj = {
          snackBarMessage: 'Revoke Connection Successful!!',
          snackBarIcon: 'info',
          snackBarDuration: 2000,
        };
        that.openSnackBar(that.snackBarObj);
        this.isRevokeAuthorization = false;
        this.isAuthorize = true
        this.connectionService.getAuthorizationLink(this.updateConnection);
        this.configForm.patchValue(this.connection.box_config);
      }).catch((err) => {
        console.log(err);
      })
    } else {
      this.connectionService.deleteConnection(this.connectionId);
      await this.setConnectionLength()
    }
    this.spinner = false;
  }

  // Get Single Connection using _id by calling Api to get single connection
  async getConnectionById(_id: any) {
    this.spinner = true;
    this.updateId = _id;
    var result = await this.connectionService.getConnection(_id, this.preAuthenticatedToken)
    this.ngZone.run(() => {
      this.connection = result;
    });
    console.log("[GET CONNECTION BY ID] result", result);
    if (result['authentication_map']['tokens'] != undefined) {
      console.log("[GET CONNECTION BY ID] if block");
      this.setAccessToken(result)
      this.tempBoxConfig = result.box_config; //store this to compare the key values while updating the connection.

      await this.updateAccessToken();
      if(this.isRedirectEventReceived){
        console.log("[GET CONNECTION BY ID] isRedirectEventReceived");
        await this.onConnect();
      }
    } else {
      console.log("[GET CONNECTION BY ID] else block");
      await this.connectionService.getAuthorizationLink(result);
    }
    console.log("[GET CONNECTION BY ID] ends");
    this.spinner = false;
  }

  //Set Access Token in => Config => Access Token Field and Base URL
  async setAccessToken(connection: any) {
    this.accessToken = connection['authentication_map']['tokens']['access_token'];
    this.updateConnection = connection;
    console.log("Debug2 ==> ", this.configForm.value);
    this.configForm.patchValue({ accesstoken: this.accessToken });
    // this.boxConfigForm.controls['config'].value.find((i: any) => i.__id == 'accesstoken').value = connection['authentication_map']['tokens']['access_token'];
    // this.boxConfigForm.controls['config'].setValue(this.boxConfigForm.controls['config'].value);
    // this.boxConfigForm.controls['config'].patchValue([null, null, {
    //   value: connection['authentication_map']['tokens']['access_token']
    // }])
  }

  //Insert or Update Connection
  async insertOrUpdateConnection(isConnectionVaild?: boolean, connectionStatus?: string) {
    this.spinner = true;
    console.log("[insertOrUpdateConnection]", this.connectionFormGroup.valid, this.updateConnection);
    if (this.connectionFormGroup.valid) {
      // console.log("[insertOrUpdateConnection] valid!")
      //Check updateConnection is Empty. If Empty Perform Insert
      if (!this.updateConnection) {
        console.log("[insertOrUpdateConnection] NOT update connection")
        //Insert Data into Connection Table => If isNewConnection is True.
        if (this.isNewConnection) {
          console.log("[insertOrUpdateConnection] new connection true")
          let that = this;
          var payload = { options: {} };
          payload['workspace_id'] = this.workSpaceId;
          payload['name'] = this.connectionFormGroup.controls.nameCtrl.value;
          payload['box_id'] = this.connectionFormGroup.controls.appNameCtrl.value.__id;
          payload.options['box_name'] = this.connectionFormGroup.controls.appNameCtrl.value.name;
          payload['box_token'] = this.boxToken;
          payload.options['box_logo_url'] = this.connectionFormGroup.controls.appNameCtrl.value.logo;
          payload['status'] = isConnectionVaild ? 'ACTIVE' : 'DRAFT';
          if(connectionStatus) payload['status'] = connectionStatus;
          if (this.authTypeValues) {
            payload['authentication_map'] = {
              auth_type: this.authenticationTypeSelect.value,
              token_type: 'internal',
            };
          }
          // combine primary and secondary config values
          // payload['box_config'] = this.configForm.value;
          // let sourceObj = {}
          // this.secondaryConfigs.forEach(config => {
          //   sourceObj[config.__id] = config.value
          // })
          // Object.assign(payload["box_config"], sourceObj)
          payload["box_config"] = this.combinedBoxConfig()

          try{
            // console.log("[insertOrUpdateConnection] payload", payload);
            // console.log("[insertOrUpdateConnection] will call create connection")
            let userEmail = this.clientPlatformService.userEmail || this.connectionService.selectedWorkSpaceMap.created_by
            let token = userEmail  ? this.connectionService.getPreAuthenticatedTokenForEmail(userEmail) : (this.clientPlatformService.preAuthenticatedToken || this.connectionService.preAuthenticatedToken);
            let response = await this.connectionService.createConnection(payload, this.workSpaceId, token);
            this.ngZone.run(() => {
              this.connection = response;
            });
            this.constructAndShowSnackBar('Connection Insert Successfully', 'c', 2000);
            if (this.hasInput) {
              this.connectionChange.emit({ isConnectionCreated: true, connection: response });
            // } else if(this.boxId) {
            //   this.location.back();
            } else if(this.boxId && this.connection?.connection?.authentication_map?.auth_type === 'key') {
              await this.cpStoreConnectionInWorkspace(this.connection?.connection?._id);
            } else {
              this.router.navigate(['connection'], {
                queryParams: {
                  workspaceid: this.workSpaceId,
                  isRefresh: true,
                  authorization: encodeURIComponent(this.preAuthenticatedToken),
                },
              });
            }
          } catch(e){
            console.log(e);

            this.constructAndShowSnackBar('Error while Inserting Connection', 'e', 2000);
          }
        } else {
          console.log("[insertOrUpdateConnection] new connection false data");
          //update connection. If isNewConnection is False
          this.updateConnectionData();
        }
      } else {
        console.log("[insertOrUpdateConnection] update connection")
        //update connection. Add access Token and *Box Token in Connection. If updateConnection is not Empty.
        //*If box token is not empty
        let response = await this.updateAccessToken(!isConnectionVaild);
        console.log('What is boxid', this.boxId, this.hasInput);

        let snackbarMessage = this.isNewConnection ? 'Data Insert Successfully' : 'Data Update Successfully';
        // this.constructAndShowSnackBar(snackbarMessage, 'c', 2000);

        this.accessToken = undefined;
        this.updateId = undefined;
        this.boxToken = undefined;
        this.updateConnection = undefined;
        this.connectionService.authorizationUrl = undefined;

        if (this.hasInput) {
          console.log('IS INSIDE HAS INPUT');
          this.connectionChange.emit({ isConnectionCreated: true, connection: response });
        } else if(this.navigation?.extras?.state?.con_explorer){
          console.log("[insertOrUpdateConnection] before navigation to connection explorer")
          this.router.navigate(['connection/connectionexplorer'],{
            state: {connection: response.connection}
          })
        } else if(this.redirectUrl) {

          const queryParams = {
            connectionId : response.connection._id,
            isNew: this.isNewConnection
          }

          console.log("[insertOrUpdateConnection] before navigation to redirectUrl",this.configForm.value);
          this.router.navigate([this.redirectUrl], {queryParams});
        }else if(this.boxId) {
          await this.cpStoreConnectionInWorkspace(response.connection._id);
        } else {
          console.log("[insertOrUpdateConnection] before navigation to connection listing")
          console.log("[insertOrUpdateConnection] configForm", this.configForm.value)
          console.log("[insertOrUpdateConnection] boxConfig", this.boxConfig)


          this.router.navigate(['connection'], {
            queryParams: {
              isnewconnection: false,
              isRefresh: true,
              workspaceid: this.workSpaceId,
              authorization: encodeURIComponent(this.preAuthenticatedToken),
            },
          });
          // this.router.navigate(['connection/connectionexplorer'],{
          //   state: {connection: response.connection}
          // })
        }
      }
    } else {
      this.connectionFormGroup.markAllAsTouched();
    }
    this.spinner = false;
  }

  async cpStoreConnectionInWorkspace(connId: string) {
    let connKey = `${this.boxId}ConnectionId`;
    let queryParams: any = {};

    if (this.connectionKey) {
      connKey = this.connectionKey;
      queryParams.isAppConnection = true;
      queryParams.box = this.boxId;
    }
    //update workspace with the connection and redirect to client platform connection success page.
    await this.connectionService.updateWorkspaceForClientPlatform({ [connKey]: connId })
    this.router.navigate(['client-platform/connection/success'], {queryParams});
  }

  combinedBoxConfig(){
    // combine primary and secondary box configs
    console.log("Debug 1 ==> ", this.configForm.value);
    let boxConfig = JSON.parse(JSON.stringify(this.configForm.value))
    this.secondaryConfigs.forEach(config => {
      if(!boxConfig[config.__id]){
        boxConfig[config.__id] = config.value
      }
    })
    return boxConfig
  }

  checkIsBoxConfigChanged(oldConfig, newConfig){
    if(oldConfig){
      for (const key in oldConfig) {
        // Check if the property exists in newConfig and if its value is different
        if (newConfig.hasOwnProperty(key) && newConfig[key] !== oldConfig[key]) {
          this.isBoxConfigChanged = true;
        }
      }
    }
  }

  // update data in connection table
  async updateConnectionData() {
    this.spinner = true;
    var payload = { options: {} };
    if (this.boxToken && this.updateConnection['box_config']['accesstoken'].length > 0 ) {
      payload['status'] = 'ACTIVE';
    }

    // console.log("Payload----->", payload);

    payload['workspace_id'] = this.workSpaceId;
    console.log(this.connectionFormGroup.controls.nameCtrl.value);
    payload['_id'] = this.updateId;
    payload['created_at'] = this.updateConnection?.created_at || new Date().toISOString;
    payload['name'] = this.connectionFormGroup.controls.nameCtrl.value;
    payload['box_id'] = this.connectionFormGroup.controls.appNameCtrl.value.__id;
    payload.options['box_logo_url'] = this.connectionFormGroup.controls.appNameCtrl.value.logo;
    payload.options['box_name'] = this.connectionFormGroup.controls.appNameCtrl.value.name;
    if (this.authTypeValues.length > 0) {
      payload['authentication_map'] = {
        auth_type: this.authenticationTypeSelect.value,
        token_type: 'internal',
      };
    }
    console.log('payload', JSON.stringify(payload));
    payload['box_config'] = this.configForm.value;
    payload['box_token'] = this.boxToken;

    try {
      let token = this.connectionService.getPreAuthenticatedTokenForEmail(this.connectionService.selectedWorkSpaceMap.created_by);
      let response = await this.connectionService.updateConnection(payload, this.workSpaceId, token);
      this.ngZone.run(() => {
        this.connection = response;
      });
      this.constructAndShowSnackBar('Connection Updated Successfully', 'c', 2000);
      if (this.hasInput) {
        this.connectionChange.emit({ isConnectionCreated: true, connection: response });
        return;
      } else {
        this.router.navigate(['connection'], {
          queryParams: {
            isnewconnection: false,
            isRefresh: true,
            workspaceid: this.workSpaceId,
            authorization: encodeURIComponent(this.preAuthenticatedToken),
          },
        });
      }
    } catch(e){
      this.constructAndShowSnackBar('Error while Updating Connection', 'c', 6000);
    }
    this.spinner = false;
  }

  //Update Access Token in Connection => Box_config => Access Token and Base URL
  async updateAccessToken(isSaveTriggered?: boolean) {
    this.spinner = true;
    var payload = { options: {}};
    if (this.authTypeValues.length > 0) {
      console.log(this.updateConnection);
      console.log(this.isNewConnection);
      payload['authentication_map'] = {
        auth_type: this.authenticationTypeSelect.value,
        token_type: 'internal',
        state: this.state
      };
      payload['authentication_map']['tokens'] = this.updateConnection['authentication_map']['tokens'];
    }
    //set auth key value params if not present.
    if (this.tempBoxConfig) {
      console.log(this.tempBoxConfig, this.boxId, this.disableAppSelection);
      console.log("CONFIG FORM", JSON.parse(JSON.stringify(this.configForm.value)))
      var tempConfigFormObjKeysArr = Object.keys(JSON.parse(JSON.stringify(this.configForm.value)));
      tempConfigFormObjKeysArr.forEach((configKey) => {
        if (!this.configForm.get(configKey).value) {
          this.configForm.patchValue({[configKey]: this.tempBoxConfig[configKey]})
        }
      })
    }
    payload['workspace_id'] = this.workSpaceId;
    let newBoxConfig = await this.combinedBoxConfig();
    payload['box_config'] = newBoxConfig
    if(!this.isNewConnection && isSaveTriggered) this.checkIsBoxConfigChanged(this.connection?.box_config, newBoxConfig)
    if (!this.boxToken){
      let token = this.updateConnection?.authentication_map?.tokens?.access_token
      this.boxToken = token ? token : undefined
    }
    payload['box_token'] = this.boxToken;
    payload['_id'] = this.updateId ? this.updateId : this.updateConnection?._id;
    payload['created_at'] = this.updateConnection?.created_at || new Date().toISOString;
    payload['name'] = this.connectionFormGroup.controls.nameCtrl.value;
    payload['box_id'] = this.connectionFormGroup.controls.appNameCtrl.value?.__id || "";
    payload.options['box_logo_url'] = this.connectionFormGroup.controls.appNameCtrl.value.logo;
    payload.options['box_name'] = this.connectionFormGroup.controls.appNameCtrl.value.name;
    this.boxToken ? payload['status'] = 'ACTIVE' : payload['status'] = 'DRAFT';
    if(this.isBoxConfigChanged && isSaveTriggered) payload['status'] = 'DRAFT';
    var result = null;
    console.log('--------->PAYLOAD------>', payload)
    try{
      result = await this.connectionService.updateConnection(payload)
      this.ngZone.run(() => {
        this.connection = result;
      });
      // this.checkFormValidation()
    } catch (error){ }
    this.spinner = false;
    return result;
  }

  //Back Button Routing
  async closeNewRecord() {

    // Check if the close button should be visible and it's not a new connection
    // Close the current window if the close button is visible and it's not a new connection
    if (this.clientPlatformService.isCloseButtonVisible && !this.isNewConnection) {
      window.close();
      return;
    }


    // If there is input, emit the connection change event
    if (this.hasInput) {
      this.connectionChange.emit({ isBack: true });
      return;
    }
  
    // Handle navigation for new connections
    if (this.isNewConnection) {
      this.router.navigate(['connection/apps'], {
        queryParams: {
          workspaceid: this.workSpaceId || this.connectionService.workSpaceId,
          authorization: encodeURIComponent(this.preAuthenticatedToken),
        },
      });
      return;
    }
  
    // Handle navigation based on the state of the connection explorer or relative navigation
    if (this.navigation?.extras?.state?.con_explorer) {
      this.location.back();
    } else {
      this.router.navigate(['../'], {
        queryParams: {
          workspaceid: this.workSpaceId || this.connectionService.workSpaceId,
          authorization: encodeURIComponent(this.preAuthenticatedToken),
        },
        relativeTo: this.route,
      });
    }
  }
  

  //New Connection --> Set Coonection and get Box Config
  async setConnectionLength() {
    this.spinner = true;
    if (!this.connectionService?.connectionLength) {
      await this.connectionService.getTotalConnection(1, 5, this.preAuthenticatedToken);
    }

    let box: any = null;
    if (this.navigation?.extras?.state?.box) {
      box = this.navigation?.extras?.state?.box;
      this.selectedBox = box
    } else if (this.boxId) {
      box = await this.getBoxInfo(this.boxId);
    }
    console.log('BOX----->',box, this.boxId);

    this.setConnectionName(this.navigation?.extras?.state?.connName || box?.name || '');

    this.connectionFormGroup.controls.appNameCtrl.setValue(box);
    if (box) {
      let authValue = typeof box?.authType == 'string' ? box.authType : typeof box?.authType == 'object' ? box.authType[0] : null;
      await this.getBoxConfig(this.connectionFormGroup.controls.appNameCtrl.value, authValue);
    }
    this.spinner = false;
  }

  //Set Existing Connection Details and set Box Config
  async setConnectionDetails(connectionId?: string) {
    this.spinner = true;
    try {
    console.log("set con details state con :", this.navigation?.extras?.state?.connection);
    let connection = this.navigation?.extras?.state?.connection || await this.connectionService.getConnection(connectionId, this.preAuthenticatedToken);
    this.updateConnection = connection;
    console.log("c", connection);
    this.ngZone.run(() => {
      this.connection = connection;
    });
    this.setValidateButton(this.updateConnection)
    this.boxToken = connection['box_token'];
    this.isAuthorize = false;
    if (connection.authentication_map['auth_type']?.indexOf('oauth') == 0) {
      this.isAuthorize = true;
      connection['status'] == 'ACTIVE' ? this.isRevokeAuthorization = true : this.connectionService.getAuthorizationLink(connection);
      this.state = connection['authentication_map']['state'];
    }
    this.updateId = connection._id;

    let box = await this.getBoxInfo(connection.box_id);
    console.log("box info fetched", box)
    this.selectedBoxId = box.__id
    this.selectedBox = box

    if (typeof box.authType == 'object') {
      console.log("multi auth")
      this.authTypeValues = box.authType;
      this.authenticationTypeSelect.setValue(connection['authentication_map']['auth_type']);
    } else if (connection['authentication_map']['auth_type']) {
      this.authTypeValues.push(connection['authentication_map']['auth_type']);
      this.authenticationTypeSelect.setValue(connection['authentication_map']['auth_type']);
    }
    this.connectionFormGroup.controls.nameCtrl.setValue(connection.name);
    this.connectionFormGroup.controls.nameCtrl.markAsTouched({ onlySelf: true });
    this.connectionFormGroup.controls.appNameCtrl.setValue(box);
    await this.setBoxConfig(box, connection);
    } catch (error) {
      let msg = `connection needs to be re authorized. please disable authorization and re-authorize the connection.`
      this.clientPlatformService.openErrorActionSnackBar(msg, 'Disable Authorization', async () => {
        await this.deauthorizeConnection()
      })
    }
    this.spinner = false;
  }

  async getBoxInfo(boxId: string) {
    if (this.connectionService.allBoxes.length == 0) {
      let boxResponse:any = await this.connectionService.getBoxes();
      this.allBoxes = boxResponse.data;
    }
    let box = this.connectionService.allBoxes.find(
      (currBox) => currBox.__id == boxId
    );

    if(!box){
      box = await this.connectionService.getBox(boxId)
    }
    return box;
  }

  //get Box Config on Selection Change
  async onSelectionChangedAuthenticationType() {
    await this.getBoxConfig(
      this.connectionFormGroup.controls.appNameCtrl.value,
      this.authenticationTypeSelect.value
    );
    this.authenticationTypeSelect.value == 'oauth2' ? this.isAuthorize = true : this.isAuthorize = false
    this.setValidateButton()
  }

  //Set Value in appropriate field.
  setFileId(res: any) {
    console.log(res);
    // this.boxConfigForm.controls['config'].value.find((i: any) => i.__id == res[0]).value = res[1]
    // this.boxConfigForm.controls['config'].patchValue(this.boxConfigForm.controls['config'].value);
    this.document.getElementById(Object.keys(res)[0]).focus();
    this.configForm.patchValue(res);
  }


  navigationLink(v: string) {
    if(this.selectedBox?.config?.navigationUrlById){
      var url = this.selectedBox.config.navigationUrlById;
      url = url.split("$")[0]+`${v}/`+url.split("$")[1].split("/")[1]
      return url
    }
  }

  objectInputChange(event: any, formControlName: string) {
    let inputValue = event.target.value as string;
    console.log(inputValue);
    try {
      JSON.parse(inputValue)
    } catch (error) {
      this.configForm.get(formControlName).setValue("");
      return
    }
    this.configForm.get(formControlName).setValue(JSON.parse(inputValue));
  }
}
