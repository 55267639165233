import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Slider extends Widget {
  type = 'slider';
  gridX = 6
  minGridX = 3

  config = {
    props: [
      "alignment",
      "minimum",
      "maximum",
      "initial",
      "step",
      "thumbLabel",
      "vertical",
      "inverted",
      "disabled",
      "leftMarker",
      "rightMarker",
    ],
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    minimum: {
      displayName: "Minimum Value",
      value: 0,
      type: 'number'
    },
    maximum: {
      displayName: "Maximum Value",
      value: 100,
      type: 'number'
    },
    initial: {
      displayName: "Initial Value",
      value: 0
    },
    step: {
      displayName: "Step Change",
      value: 1,
      type: 'number'
    },
    thumbLabel: {
      displayName: "Thumb Label",
      value: true    //we can add new modes later(we can put variable/function return values in thumb label)
    },
    vertical: {
      displayName: "Orientation Vertical",
      value: false
    },
    inverted: {
      displayName: "Inverted",
      value: false
    },
    disabled: {
      displayName: "Disabled",
      value: false
    },
    leftMarker: {
      displayName: "Left Marker",
      value: "",
      type: 'text',
    },
    rightMarker: {
      displayName: "Right Marker",
      value: "",
      type: 'text',
    },
  }

  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = "Slide the handle to set value";
    this.config['value'].value = 0;
    this.config['value'].type = 'number';
    this.config['defaultValue'].valueType = "number";
    this.config['defaultValue'].value = 0;

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }   //should apply to the heading

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);
    return map;
  }
}
