import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { BallAtomSpinnerComponent } from '../ball-atom-spinner/ball-atom-spinner.component';
import { SpinnerService } from '../spinner.service';

@Component({
    selector: 'app-ball-pulse-spinner',
    templateUrl: './ball-pulse-spinner.component.html',
    styleUrls: ['./ball-pulse-spinner.component.scss'],
    standalone: false
})
export class BallPulseSpinnerComponent extends BallAtomSpinnerComponent implements OnInit {
  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) { super(spinnerService, themeService); }

  ngOnInit(): void { }
}
