import { Component, Inject, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { debounceTime, map } from 'rxjs/operators';
import { FormService } from '../form.service';
const generateName = require("project-name-generator");
const uuid = require('uuid');


@Component({
    selector: 'app-section-popup',
    templateUrl: './section-popup.component.html',
    styleUrls: ['./section-popup.component.scss'],
    standalone: false
})
export class SectionPopupComponent implements OnInit {


  timeout: any = null;
  formMap: any = {};
  suggestFormCode: any;
  isNameExists: boolean = false
  spinner:boolean = false;
  nameMap: any = {name: "Section"};
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public dialog: MatDialogRef<SectionPopupComponent>,
    public formService: FormService
  ) {


   }

  async ngOnInit() {
    this.nameMap.name = this.dataInput.name;
  }

  nameChanged(e){
    console.log("nameMap", e)
    this.nameMap = e;
  }

  async createForm(){
    console.log("this.formGroup.", this.nameMap)
    this.dialog.close(this.nameMap);
  }

}
