import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { SystemFieldsService } from '../../services/system-fields.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';


const DATATYPE_OPERATOR_MAPPING = {
  number: ["!=", "=", "<", "<=", ">", ">=", "!~", "~"],
  string: ["!=", "=", "!~","~", "%"],
  date: ["!=", "=", "<", "<=", ">", ">=", "#", "!~", "~"],
  time: ["!=", "=", "<", "<=", ">", ">=", "!~", "~"],
  datetime: ["!=", "=", "<", "<=", ">", ">=", "#", "!~", "~"],
  timestamp: ["!=", "=", "<", "<=", ">", ">=", "!~", "~"],
  object: ["!%", "%", "!~", "~"],
  array: ["!%", "%", "!~", "~"],
  boolean: ["!=", "=", "!~", "~"]
}


@Component({
    selector: 'app-filter-config',
    templateUrl: './filter-config.component.html',
    styleUrls: ['./filter-config.component.scss'],
    standalone: false
})
export class FilterConfigComponent implements OnInit {
  @Input() filter;
  @Input() boxObjectAttributes;
  @Input() enableSecurity;
  @Input() supportApplyButton: boolean;
  @Input() hideToggle: boolean;
  @Input() maskOperator: boolean;
  @Input() lockFilterType: boolean;
  @Input() showAttributePicker: boolean;
  @Input() fieldListingMap: any;
  @Input() boxObjectFunction: any;
  @Input() pageMeta: any; // if pageMeta is supplied, page reference map will be created for in field picker
  @Output() "selectedFilter" = new EventEmitter<any>();
  @Output() "cancelFilter" = new EventEmitter<any>();
  @Output() filtersValid = new EventEmitter<any>();


  macroOptions:any = [
    {name: "Today", id: "today"},
    {name: "Yesterday", id: "yesterday"},
    {name: "This Week", id: "this_week"},
    {name: "Last Week", id: "previous_week"},
    {name: "Last 7 Days", id: "last_7_days"},
    {name: "Last 30 Days", id: "last_30_days"},
    {name: "This Month", id: "this_month"},
    {name: "Last Month", id: "previous_month"},
    {name: "This Quarter", id: "this_quarter"},
    {name: "Last Quarter", id: "previous_quarter"},
    {name: "Next Quarter", id: "next_quarter"},
    {name: "This Year", id: "this_year"},
    {name: "Last Year", id: "previous_year"},
    {name: "Next Year", id: "next_year"},
    {name: "Year to Last Month", id: "year_to_last_month"}
  ]
  fieldPickerMap:any;
  attributesForFilter: any;
  pageFilters: any[] = [];
  unselectedListAttributes: any = [];
  // showToggle: boolean = true;
  // maskOperator: boolean = false;

  systemFields: any[] = []
  isFilterValid: boolean;
  @ViewChild('filterComponent', { static: false }) filterComponent!: ElementRef;
  componentWidth: any = 1000;
  constructor(
    private pageService: PageService,
    private systemFieldsService: SystemFieldsService,
    private widgetService: WidgetService,
  ) {

  }

  selectFilterAttributeForm = new UntypedFormGroup({});
  fieldPickerMapForValue: any;

  ATTR_TO_OPERATORS: any = {}

  OPERATORS = {
    "!=": {__id: "NOT_EQUAL", name: "Not equal", value: "!="},
    "=": {__id: "EQUAL", name: "Equal", value: "="},
    "<": {__id: "LESS_THAN", name: "Less than", value: "<"},
    "<=": {__id: "LESS_THAN_OR_EQUALS_TO", name: "Less than or equal", value: "<="},
    ">": {__id: "GREATER_THAN", name: "Greater than", value: ">"},
    ">=": {__id: "GREATER_THAN_OR_EQUALS_TO", name: "Greater than", value: ">="},
    "!%": {__id: "NOT_CONTAIN", name: "Not contain", value: "!%"},
    "%": {__id: "CONTAIN", name: "Contain", value: "%"},
    "#": { __id: "MACRO" , name: "Macro", value: "#" },
    // "!": { __id: "EMPTY" , name: "Empty", value: "!" },
    "~": {__id: "EMPTY", name: "Is Empty", value: "~"},
    "!~": { __id: "NOT_EMPTY" , name: "Not empty", value: "!~" },
  }

  // newFilter: FILTER_ITEM = {
  //   attribute: '',
  //   operator: '=',
  //   value: '',
  //   dataType: '',
  //   filterType: 'static_filter'
  // }
  filterTypes: any[] = [
    {name: "Static Filter", icon:"text_fields", value: 'static_filter', description: 'User specified hardcoded filter'},
    {name: "Page Filter", icon:"find_in_page", value: 'page_filter', description: 'Some value from the page is used as filter value i.e. filter value depends on another element in this page'},
    {name: "Navigation Filter", icon:"link", value: 'navigation_filter', description: 'An attribute vlaue coming from the navigation is used as filter value'},
  ]

  filterTypeLogoMap: any = {
    "static_filter": {
      logo: "text_fields",
      name: "Static Filter"
    } ,
    'page_filter': {
      logo: "find_in_page",
      name: "Page Filter"
    } ,
    "navigation_filter": {
      logo: "link",
      name: "Navigation Filter"
    }
  }

  ngAfterViewInit() {
    // Access the native element and log its width
    this.componentWidth = this.filterComponent.nativeElement.offsetWidth;
    console.log('Component width:', this.componentWidth);
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  ngOnInit(): void {
    // console.log("[FILTER CONFIG] onInit hit : ",this.filter?.filterItems)

    this.generateFieldPickerMapForValue()

    if(!this.filter?.filterItems) {
      this.filter.filterItems = [];
      this.addFilterItem()
    }

    this.initPageFilters()
    this.initAvailableOperators()
    this.applyAttributeValueOptions();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes.filter && changes.filter.firstChange){
      this.emitFilterOnInput()
    }
    if(changes.filter && changes.filter.currentValue) {
      console.log("filter received in filter component", changes.filter.currentValue)
      if(!this.filter.filterItems || !Array.isArray(this.filter.filterItems) || this.filter.filterItems.length == 0) return
      for(var i = 0; i < this.filter.filterItems.length; i ++){
        this.selectFilterAttributeForm.addControl("form" + i, new UntypedFormControl());
        let valueMap = {
          __id: this.filter.filterItems[i].attribute,
          dataType: this.filter.filterItems[i].dataType || "string"
        }
        this.selectFilterAttributeForm.patchValue({[`form${i}`]: valueMap});
      }
    }
    this.constructFieldPickerFields();
  }

  //resturcting for pasing it into field picker
  constructFieldPickerFields() {
    //only assigning default value if the field picker is required, doing this to hide field picker in value.
    if (this.fieldListingMap && Object.keys(this.fieldListingMap).length) {
      this.fieldPickerMap = {};
    }

    // console.log("boxObjectAttributes", this.boxObjectAttributes)
    console.log("fieldListingMap", this.fieldListingMap)
    for(const key in this.fieldListingMap) {
      if(key == 'list') continue;
      if(!this.fieldPickerMap.list) this.fieldPickerMap.list = [];
      this.fieldPickerMap.list.push(key);
      if (key == "sourceFields") {
        this.fieldPickerMap[key] = {
          id:'sourceFields',
          displayName: 'SOURCE FIELDS',
          fields: this.fieldListingMap?.sourceFields,
          options:{}
        }
      }
      if(key == "oldAttributes"){
        this.fieldPickerMap[key] = {
          id:'oldAttributes',
          displayName: 'OLD FIELDS',
          fields: this.fieldListingMap?.oldAttributes,
          options:{}
        }
      }
      if (key == "systemFields") {
        this.fieldPickerMap[key] = {
          id:'systemFields',
          displayName: 'SYSTEM FIELDS',
          fields: this.fieldListingMap?.systemFields,
          options:{}
        }
      }
    }

    console.log("fieldPickerMap", this.fieldPickerMap)
  }

  initPageFilters(){
    // get page filters
    let pageModel = this.pageService.getPageModel()
    // console.log("page model", pageModel)

    this.pageFilters = []

    let pageMeta = this.pageService.pageMeta
    console.log("page service pageMeta", JSON.parse(JSON.stringify(this.pageService.pageMeta || {})))
    if(pageMeta && Object.keys(pageMeta).length){
      pageMeta.panels.forEach((panel, index) => {
        let panelId = panel.id
        if(panel.type == 'listpanel') return
        if(pageModel[panelId] == undefined || !Object.keys(pageModel[panelId]).length) return
        Object.keys(pageModel[panelId]).forEach((widget: any) => {
          this.pageFilters.push({
            panelId: panelId,
            widgetId: pageModel[panelId][widget]?.widgetId,
            widgetName: pageModel[panelId][widget]?.widgetName,
            // displayValue: `Panel no. ${index + 1}  :  ${pageModel[panelId][widget]?.widgetName}`,
            value: `$` + `{${panelId}.${pageModel[panelId][widget]?.widgetId}}`,
            panelName: "Panel no. " + (index + 1),
            panelType: panel.type,
            widgetType: pageModel[panelId][widget]?.widgetType
          })
        })
      })
    }
    console.log("page filters initialized", this.pageFilters)
  }

  /**
   * initializes ATTR_TO_OPERATOR mapping. attr_id => available_operators_array
   */
  initAvailableOperators(){
    this.boxObjectAttributes.forEach(attr => {
      // console.log("dealing attr", attr)

      let operators = []
      // initialize with whats allowed in datatype level
      operators =  DATATYPE_OPERATOR_MAPPING[attr.dataType || "string"]
      // console.log("operators initialized", JSON.parse(JSON.stringify(operators)))

      // let fnSupportedOperators = ["<", ">", "<=", ">=", "!", "!~"]  // "=", "!=",
      if(this.boxObjectFunction?.options?.supportedOperators?.length){
        operators = operators.filter(op => this.boxObjectFunction.options.supportedOperators.includes(op))
      }
      // console.log("handled function level ops", JSON.parse(JSON.stringify(operators)))

      // rmeove masked ones from supported ones
      if(attr.maskedOperators){
        operators = operators.filter(op => !attr.maskedOperators.includes(op))
      }
      // console.log("handled attr level masked ops", JSON.parse(JSON.stringify(operators)))

      // place in map
      this.ATTR_TO_OPERATORS[attr.__id] = operators
    })
    this.ATTR_TO_OPERATORS["__templated"] = DATATYPE_OPERATOR_MAPPING["string"]
    // console.log("operators initalized", this.ATTR_TO_OPERATORS)
  }


  filterToggleChanged(toggleValue: any){
    console.log(toggleValue)
    this.filter.filterEnabled = toggleValue.checked;
    if(this.filter.filterEnabled == false){
      this.filter.filterItems = [];
      this.emitFilterOnInput()
    }
    if(!this.filter.filterItems || this.filter.filterItems.length == 0){
      this.addFilterItem();
    }
    this.checkValidation()
  }

  //filter for attributes for adding filter
  private _filterAttributesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filterables: any[] = this.boxObjectAttributes.filter((attr: any) => attr) //.filterable
    return filterables.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  displayFn(attr) {
    return attr?.name ? attr.name : (attr?.__id ? attr.__id : '');
  }

  addFilterItem(){
    this.filter.filterItems.push({
      attribute: '',
      operator: '=',
      value: '',
      dataType: '',
      filterType: 'static_filter'
    })
    this.selectFilterAttributeForm.addControl("form" + (this.filter.filterItems.length - 1), new UntypedFormControl())
    this.checkValidation()
  }


  insertAttributeCursor(e, filter){
    let value = e.__id;
    let cursorValue = '${' + value + '}';
    filter.attribute = cursorValue;
    filter.dataType = "string";
    this.emitFilterOnInput();
    // this.selectFilterAttributeForm.patchValue({[`form${i}`]: valueMap});
  }

  removeFilter(i: number){
    this.filter.filterItems.splice(i, 1)
    console.log("this.filter", this.filter)
    if(this.filter.filterItems?.length == 0) this.selectFilterAttributeForm.reset()

    this.emitFilterOnInput();
  }

  setFilterList(i){
    // FILTER FOR LIST PANEL FILTERS
    this.attributesForFilter = this.selectFilterAttributeForm.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._filterAttributesFilter(value))
    );
    this.checkValidation()
  }

  filterAttrSelected(event: any, filter, i) {

    console.log("new filter event:", event)
    console.log("OPERATORS:", this.ATTR_TO_OPERATORS[event.option.value.__id])
    filter.attribute = event.option.value.__id;
    filter.dataType = event.option.value.dataType;

    //if value options is available set that in filter
    if(event.option?.value?.valueOptions?.length) {
      filter.valueOptions = event.option.value.valueOptions;
      filter.valueType = event.option.value.valueType;
    }
    this.emitFilterOnInput();

    console.log("this.selectFilterAttributeForm", this.selectFilterAttributeForm)
    // this.newFilter.attribute = event.option.value.__id
    // this.newFilter['dataType'] = event.option.value.dataType
  }
  // pageFilterSelected(event, filter, i){
  //   console.log("dynamic filter value selected", event, "index", i, "current filters", JSON.parse(JSON.stringify(this.filter)))
  //   console.log("pageFilters", this.pageFilters)
  //   filter.value = event.value
  //   console.log("filter formed", filter)
  //   this.checkValidation()
  //   this.filter.filterItems[i] = filter
  //   this.emitFilterOnInput()
  // }
  pageFilterSelected(event, filter, i){
    console.log("dynamic filter value selected", event, "index", i, "current filters", JSON.parse(JSON.stringify(this.filter)))
    console.log("pageFilters", this.pageFilters)
    filter.value = event.value
    filter.displayValue = event.panelName + " > " + event.widgetName
    console.log("filter formed", filter)
    this.checkValidation()
    this.filter.filterItems[i] = filter
    this.emitFilterOnInput()
  }

  onSelectFilterType(filter, obj){
    console.log("filter type selected", filter, obj)
    filter.filterType = obj.value;
    filter.value = ""
    if(obj.value == "page_filter"){
      this.initPageFilters()
    }
    this.emitFilterOnInput()
  }

  filterOperatorSelected(index, op){
    console.log(op)
    this.filter.filterItems[index].operator = op;
    this.emitFilterOnInput()
    console.log(this.filter)
  }

  filterValue(filter, event){
    // console.log(event)
    filter.value = event.target.value;
    this.emitFilterOnInput()
    console.log(this.filter)
  }

  emitFilterOnInput(){
    this.checkValidation();
    if (this.supportApplyButton) return;
    this.selectedFilter.emit(this.filter)
  }

  applyFilters(){
    this.checkValidation()
    this.selectedFilter.emit(this.filter)
  }

  checkValidation(){
    console.log("filter", this.filter?.filterItems)
    this.isFilterValid = true;
    if(!this.filter.filterEnabled) return;
    for (let index = 0; index < this.filter?.filterItems.length; index++) {
      const element = this.filter?.filterItems[index];
      if(!element.attribute) this.isFilterValid = false;
      if(!["~", "!~"].includes(element.operator) && !element.value) this.isFilterValid = false;
    }
    this.filtersValid.emit(this.isFilterValid)
  }

   /**
  * Adjusts existing filters by assigning appropriate value options and types.
  *
  * This function iterates over `filterItems` and checks if any attribute in `boxObjectAttributes`
  * has matching `__id`. If a match is found and the attribute has predefined `valueOptions`,
  * it assigns them to the filter, ensuring proper type adjustment.
  */
   applyAttributeValueOptions() {
    for (const filter of this.filter?.filterItems) {
      for (const attribute of this.boxObjectAttributes) {  // Loop through available attributes.
        if (attribute?.__id == filter?.attribute) {  // Check if attribute matches filter.
          if (attribute?.valueOptions?.length) {  // If attribute has predefined value options.
            filter.valueOptions = attribute.valueOptions;
            filter.valueType = attribute.valueType;
          }
          continue;
        }
      }
    }
  }

  cancelFilters(){
    // Object.keys(this.selectFilterAttributeForm.controls).forEach(key => {
    //   this.selectFilterAttributeForm.controls[key].patchValue();
    // });
    // this.selectFilterAttributeForm.reset()
    // this.filter.filterItems = []
    // this.filter.filterItems.push({
    //   attribute: '',
    //   operator: '=',
    //   value: '',
    //   dataType: '',
    //   filterType: 'static_filter'
    // })
    this.checkValidation()
    this.cancelFilter.emit(true)
  }

  async generateFieldPickerMapForValue(){

    this.fieldPickerMapForValue = {
      list:['systemFields'],
      systemFields: {
        id:'systemFields',
        displayName: 'SYSTEM FIELDS',
        fields: [],
        options:{}
      }
    }

    let res = await this.systemFieldsService.getSystemFields();
    for (let index = 0; index < res?.fields?.length; index++) {
      const field = res?.fields[index];
      this.systemFields.push(res[field])
    }
    console.log("SYSTEM FIELDS: ",this.systemFields);
    this.fieldPickerMapForValue.systemFields.fields = this.systemFields

    if(this.pageMeta){
      console.log("will generate page reference in fieldPickerMap")
      this.pageMeta.panels?.forEach(panel => {
        if(panel.type !== 'listpanel' && panel.type !== 'searchpanel') {
          // this.allPanels.push({ id: panel.id, name: panel.name, type: panel.type, layoutMap: panel.layoutMap })

          let widgetOptions: any[] = []
          let widgetMetas = this.widgetService.getWidgetsFromPanel(panel)
          widgetMetas.forEach(wid => {
            widgetOptions.push({
              name: wid.name,
              __id: `${panel.id}.${wid.id}`,
              value: '${' + `${panel.id}.${wid.id}` + '}',
              dataType: "string"
            })
          })

          this.fieldPickerMapForValue.list.push(panel.id)
          this.fieldPickerMapForValue[panel.id] = {
            id: panel.id,
            displayName: panel.name,
            fields: widgetOptions,
            options: {}
          }
        }
      });
    }
    console.log("field picker map for value generated", this.fieldPickerMapForValue)
  }

  insertFilterCursor(e, filterMap){
    console.log("[INSERT-FILTER]", e, filterMap)
    let value = e.__id;
    //if value type is static use the value directly on filter
    if(e?.valueType != "static") value = '${' + value + '}';
    filterMap.value = value;
    this.emitFilterOnInput()
  }


  systemFieldValueSelected(filter, event){
    console.log("filter", filter)
    console.log("event", event)
    if(event.__id.split('.')[0] == 'systemFields'){
      filter.value = '${' + event.__id?.split('.')?.[1] + '}';
    }else {
      filter.value = event.value
    }
    this.emitFilterOnInput()
  }

}
