export const AvailableOptions: any = {
    toPersist: ['staticOptions', 'dynamicOptions'],
    displayName: "Available Options",
    staticOptions: [
      {
        name: 'Option 1',
        value: 'Option 1',
        default: false,
      },
      {
        name: 'Option 2',
        value: 'Option 2',
        default: false,
      },
      {
        name: 'Option 3',
        value: 'Option 3',
        default: false
      },
    ],
    dynamicOptions: {
      enabled: false,
      connectionId: '',
      boxId: '',
      boxName: '',
      boxObjectId: '',
      nameAttribute: '',
      namePath: '',
      valueAttribute: '',
      valuePath: '',
      getFnOptions: [],
      filter: {
        enabled: false,
        filters: []
      }
    },
    type: 'selection-widgets-options-config'
}

export const AvailableTagsOptions: any = {
  toPersist: ['staticOptions', 'dynamicOptions'],
  displayName: "Available Options",
  staticOptions: [
    {
      name: 'Tag 1',
      value: 'tag1',
      default: false,
    },
    {
      name: 'Tag 2',
      value: 'tag2',
      default: false,
    },
    {
      name: 'Tag 3',
      value: 'tag3',
      default: false
    },
  ],
  dynamicOptions: {
    enabled: false,
    userData: false,
    connectionId: '',
    boxId: '',
    boxName: '',
    boxObjectId: '',
    nameAttribute: '',
    namePath: '',
    valueAttribute: '',
    valuePath: '',
    getFnOptions: [],
    filter: {
      enabled: false,
      filters: []
    }
  },
  type: 'selection-widgets-tag-options-config'
}
