import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

interface Filter {
  key: string,
  value: any,
  dataType: string
}

@Component({
    selector: 'app-select-box',
    templateUrl: './select-box.component.html',
    styleUrls: ['./select-box.component.css'],
    standalone: false
})
export class SelectBoxComponent implements OnInit, AfterViewInit {

  @Input() oldValue: string;
  @Input() filters: Filter[];
  @Output() boxInput: EventEmitter<any> = new EventEmitter()
  @Output() boxSelectionError: EventEmitter<any> = new EventEmitter()

  connectionList: any[] = []
  selectBoxControl = new UntypedFormControl();
  filteredBoxes: any;
  isReceivedConnections: boolean = false;
  selectedConnectionId: string;

  preAuthenticatedToken: string;
  workSpaceId: string;

  @ViewChild('boxInput', { static: false }) boxInputElement: ElementRef;

  constructor(
    private connectionService: ConnectionService,
    private TokenUtil: TokenUtil,
    private http: HttpClient,
    private SpinnerService: SpinnerService
  ) {
    
    this.preAuthenticatedToken = encodeURIComponent(this.connectionService.preAuthenticatedToken)
    this.workSpaceId = this.connectionService.workSpaceId
  }

  ngOnInit(): void {
    if (this.oldValue) {
      console.log("old value for box", this.oldValue)
      this.isReceivedConnections = true
      this.selectBoxControl.disable()
    } else {
      console.log("no old box value found")
    }

    this.getAllConnection()

    //FILTERS FOR BOX AUTOCOMPLETE
    this.filteredBoxes = this.selectBoxControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : (value && value.name ? value.name : ''))),
      map(value => this._boxFilter(value))
    );
  }

  ngAfterViewInit(): void {
    console.log("input element:", this.boxInputElement)
  }

  //filter for box names autocomplete from the connections
  private _boxFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.connectionList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFnBox(box) {
    return box && box.name ? box.name : ''
  }

  // fetches all connections
  getAllConnection() {
    let url = `${environment.SERVER_BASE_URL}/connection/${this.connectionService.workSpaceId}`;
    // let url = `http://localhost:8081/api/connection/${this.connectionService.workSpaceId}`;

    if(this.filters && this.filters.length){
      this.filters.forEach((filterObj: Filter) => {
        let filterString = `${filterObj.key}=${filterObj.value}|${filterObj.dataType}`
        url = url + ',' + filterString
        // url = encodeURIComponent(url)
      })
    }

    console.log("get all connection URL", url)
    console.log("connectionService.workspaceId", this.connectionService.workSpaceId)
    // let token = this.TokenUtil.getStatelessToken()
    const headers = new HttpHeaders()
      .set(
        'Authorization',
        'PreAuthenticatedToken ' + this.connectionService.preAuthenticatedToken
      );

    this.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log('CONNECTION LIST RECEIVED (select box component)', response.data)
        this.connectionList = response.data;
        this.isReceivedConnections = true
        this.selectBoxControl.enable()

        if (this.oldValue) {
          this.boxInputElement.nativeElement.value = this.oldValue
        }
      },
      (error) => {
        this.isReceivedConnections = true
        console.log(error);
        this.boxSelectionError.emit(error)
      }
    );
  }

  refreshConnections() {
    // this.isReceivedConnections = false
    this.selectBoxControl.disable()
    this.getAllConnection()
  }

  boxSelected(event: any) {
    console.log("box selected in boxSelect: ", event)
    this.selectedConnectionId = event.option.value._id
    this.boxInput.emit(event.option.value)
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }
  
}
