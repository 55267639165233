
import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-list-all-workspaces-diolog',
    templateUrl: './list-all-workspaces-diolog.component.html',
    styleUrls: ['./list-all-workspaces-diolog.component.scss'],
    standalone: false
})
export class ListAllWorkspacesDiologComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<ListAllWorkspacesDiologComponent>,
    private _connectionService: ConnectionService,
    public snackBar: MatSnackBar,
    ) { }
  get connectionService() { return this._connectionService; }

  ngOnInit(): void {}

  async selectedWorkspace(workspace){
    this.snackBar.open(workspace.name+" is  selected.")._dismissAfter(3000);
    this.dialog.close(workspace);
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

}
