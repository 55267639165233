import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'base-selection-popup',
    templateUrl: './base-selection-popup.component.html',
    styleUrls: ['./base-selection-popup.component.scss'],
    standalone: false
})
export class BaseSelectionPopupComponent implements OnInit {

  config:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dialogRef: any,
    public dialog: MatDialogRef<BaseSelectionPopupComponent>, 
  ) {
    this.config = this.dialogRef;
   }

  ngOnInit(): void {

  }

  baseCreated(e){
    console.log("baseCreated", e)
    this.dialog.close(null);
  }

  cancelled(){
    this.dialog.close(null);
  }

}
