import {
  Injectable,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PageService } from '../../bloom/services/page-service.service'
// import {MatSnackBar} from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthServiceService } from './auth-service.service';
import { FormService } from 'src/app/form/form.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { ValidatorRegistry } from '../widget-validator/ValidatorRegistry';

interface ValidationFeedback {
  widgetId: string,
  panelId: string,
  status: boolean,
  violatedCodes?: string[]
}

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  $validationFeedback: any = new Subject <ValidationFeedback> ()

  constructor(
    private pageService: PageService,
    private snack: MatSnackBar,
    private authService: AuthServiceService,
    private formService: FormService,
    private automationService: AutomationService
  ) {}

  /**
   * checks if all the source fields have valid value in pageModel
   * if not, sends alert to respective widget through observable
   * @param actions: array of actions
   * @param trigger: { type: 'widget' | 'panel', id: string }
   * @returns booelan; true if all mapped fields of all mapped values are valid, false otherwise
   */
  checkValidation(actions: any[] = [], customValid?: any) {
    let ids: any[] = []

    actions?.forEach(a => {
      console.log("handling action --> ", a.actionMap.action)
      a.actionMap.mapping?.forEach(mapping => {
        if(mapping.mappingType !== 'sourceField') return
        ids.push(mapping.sourceField?.__id)
        // console.log("pushed", mapping.sourceField?.__id)
      })
    })
    // console.log("mapped source ids", ids)

    let violatedIds: any[] = []
    let widgetViolatedIds: any[] = []
    // console.log("ids received", ids)

    //for Forms getting the section panels from service and constructing the id's
    if(!ids.length && this.authService?.subproduct == "form" && customValid) {
      let sectionMeta = this.formService.pageSelectionRequest?.value;
      sectionMeta?.panels?.forEach(panel => {
        let widgets = this.automationService.getWidgetsFromPanel(panel);
        widgets?.forEach(widget => {
          ids.push(`${panel.id}.${widget.id}`);
        })
      });
    }

    if(!ids.length) return true;

    let pageModel = this.pageService.getPageModel()
    // console.log("pageModel collected", pageModel);

    let len = ids.length
    for (let i = 0; i < len; i++) {
      const id = ids[i];
      let parts = id.split('.')
      let panelId = parts[0]
      let widgetId = parts[1]
      // console.log("dealing", panelId, widgetId)

      let validity = pageModel[panelId]?.[widgetId]?.['validity']
      if(validity == false){
        // console.log("validity false")
        let feedback = {
          panelId: panelId,
          widgetId: widgetId,
          status: false
        }
        violatedIds.push(id)
        if(pageModel[panelId]?.[widgetId]?.['widgetValidationObj']?.isValid === false) widgetViolatedIds.push(id)
        // console.log("feedback prepared, pushing", feedback)
        this.$validationFeedback.next(feedback)
      }
    }
    if (violatedIds.length) {
      let snackbarText = "Please fill in all required fields and provide a valid input.";
    
      const hasMissingRequiredFields = violatedIds.length > widgetViolatedIds.length; // At least one required field is missing
      const hasOnlyNonRequiredViolations = JSON.stringify(violatedIds) === JSON.stringify(widgetViolatedIds); // All fields have non-required violations
    
      if (hasMissingRequiredFields) {
        snackbarText = "Please fill in all required fields.";
      } else if (hasOnlyNonRequiredViolations) {
        snackbarText = "Please provide a valid input.";
      }
    
      // Show toast with the message
      this.snack.open(snackbarText, "Ok", {
        duration: 5000,
        horizontalPosition: "center",
      });
    
      return false; 
    }
    else return true
  }

  checkWidgetValidation(widgetMeta: any){
    let res: any
    var isValid: boolean
    var customErrorMessage: string = ''
    let isWidgetValidatorEnabled = widgetMeta?.validatorConfig?.isEnabled

    if(isWidgetValidatorEnabled && widgetMeta?.validatorConfig?.validators.length){
      for(let item of widgetMeta?.validatorConfig?.validators){
        let validatorType = item?.id;
        let widgetValue = widgetMeta?.config?.value?.value;
        if(validatorType && widgetValue) {
          isValid = ValidatorRegistry.validate(validatorType, widgetValue, item.config?.inputParams)
          if(!isValid) return {isValid: false, customErrorMessage: item?.config?.inputParams?.customErrorMessage?.value || 'Invalid Input'}
          customErrorMessage = customErrorMessage + (item?.config?.inputParams?.customErrorMessage?.value || '') + ' & '
        }
      }
    }
    if(isValid != undefined && customErrorMessage != '') res = {isValid: isValid, customErrorMessage: customErrorMessage}
    return res
  }
}
