import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { BallAtomSpinnerComponent } from '../ball-atom-spinner/ball-atom-spinner.component';
import { SpinnerService } from '../spinner.service';

export interface SkeletonElement {
  shape: 'circle' | 'rectangle' | 'image';
  rows: number;
  cols: number;
}

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
    standalone: false
})
export class SkeletonComponent extends BallAtomSpinnerComponent implements OnInit {

  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) { super(spinnerService, themeService); }

  @Input() skeletonSpinnerGridMap: SkeletonElement[] = [];
  @Input() skeletonPresetCode: string;


  ngOnInit(): void {
    if(this.skeletonPresetCode){
      this.skeletonSpinnerGridMap = this.spinnerService.getPresetgridMap(this.skeletonPresetCode)
    }
  }
}
