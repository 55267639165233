<div>
  <div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="primary" > find_in_page </mat-icon>
      <form class="w-100">
        <mat-form-field class="w-100">
          <input type="text"
                  placeholder="Choose a navigation page OR Provide URL"
                  matInput
                  [formControl]="myControl"
                  [(ngModel)]="navigationValue"
                  [matAutocomplete]="auto"
                  (input)="navigationRecieved($event.target.value)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="navigationRecieved($event.option.value)">
            <mat-option value="prev_page">Previous Page</mat-option>
            <mat-option *ngFor="let option of metaService.loadedPageStructure.pages; trackBy: trackByFn" [value]="option">
              {{ metaService.loadedPageStructure[option].name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div *ngIf="noValidnavigation" style="color: red">
      {{validateMessage}}
    </div>
  </div>

  <div>
    <div fxLayout="row" *ngFor="let data of actionConfig.actionMap.parameters;let i = index; trackBy: trackByFn"  fxLayoutAlign="start center"> 
      <div fxLayout="column" fxFlex="40">
          <mat-form-field class="example-full-width" >
              <mat-label>Attribute</mat-label>
              <input (input)="setAttributeId(data.keyField)" matInput [matTooltip]="data.keyField.__id" [(ngModel)] = 'data.keyField.__id' >
          </mat-form-field>
      </div>
      
      <div fxLayout="column" fxFlex="5" style="height: 60px;" fxLayoutAlign="center center">
        <mat-icon color="primary"> widgets </mat-icon>
      </div>
      <div fxLayout="column" fxFlex="50">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="wide-select" [matTooltip]="data.sourceField.name">
            <mat-label>Value</mat-label>
            <mat-select (selectionChange)="onWidgetActionSelected()" [(ngModel)] = "data.sourceField" [compareWith]="compareWith">
              <mat-option [value]="option" *ngFor="let option of sourceFields; trackBy: trackByFn">
                {{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" fxFlex="5"> 
          <mat-icon style="cursor: pointer;" color="warn" (click)="removePayloadAttribute(actionConfig.actionMap.parameters, i, 'widgetAction')">
          delete
        </mat-icon></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center"> 
      <button style="cursor: pointer;" (click)="addNewAttribute()"  color="primary" mat-stroked-button matTooltip="Add new attribute">
          <mat-icon >
              add_circle_outline
            </mat-icon>
            Add attribute
      </button>
    </div>
  </div>
</div>
