import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StarchService } from 'src/app/shared/services/starch.service';
import { QoutaPopupComponent } from 'src/app/shared/qouta-popup/qouta-popup.component';
import { SmsService } from 'src/app/shared/services/sms-service.service';
import { environment } from 'src/environments/environment';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';


const uuid = require('uuid');
const generateName = require("project-name-generator");

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon?: string;
  snackBarError?: boolean;
  snackBarDuration?: number;
  snackBarRecommendation?: string
}


@Component({
    selector: 'starch-add-base',
    templateUrl: './starch-add-base.component.html',
    styleUrls: ['./starch-add-base.component.scss'],
    standalone: false
})

export class StarchAddBaseComponent implements OnInit {


  formGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]),
    name: new UntypedFormControl('', Validators.required)
  });

  timeout: any = null;
  isNameExists: boolean;
  spinner: boolean;
  sugestBaseCode: any;
  baseMap: any = {};
  @Input()allBases: any = []


  @Output() "baseCreated" = new EventEmitter<any>();
  @Output() "cancelled" = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public smsService: SmsService
  ) { }



  async ngOnInit() {
    await this.checkAndGetRandomCode();
    this.formGroup.get("name").setValue(`Base ${this.allBases?.length + 1 || ''}`);
  }




  ngAfterViewInit(): void {
    
  }
  async createBase(){

    let eligibility = this.smsService.checkEligibility("STARCH");
    console.log("isEligible", eligibility)
    if(!eligibility.isEligible){
      let dialogRef = this.dialog.open(QoutaPopupComponent, {
            width: "500px",
            data: {
              type: "STARCH"
            },
          });
      return ;
    }
    console.log("this.formGroup.", this.formGroup.value);
    this.baseMap = this.formGroup.value;

    // add randomly selected logo
    this.baseMap['logo'] = this.getLogoUrl()

    this.spinnerService.show();
    try{
      let result = await this.starchService.createStarchBase(this.baseMap);
      console.log("result", result)
    }catch(error){
      console.log("error", error)
      let snackBarObj
      if(error?.error?.error?.errorMap?.message && error?.error?.error?.errorMap?.error){
        snackBarObj = {
          snackBarMessage: error?.error?.error?.errorMap?.message,
          snackBarIcon: 'error',
          snackBarRecommendation: error?.error?.error?.errorMap?.error
        };
      }else{
        snackBarObj = {
          snackBarMessage: "Starch Base could not be created",
          snackBarIcon: 'error',
          snackBarRecommendation: "Please try again"
        };
      }
      this.openSnackBar(snackBarObj, error);
      this.spinnerService.hide();
      return;
    }
    
    this.baseCreated.emit();
    this.spinnerService.hide();
  }

  openSnackBar(snackBarObj: snackBarValues, errorMessage?: any) {
    if (errorMessage) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          title: errorMessage?.error?.error?.name || '',
          description: errorMessage?.error?.error?.message || JSON.stringify(errorMessage?.error?.error) || errorMessage?.error?.message || '',
          recommendation: snackBarObj?.snackBarRecommendation || '',
          message: snackBarObj.snackBarMessage,
          iconname: snackBarObj.snackBarIcon,
          isError: true
        },
        duration: snackBarObj.snackBarDuration || undefined,
        horizontalPosition: 'end',
      });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          message: snackBarObj.snackBarMessage,
          iconname: snackBarObj.snackBarIcon,
        },
        duration: snackBarObj.snackBarDuration,
        horizontalPosition: 'end',
      });
    }
  }

  getLogoUrl() {
    let randomLogoCode: any = Math.floor(Math.random() * 5) + 1
    let logoUrl = environment.STARCH_LOGO_BASE_URL + "0" + randomLogoCode + '.png'
    return logoUrl
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 1000);
  }

  async executeListing(value: string) {
    console.log("value", value);
    let code = this.constructPagePath(value);
    this.formGroup.get("code").setValue(code);
    await this.checkBaseExists(code);
  }

  async checkBaseExists(code){
    this.spinner = true;
    this.isNameExists = false;
    let result = await this.starchService.getBaseByCode(code);
    console.log("result", result);
    if(result) this.isNameExists = true;
    this.spinner = false;
  }

  async checkAndGetRandomCode(code?:any){
    this.spinner = true;
    let randomCode
    if(!code){
      randomCode = uuid.v4().substring(0, 4);
      if(!randomCode){
        randomCode = Date.now()
      }
  
      randomCode = `${generateName.generate({ words: 1}).dashed}-${randomCode}`;
    } else randomCode = code;
   

    console.log("randomCode", randomCode)
    let result = await this.starchService.getBaseByCode(randomCode);
    console.log("result", result);
    if(result) return this.checkAndGetRandomCode();
    else {
      this.spinner = false;
      this.isNameExists = false;
      this.sugestBaseCode = randomCode;
      this.formGroup.get('code').setValue(this.sugestBaseCode);
      return randomCode;
    } 
    
  }

  cancelCreation(){
    this.cancelled.emit();
  }


  constructPagePath(code){
    //reference https://quickref.me/convert-a-string-to-url-slug;
    let slugify = (string) => string.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    return slugify(code);
  }

}


