<button matTooltip="Choose a field to insert"  mat-icon-button [matMenuTriggerFor]="menu" class="field-insert-button">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="field-insert-menu">
    <mat-accordion>
        <div *ngFor="let fieldObj of inputFieldMap?.list; trackBy: trackByFn">
            <mat-expansion-panel (click)="onExpansionPanelClick($event)" [expanded]="isExpanded || fieldObj === 'sourceFields'" class="field-panel">
                <mat-expansion-panel-header class="field-panel-header">
                    <mat-panel-title class="field-panel-title">
                        <b>{{inputFieldMap[fieldObj]?.displayName}}</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngFor="let templSourceField of inputFieldMap[fieldObj]?.fields; trackBy: trackByFn" >
                    <div mat-menu-item >
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div fxFlex="90" (click)="insertField(templSourceField, inputFieldMap[fieldObj])" class="field-item">
                        <mat-icon matTooltip="{{ templSourceField?.dataType }}" class="field-item-icon">
                          {{ iconMap[templSourceField?.dataType] }}
                        </mat-icon>
                        <span class="field-item-text">{{ templSourceField?.name }}</span>
                      </div>
                      <div fxFlex="10" fxLayoutAlign="end center">
                        <mat-icon
                        matTooltip="Configure field"
                        (click)="configureField(templSourceField);"
                        class="field-item-icon"
                        fxFlex="none"
                      >
                        settings
                      </mat-icon>
                      </div>
                    </div>
                    <div  fxLayout="row" *ngIf="templSourceField?.isConfigureOpen">
                      <div >
                        <mat-checkbox
                        [(ngModel)]="templSourceField.useSystemObjects">System objects</mat-checkbox>
                      <app-system-objects-selection [selected]="templSourceField.systemObjectSemantic"
                      *ngIf="templSourceField.useSystemObjects"
                      (selectedObject)="systemObjectSelected($event, templSourceField)"></app-system-objects-selection>

                      <div fxLayoutAlign="center center">
                        <button class="applybutton" mat-raised-button color="primary" (click)="applyConfig(templSourceField)" style="margin: 1rem 0">
                            Apply
                        </button>
                      </div>
                    </div>
                    </div>
                    </div>

                    <!-- <div mat-menu-item>
                      <button
                      (click)="insertField(templSourceField, inputFieldMap[fieldObj])" class="field-item">
                      <mat-icon matTooltip="{{ templSourceField?.dataType }}" class="field-item-icon">
                        {{iconMap[templSourceField?.dataType]}}
                      </mat-icon> <span class="field-item-text">{{ templSourceField?.name }}</span>
                     </button>
                      <mat-icon matTooltip="Configure field" (click)="configureField()"
                      class="field-item-icon" fxLayoutAlign="end end">
                        settings
                      </mat-icon>
                    </div> -->
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
    <!-- <div *ngFor="let fieldObj of inputFieldMap?.list; trackBy: trackByFn">
        <div *ngIf="inputFieldMap[fieldObj]?.fields?.length > 0"  style="padding: 2px;">
            <div fxLayout="row" fxLayoutAlign="center center">
                <b>{{inputFieldMap[fieldObj]?.displayName}}</b>
            </div>
            <div *ngFor="let templSourceField of inputFieldMap[fieldObj]?.fields; trackBy: trackByFn" >
                <button mat-menu-item
                (click)="insertField(templSourceField)">
                <mat-icon matTooltip="{{ templSourceField?.dataType }}">
                    {{iconMap[templSourceField?.dataType]}}
                </mat-icon> <span>{{ templSourceField?.name }}</span>
            </button>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div> -->
</mat-menu>
