import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientPlatformService } from '../../client-platform.service';

@Component({
    selector: 'app-connection-success',
    templateUrl: './connection-success.component.html',
    styleUrls: ['./connection-success.component.scss'],
    standalone: false
})
export class ConnectionSuccessComponent implements OnInit {
  queryParams: any;
  isNew: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public clientPlatformService: ClientPlatformService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log('PARAMS', params);
      this.queryParams = params;
      this.isNew = params['isNew'] === 'true';
    });
    let clientType = this.clientPlatformService.clientType;
    switch (clientType) {
      case "ZOHOCRM":
      case "ZOHOBIGIN":
      case "ZOHOBOOKS":
      case "ZOHOBILLING":
      case "ZOHODESK":
        this.router.navigate(['client-platform/zoho/zoho-crm/app/setup/connection/success'], {queryParams: this.queryParams});
        return;
      default:
        break;
    }
  }

}
