import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { BallAtomSpinnerComponent } from '../ball-atom-spinner/ball-atom-spinner.component';
import { SpinnerService } from '../spinner.service';

@Component({
    selector: 'app-square-loader-spinner',
    templateUrl: './square-loader-spinner.component.html',
    styleUrls: ['./square-loader-spinner.component.scss'],
    standalone: false
})
export class SquareLoaderSpinnerComponent extends BallAtomSpinnerComponent implements OnInit {

  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) { super(spinnerService, themeService); }

  ngOnInit(): void { }
}
