
import { Injectable, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { PageService } from '../../bloom/services/page-service.service';

@Injectable()
export class SsrInterceptor implements HttpInterceptor {
  constructor(
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: any,
    private pageService: PageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip certain requests that might cause hydration issues
    if (req.method === 'OPTIONS' || req.url.includes('/health-check') || req.url.includes('/assets/')) {
      return next.handle(req);
    }

    // Generate a unique key using the URL and stringified request body
    const uniqueKey = this.hashKey(`${req.method}_${req.url}_${JSON.stringify(req.body || {})}`);
    const stateKey = makeStateKey<any>(uniqueKey);

    if (isPlatformBrowser(this.platformId)) {
      // Skip using cached responses for prerendered pages to ensure fresh data
      if (this.pageService.isPrerendered) {
        console.log("[SSR] Skipping cache for prerendered page:", req.url);
        return next.handle(req);
      }

      const cachedResponse = this.state.get(stateKey, null);
      if (cachedResponse) {
        // Ensure we have a valid cached response
        if (!cachedResponse.body && !cachedResponse.status) {
          console.warn("[SSR] Invalid cached response for:", req.url);
          return next.handle(req);
        }

        console.log("[SSR] Retrieved Cached Response for:", req.url);

        // Ensure headers are correctly formatted
        const headers = new HttpHeaders(cachedResponse.headers || {});

        // Remove from state after a delay to prevent race conditions
        setTimeout(() => this.state.remove(stateKey), 100);

        return of(new HttpResponse({
          body: this.ensureSerializable(cachedResponse.body),
          status: cachedResponse.status || 200,
          statusText: cachedResponse.statusText || 'OK',
          headers: headers,
          url: cachedResponse.url || req.url
        }));
      }
    }

    return next.handle(req).pipe(
      tap(event => {
        if (isPlatformServer(this.platformId) && event instanceof HttpResponse) {
          try {
            // Ensure we can safely serialize the response
            const safeBody = this.ensureSerializable(event.body);
            const safeHeaders = event.headers?.keys().reduce((acc, key) => {
              const values = event.headers.getAll(key);
              if (values) acc[key] = values;
              return acc;
            }, {} as any);

            // Store response in transfer state
            this.state.set(stateKey, {
              body: safeBody,
              status: event.status,
              statusText: event.statusText,
              headers: safeHeaders,
              url: event.url
            });
            console.log("[SSR] stored for url:", event.url);
          } catch (err) {
            console.error("[SSR] Failed to store response:", err);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("❌ HTTP Error:", error);
        return throwError(() => error);
      })
    );
  }

  // Simple hash function to create consistent keys
  private hashKey(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return 'key_' + Math.abs(hash).toString(36);
  }

  // Ensure the object can be safely serialized
  private ensureSerializable(obj: any): any {
    if (!obj) return obj;
    if (typeof obj !== 'object') return obj;

    try {
      // Test if object can be serialized
      JSON.stringify(obj);
      return obj;
    } catch (e) {
      console.warn("[SSR] Object cannot be serialized, returning simplified version");
      // Return a simplified version that can be serialized
      if (Array.isArray(obj)) {
        return obj.map(item => this.ensureSerializable(item));
      } else {
        const result: any = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key) &&
              typeof obj[key] !== 'function' &&
              key !== '__proto__' &&
              key !== 'constructor') {
            try {
              result[key] = this.ensureSerializable(obj[key]);
            } catch (err) {
              result[key] = '[Unserializable]';
            }
          }
        }
        return result;
      }
    }
  }
}
