import { Component, OnInit, Output, ViewChild, EventEmitter, SimpleChanges, OnChanges, ElementRef, DoCheck, Injector } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators'
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { WidgetMetaTransformationService } from 'src/app/bloom/services/widget-meta-transformation.service';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.css'],
    standalone: false
})
export class AutoCompleteComponent extends BaseWidgetComponent implements OnInit, OnChanges, DoCheck {

  contextMenuActions: any;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  availableOptions: any[] = [];
  private destroy:any = new Subject();

  // value: string = ""
  oldValue: string
  // fc = new FormControl('')
  preparingOptions: boolean = false

  @ViewChild('menuTrigger') autocompleteMenuTrigger: MatMenuTrigger
  @ViewChild('acInput') acInput: ElementRef

  @Output() selectionChange = new EventEmitter<any>();
  validationSubscription: any;

  dynamicOptionsError: any;

  constructor(
    // public expressionUtility: ExpressionUtility,
    private widgetUtilityService: WidgetUtilityService,
    private _snackBar: MatSnackBar,
    // public actionManager: ActionManager,
    public validationService: ValidationService,
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public injector: Injector,
    public wmtService: WidgetMetaTransformationService,
  ) {
    super(metaService, pageService, resourcePermissionService, wmtService, injector)
  }

  private getActionManager(): ActionManager {
    return this.injector.get(ActionManager);
  }

  private getExpressionUtility(): ExpressionUtility {
    return this.injector.get(ExpressionUtility);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions();

      this.oldValue = this.widgetMeta.config?.value?.value ? JSON.stringify(this.widgetMeta.config?.value?.value) : ""
      this.initForm()
      // console.log("calling from onChanges()")
      this.generateAvailableOptions()
    }
  }

  ngDoCheck(): void {
    // //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    // //Add 'implements DoCheck' to the class.
    // let newValue = this.widgetMeta.config?.value?.value || ""
    // if(newValue != this.oldValue){
    //   // console.log("oldValue", this.oldValue)
    //   // console.log("vlaueChange", newValue)
    //   this.oldValue = JSON.parse(JSON.stringify(newValue))
    //   // this.value = newValue
    //   // console.log("calling from doCheck")
    //   this.setValue()
    // }
  }

  ngOnInit(): void {
    super.ngOnInit()

    // console.log("calling from onInit()")
    this.initForm()
    this.generateAvailableOptions()

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          // console.log("valueChange", value);
          if(typeof value == 'string'){
            return this._filter(value)
          }else if(value && typeof value == 'object' && typeof value.name == 'string'){
            return this._filter(value.name)
          }else{
            return []
          }
        })
      )

    
    this.setValueNotifierSub = this.pageService.setValueNotifier.subscribe(widgetMeta => {
      if (widgetMeta.id !== this.widgetMeta.id) return
      // console.log("set value notifier in AC", widgetMeta)
      let newValue = this.widgetMeta.config?.value?.value || ""
      if(newValue != this.oldValue){
        this.oldValue = JSON.parse(JSON.stringify(newValue))
        this.setValue()
      }
    })

    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.myControl.markAsTouched()
      }
    })

    //set the value if default expression enabled
    if (this.widgetMeta.config?.expressionConfig?.id  && !this.widgetMeta.config?.value?.value) {
      let value = this.getExpressionUtility().resolveExpression(this.widgetMeta.config?.expressionConfig);
      this.widgetMeta.config.value.value = value;
    }
  }

  initForm(){
    if(this.widgetMeta.config?.required?.value){
      this.myControl.addValidators(Validators.required);
    }
    // this.myControl.patchValue(this.widgetMeta.config?.value?.value || '')
    this.setValue()
    this.myControl.updateValueAndValidity()
    if (!this.widgetMeta.config.viewOnly.value) {
      this.myControl.enable()
    }
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    this.validationSubscription?.unsubscribe();
  }

  //------------------------------------ METHODS -------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit"
        // "addOption"
      ]
    }
    if(this.widgetMeta.config.appearance){
      this.contextMenuActions.actions.unshift('appearance')
      this.contextMenuActions['appearance'] = {
        value: this.widgetMeta?.config.appearance.value,
        type: this.widgetMeta?.config.appearance.type
      }
    }
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    // console.log("action is", event)
    // console.log("widgetMeta", this.widgetMeta)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case 'updateStyles':
        if (event?.data) {
          this.widgetMeta = event.data;
        }
        this.generateStyles();
        if (!event.noEmit)
        this.newWidgetMeta.emit(this.widgetMeta);
        break;

      case 'settingsChanged':
        this.widgetMeta = event.data
        // console.log("calling from action()")
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;

      case "newOption":
        if(event.returnData && typeof event.returnData == 'string'){
          this.addOption(event.returnData)
        }
        this.autocompleteMenuTrigger.closeMenu();
        break;

      case "removeOption":
        let temp: any = []
        this.widgetMeta.config.availableOptions.staticOptions.forEach(option => {
          if (option !== event.returnData) {
            temp.push(option)
          }
        });
        this.widgetMeta.config.availableOptions.staticOptions = temp

        // this.newWidgetMeta.emit(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        this.autocompleteMenuTrigger.closeMenu();
        break;
      default:
        break;
    }
  }

  displayFn(option) {
    return option && option.name ? option.name : ''
  }

  // onFocus(){
  //   console.log("focus detected: value:", this.acInput.nativeElement.value)
  //   console.log("patching:", this.acInput.nativeElement.value)
  //   this.myControl.patchValue(this.acInput.nativeElement.value)
  //   // this.filteredOptions = this._filter(this.acInput.nativeElement.value)
  // }

  // onInput(){
  //   console.log("input detected: value:", this.acInput.nativeElement.value)
  //   this.myControl.patchValue(this.acInput.nativeElement.value)
  // }

  private _filter(value: any): any[] {
    // console.log("filter value", value)
    // console.log("options", this.availableOptions)
    const filterValue = value.toLowerCase();
    return this.availableOptions.filter(option => {
      return option && option.name && typeof option.name == 'string' && option.name.toLowerCase().includes(filterValue)
    });
  }

  onDelete() {
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.autocompleteMenuTrigger.closeMenu();
  }

  private addOption(option: string){
    let optionObject = {
      name: option,
      value: option
    }
    this.widgetMeta.config.availableOptions.staticOptions.push(optionObject)
    // console.log("calling from addOption()")

    this.generateAvailableOptions(true)
    // this.newWidgetMeta.next(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
    this._snackBar.open("option added", '', {duration: 1500})
  }

  removeOption(i: number){
    this.widgetMeta.config.availableOptions.staticOptions.splice(i, 1)
    // console.log("option removed")
    // console.log("calling from removeOption()")

    this.generateAvailableOptions(true)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  onClick() {
    if (!this.builderMode) {
      return
    }

    // console.log("autocomplete clicked")
    if (!this.builderMode) {
      this.autocompleteMenuTrigger.closeMenu();
    }
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  async onSelect(event){
    event.type = "select";
    let res = await this.executeAction(event)
  }

  async executeAction(e) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    // if (!this.builderMode){
      let res = await this.getActionManager().executeActions(this.widgetMeta.actionConfig.actions, e);
      return res
    // }
  }

  selectionHandler(event) {
    this.widgetMeta.config.value.value = event.option.value.value
    this.setValue()
  }

  emitUserInput(valueMap){
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: valueMap.value,
      validity: this.isInputValid(valueMap.value)
    }
    this.userInputReceived.emit(userInput);
  }


  async generateAvailableOptions(noRefetch: boolean = false){

    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableOptions.filter(opt => opt.type == 'dynamic')
    } else {
      if(this.widgetMeta.config.availableOptions.dynamicOptions.enabled){
        this.preparingOptions = true
        this.dynamicOptionsError = ''
        // this.myControl.disable()
        if(!this.widgetMeta.config.availableOptions.dynamicOptions?.userData){
          try {
            rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode);
          } catch(e) {
            console.log("error fetching dynamic options", e)
            this.dynamicOptionsError = "Could not fetch dynamic options"
            this.preparingOptions = false
            // this.myControl.disable()
          }
        } else {
          rawDynamicOptions = await this.widgetUtilityService.getBloomUsers();
        }
        dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
        this.preparingOptions = false
        // this.myControl.enable()
      }
    }

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    let newOptions: any[] = []
    newOptions = newOptions.concat(staticOptions)
    newOptions = newOptions.concat(dynamicOptionItems)
    this.availableOptions = newOptions

    let defaultOption = this.availableOptions.find(option => option.default)
    if(defaultOption){
      if (this.widgetMeta.config.value.value == undefined || this.widgetMeta.config.value.value == null || this.widgetMeta.config.value.value == '') {
        this.widgetMeta.config.value.value = defaultOption.value
      }
      this.setValue()
    } else {
      this.setValue()
    }

    // console.log("options", this.availableOptions)
  }

  setValue(){
    let selectedOptIndex = this.availableOptions.findIndex(op => op.value == this.widgetMeta.config.value.value)
    if(selectedOptIndex > -1){
      this.myControl.patchValue(this.availableOptions[selectedOptIndex])
    } else if (this.widgetMeta.config.value.value !== undefined && this.widgetMeta.config.value.value !== null && this.widgetMeta.config.value.value !== ''){
      let newTempOption = {
        name: this.widgetMeta.config.value.value,
        value: this.widgetMeta.config.value.value
      }
      this.availableOptions.push(newTempOption)
      this.myControl.patchValue(newTempOption)
    } else {
      this.myControl.patchValue('')
    }

    this.emitUserInput({value: this.widgetMeta.config.value.value})
  }

  isInputValid(value) {
    if (this.widgetMeta.config.required?.value) {
      return value?.length ? true : false
    } else {
      return true
    }
  }

  clearSelection(e) {
    e.stopPropagation()
    this.widgetMeta.config.value.value = ''
    this.setValue()
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }
}
