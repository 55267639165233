import { Injectable, Injector } from "@angular/core";
import { ApplicationAction } from "./ApplicationAction";
import { BaseAction } from "./BaseAction"
import { NavigationAction } from "./NavigationAction";
import { WidgetAction } from "./WidgetAction";
import { FlowAction } from "./FlowAction";
import { HttpCacheService } from "src/app/core/services/HttpCacheService";
import { ActionServiceUtility } from "./ActionServiceUtility";
import { ContextDataService } from "src/app/shared/services/context-data.service";


@Injectable({
    providedIn: 'root'
  })


export class ActionManager {

    constructor(
        // public navigationAction: NavigationAction,
        // public applicationAction: ApplicationAction,
        // public widgetAction : WidgetAction,
        // public flowAction: FlowAction,
        private httpCacheService: HttpCacheService,
        // private actionServiceUtility: ActionServiceUtility,
        // private contextDataService: ContextDataService,
        private injector: Injector
    ){}

    private getContextDataService(): ContextDataService {
      return this.injector.get(ContextDataService);
    }

    private getActionServiceUtility(): ActionServiceUtility {
      return this.injector.get(ActionServiceUtility);
    }

    private getNavigationAction(): NavigationAction {
      return this.injector.get(NavigationAction);
    }

    private getApplicationAction(): ApplicationAction {
      return this.injector.get(ApplicationAction);
    }

    private getWidgetAction(): WidgetAction {
      return this.injector.get(WidgetAction);
    }

    private getFlowAction(): FlowAction {
      return this.injector.get(FlowAction);
    }


    registry = {
        navigation: () => this.getNavigationAction(),
        application: () => this.getApplicationAction(),
        widget: () => this.getWidgetAction(),
        flow: () => this.getFlowAction()
    }
    getAction(type: string, options?: any): BaseAction {
        // switch(type){
        //     case "navigation": {
        //         return this.navigationAction;
        //     }
        // }
        let actionService = this.registry[type]();
        return actionService;
        // if (!actionFunction) throw "No action available: " + type;
        // return actionFunction(options);
        // return ActionRegistry.getAction(type, options);
    }


    getActions(){
        let result: any[] = []
        var keys =  Object.keys(this.registry);
        for(var k in keys){
            var macroDescriptor = this.getAction(keys[k]).getActionDetail();
            result.push(macroDescriptor)
        }
        return result;
    }

    async executeActions(actions, event, options?){
      var actionResponse = [];
      let index = 0;
      this.getContextDataService().clearContext();

      for (let key in actions){
        let action = actions[key];
        if(action?.active === false) continue;
        let isConditionSatisfy = await this.getActionServiceUtility().checkActionCondition(action, options)

        let res;
        action.code = `action_${index + 1}`;
        console.log("isConditionSatisfy", isConditionSatisfy);
        if(isConditionSatisfy) res = await this.getAction(action.action).doAction(action, event, options);
        else res = {status: "SKIPPED", message: "Conditions not matched"}
        if(res) actionResponse.push(res?.status);
        index++;
      }

      //clearing all stored box list cache
      this.httpCacheService.deleteCacheBasedOnKeyPrefix("box_get_");
      if(actionResponse.length) return actionResponse

    }
}
