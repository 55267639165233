import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';


@Component({
    selector: 'action-condition-popup',
    templateUrl: './action-condition-popup.component.html',
    styleUrls: ['./action-condition-popup.component.scss'],
    standalone: false
})
export class ActionConditionComponent implements OnInit {

  widgetManager: any = WidgetManager;

  spinner:boolean = false;
  conditionOperators:any = [
    {name:'all', operator: "&&"}, {name:'any', operator: "||"}
  ]
  systemFields: any = [];
  sourceFields: any = [];
  whitelabeledFields: any = [];
  isActionDisable: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public dialogRef: MatDialogRef<ActionConditionComponent>,
    private dialog: MatDialog,
    public systemFieldsService: SystemFieldsService,
    public metaService: MetaService,
    public widgetService: WidgetService,
  ) {


   }

  async ngOnInit() {
    console.log("dataInput", this.dataInput.action);
    if(!this.dataInput.action.condition){
      this.dataInput.action.condition = {
        conditionOperator: {
          name: "all",
          operator: "&&"
        },
        conditions: []
      }
    }

    this.metaService.pageMeta.subscribe(pageMeta => {
      console.log("pageMeta--->", pageMeta)
      // this.pageMeta = pageMeta
      if(pageMeta) this.manageSourceFields(pageMeta);
    })
  }

  filterChanged(e, condition){
    console.log("e", e, condition)
    this.dataInput.action.condition.conditions = e.filterItems;
  }

  compareOptions(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.value === option2.value : option1 === option2;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  filtersValid(e){
    this.isActionDisable = !e;
  }

  onSave(){
    this.dialogRef.close({action: this.dataInput.action});
  }

  async getSystemFields(){
    let res = await this.systemFieldsService.getSystemFields();
    for (let index = 0; index < res?.fields?.length; index++) {
      const field = res?.fields[index];
      this.systemFields.push(res[field])
    }
    console.log("SYSTEM FIELDS: ",this.systemFields);
  }

  manageSourceFields(pageMeta){
    let te = new TemplateEngine();
    this.sourceFields = [];
    pageMeta?.panels?.forEach(panelMeta => {
      let panel = panelMeta;
      let widgets = this.widgetService.getWidgetsFromPanel(panel);
      widgets.forEach((widget ) => {

        if(WidgetManager.getWidget(widget.type)?.getMappingConfig(this.dataInput.widget)){
          let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
          let data = {
            panel: panel,
            widget: widget
          }
          this.sourceFields.push(te.fillTemplateObject(config, data))
        }

        if(widget.type == "connection" && WidgetManager.getWidget(widget.type)?.getMappingConfig(this.dataInput.widget)){
          let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
          let data = {
            panel: panel,
            widget: widget
          }
          this.whitelabeledFields.push(te.fillTemplateObject(config, data));
          console.log("this.whitelabeledFields", this.whitelabeledFields)
        }
      })
    })
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

}
