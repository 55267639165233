import { Widget } from "./baseWidget";
import { ChartDataSource } from "./chartDataSource";
import { BarChartConfig } from "./chartTypeConfigs/barChartConfig";
import { LineChartConfig } from "./chartTypeConfigs/lineChartConfig";
import { PieChartConfig } from "./chartTypeConfigs/pieChartConfig";
import { GaugeChartConfig } from "./chartTypeConfigs/gaugeChartConfig";
import { ScorecardConfig } from "./chartTypeConfigs/scorecardConfig";
import { TextFormatting } from "./textFormat";

export class Chart extends Widget {
  type = 'chart';
  gridX = 6
  gridY = 3
  minGridX = 2
  minGridY = 2
  initialPixelHeight = 0
  config = {
    props: [
      "chartType",
      "dataSource"
    ],
    chartType: {
      displayName: 'Chart Type',
      value: 'pie',   // pie || bar || gauge || line || scorecard
      type: 'select',
      availableTypes: [
        "pie",
        "bar",
        "gauge",
        "line",
        "scorecard"
      ],
      // toPersist: ['value', "availableTypes"]
    },
    dataSource: ChartDataSource,
  };
  textFormat = undefined

  constructor(id, name, chartType: string = 'bar'){
    super(id, name)
    // console.log("this", this)
    this.attachCommonConfig(this)

    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    //set default text formatting
    this.textFormat.fontSize.value = 18
    this.textFormat.color.value = '#444444'
    delete this.textFormat.underline
    this.textFormat.props.splice(this.textFormat.props.findIndex(prop => prop == 'underline'), 1)

    this.config.chartType.value = chartType
    switch(chartType) {
      case 'pie':
        this.attachExtraConfig(PieChartConfig)
        this.config.chartType.value = 'pie'
        break;
      case 'bar':
        this.attachExtraConfig(BarChartConfig)
        this.config.chartType.value = 'bar'
        break;
      case 'line':
        this.attachExtraConfig(LineChartConfig)
        this.config.chartType.value = 'line'
        break;
      case 'gauge':
        this.attachExtraConfig(GaugeChartConfig)
        this.config.chartType.value = 'gauge'
        break;
      case 'scorecard':
        this.attachExtraConfig(ScorecardConfig)
        this.config.chartType.value = 'scorecard'
        this.gridX = 3
        break;
      }
      // console.log("unique props assigned, this.config.dataSource", this.config.dataSource)
  }

  attachExtraConfig(source){
    if(source?.dataSource){
      Object.assign(this.config.dataSource, source.dataSource)
    }
    source?.otherProps?.props?.forEach(prop => {
      this.config.props.push(prop)
      this.config[prop] = source.otherProps[prop]
    });

    if(source.otherProps['precision']){
      this.config['precision'] = source.otherProps.precision
    }
  }

  public getWidgetConfig(){
    let map = {
      valuePath: ""
    }
    return map;
  }
}
