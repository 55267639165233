import { Widget } from "./baseWidget";
import { TextFormatting } from './textFormat'

export class Link extends Widget {
  type = 'link';
  gridX = 4
  minGridX = 3

  config = {
    props: [
      "linkText",
      'tooltip',
      'alignment'
    ],
    linkText: {
      displayName: "Link Text",
      value: "clickable link",
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: this.type,
      type: 'text',
      enabled: true
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      resetValue: 'flex-start',
      type: 'app-alignment-settings'
    },
  };

  textFormat;

  constructor(id: number, name: string) {
    super(id, name);
    this.attachCommonConfig(this)
    //user navigation type linkUrl if in bloom
    if (window.location.href.includes("/bloom")) {
      this.config['value'].resetValue = '';
      this.config['value'].value = '';
      this.config['value'].type = 'navigation';
      this.config['value'].parameters = [],
      this.config['value'].pageType = ""
    }
    this.config['value'].displayName = 'Link URL';
    // initialize default text formatting
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    this.config['title'].value = "Click Here"

    // add widget specific text formatting
    this.textFormat.color.value = '#0000EE'
    this.textFormat.underline.value = true
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.value.value"
    }
    return map;
  }
}

