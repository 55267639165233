import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MappingSetupComponent } from './mapping-setup.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { FieldPickerModule } from 'src/app/shared/field-picker/field-picker.module';
import { FilterConfigModule } from 'src/app/shared/query-components/filter-config/filter-config.module';
import { MappingDrillDownDialog } from './mapping-drill-down/mapping-drill-down.dialog';
import { MappingAddAttributeDialog } from './mapping-add-attribute/mapping-add-attribute.dialog';
import { ClientPlatformPipesModule } from 'src/app/client-platform/common-pipes/client-platform-pipes.module';
import { FilterComponentModule } from '../query-components/filter-component/filter-component.module';
import { BoxConfigInputsModule } from 'src/app/bloom/box-config-inputs/box-config-inputs.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [MappingSetupComponent, MappingDrillDownDialog, MappingAddAttributeDialog],
  imports: [
    FieldPickerModule,
    CommonModule,
    SharedModule,
    SpinnerModule,
    ClientPlatformPipesModule,
    FilterConfigModule,
    FilterComponentModule,
    BoxConfigInputsModule,
    QuillModule.forRoot(),
  ],
  exports: [MappingSetupComponent, MappingDrillDownDialog, MappingAddAttributeDialog]
})
export class MappingSetupModule { }
