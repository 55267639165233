import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { MetaService } from '../../services/meta-service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-bloom-collaboration',
    templateUrl: './bloom-collaboration.component.html',
    styleUrls: ['./bloom-collaboration.component.scss'],
    standalone: false
})
export class BloomCollaborationComponent implements OnInit, OnDestroy {

  isShowInvite: boolean = false;
  bloomMap: any;
  workspaceMap: any;
  access_type: any = "private";
  require_login: boolean = true;
  availableRoles: any = [];
  restoreInput: number = 0;
  isMemberReady:boolean = false;
  pageStructureSubscription: Subscription;
  pageData: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public metaService: MetaService,
    public dialog: MatDialogRef<BloomCollaborationComponent>,
    public spinner: SpinnerService,
    public adminService: AdminService,
    public connectionService: ConnectionService,
    public resourcePermission: ResourcePermissionService,
    public permission: PermissionService,
    private ngZone: NgZone,
    public authService: AuthServiceService
  ) { }

  resetLoading(){

    this.restoreInput += 1

  }

  async ngOnInit() {
    console.log("isShowInvite", this.isShowInvite);
    this.bloomMap = this.dataInput.bloomMap;
    this.workspaceMap = this.dataInput.workspaceMap;
    console.log("workspaceMap", this.workspaceMap);
    this.spinner.show();
    if(!this.bloomMap.landing_page_type) this.bloomMap.landing_page_type = "default";
    if(this.bloomMap) {
      await this.delinkBloomPermissions();
      await this.resourcePermission.setBloomPrivilages(this.bloomMap, this.authService.userProfile);
    }
    // if(this.connectionService.selectedWorkSpaceMap) this.workspace = this.connectionService.selectedWorkSpaceMap;
    var defaultRoles = await this.adminService.listRole(1, 500, this.connectionService.preAuthenticatedToken, {filter: "resource_type=default|string"})

    var bloomRoles = await this.adminService.listRole(1, 500, this.connectionService.preAuthenticatedToken, {filter: `resource_type=bloom|string,resource_id=${this.bloomMap.code}|string`})
    var availableRoles = defaultRoles.data.concat(bloomRoles.data);
    this.availableRoles = availableRoles;

    this.pageStructureSubscription = this.metaService?.get_page_structure.subscribe(page_structure => {
      if (page_structure && Array.isArray(page_structure.pages)) {
        page_structure?.pages?.forEach(code => {
          let page: any = {
            name: page_structure[code].name,
            code: page_structure[code].code
          }
          this.pageData.push(page);
        });
      }
      if(!this.bloomMap.landing_page) this.bloomMap.landing_page = page_structure?.homePageCode;
    })

    if(!this.bloomMap.hasOwnProperty("require_login")) this.bloomMap.require_login = true;
    if(!this.bloomMap.hasOwnProperty("access_type")) this.bloomMap.access_type = "private";
    this.spinner.hide();
  }

  async onAccessChange(){
    console.log("checked", this.bloomMap);
    if(this.bloomMap.access_type == 'private'){
      this.bloomMap.require_login = true;
    }
    await this.metaService.updateBloom(this.bloomMap);
  }

  async onLandingChange(){
    console.log("checked", this.bloomMap);
    await this.metaService.updateBloom(this.bloomMap);
  }

  async onPageSelected(){
    await this.metaService.updateBloom(this.bloomMap);
  }

  async delinkBloomPermissions(){
    if(this.bloomMap.isPermissionDelinked) {
      console.log("inheriting permissions", this.bloomMap);
      this.isMemberReady = true;
      return;
    }
    this.spinner.show();

    try{
      if(this.permission.roleMap && Object.keys(this.permission.roleMap).length == 0) await this.permission.listRole()
      await this.resourcePermission.inheritWorkspacePermissions(this.workspaceMap._id, this.bloomMap.code, "bloom");
      this.bloomMap.isPermissionDelinked = true;
      await this.metaService.updateBloom(this.bloomMap);
      this.isMemberReady = true;
    } catch(e){
      console.log("error while inheriting permissions", e)
    }

    this.spinner.hide();
  }

  trackById(index: number, item: any): any {
    return item._id || index; 
  }

  trackByCode(index: number, item: any): any {
    return item.code || index; 
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }
  ngOnDestroy(): void {
    this.pageStructureSubscription?.unsubscribe()
  }
}
