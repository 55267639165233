<div class="outerCover" *ngIf="builderMode" [ngClass]="{ selectedNow: selectedWidgetId == widgetMeta.id, hoveredNow: selectedWidgetId != widgetMeta.id && hoveredNow }"
  (mouseenter)="widgetMouseenter()" (mouseleave)="widgetMouseleave()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="selectedWidgetId !== widgetMeta.id && !hoveredNow"></widget-drag-handle>
  <div class="innerCover" [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [showControls]="hoveredNow" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>

    <mat-form-field [class]="getFormFieldClass()"
      [floatLabel]="widgetMeta?.config?.floatLabel?.value || 'auto'"
      [appearance]="widgetMeta.config.appearance?.value">
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.label?.value || widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
      <textarea 
        (click)="onClick()" 
        matInput #input
        [(ngModel)]="widgetMeta.config.value.value"
        [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false"
        [placeholder]="widgetMeta.config.placeholder.value"
        [attr.rows]="rowsValue" (input)="emitUserInput()"
      ></textarea>
    </mat-form-field>
  </div>
</div>

<div class="outerCover" *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>

    <mat-form-field class="input"
      (mousedown)="$event.stopPropagation()" fxFlexFill [class]="getFormFieldClass()"
      [floatLabel]="widgetMeta?.config?.floatLabel?.value || 'auto'"
      [appearance]="widgetMeta?.config?.appearance?.value">
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.label?.value || widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
      <textarea 
        matInput #input 
        [formControl]="fc"
        [(ngModel)]="widgetMeta.config.value.value" 
        [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false"
        [placeholder]="widgetMeta.config.placeholder.value"
        [attr.rows]="rowsValue" (input)="emitUserInput()"
      >
      </textarea>
      <mat-error *ngIf="fc.invalid && fc.hasError('required')">This field is required</mat-error>
      <mat-hint *ngIf="widgetMeta.note">
        <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
        <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
      </mat-hint>
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    {{widgetMeta.config.value.value}}
  </div>
</div>
