import { Location } from '@angular/common';
import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { ObjectExplorerService } from './object-explorer.service';
import { InputparameterDialogComponent } from 'src/app/modules/organization/connection/connection-explorer/inputparameter-dialog/inputparameter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BoxService } from '../services/box.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { CollectFnOptions } from './collect-fn-options/collect-fn-options.component'
import { PublishService } from '../services/publish.service';
import { ListPublishingDialogComponent } from '../list-publishing-dialog/list-publishing-dialog.component';
import { CustomObjectAttributeDialogComponent } from 'src/app/modules/organization/connection/connection-explorer/custom-object-attribute-dialog/custom-object-dialog.component';
import { AuthServiceService } from '../services/auth-service.service';
import { ThemeService } from '../services/theme.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { ConnectionExplorerDeleteRecordDialogComponent } from 'src/app/modules/organization/connection/connection-explorer/connection-explorer-delete-record-dialog/connection-explorer-delete-record-dialog.component';
import { StarchService } from '../services/starch.service';
import { FormPublishingDialogComponent } from '../form-publishing-dialog/form-publishing-dialog.component';
import { filter, takeUntil } from 'rxjs/operators';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'object-explorer',
    templateUrl: './object-explorer.component.html',
    styleUrls: ['./object-explorer.component.scss'],
    standalone: false
})
export class ObjectExplorerComponent implements OnInit, AfterViewInit {

  routerSubscription: Subscription
  currentUrl: any
  objectId: string;
  object: any;  // object details
  connection: any;
  base: any;
  box: any;
  objectActions: any[] = []
  boxActions: any[] = []
  attributes: any[] = []
  getAttributesFunction: any
  getFunction: any;
  updateFunction: any;
  deleteFunction: any;
  attributeOptions: any
  attrOptionsObj: any = {}
  collectAttrOptions: boolean = false
  objectData: any[] = []
  getFnOptions: any = {}
  showPublishedViewsList: boolean = false;
  currentBaseUrl: string = '';
  attributePanelMeta: any = {
    type: "listpanel",
    viewTypes: {
      defaultView: "table",
    },
    listPanelTitle: ' ',
    formatWidgetHeaderInTitleCase: true,
    listAttributes: [
      {
        "widgetType": "label",
        "columnName": "#",
        "sortEnabled": false,
        "show_hide": false,
        "value": "__serial__",
        "dataType": "NA",
        "isColumnSelected": true,
        "name": "__serial__",
        "__id": "___serial__",
        "fieldType": "label"
      },
      {
        "widgetType": "icon",
        "columnName": "Required",
        "filterable": false,
        "sortable": false,
        "sortEnabled": false,
        "show_hide": false,
        "dataType": "boolean",
        "isColumnSelected": true,
        "name": "Required",
        "__id": "required",
        "fieldType": "attribute",
        "conditionalConfig": {
          "enabled": true,
          "attributeRelation": "",
          "displayRules": [
            {
                "compareValue": "true",
                "operator": "=",
                "result": "check",
                "tooltip": "Applicable",
                "color": "primary"
            }
          ]
        }
      },
      {
        "widgetType": "icon",
        "columnName": "Filterable",
        "filterable": false,
        "sortable": false,
        "sortEnabled": false,
        "show_hide": false,
        "dataType": "boolean",
        "isColumnSelected": true,
        "name": "Filterable",
        "__id": "filterable",
        "fieldType": "attribute",
        "conditionalConfig": {
          "enabled": true,
          "attributeRelation": "",
          "displayRules": [
            {
                "compareValue": "true",
                "operator": "=",
                "result": "check",
                "tooltip": "Applicable",
                "color": "primary"
            }
          ]
        }
      },
      {
        "widgetType": "icon",
        "columnName": "Sortable",
        "filterable": false,
        "sortable": false,
        "sortEnabled": false,
        "show_hide": false,
        "dataType": "boolean",
        "isColumnSelected": true,
        "name": "Sortable",
        "__id": "sortable",
        "fieldType": "attribute",
        "conditionalConfig": {
          "enabled": true,
          "attributeRelation": "",
          "displayRules": [
            {
                "compareValue": "true",
                "operator": "=",
                "result": "check",
                "tooltip": "Applicable",
                "color": "primary"
            }
          ]
        }
      },
      {
        "widgetType": "icon",
        "columnName": "Writable",
        "filterable": false,
        "sortable": false,
        "sortEnabled": false,
        "show_hide": false,
        "dataType": "boolean",
        "isColumnSelected": true,
        "name": "Writable",
        "__id": "writable",
        "fieldType": "attribute",
        "conditionalConfig": {
          "enabled": true,
          "attributeRelation": "",
          "displayRules": [
            {
                "compareValue": "true",
                "operator": "=",
                "result": "check",
                "tooltip": "Applicable",
                "color": "primary"
            }
          ]
        }
      },
      {
        "widgetType": "icon",
        "columnName": "Primary",
        "filterable": false,
        "sortable": false,
        "sortEnabled": false,
        "show_hide": false,
        "dataType": "boolean",
        "isColumnSelected": true,
        "name": "Primary",
        "__id": "primary",
        "fieldType": "attribute",
        "conditionalConfig": {
          "enabled": true,
          "attributeRelation": "",
          "displayRules": [
            {
                "compareValue": "true",
                "operator": "=",
                "result": "check",
                "tooltip": "Applicable",
                "color": "primary"
            }
          ]
        }
      }
    ],
  };

  dataListMeta: any;
  spinner: boolean = false
  authError: boolean = false
  customAttributeSupported: boolean = false
  actionResults: { action: any, result: any[], isExpanded: boolean, listPanelMeta?: any }[] = []  // array containing the results of all the executed functions
  openResultIndex: number = -1
  allActions: any[] = []
  connectionId: string;
  baseId: string;
  dependancyMap: any;
  showPublishedViewsFrom: boolean;

  isStarch: boolean = false
  isObjectFnSupported: boolean;
  refreshPanel: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    private connectionService: ConnectionService,
    private starchService: StarchService,
    private objectExplorerService: ObjectExplorerService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthServiceService,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private boxService: BoxService,
    private pageService: PageService,
    private publishService: PublishService,
    private location: Location,
    private widgetUtilityService: WidgetUtilityService,
    public clientPlatformService: ClientPlatformService,
    private snackbar: MatSnackBar
  ) {
    this.routerSubscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentUrl = decodeURI(event.url)
      console.log("--------------- router event decoded", this.currentUrl);
      if(this.currentUrl.split("?")[1]?.split("=")[0] == 'connectionid'){
        this.objectId = this.currentUrl?.split("/")[3]?.split("?")[0]
        this.connectionId = this.currentUrl.split("?")[1].split("=")[1]
      }else if(this.currentUrl.split("?")[1]?.split("=")[0] == 'baseid'){
        this.objectId = this.currentUrl?.split("/")[3]?.split("?")[0]
        this.baseId = this.currentUrl.split("?")[1].split("=")[1]
      }
      this.objectExplorerService.boxObjectId = this.objectId;
    });

    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
  }

  async ngOnInit(): Promise<void> {
    if (
      !this.connectionService.selectedWorkSpace &&
      !this.authService.loggedIn
    ) {
      // console.log("no workspace and not aauthed")
      await this.authService.authCheck();
      this.authService.authCheckPositive.pipe(takeUntil(this.destroy$)).subscribe(async (authStatus) => {
        //if logged in
        if (authStatus) {
          this.spinner = true;
          console.log('logged in');
          await this.themeService.loadTheme();
          await this.themeService
            .getExistingTheme(this.authService.profile._id)
            .then((res: any) => {
              if (res?.data != null) {
                console.log(res);
                this.themeService.settings_Id = res?.data[0]._id;
                let theme = res.data[0].themeSetting.theme;
                this.themeService.setTheme(theme);
                this.themeService.currentLocale = res.data[0].localeSetting;
                this.themeService.textDirection =
                  res.data[0].themeSetting.direction;
                if (res.data[0].themeSetting.direction == 'rtl') {
                  this.themeService.enableDirMode('rtl');
                }
              }
            });
          this.spinner = false;
          this.init()
        } else {
          this.authError = true;
          this.spinner = false;
          // this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top',
          // });
          // redirect to login page
          this.ngZone.run(() => {
            this.router.navigate(['../../'], { relativeTo: this.route });
          });

          return;
        }
      });
    } else {
      // console.log("this.connectionService.selectedWorkSpace", this.connectionService.selectedWorkSpace)
      // console.log("this.authService.loggedIn", this.authService.loggedIn)
      this.init()
    }


  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

  async getBox() {
    this.objectExplorerService.$parentBox.pipe(takeUntil(this.destroy$)).subscribe(async data => {
      this.box = data
    })

    if(!this.box) {
      try {
        this.box = await this.connectionService.getBox(this.connection?.box_id || this.base.storage_box);
        this.objectExplorerService.parentBox.next(this.box)
        console.log(this.box);
      } catch (error) {
        console.error('Could not fetch box :', error);
      }
    }
    console.log("box data received", this.box)
  }

  async getConnection() {
    this.objectExplorerService.$parentConnection.pipe(takeUntil(this.destroy$)).subscribe(async data => {
      this.connection = data
    })

    if(!this.connection || this.connectionId != this.connection._id) {
      try {
        this.connection = await this.connectionService.getConnection(this.connectionId);
        this.objectExplorerService.setConnectionData(this.connection)
        console.log(this.connection);
      } catch (error) {
        console.error('Could not fetch box :', error);
      }
    }
    console.log("connection data received", this.connection)
    if(this.connection.published_views?.[this.objectId]?.published_lists?.list_ids?.length){
      this.showPublishedViewsList = true
    }
    if(this.connection.published_views?.[this.objectId]?.published_forms?.list_ids?.length){
      this.showPublishedViewsFrom= true
    }
  }

  async getBase() {
    this.objectExplorerService.$parentBase.pipe(takeUntil(this.destroy$)).subscribe(async data => {
      this.base = data
    })

    if(!this.base) {
      try {
        this.base = await this.starchService.getStarchBase(this.baseId);
        this.objectExplorerService.setBaseData(this.base)
        console.log(this.base);
      } catch (error) {
        console.error('Could not fetch base :', error);
      }
    }
    console.log("base data received", this.base)
    // if(this.connection.published_views?.[this.objectId]?.published_lists?.list_ids?.length){
    //   this.showPublishedViewsList = true
    // }
  }

  async getObject(objectId: any) {
    this.objectExplorerService.$parentObject.pipe(takeUntil(this.destroy$)).subscribe(async data => {
      this.object = data
    })

    if(!this.object){
    try {
      let objects = []
      if(this.connectionId){
        objects = await this.boxService.getBoxObjects(
          this.connection._id,
          this.connection.box_id
        );
      }else if(this.baseId){
        objects = await this.starchService.getBaseObjects(this.base);
      }
      // if (this.box?.supports?.includes('customobject')) {
      //   objects.push({
      //     name: '__custom',
      //   });
      // }
      console.log('OBJECTS', objects);
      this.object = objects.find(obj => obj.__id == objectId)
      this.objectExplorerService.parentObject.next(this.object)
    } catch (error) {
      this.spinner = false
      console.error('Could not fetch objects :', error);
    }}
    console.log("object data received", this.object)
    this.checkCustomAttrSupport()
  }

  async init(){
    if(this.connectionId){
      await this.getConnection();
    }else if(this.baseId){
      await this.getBase()
    }
    await this.getBox();
    await this.getObject(this.objectId);

    await this.getBoxActions()
    await this.getObjectActions()
    await this.getAttributes()
    // await this.fetchObjectData(this.objectId)
  }

  checkCustomAttrSupport(){
    if(this.object?.supports?.includes('customattribute')){
      this.customAttributeSupported = true
    }
  }

  async getObjectActions(){
    if(this.base) return
    this.spinner = true
    try{
      let actions = await this.boxService.getAllActions(this.connection.box_id, this.connection.box_token, {}, "token")
      console.log("all actions", actions)
      this.allActions = actions
      this.objectActions = actions.filter(action => action.__id.split("/")[0] == this.objectId)
      // this.objectActions = await this.boxService.getObjectActions(this.connection.box_id, this.objectId, this.connection._id)
      console.log("object actions fetched", this.objectActions)
      this.getFunction = this.objectActions.find((fn) => fn.__id == `${this.objectId}/get`) //${this.objectId}/
      console.log("get fn", this.getFunction)
      this.updateFunction = this.objectActions.find((fn) => fn.__id == `${this.objectId}/update`) //${this.objectId}/
      this.deleteFunction = this.objectActions.find((fn) => fn.__id == `${this.objectId}/deleteById`) //${this.objectId}/
      console.log("update function", this.updateFunction)
      // if(this.getFunction) this.getFunction.__id = this.objectId + "/get"
      this.spinner = false
    }catch(e){
      this.spinner = false
      console.log("could not fetch object actions", e)
    }
  }

  async getBoxActions(){
    if(this.base) return
    this.spinner = true
    try{
      this.boxActions = await this.boxService.getBoxActions(this.connection.box_id, this.connection.box_token, "token")
      console.log("box actions fetched", this.boxActions)

      this.getAttributesFunction = this.boxActions.find((fn) => fn.__id == 'getattributes')
    this.spinner = false
    }catch(e){
      this.spinner = false
      console.log("could not fetch box actions", e)
    }
  }

  ngAfterViewInit(): void {

  }

  async getAttributes(){
    this.spinner = true
    // this.attrOptionsObj = {}

    if (this.connection) {
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttributesFunction)
      this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)
      console.log("get attributes options", this.attributeOptions)
      // if(this.attributeOptions?.length){
    }

    if(this.attributeOptions?.length){

      // open dialog and collect options
      var dialog = this.dialog.open(CollectFnOptions, {
        minWidth: '60%',
        data: {
          fn: this.getAttributesFunction,
          allfn: this.allActions,
          getAttributesFunction: this.getAttributesFunction,
          objectId: this.objectId,
          connection: this.connection,
          fnOptions: this.attributeOptions,
          isOnlyOptions: true
        },
      });
      let dialogRes = await dialog.afterClosed().toPromise();
      this.attributeOptions = dialogRes?.fnOptions
      this.dependancyMap = dialogRes?.dependencyMap
      console.log('attributeOptions', this.attributeOptions);
      // data['getFnOptions'] = this.getFnOptions

      this.attributeOptions.forEach(op => this.attrOptionsObj[op.__id] = op.value)
    }

    try{
      this.attributes = await this.executeFetchAttributes()
    }catch(e){
      console.log("error in fetching attributes", e)
    }
  }

  async executeFetchAttributes(){
    let attributes: any[] = []
    try{
      if(this.connection) {
        attributes = await this.boxService.getAttributes(this.connection.box_id, "", this.connection.__id, this.connection.box_token, this.attrOptionsObj, this.objectId);
      }else if (this.base) {
        let getAttrPayload = {
          object: this.object.__id,
          options: {
            relationObject: 'starch_relationship'
          }
        }
        attributes = await this.starchService.getBaseAttributes(this.base, getAttrPayload)
      }
      console.log("attributes fetched", attributes)
      if(attributes.length) this.objectExplorerService.boxObjectAttributes = attributes;
      this.spinner = false
      return attributes
    }catch(e){
      // console.log("error in fetching attributes")
      this.spinner = false
      throw e
    }
  }

  publishAction(type: string){
    if(type == 'list') this.publishList()
    if(type == 'form') this.publishForm()
  }

  async publishForm(){
    console.log("publish as form");
    var dialog = this.dialog.open(FormPublishingDialogComponent, {
      minWidth: '60%',
      disableClose: true,
      data: {
        action: "create",
        objectId: this.objectId,
        connection: this.connection,
        box: this.box
      },
    });

    let dialogRes = await dialog.afterClosed().toPromise();
    console.log("publish as form dialogRes", dialogRes);
  }

  async tabChanged(event: any){
    console.log("tab changed", event)
    if(event.index == 1){
      if(!this.objectData.length){
        await this.createDataListMeta()
      }
    } else if(event.index == 2){
      if(!this.objectActions.length){
        await this.getObjectActions()
      }
    }
  }

  async createDataListMeta() {
    console.log("fetch object data called")
    let getFnOptions: any[] = []
    let updateFnOptions: any = []
    if (this.connection) {
      getFnOptions = this.pageService.checkOptionsToCollect(this.getFunction)
      getFnOptions = getFnOptions.filter(op => !op.hidden)
      console.log("getFnOptions", getFnOptions)
      updateFnOptions = this.pageService.checkOptionsToCollect(this.updateFunction)
      updateFnOptions = updateFnOptions.filter(op => !op.hidden)
      console.log("updateFnOptions", updateFnOptions)
    }

    let data = {
      boxId: this.connection?.box_id || this.base.storage_box,
      boxObjectId: this.objectId,
      // connection: this.connection,
      // base: this.base,
      attributes: JSON.parse(JSON.stringify(this.attributes.filter(a => !a.__id.includes('.')))),
      hideTitle: true,
      userFilterEnabled: true,
      userSortEnabled: true,
      canSelectFields: true,
      pageSize: 20
    }

    if (this.connection) {
      data['connection'] = this.connection
    } else if (this.base) {
      data['base'] = this.base
      data['boxConfigToken'] = this.base.storage_token
    }

    this.attributes.forEach(attr => attr['isColumnSelected'] = false)
    let reccommended = this.widgetUtilityService.intelliSenseAttribute(this.attributes, 8, true)
    console.log("recoomended attributes", reccommended)
    data.attributes.forEach(attr => {
      let reccomendedAttr = reccommended.find(rAttr => rAttr.__id == attr.__id)
      if(reccomendedAttr) {
        attr['isColumnSelected'] = true

        // for zoho crm; nested attributes come as separate attributes. We club it together
        if (reccomendedAttr.children?.length) {
          console.log()
          let nestedProperties = []
          reccomendedAttr.children.forEach(childId => {
            nestedProperties.push({ path: childId, fieldType: 'string', widgetType: 'label'}) //, headerTitle: childId
          })
          attr['nestedProperties'] = nestedProperties
          attr['isDrillDown'] = true
        }

      } else attr['isColumnSelected'] = false
    })

    let primaryAttr = this.attributes.find(attr => attr.primary)
    if(primaryAttr){
      data['primaryAttribute'] = primaryAttr

      // if primary attribute is not already added, add it now
      if(!data.attributes.find(attr => attr.__id == primaryAttr.__id)){
        data.attributes.unshift(primaryAttr)
      }
    }

    // if no options to collect, create list panel directly
    if(getFnOptions?.length){
      var dialog = this.dialog.open(CollectFnOptions, {
        minWidth: '60%',
        data: {
          fn: this.getFunction,
          allfn: this.allActions,
          dependency: this.dependancyMap,
          // getAttributesFunction: this.getAttributesFunction,
          objectId: this.objectId,
          connection: this.connection,
          fnOptions: this.pageService.checkOptionsToCollect(this.getFunction)?.filter(op => !op.hidden),
          isOnlyOptions: true,
          hideTitle: true,
          // isSupportQuery : true
        },
      });
      let dialogRes = await dialog.afterClosed().toPromise();
      this.getFnOptions = dialogRes?.fnOptions
      this.dependancyMap = dialogRes?.dependencyMap
      console.log('get fn options', this.getFnOptions);
      if(Object.keys(this.getFnOptions).length < 1) return
      data['getFnOptions'] = this.getFnOptions
      data['attributeOptions'] = this.attributeOptions

      let primaryAttr = this.attributes.find(attr => attr.primary)
      if(primaryAttr){
        data['primaryAttribute'] = primaryAttr
      }
    }

    data = await this.attachIcons(data)
    data.attributes.unshift(
      {
        "widgetType": "label",
        "columnName": "#",
        "sortEnabled": false,
        "show_hide": false,
        "value": "__serial__",
        "dataType": "NA",
        "isColumnSelected": true,
        "name": "__serial__",
        "__id": "__serial__",
        "fieldType": "label"
      }
    )

    this.dataListMeta = this.publishService.generateListPanelMeta(data)
    this.dataListMeta.authMode = 'preauthenticated_token'
    this.dataListMeta.getFn = this.getFunction
    console.log("panel meta generated", JSON.parse(JSON.stringify(this.dataListMeta)))
  }

  /**
   * attaches edit and delete icons to the list panel
   * @param panelMeta
   */
  async attachIcons(data){
    let editIcon = {
      "__id": "edit",
      "name": "edit",
      "value": "edit",
      "columnName": "edit",
      "fieldType": "icon",
      "dataType": "NA",
      "widgetType": "icon",
      "eventType": "popup-edit",
      "updateFnOptions": [],
      "isColumnSelected": true
    }
    let deleteIcon = {
      "columnName": "Delete",
      "dataType": "NA",
      "fieldType": "icon",
      "name": "delete",
      "value": "delete",
      "widgetType": "icon",
      "__id": "delete",
      "isColumnSelected": true,
      eventType: 'action',
      actionConfig: {
        action: 'record-deletion',
        event: 'click'
      }
    }
    if(!this.objectActions.length) await this.getObjectActions()
    if (this.updateFunction) data.attributes.push(editIcon)
    if (this.deleteFunction) data.attributes.push(deleteIcon)
    // if(panelMeta.primaryAttribute && !panelMeta.listAttributes.find(attr => attr.__id == 'delete'))
    return data
  }

  editMethodAvailable(){

  }

  async rowSelected(event: any){
    console.log("row action", event)
    if (event?.actionId == 'deleteById') {
      // var deleteRecord = this.dialog.open(ConnectionExplorerDeleteRecordDialogComponent, {
      //   // width: '400px',
      //   minWidth: '30%'
      // });
      // var confirmedDelete = await deleteRecord.afterClosed().toPromise();
      var confirmedDelete = event?.deleteConfirmed;
      if (confirmedDelete){
        // for (let item = 0; item < this.attributeFetched.length; item++){
        //   if(Object.keys(this.attributeFetched[item]).includes('primary')) var id = this.attributeFetched[item].__id
        // }
        let result: any
        let primaryAttr = this.dataListMeta['primaryAttribute']
        console.log("id", primaryAttr.__id)
        console.log("rowDataRaw", event.rowDataRaw)
        if(primaryAttr.__id){
          let data = event.rowDataRaw[primaryAttr.__id];
          let payload = { parameters: {id:""} };
          payload.parameters.id = data;
          let actionId: string = this.dataListMeta.boxObjectId + '/' + event.actionId;
          console.log('PAYLOAD', payload);
          var executionResponse = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, actionId, payload)
          if(!executionResponse.error) {
            result = 'success'
            this.snackbar.open("Record deleted successfully", "", { duration: 3000 })
          } else {
            this.refreshPanel = true;
          }
          // this.dataListMeta = JSON.parse(JSON.stringify(this.dataListMeta))
        } else {
          this.snackbar.open("Can not delete record, unique key not present", "", {duration: 5000})
        }
      }
    }
  }

  // async getActions(box_id: any, connectionId?:any, options?: any, config?:any) {
  //   console.log("[ACTION SELECTION] getAllActions(): box_id", box_id, "connectionId", connectionId);
  //   let conType = null;
  //   if (config?.boxConfigToken){
  //     conType = 'token';
  //     connectionId = config.boxConfigToken;
  //     if(config?.base_box_id) box_id = config.base_box_id;
  //   }
  //   let res: any;
  //   try{
  //     res = await this.boxService.getAllActions(box_id, connectionId, options, conType)
  //   }catch(e){
  //     console.log("error occurred", e)
  //   }
  //   return res
  // }

  openView(item: any){
    console.log("need to open", item)
    window.open(this.currentBaseUrl + item.url, '_blank')
  }

  async publishList(){
    console.log("this.getFunction", this.getFunction);
    let action = JSON.parse(JSON.stringify(this.getFunction))

    action.__id = this.objectId + "/get"

    console.log("this.getArttrbutesfunciton:", this.boxActions.find(
      (fn) => fn.__id == 'getattributes'
    ));

    var dialog = this.dialog.open(ListPublishingDialogComponent, {
      minWidth: '60%',
      disableClose: true,
      data: {
        action: action,
        getAttributesFunction: this.boxActions.find(
          (fn) => fn.__id == 'getattributes'
        ),
        connection: this.connection,
      },
    });
    let result = await dialog.afterClosed().toPromise();
    console.log("result", result)
    return
  }

  goBack(){
    this.location.back()
  }

  async executeAction(selectedAction: any) {
    console.log("execute action", selectedAction)
    var action: any;
    this.isObjectFnSupported = await this.clientPlatformService.checkBoxSupportsObject(this.box.__id);
    for (action of this.objectActions) {
      if (action.name === selectedAction.name) {
        if (action.input?.list) {
          var dialog = this.dialog.open(InputparameterDialogComponent, {
            minWidth: '60%',
            data: {
              action: action,
              allfn: this.allActions,
              getAttributesFunction: this.getAttributesFunction,
              availableAttributes: this.attributes,
              connection: this.connection,
              objectId: this.objectId,
              isObjectFnSupported: this.isObjectFnSupported
            },
          });
          selectedAction.spin = true;
          result = await dialog.afterClosed().toPromise();
          // result.pop()

          // pop is required since the last element is the object with all the attributes (), not a data row
          // it is injected from InputparameterDialogComponent
          if (action.crudType == 'R') result.pop()

        } else {
          selectedAction.spin = true;
          var result = await this.boxService.executeBoxObjectFunction(
            this.connection._id,
            this.connection.box_id,
            action.__id
          );
          if(result && result?.data) result = result?.data
          console.log("result", result)
        }
        if (!Array.isArray(result) && typeof result == 'object') {
          console.log("if the received type is object", result, "put it inside array for standardized usage in list panel")
          result = [result]
        }
        this.actionResults.unshift({
          action: action,
          result: result,
          isExpanded: true
          // listPanelMeta: this.createListMetaForResult(action, result)
        })
        this.actionResults.forEach((item, i) => {
          if (i > 0) item.isExpanded = false
        })
        console.log("action results updated", this.actionResults)
      }
    }
    return result
  }

  deleteResult(i){
    this.actionResults.splice(i, 1)
  }

  //CustomAttribute Implementation
  async createCustomAttribute() {
    var dialog = this.dialog.open(CustomObjectAttributeDialogComponent, {
      // maxWidth: '70%',
      // minWidth: '30%',
      // maxHeight: 'auto',
      minWidth: '40%',
      data: {
        type: 'customAttribute',
        object: this.objectId,
        connection: this.connection,
        box: this.box,
        createAttributeFunction: this.boxActions.find(
          (fn) => fn.__id == 'createattribute'
        ),
      },
    });
    var result = await dialog.afterClosed().toPromise();
    console.log('CUSTOM ATTRIBUTE RESPONSE', result);

    // REFRESH SCHEMA
    await this.executeFetchAttributes()

  }

  // async createNewRecord(node){
  //   var rowDataObj = {};
  //   var result: any;
  //   for (let key in node.objectData[0]) rowDataObj[key] = null;
  //   console.log("rowDataObj value to be sent : ",JSON.parse(JSON.stringify(rowDataObj)))

  //   let hostPanel = new FormPanel(Date.now(), 'formPanel_Meta');
  //     if (event) {
  //       var dialog = this.dialog.open(ConnectionExplorerSidePanelComponent, {
  //         // maxWidth: '50%',
  //         // minWidth: '30%',
  //         // maxHeight: 'auto',
  //         minWidth: '40%',
  //         position: { right: '2.5%' },
  //         data: {
  //           meta: hostPanel,
  //           selectedRowData: rowDataObj,
  //           connection: this.connection,
  //           box: this.box,
  //           object: node.name,
  //           attributeFetched: this.attributeFetched ? this.attributeFetched : undefined,
  //           operationMode: 'save'
  //         },
  //       });
  //       result = await dialog.afterClosed().toPromise();
  //       if (result) result = result[0];
  //       console.log('CREATE ROW RESPONSE', result);
  //     }

  //     //refresh data source
  //   if (result == 'success') {
  //     this.initializeMeta(node);
  //     this.customAttributeSpinner = true;
  //     node.isIconChanged = false;
  //     var name = 'Get ' + node.name;
  //     for (var action of this.actions) {
  //       if (action.name == name) {
  //         var inputList = action.functions?.get?.input?.list || [];
  //         console.log('list', inputList);
  //         var payload = { parameters: {} };
  //         for (var j = 0; j < inputList.length; j++) {
  //           var list = inputList[j];
  //           let value: any = list.value || '';
  //           if (value) {
  //             console.log('list.value', value);
  //             value = JSON.parse(value);
  //           }
  //           payload.parameters[list.name] = value;
  //         }
  //         var response = await this.boxService.executeBoxObjectFunction(
  //           this.connection._id,
  //           this.connection.box_id,
  //           action.__id,
  //           payload
  //         );
  //         if (response.data) {
  //           console.log(name, ' RESULT FETCHED : ', response.data);
  //           node.objectData = response.data;
  //           this.customAttributeSpinner = false;
  //           node.isIconChanged = true;
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  // exploreObject(node: any){
  //   console.log("explore object", node)
  //   this.objectExplorerService.setConnectionData(this.connection)
  //   let obj = this.objects.find(o => o.name == node.name)
  //   this.objectExplorerService.parentObject.next(obj)
  //   console.log("object to open is", obj)
  //   console.log("current url", this.currentBaseUrl)
  //   this.router.navigate(['./' + obj.__id], {relativeTo: this.route});
  //   // window.open(this.currentBaseUrl + node.name, '_blank')
  // }
    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete(); // Ensures cleanup
      this.routerSubscription.unsubscribe();
    }

}
