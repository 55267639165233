import { Widget } from "./baseWidget";
import { TextFormatting } from './textFormat'

export class Label extends Widget {
  //widget type
  type = 'label';
  gridX = 6
  minGridX = 3

  //widget specific properties
  config = {
    props: [
      "prefixText",
      "alignment",
    ],
    prefixText: {
      displayName: "Prefix Text",
      value: "",
      type: 'text',
      defaultValue: ''
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      resetValue: 'flex-start',
      defaultValue: 'flex-start',
      type: 'app-alignment-settings'
    },
  };

  //text formatting properties
  // textFormat = JSON.parse(JSON.stringify(textFormatting));
  textFormat = undefined;

  constructor(id?: number, name?: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['value'].resetValue = '';
    this.config['value'].value = 'Click to customize this text as you need, adjust the styles and more';
    this.config['value'].type = 'longtext';
    this.config['value'].displayName = 'Label Text';
    this.config['defaultValue'].value = '';

    // console.log("in constructor of :", this.type, "-- before init style--", JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    // console.log("attached styles", JSON.parse(JSON.stringify(this)))

    // add widget specific text formatting if any

  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}

